import { Img } from "components";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setAdditionalDocBase64,
  setAdditionalDocs,
  setAllDocs,
  setDocs,
  setKycDocs,
  setRequiredDocs,
} from "redux/features/CreateOrder";
import { AxiosRequestConfig } from "axios";
import {
  GetDocumentMappingRequestType,
  GetKYCDocsRequestType,
  getDocumentMapping,
  getKYCDocs,
} from "service/apis/CreateOrderapi";
import { ChangeEvent, useEffect, useState } from "react";
import { Plus } from "lucide-react";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import { toast } from "sonner";

type documenttype = {
  doc_list_id: string;
  id: number;
  doc_name: string;
};

const Step7 = () => {
  const [documents, setDocuments] = useState<documenttype[]>([]);
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [uploadedDocs, setUploadedDocs] = useState<number>(0);
  const [uploadedIndexes, setUploadedIndexes] = useState<Set<number>>(
    new Set(),
  );
  const dispatch = useAppDispatch();
  const { service_partner_id, purpose_id } = useAppSelector(
    (state) => state.CreateOrder,
  );

  useEffect(() => {
    fetchDocs();
  }, []);

  const fetchDocs = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetDocumentMappingRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: purpose_id || 3,
        service_provider: 15,
        // service_partner_id.toString() === "8" ? "" : service_partner_id,
      },
    };

    getDocumentMapping(payload)
      .then((res) => {
        setDocuments(res.data);
        dispatch(setRequiredDocs(res.data.length));
      })
      .catch((_err) => {});
  };

  const handleFileUpload = (_e: any, id: number) => {
    // Check if a file already exists at the current index
    const isFileAlreadyUploaded = uploadedIndexes.has(id);

    // If a file doesn't already exist, increase the uploadedDocs count
    if (!isFileAlreadyUploaded) {
      setUploadedDocs((prev) => prev + 1);
      // Add the index to the set of uploaded indexes
      setUploadedIndexes((prevIndexes) => new Set([...prevIndexes, id]));
    }
  };

  return (
    <div className="w-full flex flex-col justify-between gap-8 rounded-lg">
      <KycDocComponent />
      <OtherDocumentsComponent
        uploadedDocs={uploadedDocs}
        Documents={documents}
        handleFileUpload={handleFileUpload}
        files={files}
        setFiles={setFiles}
      />
      <AdditionalDocumentsComponent />
    </div>
  );
};

const KycDocComponent = () => {
  const [documents, setDocuments] = useState([]);
  const customer_id = useAppSelector((state) => state.CreateOrder.customers_id);
  const [kyCDocTimeStamp, setKYCDocTimeStamp] = useState<any>("");
  const { kyc_docs, allDocs, isNameMismatch } = useAppSelector(
    (state) => state.CreateOrder,
  );
  const dispatch = useAppDispatch();

  const fetchKycDocs = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetKYCDocsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        customers_id: customer_id,
      },
    };

    getKYCDocs(payload)
      .then((res) => {
        setKYCDocTimeStamp(res?.data);
        setDocuments(res.data?.customer_kyc);
      })
      .catch((_err) => {});
  };

  useEffect(() => {
    fetchKycDocs();
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-2 items-center text-gray-500">
          <div className="">KYC Documents</div>
        </div>
      </div>
      {isNameMismatch && (
        <div className="flex gap-2 items-center rounded-lg bg-[#F2F7FF] py-1 px-[7px] text-sm leading-[18.2px] text-[#666666] font-normal">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.90625 0.09375C5.54033 0.09375 4.20507 0.498795 3.06935 1.25766C1.93362 2.01653 1.04843 3.09514 0.52571 4.35709C0.00299237 5.61904 -0.133774 7.00766 0.132705 8.34734C0.399184 9.68702 1.05694 10.9176 2.0228 11.8835C2.98865 12.8493 4.21923 13.5071 5.55891 13.7735C6.89859 14.04 8.28721 13.9033 9.54916 13.3805C10.8111 12.8578 11.8897 11.9726 12.6486 10.8369C13.4075 9.70118 13.8125 8.36593 13.8125 7C13.8106 5.16894 13.0823 3.41343 11.7876 2.11868C10.4928 0.823925 8.73731 0.0956836 6.90625 0.09375ZM6.64063 3.28125C6.79823 3.28125 6.9523 3.32799 7.08335 3.41555C7.21439 3.50311 7.31653 3.62756 7.37684 3.77317C7.43716 3.91878 7.45294 4.07901 7.42219 4.23359C7.39144 4.38817 7.31555 4.53016 7.2041 4.6416C7.09266 4.75305 6.95067 4.82894 6.79609 4.85969C6.64151 4.89044 6.48129 4.87466 6.33568 4.81434C6.19007 4.75403 6.06561 4.65189 5.97805 4.52084C5.89049 4.3898 5.84375 4.23573 5.84375 4.07812C5.84375 3.86678 5.92771 3.66409 6.07715 3.51465C6.2266 3.36521 6.42928 3.28125 6.64063 3.28125ZM7.4375 10.7188C7.15571 10.7188 6.88546 10.6068 6.6862 10.4076C6.48694 10.2083 6.375 9.93804 6.375 9.65625V7C6.23411 7 6.09898 6.94403 5.99935 6.8444C5.89972 6.74477 5.84375 6.60965 5.84375 6.46875C5.84375 6.32785 5.89972 6.19273 5.99935 6.0931C6.09898 5.99347 6.23411 5.9375 6.375 5.9375C6.6568 5.9375 6.92705 6.04944 7.1263 6.2487C7.32556 6.44796 7.4375 6.71821 7.4375 7V9.65625C7.5784 9.65625 7.71353 9.71222 7.81315 9.81185C7.91278 9.91148 7.96875 10.0466 7.96875 10.1875C7.96875 10.3284 7.91278 10.4635 7.81315 10.5632C7.71353 10.6628 7.5784 10.7188 7.4375 10.7188Z"
              fill="#165FE3"
            />
          </svg>
          <p className="">
            This customer has different name on PAN and Aadhaar. Would you like
            to upload any additional documents as supporting proof?
          </p>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        {documents.map((data: any, index) => {
          return (
            <div
              key={index.toFixed(2)}
              className="flex gap-2 flex-col border-[#FFFFFF]"
            >
              <div
                className={`p-4 justify-between rounded-md items-center flex  bg-[#FAFAFA] ${kyc_docs.find((el) => el.id === data?.doc_id)?.file?.name && "bg-[#F2F7FF]"}`}
              >
                <div className="flex flex-col overflow-hidden">
                  <h3 className="text-base font-medium text-gray-800">
                    {data.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    Updated on{" "}
                    {kyCDocTimeStamp?.customer_reg_at &&
                      new Date(kyCDocTimeStamp.customer_reg_at)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                        .replace(",", "")
                        .replace(" at", "")}
                  </p>
                </div>

                <div className="flex gap-4 items-center">
                  {data?.data?.map((doc: any, index: number) => {
                    return (
                      <a
                        key={index.toFixed(2)}
                        href={doc?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline underline-offset-2 hover:underline text-sm mr-6"
                      >
                        View
                      </a>
                    );
                  })}
                  {/* <label
                    htmlFor={data.doc_id}
                    className="text-blue-700 border cursor-pointer border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                  >
                    Re-upload
                  </label> */}

                  {/* <input
                    type="file"
                    id={data.doc_id}
                    className="hidden"
                    onChange={(e) => {
                      dispatch(
                        setKycDocs([
                          ...kyc_docs,
                          {
                            id: data.doc_id,
                            file: e.target.files?.[0] || null,
                          },
                        ]),
                      );
                      convertFileToBase64(e.target.files?.[0] as File).then(
                        (base64) => {
                          const existingDocIndex = allDocs.findIndex(
                            (el) => el.doc_id === Number(data.doc_id),
                          );
                          if (existingDocIndex !== -1) {
                            // Overwrite the existing document
                            const updatedDocs = [...allDocs];
                            updatedDocs[existingDocIndex] = {
                              doc: base64,
                              doc_name: data.name,
                              doc_id: Number(data.doc_id),
                              reupload: true,
                            };
                            dispatch(setAllDocs(updatedDocs));
                          } else {
                            // Add the new document
                            dispatch(
                              setAllDocs([
                                ...allDocs,
                                {
                                  doc: base64,
                                  doc_name: data.name,
                                  doc_id: Number(data.doc_id),
                                  reupload: true,
                                },
                              ]),
                            );
                          }
                        },
                      );
                    }}
                    accept="image/*,application/pdf"
                  /> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const OtherDocumentsComponent = ({
  Documents,
  files,
  uploadedDocs,
  handleFileUpload,
  setFiles,
}: {
  Documents: documenttype[];
  files: { [key: string]: File };
  uploadedDocs: number;
  handleFileUpload: (e: any, id: number) => void;
  setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File }>>;
}) => {
  const dispatch = useAppDispatch();
  const { allDocs } = useAppSelector((state) => state.CreateOrder);

  return (
    <>
      <div className="flex justify-between p-2">
        <div className="flex gap-2 items-center text-gray-500">
          {/* <div className="">
          <Img src="/images/remit/documentation.svg" alt="" />
        </div> */}
          <div className="">Others Documents</div>
        </div>
        <small className="">
          {uploadedDocs}/{Documents.length} uploaded
        </small>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6">
        {Documents.map(({ id, doc_name, doc_list_id }) => {
          return (
            <div key={id} className="flex gap-2 flex-col">
              <div className="flex justify-between flex-row">
                <h3>{doc_name}</h3>
                {files[doc_name]?.name && (
                  <a
                    href={URL.createObjectURL(files[doc_name])}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#165FE3] underline underline-offset-2"
                  >
                    View
                  </a>
                )}
              </div>

              <div
                className={`py-1.5 px-3.5 justify-between rounded-md flex border ${files[doc_name] && "bg-[#F2F7FF]"}`}
              >
                <div className="flex gap-3 items-center overflow-hidden">
                  <Img src="/images/remit/file.svg" alt="" />
                  <p className="truncate">
                    {files[doc_name] ? files[doc_name].name : "No file chosen"}
                  </p>
                </div>
                <label
                  htmlFor={doc_name}
                  className="text-blue-700 border cursor-pointer border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                >
                  {files[doc_name] ? "Re-upload" : "Upload"}
                </label>
                <input
                  type="file"
                  id={doc_name}
                  className="hidden"
                  // skipcq: JS-0417
                  onChange={(e) => {
                    dispatch(
                      setDocs({ filename: id, file: e.target.files?.[0] }),
                    );
                    handleFileUpload(e.target.files?.[0], id);
                    setFiles((prev) => ({
                      ...prev,
                      [doc_name]: e.target.files?.[0] as File,
                    }));
                    convertFileToBase64(e.target.files?.[0] as File).then(
                      (base64) => {
                        const existingDocIndex = allDocs.findIndex(
                          (el) => el.doc_name === doc_name,
                        );
                        if (existingDocIndex !== -1) {
                          // Overwrite the existing document
                          const updatedDocs = [...allDocs];
                          updatedDocs[existingDocIndex] = {
                            doc: base64,
                            doc_name: doc_name,
                            doc_id: Number(doc_list_id),
                            reupload: false,
                          };
                          dispatch(setAllDocs(updatedDocs));
                        } else {
                          // Add the new document
                          dispatch(
                            setAllDocs([
                              ...allDocs,
                              {
                                doc: base64,
                                doc_name: doc_name,
                                doc_id: Number(doc_list_id),
                                reupload: false,
                              },
                            ]),
                          );
                        }
                      },
                    );
                  }}
                  accept="image/*,application/pdf"
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const AdditionalDocumentsComponent = () => {
  const [newDoc, setNewDoc] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { additional_docs: documents, allDocs } = useAppSelector(
    (state) => state.CreateOrder,
  );

  const handleAddDocument = () => {
    if (newDoc.trim() === "") return;
    if (documents.find((val) => val.id === newDoc)) {
      toast.error("Document already added");
      return;
    }
    // Add the new document to the redux store
    dispatch(setAdditionalDocs([...documents, { id: newDoc, file: null }]));
    setNewDoc("");
  };

  const handleRemoveDocument = (id: string) => {
    // Remove the document from the redux store
    dispatch(setAdditionalDocs(documents.filter((doc) => doc.id !== id)));
    dispatch(setAdditionalDocBase64({ id, base64: "" }));
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const file = e.target.files?.[0];
    if (!file) return;
    // Update the file in the redux store
    dispatch(
      setAdditionalDocs(
        documents.map((doc) => (doc.id === id ? { ...doc, file } : doc)),
      ),
    );
    // Convert the file to base64
    convertFileToBase64(file).then((base64) => {
      dispatch(setAdditionalDocBase64({ id, base64 }));
      const existingDocIndex = allDocs.findIndex(
        (el) => el.doc_name === file.name,
      );
      if (existingDocIndex !== -1) {
        // Overwrite the existing document
        const updatedDocs = [...allDocs];
        updatedDocs[existingDocIndex] = {
          doc: base64,
          doc_name: file.name,
          doc_id: 0,
          reupload: false,
        };
        dispatch(setAllDocs(updatedDocs));
      } else {
        // Add the new document
        dispatch(
          setAllDocs([
            ...allDocs,
            {
              doc: base64,
              doc_name: file.name,
              doc_id: 0,
              reupload: false,
            },
          ]),
        );
      }
    });
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex justify-between cursor-pointer items-center w-full px-4 py-1 rounded-md text-white font-medium transition-all duration-300 ${
          !isOpen ? "text-blue-700" : "text-gray-500"
        }`}
      >
        <div
          className={`flex justify-between items-center font-bold  ${!isOpen && "hover:underline"}`}
        >
          {!isOpen && <Plus size={15} />} Additional Documents
        </div>

        {isOpen && (
          <small className="text-gray-500">{documents.length} uploaded</small>
        )}
      </button>

      {isOpen && (
        <div className="mt-2">
          <div className="flex justify-between items-center mb-3 w-[400px] md:w-[300px]">
            <h1 className="text-lg font-medium text-gray-800">
              Name of Document
            </h1>
            <button
              className="text-blue-600 hover:underline"
              onClick={() => {
                setIsOpen(!isOpen);
                setNewDoc("");
                dispatch(setAdditionalDocs([]));
                dispatch(setAdditionalDocBase64({}));
              }}
            >
              Cancel
            </button>
          </div>

          <div className="flex mb-4 border border-black rounded-md overflow-hidden w-[400px] md:w-[300px]">
            <input
              type="text"
              className="w-full px-3 py-2.5 focus:outline-none border-none h-11"
              placeholder="Enter document name"
              value={newDoc}
              onChange={(e) => setNewDoc(e.target.value)}
            />
            <button
              onClick={handleAddDocument}
              className="text-blue-600 p-2 flex items-center justify-center"
            >
              <Plus size={20} />
            </button>
          </div>

          <div className="grid grid-cols-1 gap-4">
            {documents.map(({ id, file }) => (
              <div
                key={id}
                className="p-3 flex items-center justify-between bg-[#FAFAFA] rounded-md"
              >
                <div className="flex items-center gap-3">
                  {file?.name ? (
                    <img
                      src="/images/login/green-tick.svg"
                      alt="not completed"
                      className="w-5 h-5"
                    />
                  ) : (
                    <img
                      src="/images/remit/grayTick.svg"
                      alt="completed"
                      className="w-5 h-5"
                    />
                  )}
                  <div>
                    <h3 className="text-sm font-medium text-gray-800">
                      {id.toUpperCase()} - {file?.name ?? "No file choosen"}
                    </h3>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  {file?.name && (
                    <a
                      href={URL.createObjectURL(file)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline underline-offset-2 text-sm"
                    >
                      View
                    </a>
                  )}
                  <label
                    htmlFor={id}
                    className="text-blue-700 border border-blue-700 cursor-pointer px-3 py-1.5 rounded-lg text-sm"
                  >
                    {file?.name ? "Re-upload" : "Upload"}
                  </label>
                  <input
                    type="file"
                    id={id}
                    className="hidden"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      handleChangeFile(e, id);
                    }}
                  />

                  {/* Remove button */}
                  <button onClick={() => handleRemoveDocument(id)} className="">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.75705 4.86316L6.44084 16.3562C6.45114 16.5275 6.52641 16.6884 6.65129 16.8061C6.77617 16.9238 6.94125 16.9894 7.11284 16.9895H13.2029C13.3745 16.9894 13.5396 16.9238 13.6645 16.8061C13.7894 16.6884 13.8647 16.5275 13.8749 16.3562L14.5587 4.86316H5.75705ZM15.5718 4.86316L14.8838 16.416C14.8582 16.8445 14.67 17.2471 14.3576 17.5415C14.0452 17.8359 13.6322 17.9999 13.2029 18H7.11284C6.68358 17.9999 6.27055 17.8359 5.95814 17.5415C5.64574 17.2471 5.45754 16.8445 5.432 16.416L4.744 4.86316H3V4.27368C3 4.16201 3.04436 4.05492 3.12332 3.97596C3.20229 3.89699 3.30938 3.85263 3.42105 3.85263H16.8947C17.0064 3.85263 17.1135 3.89699 17.1925 3.97596C17.2714 4.05492 17.3158 4.16201 17.3158 4.27368V4.86316H15.5718ZM11.8421 2C11.9538 2 12.0609 2.04436 12.1398 2.12332C12.2188 2.20229 12.2632 2.30938 12.2632 2.42105V3.01053H8.05263V2.42105C8.05263 2.30938 8.09699 2.20229 8.17595 2.12332C8.25492 2.04436 8.36201 2 8.47368 2H11.8421ZM8.05263 7.05263H9.06316L9.48421 14.6316H8.47368L8.05263 7.05263ZM11.2526 7.05263H12.2632L11.8421 14.6316H10.8316L11.2526 7.05263Z"
                        fill="#FF0000"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Step7;
