import { AxiosRequestConfig } from "axios";
import { Img, Spinner } from "components";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { postExistingOpportunity } from "service/apis/Opportunities";
import { toast } from "sonner";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setIsProductLoan: React.Dispatch<React.SetStateAction<string>>;
  isProductLoan: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  handlePrevious: () => void;
  processState: any;
}

interface PayloadDataType {
  contact_id: string;
  typeOfRefferal: string;
  refferedBy_staff: string;
  refferedBy_contacts: string;
  refferedBy_partner?: string;
  pipelineType: string;
  forex_branch: string;
  outlet_branch: string;
  exp_time: string;
  quotation_req: boolean;
  took_loan: boolean;
  mode: string;
  channel_of_interaction_id: string;
  doc_quote: Array<{
    doc_list_id: number;
    file: string;
  }>;
  items: Array<{
    subject: string;
    remarks: string;
    notes: string;
    assignedTo: string;
    product_id: string;
  }>;
}

export const L2Step4: React.FC<Step2Props> = ({
  setCurrentStep,
  setPageTitle,
  processState,
  handlePrevious,
  setProcessState,
  setIsProductLoan,
}) => {
  const { state }: any = useLocation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const {
    product_purpose,
    remit_note,
    outlet_id,
    moneeflo_branch,
    medium_of_contact,
    partner_reffered,
    employee_reffered,
    contact_reffered,
  } = useAppSelector((state) => state.CreateOpportunity);

  const [offerLetterFile, setOfferLetterPath] = useState<File | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageTitle("Offer Letter Upload");
  }, [setPageTitle]);

  // final lead create
  const handleSubmit = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    let base64 = "";
    if (offerLetterFile) {
      base64 = await convertFileToBase64(offerLetterFile as File);
    }

    const payloadData: PayloadDataType = {
      contact_id: state?.id as string,
      typeOfRefferal: "partner",
      refferedBy_staff: employee_reffered,
      refferedBy_contacts: contact_reffered,
      refferedBy_partner:
        medium_of_contact === "refferal" ? partner_reffered : "",
      pipelineType: "retail",
      forex_branch: moneeflo_branch,
      outlet_branch: outlet_id,
      exp_time: "1",
      quotation_req: true,
      took_loan: !!processState["Education Loan"],
      mode: processState?.Mode ?? "",
      channel_of_interaction_id: "12",
      doc_quote: [
        {
          doc_list_id: processState?.Prodcut === "Education Payment" ? 5 : 13,
          file: base64,
        },
      ],
      items: [
        {
          subject: "",
          remarks: "",
          notes: remit_note,
          assignedTo: "",
          product_id: product_purpose,
        },
      ],
    };

    const payload: AxiosRequestConfig<PayloadDataType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      data: payloadData,
    };

    try {
      const res: any = await postExistingOpportunity(payload);
      if (res.status === 200) {
        setLoading(false);
        if (
          (res.data?.throwError as any)?.toLowerCase() ===
          "contact already exists"
        ) {
          toast.error("Contact already exists");
          return;
        }
        navigate(`/leads/contact/${res?.data?.response?.contacts_id}`, {
          state: {
            leadID: res?.data?.response?.id,
            requestQuote: true,
          },
        });
        toast.success("Request sent for quotation");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create Lead");
    }
  };

  const handleCheck = (e: any) => {
    setIsChecked(e.target.checked);
    setPageTitle("Select Country");
    setCurrentStep((prev) => prev + 1);
    setProcessState((prevState: any) => ({
      ...prevState,
      "Offer letter": "N/A",
    }));
  };

  return (
    <>
      {loading && <Spinner />}
      <form className="w-full">
        <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex flex-col gap-4 w-[450px]">
            <label className="text-[14px] font-medium text-[#000]">
              Offer Letter
            </label>

            {/* offer letter upload input */}
            <div className="flex gap-2 flex-col ">
              <div
                className={`py-1.5 px-3.5 justify-between rounded-md flex border ${offerLetterFile ? "bg-[#F2F7FF]" : "bg-[#fff]"}`}
              >
                <div className="flex gap-3 items-center overflow-hidden">
                  <Img src="/images/remit/file.svg" alt="" />
                  <p className="truncate">
                    {offerLetterFile?.name || "Upload offer letter"}
                  </p>
                </div>
                <label
                  htmlFor="offerLetterInput"
                  className="text-blue-700 border cursor-pointer border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                >
                  Upload
                </label>
                <input
                  id="offerLetterInput"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    const selectedFile = e.target.files?.[0];
                    if (selectedFile) {
                      if (selectedFile.type === "application/pdf") {
                        setOfferLetterPath(selectedFile);
                        setProcessState((prevState: any) => ({
                          ...prevState,
                          "Offer letter": "Yes",
                        }));
                      } else {
                        toast.error("Only PDF files are allowed.");
                      }
                    }
                  }}
                  accept="application/pdf"
                />
              </div>
            </div>

            {/* check box */}
            <div className="flex items-center gap-2 ">
              <input
                type="checkbox"
                id="tcsConfirmation"
                className="w-4 h-4 cursor-pointer"
                style={{ borderRadius: "5px" }}
                checked={isChecked}
                onChange={(e) => handleCheck(e)}
              />
              <label htmlFor="offerletterConfirm" className="text-sm">
                I do not have the offer letter.
              </label>
            </div>

            {/* info  */}
            <div className=" flex flex-col py-5 px-8 gap-8 border border-white-A700 rounded-xl bg-[#F9F9F9]">
              <p className=" flex gap-2 font-normal text-sm leading-[14px] text-[#5e5e5e]">
                <Img src="/images/overview/exclamation.svg" />
                <span>You wil get the quote in 10-15 minutes</span>
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-8">
            <button
              className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
              onClick={() => {
                if (processState?.Mode === "ViaTransfer") {
                  setCurrentStep(2);
                } else {
                  handlePrevious();
                }
                setPageTitle("Education Loan");
                setProcessState((prevState: any) => ({
                  ...prevState,
                  "Education Loan": "",
                }));
                setIsProductLoan("");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>

            <button
              type="button"
              className={`w-[150px] text-[14px] text-[#fff] p-3 ${
                !offerLetterFile && !isChecked ? "bg-blue-200" : "bg-[#165FE3]"
              } rounded-lg text-center`}
              disabled={!offerLetterFile && !isChecked}
              onClick={(e) => {
                e.preventDefault();
                if (!isChecked && !offerLetterFile) {
                  toast.error(
                    "Please upload the offer letter or check the confirmation box.",
                  );
                  return;
                }
                handleSubmit();
              }}
            >
              Request Quote
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
