"use client";

import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { PenLine } from "lucide-react";
import { AxiosRequestConfig } from "axios";
import {
  generateChallan,
  GenerateChallanRequestType,
} from "service/apis/CreateOrderapi";
import { toast } from "sonner";
import { Spinner } from "components";

interface BankDetails {
  bank_acc_No: string;
  bank_ifsc_code: string;
  Branch_Name: string;
  Bank_name: string;
  branch_address: string;
  Name_on_acc: string;
  id: string;
}

const bankOptions = [
  {
    name: "State Bank of India",
    details: {
      accountNumber: "238827329873283",
      ifsc: "SBIN001828",
      branch: "R.K. Puram New Delhi",
    },
  },
  {
    name: "HDFC Bank",
    details: {
      accountNumber: "123456789012345",
      ifsc: "HDFC000123",
      branch: "Connaught Place",
    },
  },
];

interface IProps {
  children: React.ReactNode;
  service_partner_banks: any[];
  setTcsEditing: (value: boolean) => void;
  amountDetails: any;
  order_id: string;
  refechOrderdetails: () => void;
}
export default function ChallanDialog({
  children,
  refechOrderdetails,
  service_partner_banks,
  setTcsEditing,
  amountDetails,
  order_id,
}: IProps) {
  const [open, setOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState<BankDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleBankChange = (id: string) => {
    const bank = service_partner_banks?.find((b) => b?.id?.toString() === id);
    if (bank) {
      setSelectedBank(bank);
    }
  };

  const handleGenerateChallan = async () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GenerateChallanRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        order_id,
        data: {
          orderId: order_id,
          generatedDate: new Date()
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
            .split("/")
            .join("-"),
          beneficiary: selectedBank?.Name_on_acc,
          accountNumber: selectedBank?.bank_acc_No,
          ifsc: selectedBank?.bank_ifsc_code,
          bankName: selectedBank?.Bank_name,
          branch: selectedBank?.Branch_Name,
          dueDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
            .replace(/\//g, "-"),
          amount: amountDetails?.totalAmountPayable,
        },
      },
    };
    setIsLoading(true);
    try {
      const response = await generateChallan(payload);
      if (response.status === 200) {
        window.open(response?.data as unknown as string, "_blank");
        refechOrderdetails();
        toast.success("Challan generated successfully");
        setOpen(false);
      }
    } catch (error) {
      console.error("Error generating challan:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <AlertDialog
        onOpenChange={(val) => {
          if (val) {
            setSelectedBank(null);
          }
        }}
      >
        <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
        <AlertDialogPortal>
          <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
          <AlertDialogContent className="max-w-4xl p-0 bg-white-A700 rounded-lg">
            <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 border-b ">
              <AlertDialogTitle>Generate Challan</AlertDialogTitle>
            </AlertDialogHeader>
            <div className="space-y-6 px-8 py-6 max-h-[550px] overflow-auto ">
              <div className="space-y-2 max-w-sm">
                <label className="text-sm font-medium">Bank</label>
                <Select
                  onValueChange={handleBankChange}
                  style={{ backgroundCOlor: "#FAFAFA" }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select bank" />
                  </SelectTrigger>
                  <SelectContent>
                    {service_partner_banks?.map((bank) => (
                      <SelectItem key={bank?.id} value={bank?.id?.toString()}>
                        {bank?.Bank_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {true && (
                <>
                  <div className="space-y-4 bg-[#FAFAFA] p-4 rounded-lg">
                    <div
                      className="grid gap-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <label className="text-sm text-muted-foreground">
                        A/C number :
                      </label>
                      <div className="text-base">
                        {selectedBank?.bank_acc_No}
                      </div>
                    </div>
                    <div
                      className="grid gap-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <label className="text-sm text-muted-foreground">
                        IFSC :
                      </label>
                      <div className="text-base">
                        {selectedBank?.bank_ifsc_code?.trim() ? (
                          <>
                            {selectedBank.bank_ifsc_code}{" "}
                            <span className="text-muted-foreground">
                              {selectedBank.Branch_Name?.trim()}
                            </span>
                          </>
                        ) : (
                          <span className="text-muted-foreground">N/A</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4 bg-[#FAFAFA] p-4 rounded-lg">
                    <div className="flex justify-between items-center">
                      <span>FX Amount</span>
                      <div className="flex gap-2">
                        <span className="bg-gray-200 border-solid rounded-lg text-black-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.fxAmount || 0}
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <span>Total Charges</span>
                      <div className="flex gap-2">
                        <span className="bg-gray-200 border-solid rounded-lg text-black-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.totalCharges || 0}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>GST on FX conversion</span>
                      <div className="flex gap-2">
                        <span className="bg-gray-200 border-solid rounded-lg text-black-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.gstOnFx || 0}
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <span>TCS</span>
                        <span className="text-xs text-muted-foreground">
                          (incl. GST)
                        </span>
                      </div>
                      <div className="flex gap-2 items-center">
                        <span className="bg-gray-200 border-solid rounded-lg text-black-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.tcs || 0}
                        <button
                          className=""
                          onClick={() => setTcsEditing(true)}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.5 4.32812H2.66667C2.22464 4.32813 1.80072 4.50372 1.48816 4.81628C1.17559 5.12884 1 5.55276 1 5.99479V13.4948C1 13.9368 1.17559 14.3607 1.48816 14.6733C1.80072 14.9859 2.22464 15.1615 2.66667 15.1615H10.1667C10.6087 15.1615 11.0326 14.9859 11.3452 14.6733C11.6577 14.3607 11.8333 13.9368 11.8333 13.4948V12.6615"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.9974 2.66676L13.4974 5.16676M14.6516 3.98759C14.9798 3.65938 15.1642 3.21424 15.1642 2.75009C15.1642 2.28594 14.9798 1.84079 14.6516 1.51259C14.3234 1.18438 13.8782 1 13.4141 1C12.9499 1 12.5048 1.18438 12.1766 1.51259L5.16406 8.50009V11.0001H7.66406L14.6516 3.98759Z"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <span>TT charges</span>
                        <span className="text-xs text-muted-foreground">
                          (incl. GST)
                        </span>
                      </div>
                      <div className="flex gap-2">
                        <span className="bg-gray-200 border-solid rounded-lg text-black-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.tt_charges || 0}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Discount %</span>
                      <div className="flex gap-2">
                        <span className="bg-gray-200 border-solid rounded-lg text-black-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.discount || 0}
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <span>Foreign Bank charges</span>
                        <span className="text-xs text-muted-foreground">
                          (incl. GST)
                        </span>
                      </div>
                      <div className="flex gap-2">
                        <span className="bg-gray-200 border-solid rounded-lg text-black-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.foreign_bank_charges || 0}
                      </div>
                    </div>

                    <div className="flex justify-between items-center pt-4 border-t">
                      <span className="font-medium text-blue-600">
                        Total Amount Payable
                      </span>
                      <div className="flex gap-2 text-blue-600 font-medium">
                        <span className="bg-[#E1EBFC] border-solid rounded-lg text-blue-500 text-[10px] p-1">
                          INR
                        </span>
                        {amountDetails?.totalAmountPayable || 0}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
              <AlertDialogCancel className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                Close
              </AlertDialogCancel>
              <AlertDialogAction
                className="rounded-lg border-blue-700 disabled:opacity-40 py-[11px] px-4 bg-blue-700 border text-white-A700"
                onClick={handleGenerateChallan}
                disabled={
                  !selectedBank?.bank_acc_No || !selectedBank?.bank_ifsc_code
                }
              >
                Generate Challan
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </>
  );
}
