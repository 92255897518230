import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosRequestConfig } from "axios";
import { Option } from "pages/Opportunities/types";
import {
  channelOptionInbound,
  channelOptionOutbound,
} from "pages/Opportunities/utils/featureRule";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReactSelect, { components, MenuProps } from "react-select";
import {
  setContactReffered,
  setEmployeeReffered,
  setMediumOfContact,
  setMeetingType,
  setOutletId,
  setPartnerName,
  setPartnerReffered,
  setRefferalSource,
  setRemitNote,
  setWhoContacted,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getChannel, GetChannelRequestType } from "service/api";
import {
  getBranchList,
  GetBranchRegistrationRequestType,
} from "service/apis/BranchRegistration";
import {
  getContactsForReferral,
  GetContactsForReferralReqType,
  getContactsList,
  GetContactsListReqType,
} from "service/apis/Opportunities";
import QuickRegis, {
  quickRegisSchema,
} from "../../Create Opportunities/popup/QuickRegis";
interface ContactDetailsProps {
  errors: Record<string, string>;
  handlePrevious: () => void;
}

interface OptionsExtended {
  value: string;
  label: string;
  location: string;
}

export default function OtherDetails({}: ContactDetailsProps) {
  const [partnerOptions, setPartnerOptions] = useState<Option[] | null>([]);
  const [employeeOptions, setEmployeeOptions] = useState<Option[] | null>([]);
  const [contactOptions, setContactOptions] = useState<
    OptionsExtended[] | null
  >([]);
  const [branchList, setBranchList] = useState<OptionsExtended[] | null>([]);
  const methods = useForm({
    mode: "onChange",
    resolver: zodResolver(quickRegisSchema),
  });
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);

  const dispatch = useAppDispatch();
  const {
    medium_of_contact,
    outlet_id,
    who_contacted,
    meeting_type,
    employee_reffered,
    partner_reffered,
    contact_reffered,
    refferal_source,
    remit_note,
  } = useAppSelector((state) => state.CreateOpportunity);

  const { isReferralMade } = useAppSelector(
    (state) => state.CustomerRegistration,
  );

  const fetchPartner = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return undefined;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 100,
      },
    };
    try {
      const res = (await getChannel(payload)) as any;
      if (res?.data?.items?.length === 0) {
        return undefined;
      }
      const partner = res?.data?.items.filter(
        (item: any) =>
          (item?.status === "active" || item?.status === "pending") &&
          item?.approval_status === "approved",
      );
      const options = partner?.map((item: any) => ({
        value: item?.id,
        label: item?.name,
      }));
      return options;
    } catch (err) {
      console.error(err);
    }
  };

  const getStaffOrPartnerData = async () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    // Get logged in user's email from localStorage
    const myself = localStorage.getItem("staff_id");
    const req: AxiosRequestConfig<GetContactsForReferralReqType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        typeOfContact: "staff",
      },
    };
    try {
      const res = (await getContactsForReferral(req)) as any;
      // Find the logged-in user by matching email
      const options = res?.data?.map((item: { id: string; fname: string }) => ({
        value: item.id,
        label: item.fname,
      })) as Option[];

      const index = options.findIndex((item) => item?.value == myself);
      if (index > 0) {
        const item = options.splice(index, 1)[0];
        options.unshift(item);
      }
      return options;
    } catch (err) {}
  };

  const fetchStaffBranches = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetBranchRegistrationRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        type: "",
      },
    };

    try {
      const res = await getBranchList(payload);
      if (res.status === 200) {
        const branches = res.data;
        const filterActiveBranches = branches.filter(
          (branch: any) => branch.status === "active",
        );
        const options = filterActiveBranches.map((branch) => ({
          value: branch?.id?.toString(),
          label: branch.name,
          location: branch.city,
        }));
        dispatch(setOutletId(options[0]?.value as string));
        setBranchList(options as OptionsExtended[]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchContact = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const req: AxiosRequestConfig<GetContactsListReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    try {
      const res = (await getContactsList(req)) as any;
      const contacts = res?.data?.map(
        (item: { id: string; contactName: string; contactNumber: string }) => ({
          value: item.id,
          label: item.contactName,
          location: item.contactNumber,
        }),
      );
      setContactOptions(contacts as OptionsExtended[]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (who_contacted === "customer") fetchStaffBranches();
  }, [who_contacted]);

  useEffect(() => {
    if (refferal_source === "contacts") {
      fetchContact();
    }
  }, []);

  useEffect(() => {
    if (!open)
      fetchPartner().then((options) => {
        if (options) {
          setPartnerOptions(options as Option[]);
          dispatch(setPartnerReffered(options[0].value));
          dispatch(setPartnerName(options[0].label));
        }
      });
  }, [open]);

  const channel = {
    myself: channelOptionInbound,
    customer: channelOptionOutbound,
  };

  useEffect(() => {
    console.log("print isReferralMade", isReferralMade);
    if (isReferralMade) {
      dispatch(setMediumOfContact("refferal"));
      dispatch(setMeetingType("partner"));
    }
  }, [medium_of_contact, isReferralMade]);

  useEffect(() => {
    dispatch(setMediumOfContact("walk-in"));
  }, []);

  // console.log("primt medium_of_contact", medium_of_contact)

  return (
    <form className="flex flex-col gap-10" onSubmit={() => {}}>
      {open && (
        <FormProvider {...methods}>
          {open && (
            <QuickRegis open={open} setOpen={setOpen} onSubmit={() => {}} />
          )}
        </FormProvider>
      )}
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full max-w-md ">
        {!isReferralMade && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-28"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="">Select any one to proceed.</label>
            <div className="flex flex-row gap-2 items-center justify-start w-full">
              <RadioGroup
                className="flex w-full"
                value={who_contacted}
                defaultValue="myself"
                onValueChange={(e) => {
                  dispatch(setWhoContacted(e));
                  dispatch(setMediumOfContact(""));
                  if (e === "customer") {
                    dispatch(setMediumOfContact("walk-in"));
                  }
                }}
              >
                <label
                  htmlFor="customer"
                  className={`${who_contacted === "customer" ? "focus-within:border-blue-700 border-black-label" : ""} flex items-center rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value="customer"
                    id="customer"
                    className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <p>Customer contacted</p>
                </label>
                <label
                  htmlFor="myself"
                  className={`${who_contacted === "myself" ? " focus-within:border-blue-700 border-black-label" : ""} flex w-auto items-center rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value="myself"
                    id="myself"
                    className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <p className="w-full">I contacted</p>
                </label>
              </RadioGroup>
            </div>
          </div>
        )}

        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${medium_of_contact ? "after:h-24 after:border-blue-700" : "aftre:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          {!isReferralMade && (
            <label htmlFor="medium_of_conatc" className="text-sm">
              {who_contacted === "myself"
                ? "How did you contact?"
                : "How did customer contact?"}
            </label>
          )}
          <Tabs
            className="flex rounded-lg gap-2"
            value={medium_of_contact}
            onValueChange={(tab) => {
              dispatch(setMediumOfContact(tab));
              if (tab === "meeting") dispatch(setMeetingType("inPerson"));
              if (tab === "chat") dispatch(setMeetingType("whatsapp"));
              if (tab === "social media") dispatch(setMeetingType("instagram"));
              if (tab === "refferal") {
                dispatch(setMeetingType("partner"));
                fetchPartner().then((options) => {
                  if (options) {
                    setPartnerOptions(options as Option[]);
                    dispatch(setPartnerReffered(options[0].value));
                    dispatch(setPartnerName(options[0].label));
                  }
                });
              }
            }}
          >
            <TabsList className="sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
              {channel[who_contacted as keyof typeof channel]
                .filter((tab: { label: string; value: string }) => {
                  if (isReferralMade) {
                    return tab.value === "refferal";
                  }
                  return true;
                })
                .map((tab: { label: string; value: string }) => (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${tab.value === medium_of_contact ? " bg-navbar-menu-bg text-blue-700" : " text-black-label bg-[#F1F1F1]"}`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
            </TabsList>
          </Tabs>
        </div>
        {medium_of_contact === "email" && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="product_notes" className="text-sm">
              Notes
            </label>
            <textarea
              placeholder="Write note here...."
              value={remit_note}
              onChange={(e) => dispatch(setRemitNote(e.target.value))}
              className="border h-36 border-black-label rounded-lg p-4 w-full"
            />
          </div>
        )}
        {medium_of_contact &&
          (["call"].includes(medium_of_contact) ? (
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label htmlFor="product_notes" className="text-sm">
                Notes
              </label>
              <textarea
                placeholder="Write note here...."
                value={remit_note}
                onChange={(e) => dispatch(setRemitNote(e.target.value))}
                className="border h-36 border-black-label rounded-lg p-4 w-full"
              />
            </div>
          ) : medium_of_contact === "meeting" ? (
            <>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-28"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="">How did you meet?</label>
                <div className="flex flex-row gap-2 items-center justify-start w-full">
                  <RadioGroup
                    className="flex w-full"
                    value={meeting_type}
                    onValueChange={(e) => dispatch(setMeetingType(e))}
                  >
                    <label
                      htmlFor="in_person"
                      className={`${meeting_type === "inPerson" ? " focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="inPerson"
                        id="in_person"
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="w-full">In person</p>
                    </label>
                    <label
                      htmlFor="video_call"
                      className={`${meeting_type === "videoCall" ? "focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="videoCall"
                        id="video_call"
                        className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p>Video call</p>
                    </label>
                  </RadioGroup>
                </div>
              </div>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="product_notes" className="text-sm">
                  Notes
                </label>
                <textarea
                  placeholder="Write note here...."
                  value={remit_note}
                  onChange={(e) => dispatch(setRemitNote(e.target.value))}
                  className="border h-36 border-black-label rounded-lg p-4 w-full"
                />
              </div>
            </>
          ) : medium_of_contact === "walk-in" ? (
            <>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-0" : "after:h-28 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="outlet_location" className="text-sm">
                  Search and select the location.
                </label>
                <ReactSelect<OptionsExtended>
                  className="w-full"
                  placeholder="Select the partner"
                  menuPlacement="auto"
                  getOptionLabel={(option) =>
                    `${option.label} - ${option.location}`
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 0,
                      borderRadius: ".5rem",
                      padding: ".3rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: state.isFocused ? "#165FE3" : "#333333",
                      boxShadow: "none",
                      height: "3rem",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  onChange={(val) => {
                    dispatch(setOutletId(val?.value as string));
                  }}
                  value={branchList?.find((item) => item.value === outlet_id)}
                  options={branchList as OptionsExtended[]}
                  name="outlet_location"
                  id="outlet_location"
                />
              </div>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="product_notes" className="text-sm">
                  Notes
                </label>
                <textarea
                  placeholder="Write note here...."
                  value={remit_note}
                  onChange={(e) => dispatch(setRemitNote(e.target.value))}
                  className="border h-36 border-black-label rounded-lg p-4 w-full"
                />
              </div>
            </>
          ) : medium_of_contact === "social media" ? (
            <>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-28"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="">Select the Social Media Platform.</label>
                <div className="flex flex-row gap-2 items-center justify-start w-full">
                  <RadioGroup
                    className="flex w-full"
                    value={meeting_type}
                    onValueChange={(e) => dispatch(setMeetingType(e))}
                  >
                    <label
                      htmlFor="instagram"
                      className={`${meeting_type === "instagram" ? " focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="instagram"
                        id="instagram"
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="w-full">Instagram</p>
                    </label>
                    <label
                      htmlFor="facebook"
                      className={`${meeting_type === "facebook" ? "focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="facebook"
                        id="facebook"
                        className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p>Facebook</p>
                    </label>
                    <label
                      htmlFor="linkedin"
                      className={`${meeting_type === "linkedin" ? "focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="linkedin"
                        id="linkedin"
                        className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p>LinkedIn</p>
                    </label>
                  </RadioGroup>
                </div>
              </div>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="product_notes" className="text-sm">
                  Notes
                </label>
                <textarea
                  placeholder="Write note here...."
                  value={remit_note}
                  onChange={(e) => dispatch(setRemitNote(e.target.value))}
                  className="border h-36 border-black-label rounded-lg p-4 w-full"
                />
              </div>
            </>
          ) : medium_of_contact === "chat" ? (
            <>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-28"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="">How did you chat?</label>
                <div className="flex flex-row gap-2 items-center justify-start w-full">
                  <RadioGroup
                    className="flex w-full"
                    value={meeting_type}
                    onValueChange={(e) => {
                      dispatch(setMeetingType(e));
                    }}
                  >
                    <label
                      htmlFor="whatsapp"
                      className={`${meeting_type === "whatsapp" ? " focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="whatsapp"
                        id="whatsapp"
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="w-full">Whatsapp</p>
                    </label>
                    <label
                      htmlFor="other"
                      className={`${meeting_type === "other" ? "focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="other"
                        id="other"
                        className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p>Other</p>
                    </label>
                  </RadioGroup>
                </div>
              </div>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="product_notes" className="text-sm">
                  Notes
                </label>
                <textarea
                  placeholder="Write note here...."
                  value={remit_note}
                  onChange={(e) => dispatch(setRemitNote(e.target.value))}
                  className="border h-36 border-black-label rounded-lg p-4 w-full"
                />
              </div>
            </>
          ) : null)}
        {medium_of_contact === "refferal" && (
          <>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-32"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label htmlFor="">Who referred the customer?</label>
              <RadioGroup
                className="flex w-full"
                value={refferal_source}
                onValueChange={(e) => {
                  dispatch(
                    setRefferalSource(e as "partner" | "staff" | "none"),
                  );
                  if (e === "partner") {
                    setEmployeeOptions([]);
                    dispatch(setEmployeeReffered(""));
                    setContactOptions([]);
                    dispatch(setContactReffered(""));
                    fetchPartner().then((options) => {
                      if (options) {
                        setPartnerOptions(options as Option[]);
                        dispatch(setPartnerReffered(options[0].value));
                        dispatch(setPartnerName(options[0].label));
                      }
                    });
                  } else if (e === "contacts") {
                    setPartnerOptions([]);
                    dispatch(setPartnerReffered(""));
                    setEmployeeOptions([]);
                    dispatch(setEmployeeReffered(""));
                    fetchContact();
                  } else {
                    setPartnerOptions([]);
                    dispatch(setPartnerReffered(""));
                    setContactOptions([]);
                    dispatch(setContactReffered(""));
                    getStaffOrPartnerData().then((options) => {
                      if (options) {
                        setEmployeeOptions(options as Option[]);
                        dispatch(setEmployeeReffered(options[0].value));
                      }
                    });
                  }
                }}
              >
                <label
                  htmlFor="partner"
                  className={`${refferal_source === "partner" ? " focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value="partner"
                    id="partner"
                    className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <p className="w-full">Partner</p>
                </label>
                <label
                  htmlFor="staff"
                  className={`${refferal_source === "staff" ? "focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value="staff"
                    id="staff"
                    className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <p>Employee</p>
                </label>
                <label
                  htmlFor="contacts"
                  className={`${refferal_source === "contacts" ? "focus-within:border-blue-700 border-black-label" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value="contacts"
                    id="contacts"
                    className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <p>Contact</p>
                </label>
              </RadioGroup>
              <div className="flex flex-row gap-2 items-center justify-start w-full"></div>
            </div>
            {refferal_source === "partner" ? (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div className="flex sm:hidden  items-center top-0 flex-col after:right-10 after:h-28 after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="partner_referred" className="text-sm">
                  Search & Select the partner.
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the partner"
                  menuPlacement="bottom"
                  menuPosition="absolute"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 0,
                      borderRadius: "0.375rem",
                      minHeight: "40px",
                      padding: "0.25rem 0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderColor: state.isFocused ? "#3B82F6" : "#E5E7EB",
                      boxShadow: state.isFocused ? "0 0 0 1px #3B82F6" : "none",
                      "&:hover": {
                        borderColor: state.isFocused ? "#3B82F6" : "#D1D5DB",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      marginTop: "0",
                      borderRadius: "0.375rem",
                      backgroundColor: "white",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                      padding: "0",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      padding: "10px 12px",
                      backgroundColor: state.isSelected
                        ? "#EFF6FF"
                        : state.isFocused
                          ? "#3B82F6"
                          : "white",
                      color: state.isSelected ? "#2563EB" : "#374151",
                      cursor: "pointer",
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    noOptionsMessage: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                      padding: "8px 12px",
                      textAlign: "left",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }),
                  }}
                  onChange={(val) => {
                    dispatch(setPartnerReffered(val?.value ?? ""));
                    dispatch(setPartnerName(val?.label ?? ""));
                  }}
                  onInputChange={(value) => {
                    setInputValue(value);

                    const hasMatch = partnerOptions?.some((option) =>
                      option.label.toLowerCase().includes(value.toLowerCase()),
                    );

                    setShowAddButton(value.trim().length > 0 && !hasMatch);
                  }}
                  value={
                    partnerOptions?.find(
                      (option) => option.value === partner_reffered,
                    ) ?? null
                  }
                  options={partnerOptions as Option[]}
                  name="partner_referred"
                  id="partner_referred"
                  noOptionsMessage={() => "Partner not found."}
                  components={{
                    Menu: (props) => (
                      <CustomMenu
                        onClick={() => setOpen(true)}
                        showAddButton={showAddButton}
                        {...props}
                      />
                    ),
                  }}
                />
              </div>
            ) : refferal_source === "contacts" ? (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div className="flex sm:hidden  items-center top-0 flex-col after:right-10 after:h-28 after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="partner_referred" className="text-sm">
                  Search & select the contact.
                </label>
                <ReactSelect<OptionsExtended>
                  className="w-full"
                  placeholder="Search by name and phone number"
                  menuPlacement="bottom"
                  menuPosition="absolute"
                  getOptionLabel={(option) =>
                    `${option.label} - ${option.location}`
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 0,
                      borderRadius: "0.375rem",
                      minHeight: "40px",
                      padding: "0.25rem 0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderColor: state.isFocused ? "#3B82F6" : "#E5E7EB",
                      boxShadow: state.isFocused ? "0 0 0 1px #3B82F6" : "none",
                      "&:hover": {
                        borderColor: state.isFocused ? "#3B82F6" : "#D1D5DB",
                      },
                    }),

                    option: (provided, state) => ({
                      ...provided,
                      padding: "10px 12px",
                      backgroundColor: state.isSelected
                        ? "#EFF6FF"
                        : state.isFocused
                          ? "#3B82F6"
                          : "white",
                      color: state.isSelected ? "#2563EB" : "#374151",
                      cursor: "pointer",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      marginTop: "0",
                      borderRadius: "0.375rem",
                      backgroundColor: "white",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                      padding: "0",
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999, // Also set high z-index for portal
                    }),
                    noOptionsMessage: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                      padding: "8px 12px",
                      textAlign: "left",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }),
                  }}
                  onChange={(val) =>
                    dispatch(setContactReffered(val?.value ?? ""))
                  }
                  value={
                    contactOptions?.find(
                      (option) => option.value === contact_reffered,
                    ) ?? null
                  }
                  options={contactOptions as OptionsExtended[]}
                  name="contact_referred"
                  id="contact_referred"
                />
              </div>
            ) : (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div className="flex sm:hidden  items-center top-0 flex-col after:right-10 after:h-28 after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="employee_referred" className="text-sm">
                  Search & Select the employee.
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the employee"
                  menuPlacement="auto"
                  menuPosition="absolute"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 0,
                      borderRadius: "0.375rem",
                      minHeight: "40px",
                      padding: "0.25rem 0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderColor: state.isFocused ? "#3B82F6" : "#E5E7EB",
                      boxShadow: state.isFocused ? "0 0 0 1px #3B82F6" : "none",
                      "&:hover": {
                        borderColor: state.isFocused ? "#3B82F6" : "#D1D5DB",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      padding: "10px 12px",
                      backgroundColor: state.isSelected
                        ? "#EFF6FF"
                        : state.isFocused
                          ? "#3B82F6"
                          : "white",
                      color: state.isSelected ? "#2563EB" : "#374151",
                      cursor: "pointer",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  options={employeeOptions as Option[]}
                  onChange={(val) =>
                    dispatch(setEmployeeReffered(val?.value ?? ""))
                  }
                  value={
                    employeeOptions?.find(
                      (option) => option.value === employee_reffered,
                    ) ?? null
                  }
                  name="employee_referred"
                  id="employee_referred"
                />
              </div>
            )}
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label htmlFor="product_notes" className="text-sm">
                Notes
              </label>
              <textarea
                placeholder="Write note here...."
                value={remit_note}
                onChange={(e) => dispatch(setRemitNote(e.target.value))}
                className="border h-36 border-black-label rounded-lg p-4 w-full"
              />
            </div>
          </>
        )}
      </div>
    </form>
  );
}

interface CustomMenuProps extends MenuProps<any, false> {
  showAddButton: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const CustomMenu: React.FC<CustomMenuProps> = ({
  children,
  showAddButton = false,
  onClick,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <components.Menu className="" {...props}>
      {children}
      <div>
        {showAddButton && (
          <button
            className="bg-blue-700 w-full absolute text-white-A700 p-2 rounded-b-xl"
            onClick={handleClick}
            onTouchEnd={(e) => {
              e.preventDefault();
              handleClick(e as unknown as React.MouseEvent);
            }}
          >
            + Add Partner
          </button>
        )}
      </div>
    </components.Menu>
  );
};
