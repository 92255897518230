import { Img } from "components";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setAdditionalDocBase64,
  setAdditionalDocs,
  setAllDocs,
  setDocs,
  setRequiredDocs,
  setKycDocs,
} from "redux/features/CreateOrder";
import { AxiosRequestConfig } from "axios";
import {
  GetDocumentMappingRequestType,
  GetKYCDocsRequestType,
  getDocumentMapping,
  getKYCDocs,
} from "service/apis/CreateOrderapi";
import { ChangeEvent, useEffect, useState } from "react";
import { ArrowUpFromLine, Plus } from "lucide-react";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import { toast } from "sonner";

type documenttype = {
  doc_list_id: string;
  id: number;
  doc_name: string;
};

const Documentation = () => {
  const [documents, setDocuments] = useState<documenttype[]>([]);
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [uploadedDocs, setUploadedDocs] = useState<number>(0);
  const [uploadedIndexes, setUploadedIndexes] = useState<Set<number>>(
    new Set(),
  );
  const dispatch = useAppDispatch();
  const { purpose_id } = useAppSelector((state) => state.CreateOrder);

  useEffect(() => {
    fetchDocs();
  }, []);

  const fetchDocs = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetDocumentMappingRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: purpose_id || 3,
        service_provider: 15,
        // service_partner_id.toString() === "8" ? "" : service_partner_id,
      },
    };

    getDocumentMapping(payload)
      .then((res) => {
        setDocuments(res.data);
        dispatch(setRequiredDocs(res.data.length));
      })
      .catch((_err) => {});
  };

  const handleFileUpload = (_e: any, id: number) => {
    // Check if a file already exists at the current index
    const isFileAlreadyUploaded = uploadedIndexes.has(id);

    // If a file doesn't already exist, increase the uploadedDocs count
    if (!isFileAlreadyUploaded) {
      setUploadedDocs((prev) => prev + 1);
      // Add the index to the set of uploaded indexes
      setUploadedIndexes((prevIndexes) => new Set([...prevIndexes, id]));
    }
  };

  return (
    <div className="w-full flex flex-col justify-between gap-8 rounded-lg">
      <KycDocComponent />
      <OtherDocumentsComponent
        uploadedDocs={uploadedDocs}
        Documents={documents}
        handleFileUpload={handleFileUpload}
        files={files}
        setFiles={setFiles}
      />
      {/* <AdditionalDocumentsComponent /> */}
    </div>
  );
};

const KycDocComponent = () => {
  const [documents, setDocuments] = useState([]);
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const customer_id = useAppSelector((state) => state.CreateOrder.customers_id);
  const [kyCDocTimeStamp, setKYCDocTimeStamp] = useState<any>("");
  const { kyc_docs, allDocs, isNameMismatch } = useAppSelector(
    (state) => state.CreateOrder,
  );
  const dispatch = useAppDispatch();

  const kycDocsMap = [
    {
      name: "Aadhaar Card Front",
      id: 1,
      doc_list_id: 2,
      doc_type: "aadhaar_front",
      label: "front",
    },
    {
      name: "Aadhaar Card Back",
      id: 2,
      doc_list_id: 50,
      doc_type: "aadhaar_back",
      label: "back",
    },
    {
      name: "Pan Card",
      id: 3,
      doc_list_id: 1,
      doc_type: "pan",
      label: "",
    },
  ];

  useEffect(() => {
    fetchKycDocs();
  }, [customer_id]);

  const fetchKycDocs = (): void | null => {
    // Skip if no customer_id is available
    if (!customer_id) return null;

    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetKYCDocsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        customer_id: customer_id,
      },
    };

    getKYCDocs(payload)
      .then((res: any) => {
        // Set the fetched KYC documents
        if (res?.data) {
          setDocuments(res.data);
          dispatch(setKycDocs(res.data));

          // Update timestamp for view links
          setKYCDocTimeStamp(Date.now());
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch KYC documents");
      });
  };

  const handleFileUpload = async (file: File | undefined, docType: string) => {
    if (!file) return;

    try {
      // Convert file to base64
      const base64Data = await convertFileToBase64(file);

      // Update local files state for preview
      setFiles((prev) => ({
        ...prev,
        [docType]: file,
      }));

      // Prepare the document object
      const docObj = {
        doc_type: docType,
        doc_name: file.name,
        doc: base64Data,
        file: file,
      };

      // Update KYC docs in Redux
      const updatedKycDocs = [...kyc_docs];
      const existingIndex = updatedKycDocs.findIndex(
        (doc: any) => doc.doc_type === docType,
      );

      if (existingIndex !== -1) {
        // Update existing document
        updatedKycDocs[existingIndex] = docObj;
      } else {
        // Add new document
        updatedKycDocs.push(docObj);
      }

      // Dispatch updated KYC docs to Redux
      dispatch(setKycDocs(updatedKycDocs));

      // Add to allDocs for submission
      const existingAllDocsIndex = allDocs.findIndex(
        (doc) => doc.doc_name === file.name,
      );
      if (existingAllDocsIndex !== -1) {
        const updatedAllDocs = [...allDocs];
        updatedAllDocs[existingAllDocsIndex] = {
          doc: base64Data,
          doc_name: file.name,
          doc_id: docType === "pan" ? 3 : 2,
          doc_type: docType,
          reupload: true,
        };
        dispatch(setAllDocs(updatedAllDocs));
      } else {
        dispatch(
          setAllDocs([
            ...allDocs,
            {
              doc: base64Data,
              doc_name: file.name,
              doc_id: docType === "pan" ? 3 : 2,
              doc_type: docType,
              reupload: true,
            },
          ]),
        );
      }

      toast.success(
        `${docType.replace("_", " ").toUpperCase()} uploaded successfully`,
      );
    } catch (error) {
      toast.error("Failed to process document");
      console.error("Error uploading document:", error);
    }
  };

  // Function to check if a document is already uploaded
  const isDocumentUploaded = (docType: string) => {
    return kyc_docs.some((doc) => doc.doc_type === docType);
  };

  // Function to get document URL for viewing
  const getDocumentUrl = (docType: string) => {
    const doc = kyc_docs.find((d) => d.doc_type === docType);

    // For locally uploaded files that haven't been submitted yet
    if (files[docType]) {
      return URL.createObjectURL(files[docType]);
    }

    // For documents already in the system
    if (doc && doc.doc) {
      // If it's a base64 string
      if (typeof doc.doc === "string" && doc.doc.includes("base64")) {
        return doc.doc;
      }

      // If it's a URL from the API
      if (typeof doc.doc === "string" && doc.doc.startsWith("http")) {
        // Add timestamp to prevent caching
        return `${doc.doc}?t=${kyCDocTimeStamp}`;
      }
    }

    return null;
  };
  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-2 items-center text-gray-500">
          <div className="">KYC Documents</div>
        </div>
      </div>
      {isNameMismatch && (
        <div className="flex gap-2 items-center rounded-lg bg-[#F2F7FF] py-1 px-[7px] text-sm leading-[18.2px] text-[#666666] font-normal">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.90625 0.09375C5.54033 0.09375 4.20507 0.498795 3.06935 1.25766C1.93362 2.01653 1.04843 3.09514 0.52571 4.35709C0.00299237 5.61904 -0.133774 7.00766 0.132705 8.34734C0.399184 9.68702 1.05694 10.9176 2.0228 11.8835C2.98865 12.8493 4.21923 13.5071 5.55891 13.7735C6.89859 14.04 8.28721 13.9033 9.54916 13.3805C10.8111 12.8578 11.8897 11.9726 12.6486 10.8369C13.4075 9.70118 13.8125 8.36593 13.8125 7C13.8106 5.16894 13.0823 3.41343 11.7876 2.11868C10.4928 0.823925 8.73731 0.0956836 6.90625 0.09375ZM6.64063 3.28125C6.79823 3.28125 6.9523 3.32799 7.08335 3.41555C7.21439 3.50311 7.31653 3.62756 7.37684 3.77317C7.43716 3.91878 7.45294 4.07901 7.42219 4.23359C7.39144 4.38817 7.31555 4.53016 7.2041 4.6416C7.09266 4.75305 6.95067 4.82894 6.79609 4.85969C6.64151 4.89044 6.48129 4.87466 6.33568 4.81434C6.19007 4.75403 6.06561 4.65189 5.97805 4.52084C5.89049 4.3898 5.84375 4.23573 5.84375 4.07812C5.84375 3.86678 5.92771 3.66409 6.07715 3.51465C6.2266 3.36521 6.42928 3.28125 6.64063 3.28125ZM7.4375 10.7188C7.15571 10.7188 6.88546 10.6068 6.6862 10.4076C6.48694 10.2083 6.375 9.93804 6.375 9.65625V7C6.23411 7 6.09898 6.94403 5.99935 6.8444C5.89972 6.74477 5.84375 6.60965 5.84375 6.46875C5.84375 6.32785 5.89972 6.19273 5.99935 6.0931C6.09898 5.99347 6.23411 5.9375 6.375 5.9375C6.6568 5.9375 6.92705 6.04944 7.1263 6.2487C7.32556 6.44796 7.4375 6.71821 7.4375 7V9.65625C7.5784 9.65625 7.71353 9.71222 7.81315 9.81185C7.91278 9.91148 7.96875 10.0466 7.96875 10.1875C7.96875 10.3284 7.91278 10.4635 7.81315 10.5632C7.71353 10.6628 7.5784 10.7188 7.4375 10.7188Z"
              fill="#165FE3"
            />
          </svg>
          <p className="">
            This customer has different name on PAN and Aadhaar. Would you like
            to upload any additional documents as supporting proof?
          </p>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        {kycDocsMap.map((doc) => (
          <div key={doc.id} className="flex gap-2 flex-col border-[#FFFFFF]">
            <div
              className={`p-4 justify-between rounded-md items-center flex bg-[#FAFAFA] ${isDocumentUploaded(doc.doc_type) && "bg-[#F2F7FF]"}`}
            >
              <div className="flex flex-col overflow-hidden gap-3">
                <h3 className="text-base font-medium text-gray-800">
                  {doc.name}
                </h3>
                {/* <span className="text-[12px] font-normal text-[#999999]">
                                        Updated on 24-Sep-2024 | 10:00 am
                                    </span> */}
              </div>
              <div className="flex items-center gap-5">
                {isDocumentUploaded(doc.doc_type) || files[doc.doc_type] ? (
                  <div className="flex items-center gap-2">
                    <a
                      href={getDocumentUrl(doc.doc_type)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline underline-offset-2 hover:underline text-sm"
                    >
                      View
                    </a>
                    <svg
                      width="18"
                      height="13"
                      viewBox="0 0 18 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.0642 5.78375C16.2922 6.10325 16.4062 6.26375 16.4062 6.5C16.4062 6.737 16.2922 6.89675 16.0642 7.21625C15.0397 8.65325 12.423 11.75 8.90625 11.75C5.38875 11.75 2.77275 8.6525 1.74825 7.21625C1.52025 6.89675 1.40625 6.73625 1.40625 6.5C1.40625 6.263 1.52025 6.10325 1.74825 5.78375C2.77275 4.34675 5.3895 1.25 8.90625 1.25C12.4237 1.25 15.0397 4.3475 16.0642 5.78375Z"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.1562 6.5C11.1562 5.90326 10.9192 5.33097 10.4972 4.90901C10.0753 4.48705 9.50299 4.25 8.90625 4.25C8.30951 4.25 7.73722 4.48705 7.31526 4.90901C6.8933 5.33097 6.65625 5.90326 6.65625 6.5C6.65625 7.09674 6.8933 7.66903 7.31526 8.09099C7.73722 8.51295 8.30951 8.75 8.90625 8.75C9.50299 8.75 10.0753 8.51295 10.4972 8.09099C10.9192 7.66903 11.1562 7.09674 11.1562 6.5Z"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                ) : null}
                <div className="flex items-center gap-2">
                  <label
                    htmlFor={doc.doc_type}
                    className="flex flex-row gap-2 item-center text-blue-700 cursor-pointer text-sm px-3 whitespace-nowrap"
                  >
                    {isDocumentUploaded(doc.doc_type) || files[doc.doc_type]
                      ? "Re-upload"
                      : "Upload"}
                    {!isDocumentUploaded(doc.doc_type) &&
                      !files[doc.doc_type] && <ArrowUpFromLine size={16} />}
                  </label>
                  {isDocumentUploaded(doc.doc_type) || files[doc.doc_type] ? (
                    <svg
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.4517 4.59091V7.04545L10.7244 3.77273L7.4517 0.5V2.95455C5.71574 2.95455 4.05088 3.64415 2.82337 4.87166C1.59586 6.09918 0.90625 7.76404 0.90625 9.5C0.90625 10.7845 1.28261 11.9791 1.9208 12.9855L3.11534 11.7909C2.73772 11.0865 2.54092 10.2993 2.54261 9.5C2.54261 8.19803 3.05982 6.94938 3.98045 6.02875C4.90109 5.10812 6.14973 4.59091 7.4517 4.59091ZM12.9826 6.01455L11.7881 7.20909C12.1481 7.89636 12.3608 8.68182 12.3608 9.5C12.3608 10.802 11.8436 12.0506 10.923 12.9713C10.0023 13.8919 8.75367 14.4091 7.4517 14.4091V11.9545L4.17898 15.2273L7.4517 18.5V16.0455C9.18767 16.0455 10.8525 15.3558 12.08 14.1283C13.3075 12.9008 13.9972 11.236 13.9972 9.5C13.9972 8.21545 13.6208 7.02091 12.9826 6.01455Z"
                        fill="#165FE3"
                      />
                    </svg>
                  ) : null}
                </div>
                <input
                  type="file"
                  name={doc.doc_type}
                  id={doc.doc_type}
                  className="hidden"
                  accept="image/*,application/pdf"
                  onChange={(e) =>
                    handleFileUpload(e.target.files?.[0], doc.doc_type)
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const OtherDocumentsComponent = ({
  Documents,
  files,
  uploadedDocs,
  handleFileUpload,
  setFiles,
}: {
  Documents: documenttype[];
  files: { [key: string]: File };
  uploadedDocs: number;
  handleFileUpload: (e: any, id: number) => void;
  setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File }>>;
}) => {
  const dispatch = useAppDispatch();
  const { allDocs } = useAppSelector((state) => state.CreateOrder);

  return (
    <>
      <div className="flex justify-between p-2">
        <div className="flex gap-2 items-center text-gray-500">
          <div className="">Order Documents</div>
        </div>
        <small className="">
          <span className="text-[#165FE3]">
            {uploadedDocs}/{Documents.length}
          </span>{" "}
          uploaded
        </small>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        {Documents.map(({ id, doc_name, doc_list_id }) => {
          return (
            <div key={id} className="flex gap-2 flex-col border-[#FFFFFF]">
              <div
                className={`p-4 justify-between rounded-md items-center flex bg-[#FAFAFA] ${files[doc_name] && "bg-[#F2F7FF]"}`}
              >
                <div className="flex flex-col overflow-hidden gap-5">
                  <h3 className="text-base font-medium text-gray-800">
                    {doc_name}
                  </h3>
                  {/* <span className="text-[12px] font-normal text-[#999999]">
                                        Updated on 24-Sep-2024 | 10:00 am
                                    </span> */}
                </div>
                <div className="flex items-center gap-5">
                  {files[doc_name]?.name ? (
                    <div className="flex items-center gap-2">
                      <a
                        href={URL.createObjectURL(files[doc_name])}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline underline-offset-2 hover:underline text-sm"
                      >
                        View
                      </a>
                      <svg
                        width="18"
                        height="13"
                        viewBox="0 0 18 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.0642 5.78375C16.2922 6.10325 16.4062 6.26375 16.4062 6.5C16.4062 6.737 16.2922 6.89675 16.0642 7.21625C15.0397 8.65325 12.423 11.75 8.90625 11.75C5.38875 11.75 2.77275 8.6525 1.74825 7.21625C1.52025 6.89675 1.40625 6.73625 1.40625 6.5C1.40625 6.263 1.52025 6.10325 1.74825 5.78375C2.77275 4.34675 5.3895 1.25 8.90625 1.25C12.4237 1.25 15.0397 4.3475 16.0642 5.78375Z"
                          stroke="#165FE3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.1562 6.5C11.1562 5.90326 10.9192 5.33097 10.4972 4.90901C10.0753 4.48705 9.50299 4.25 8.90625 4.25C8.30951 4.25 7.73722 4.48705 7.31526 4.90901C6.8933 5.33097 6.65625 5.90326 6.65625 6.5C6.65625 7.09674 6.8933 7.66903 7.31526 8.09099C7.73722 8.51295 8.30951 8.75 8.90625 8.75C9.50299 8.75 10.0753 8.51295 10.4972 8.09099C10.9192 7.66903 11.1562 7.09674 11.1562 6.5Z"
                          stroke="#165FE3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  ) : null}
                  <div className="flex items-center gap-2">
                    <label
                      htmlFor={doc_name}
                      className="flex flex-row gap-2 item-center text-blue-700 cursor-pointer text-sm px-3 whitespace-nowrap"
                    >
                      {files[doc_name] ? "Re-upload" : "Upload"}
                      {!files[doc_name] && <ArrowUpFromLine size={16} />}
                    </label>
                    {files[doc_name] ? (
                      <svg
                        width="14"
                        height="19"
                        viewBox="0 0 14 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.4517 4.59091V7.04545L10.7244 3.77273L7.4517 0.5V2.95455C5.71574 2.95455 4.05088 3.64415 2.82337 4.87166C1.59586 6.09918 0.90625 7.76404 0.90625 9.5C0.90625 10.7845 1.28261 11.9791 1.9208 12.9855L3.11534 11.7909C2.73772 11.0865 2.54092 10.2993 2.54261 9.5C2.54261 8.19803 3.05982 6.94938 3.98045 6.02875C4.90109 5.10812 6.14973 4.59091 7.4517 4.59091ZM12.9826 6.01455L11.7881 7.20909C12.1481 7.89636 12.3608 8.68182 12.3608 9.5C12.3608 10.802 11.8436 12.0506 10.923 12.9713C10.0023 13.8919 8.75367 14.4091 7.4517 14.4091V11.9545L4.17898 15.2273L7.4517 18.5V16.0455C9.18767 16.0455 10.8525 15.3558 12.08 14.1283C13.3075 12.9008 13.9972 11.236 13.9972 9.5C13.9972 8.21545 13.6208 7.02091 12.9826 6.01455Z"
                          fill="#165FE3"
                        />
                      </svg>
                    ) : null}
                  </div>
                  <input
                    type="file"
                    name={doc_name}
                    id={doc_name}
                    className="hidden"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      dispatch(
                        setDocs({ filename: id, file: e.target.files?.[0] }),
                      );
                      handleFileUpload(e.target.files?.[0], id);
                      setFiles((prev) => ({
                        ...prev,
                        [doc_name]: e.target.files?.[0] as File,
                      }));
                      convertFileToBase64(e.target.files?.[0] as File).then(
                        (base64) => {
                          const existingDocIndex = allDocs.findIndex(
                            (el) => el.doc_name === doc_name,
                          );
                          if (existingDocIndex !== -1) {
                            const updatedDocs = [...allDocs];
                            updatedDocs[existingDocIndex] = {
                              doc: base64,
                              doc_name: doc_name,
                              doc_id: Number(doc_list_id),
                              reupload: false,
                            };
                            dispatch(setAllDocs(updatedDocs));
                          } else {
                            dispatch(
                              setAllDocs([
                                ...allDocs,
                                {
                                  doc: base64,
                                  doc_name: doc_name,
                                  doc_id: Number(doc_list_id),
                                  reupload: false,
                                },
                              ]),
                            );
                          }
                        },
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const AdditionalDocumentsComponent = () => {
  const [newDoc, setNewDoc] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { additional_docs: documents, allDocs } = useAppSelector(
    (state) => state.CreateOrder,
  );

  const handleAddDocument = () => {
    if (newDoc.trim() === "") return;
    if (documents.find((val) => val.id === newDoc)) {
      toast.error("Document already added");
      return;
    }
    // Add the new document to the redux store
    dispatch(setAdditionalDocs([...documents, { id: newDoc, file: null }]));
    setNewDoc("");
  };

  const handleRemoveDocument = (id: string) => {
    // Remove the document from the redux store
    dispatch(setAdditionalDocs(documents.filter((doc) => doc.id !== id)));
    dispatch(setAdditionalDocBase64({ id, base64: "" }));
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const file = e.target.files?.[0];
    if (!file) return;
    // Update the file in the redux store
    dispatch(
      setAdditionalDocs(
        documents.map((doc) => (doc.id === id ? { ...doc, file } : doc)),
      ),
    );
    // Convert the file to base64
    convertFileToBase64(file).then((base64) => {
      dispatch(setAdditionalDocBase64({ id, base64 }));
      const existingDocIndex = allDocs.findIndex(
        (el) => el.doc_name === file.name,
      );
      if (existingDocIndex !== -1) {
        // Overwrite the existing document
        const updatedDocs = [...allDocs];
        updatedDocs[existingDocIndex] = {
          doc: base64,
          doc_name: file.name,
          doc_id: 0,
          reupload: false,
        };
        dispatch(setAllDocs(updatedDocs));
      } else {
        // Add the new document
        dispatch(
          setAllDocs([
            ...allDocs,
            {
              doc: base64,
              doc_name: file.name,
              doc_id: 0,
              reupload: false,
            },
          ]),
        );
      }
    });
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex justify-between cursor-pointer items-center w-full px-4 py-1 rounded-md text-white font-medium transition-all duration-300 ${
          !isOpen ? "text-blue-700" : "text-gray-500"
        }`}
      >
        <div
          className={`flex justify-between items-center font-bold  ${!isOpen && "hover:underline"}`}
        >
          {!isOpen && <Plus size={15} />} Additional Documents
        </div>

        {isOpen && (
          <small className="text-gray-500">{documents.length} uploaded</small>
        )}
      </button>

      {isOpen && (
        <div className="mt-2">
          <div className="flex justify-between items-center mb-3 w-[400px] md:w-[300px]">
            <h1 className="text-lg font-medium text-gray-800">
              Name of Document
            </h1>
            <button
              className="text-blue-600 hover:underline"
              onClick={() => {
                setIsOpen(!isOpen);
                setNewDoc("");
                dispatch(setAdditionalDocs([]));
                dispatch(setAdditionalDocBase64({}));
              }}
            >
              Cancel
            </button>
          </div>

          <div className="flex mb-4 border border-black rounded-md overflow-hidden w-[400px] md:w-[300px]">
            <input
              type="text"
              className="w-full px-3 py-2.5 focus:outline-none border-none h-11"
              placeholder="Enter document name"
              value={newDoc}
              onChange={(e) => setNewDoc(e.target.value)}
            />
            <button
              onClick={handleAddDocument}
              className="text-blue-600 p-2 flex items-center justify-center"
            >
              <Plus size={20} />
            </button>
          </div>

          <div className="grid grid-cols-1 gap-4">
            {documents.map(({ id, file }) => (
              <div
                key={id}
                className="p-3 flex items-center justify-between bg-[#FAFAFA] rounded-md"
              >
                <div className="flex items-center gap-3">
                  {file?.name ? (
                    <img
                      src="/images/login/green-tick.svg"
                      alt="not completed"
                      className="w-5 h-5"
                    />
                  ) : (
                    <img
                      src="/images/remit/grayTick.svg"
                      alt="completed"
                      className="w-5 h-5"
                    />
                  )}
                  <div>
                    <h3 className="text-sm font-medium text-gray-800">
                      {id.toUpperCase()} - {file?.name ?? "No file choosen"}
                    </h3>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  {file?.name && (
                    <a
                      href={URL.createObjectURL(file)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline underline-offset-2 text-sm"
                    >
                      View
                    </a>
                  )}
                  <label
                    htmlFor={id}
                    className="text-blue-700 border border-blue-700 cursor-pointer px-3 py-1.5 rounded-lg text-sm"
                  >
                    {file?.name ? "Re-upload" : "Upload"}
                  </label>
                  <input
                    type="file"
                    id={id}
                    className="hidden"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      handleChangeFile(e, id);
                    }}
                  />

                  {/* Remove button */}
                  <button onClick={() => handleRemoveDocument(id)} className="">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.75705 4.86316L6.44084 16.3562C6.45114 16.5275 6.52641 16.6884 6.65129 16.8061C6.77617 16.9238 6.94125 16.9894 7.11284 16.9895H13.2029C13.3745 16.9894 13.5396 16.9238 13.6645 16.8061C13.7894 16.6884 13.8647 16.5275 13.8749 16.3562L14.5587 4.86316H5.75705ZM15.5718 4.86316L14.8838 16.416C14.8582 16.8445 14.67 17.2471 14.3576 17.5415C14.0452 17.8359 13.6322 17.9999 13.2029 18H7.11284C6.68358 17.9999 6.27055 17.8359 5.95814 17.5415C5.64574 17.2471 5.45754 16.8445 5.432 16.416L4.744 4.86316H3V4.27368C3 4.16201 3.04436 4.05492 3.12332 3.97596C3.20229 3.89699 3.30938 3.85263 3.42105 3.85263H16.8947C17.0064 3.85263 17.1135 3.89699 17.1925 3.97596C17.2714 4.05492 17.3158 4.16201 17.3158 4.27368V4.86316H15.5718ZM11.8421 2C11.9538 2 12.0609 2.04436 12.1398 2.12332C12.2188 2.20229 12.2632 2.30938 12.2632 2.42105V3.01053H8.05263V2.42105C8.05263 2.30938 8.09699 2.20229 8.17595 2.12332C8.25492 2.04436 8.36201 2 8.47368 2H11.8421ZM8.05263 7.05263H9.06316L9.48421 14.6316H8.47368L8.05263 7.05263ZM11.2526 7.05263H12.2632L11.8421 14.6316H10.8316L11.2526 7.05263Z"
                        fill="#FF0000"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Documentation;
