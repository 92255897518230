import ReactSelect, { components } from "react-select";
import { useFormContext } from "react-hook-form";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  channel_of_contact,
  CreatePartnerLeadType,
  partner_subtype_options,
} from "./schema";
import { DateTimePicker } from "pages/Opportunities/dateandtime";
import { useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";
import {
  getAllStaff,
  GetAllStaffRequestType,
} from "service/apis/StaffDetailsapi";
import {
  getContactDetailById,
  getContactsPartnerList,
  GetContactsPartnerListReqType,
} from "service/apis/Opportunities";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Option } from "pages/Opportunities/types";
import { useLocation } from "react-router-dom";

const channelInteractionMap = {
  i_contacted: {
    meeting: 3,
    email: 2,
    phone: 1,
    in_person: 13,
  },
  partner_contacted: {
    phone: 5,
    email: 6,
    in_person: 7,
  },
};

const MultiValueLabel = (props) => (
  <components.MultiValueLabel {...props}>
    <div className="flex items-center text-[#333333] rounded-full">
      {props.data.label}
    </div>
  </components.MultiValueLabel>
);

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <span className="cursor-pointer text-[#333333]">&times;</span>
  </components.MultiValueRemove>
);

const ReactSelectStyled = ({
  options,
  onChange,
  placeholder,
  isMulti = false,
}) => (
  <ReactSelect
    options={options}
    onChange={(selected) => {
      console.log("ReactSelectStyled - Selected value:", selected);
      onChange(selected);
    }}
    isMulti={isMulti}
    placeholder={placeholder}
    className="w-full"
    components={{ MultiValueLabel, MultiValueRemove }}
    menuPlacement="auto"
    styles={{
      control: (provided, state) => ({
        ...provided,
        zIndex: 0,
        borderRadius: ".5rem",
        padding: ".3rem",
        borderColor: state.isFocused ? "#165FE3" : "#333333",
        boxShadow: "none",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 1000,
      }),
    }}
  />
);
export function isStep3Valid({
  contacted_type,
  channelofcontact_type,
  meeting_time,
  channel_of_interaction_id,
  meeting_members,
}) {
  if (!contacted_type) return false;
  if (!channelofcontact_type) return false;
  if (!channel_of_interaction_id) return false;

  if (channelofcontact_type === "meeting") {
    if (!meeting_time) return false;
    if (!meeting_members || meeting_members.length === 0) return false;
  }

  return true;
}

export default function Step3() {
  const [date, setDate] = useState<Date>();
  const location = useLocation();
  const [linkedContactList, setLinkedContactList] = useState<Option[]>([]);
  const [staffs, setStaffs] = useState<Option[]>([]);
  const [contactPartner, setContactPartner] = useState<Option[]>([]);
  const methods = useFormContext<CreatePartnerLeadType>();
  const dispatch = useAppDispatch();
  const { loading, error, success } = useAppSelector(
    (state) => state.PartnerAcquisition,
  );
  const {
    partner_type,
    partner_subtype,
    name,
    contacted_type,
    channelofcontact_type,
    meeting_time,
    channel_of_interaction_id,
    meeting_members,
  } = methods.watch();

  useEffect(() => {
    const fetchData = async (apiFunc, setOptions, mapFunc) => {
      console.log("Fetching data for API:", apiFunc.name);
      const auth = localStorage.getItem("authToken");
      if (!auth) return;
      const authToken = JSON.parse(auth);
      const payload: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      try {
        const res = await apiFunc(payload);
        console.log(`Response from ${apiFunc.name}:`, res.data);
        if (res.data) setOptions(res.data.map(mapFunc));
      } catch (error) {
        console.error(`Error fetching data from ${apiFunc.name}:`, error);
      }
    };

    fetchData(getAllStaff, setStaffs, (staff) => ({
      label: `${staff.fname}`,
      value: staff.id,
    }));
    fetchData(getContactsPartnerList, setContactPartner, (contact) => ({
      label: contact.contactName,
      value: contact.id,
    }));
  }, []);

  useEffect(() => {
    // Set default contacted_type to "i_contacted"
    if (!contacted_type) {
      methods.setValue("contacted_type", "i_contacted", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    // Set default channelofcontact_type to "meeting"
    if (!channelofcontact_type) {
      const defaultChannelId = channelInteractionMap["i_contacted"]["meeting"];
      methods.setValue("channelofcontact_type", "meeting", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      methods.setValue("channel_of_interaction_id", defaultChannelId, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, []);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const queryParams = new URLSearchParams(location.search);
      const c_id = queryParams.get("c_id");

      if (!c_id) return;

      const authToken = localStorage.getItem("authToken");
      if (!authToken) return;

      const payload = {
        headers: { Authorization: `Bearer ${JSON.parse(authToken)}` },
        data: { id: c_id },
      };

      try {
        const response = await getContactDetailById(payload);
        if (response?.data) {
          const finalUpdated = {
            label: response?.data?.contactName,
            value: response?.data?.id,
          };

          setLinkedContactList([finalUpdated]);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchCompanyDetails();
  }, [location.search]);

  const handleChannelChange = (tab) => {
    console.log("Tabs - Channel of contact type changed:", tab);
    const channelId = channelInteractionMap[contacted_type]?.[tab] || 0;
    methods.setValue("channelofcontact_type", tab, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    methods.setValue("channel_of_interaction_id", channelId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  console.log("Step3 - Watched values:", {
    partner_type,
    partner_subtype,
    name,
    contacted_type,
    channelofcontact_type,
    meeting_time,
    channel_of_interaction_id,
    meeting_members,
  });

  return (
    <form className="max-w-[500px]">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <RadioGroup
            className="flex w-full items-center gap-4"
            onValueChange={(value) => {
              console.log("RadioGroup - Contacted type changed:", value);
              methods.setValue("contacted_type", value, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
            value={contacted_type}
          >
            <label
              htmlFor="i_contacted"
              className={`flex w-full items-center rounded-lg border p-3 space-x-2 ${
                contacted_type === "i_contacted"
                  ? "focus-within:border-blue-700"
                  : ""
              }`}
            >
              <RadioGroupItem
                value="i_contacted"
                id="i_contacted"
                className="w-4 h-4"
              />
              <p>I contacted</p>
            </label>
            <label
              htmlFor="partner_contacted"
              className={`flex w-full items-center rounded-lg border p-3 space-x-2 ${
                contacted_type === "partner_contacted"
                  ? "focus-within:border-blue-700"
                  : ""
              }`}
            >
              <RadioGroupItem
                value="partner_contacted"
                id="partner_contacted"
                className="w-4 h-4"
              />
              <p>Partner contacted</p>
            </label>
          </RadioGroup>
        </div>

        {contacted_type && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <Tabs
              value={channelofcontact_type}
              onValueChange={handleChannelChange}
            >
              <TabsList className="sm:flex-wrap gap-3 sm:mb-3 sm:justify-start">
                {channel_of_contact[contacted_type]?.map((tab) => (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    className={`py-1.5 px-[18px] h-[32px] rounded-lg ${
                      tab.value === channelofcontact_type
                        ? "bg-navbar-menu-bg text-blue-700"
                        : "bg-[#F1F1F1]"
                    }`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </div>
        )}

        {contacted_type &&
          channelofcontact_type === "meeting" &&
          partner_subtype &&
          partner_type && (
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <label htmlFor="date" className="text-sm text-[#6B7280]">
                Select meeting time
              </label>
              <DateTimePicker
                fromDate={
                  // all past date from all years
                  new Date("2000-01-01")
                }
                date={new Date(meeting_time)}
                setDate={(newDate) => {
                  setDate(newDate);
                  methods.setValue(
                    "meeting_time",
                    newDate?.toISOString() as string,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    },
                  );
                }}
              />

              <label
                htmlFor="meeting_members"
                className="text-sm text-[#6B7280]"
              >
                Search & add my team members
              </label>
              <ReactSelectStyled
                options={staffs}
                onChange={(selected: any) => {
                  const selectedIds = selected.map(
                    (option: any) => option.value,
                  );
                  console.log(
                    "ReactSelectStyled - Team members selected:",
                    selectedIds,
                  );
                  methods.setValue("meeting_members", selectedIds, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }}
                isMulti
                placeholder="Select team members"
              />

              <label
                htmlFor="contacted_with"
                className="text-sm text-[#6B7280]"
              >
                Contacted With
              </label>
              <ReactSelectStyled
                options={linkedContactList}
                onChange={(selected) => {
                  console.log(
                    "ReactSelectStyled - Contacted with selected:",
                    selected,
                  );
                  methods.setValue("contacted_with", selected, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }}
                placeholder="Select contact"
              />
            </div>
          )}

        <label htmlFor="notes" className="text-sm font-medium text-black-label">
          Internal notes (optional)
        </label>
        <textarea
          {...methods.register("notes")}
          name="notes"
          placeholder="Enter note"
          className={`border ${
            methods.formState.errors.root
              ? "border-red-500"
              : "border-black-label"
          } rounded-lg p-4 w-full`}
          onChange={(e) => console.log("Notes changed:", e.target.value)}
        />
      </div>
    </form>
  );
}
