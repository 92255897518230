import React from "react";
import {
  Dialog,
  DialogOverlay,
  DialogContent,
  DialogClose,
} from "@radix-ui/react-dialog";
import { X } from "lucide-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  setPurposeId,
  setCountry,
  setAmount,
  setType,
  setCustomers_id,
  setForeign_Bank_Charges,
  setPAN,
  setBeneficiaryName,
  setBeneficiaryAddrLine1,
  setBeneficiaryAddrLine2,
  setBeneficiaryCity,
  setBeneficiaryState,
  setBeneficiaryCountry,
  setBeneficiaryZip,
  setBeneficiaryAccountNumber,
  setBeneficiaryBankName,
  setBeneficiaryBankAddr,
  setBeneficiaryBankCountry,
  setBeneficiarySwiftCode,
  setBeneficiaryRoutingNumber,
  setBeneficiaryTransitCode,
  setBeneficiaryBsbcode,
  setBeneficiaryShortCode,
  setBeneficiaryNationalId,
  setBeneficiaryIbanNo,
  setBeneficiaryCorrespondantBankName,
  setBeneficiaryCorrespondantBankNo,
  setCreatedBy,
  setDiscount,
  setAddOnRates,
  setCurrency,
  setDocs,
  setForeign_Bank_Rates,
  setTTRates,
  setServicePartnerID,
  setSettlementRate,
  setCustomer_rate,
  setDealID,
} from "redux/features/CreateOrder";

interface SuccessfullyOrderModalProps {
  setSuccessfullyOrder: React.Dispatch<React.SetStateAction<boolean>>;
  successfullyOrder: boolean;
}

const SuccessfullyOrderModal: React.FC<SuccessfullyOrderModalProps> = ({
  setSuccessfullyOrder,
  successfullyOrder,
}) => {
  const order_id = localStorage.getItem("order_id");
  const { cal_total_payable } = useAppSelector((state) => state.CreateOrder);
  const nevigate = useNavigate();
  const dispatch = useAppDispatch();

  const closeButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    localStorage.removeItem("order_id");
    dispatch(setPurposeId(""));
    dispatch(setCountry(""));
    dispatch(setAmount(""));
    dispatch(setType("beneficiary_receives"));
    dispatch(setCustomers_id(""));
    dispatch(setForeign_Bank_Charges(""));
    dispatch(setPAN(""));
    dispatch(setBeneficiaryName(""));
    dispatch(setBeneficiaryAddrLine1(""));
    dispatch(setBeneficiaryAddrLine2(""));
    dispatch(setBeneficiaryCity(""));
    dispatch(setBeneficiaryState(""));
    dispatch(setBeneficiaryCountry(""));
    dispatch(setBeneficiaryZip(""));
    dispatch(setBeneficiaryAccountNumber(""));
    dispatch(setBeneficiaryBankName(""));
    dispatch(setBeneficiaryBankAddr(""));
    dispatch(setBeneficiaryBankCountry(""));
    dispatch(setBeneficiarySwiftCode(""));
    dispatch(setBeneficiaryRoutingNumber(""));
    dispatch(setBeneficiaryTransitCode(""));
    dispatch(setBeneficiaryBsbcode(""));
    dispatch(setBeneficiaryShortCode(""));
    dispatch(setBeneficiaryNationalId(""));
    dispatch(setBeneficiaryIbanNo(""));
    dispatch(setBeneficiaryCorrespondantBankName(""));
    dispatch(setBeneficiaryCorrespondantBankNo(""));
    dispatch(setCreatedBy(""));
    dispatch(setDiscount(""));
    dispatch(setDocs({ filename: null, file: null }));
    dispatch(setAddOnRates(0));
    dispatch(setCurrency(""));
    dispatch(setForeign_Bank_Rates(""));
    dispatch(setTTRates(""));
    dispatch(setServicePartnerID(""));
    dispatch(setSettlementRate(""));
    dispatch(setCustomer_rate(""));
    dispatch(setDealID(""));
    setSuccessfullyOrder(false);
    nevigate("/remit/orders");
  };

  return (
    <Dialog open={successfullyOrder} onOpenChange={setSuccessfullyOrder}>
      <DialogOverlay className="fixed inset-0 bg-black-900 bg-opacity-60" />
      <DialogContent className="fixed inset-0 flex items-center justify-center">
        <div className="bg-[#fff] rounded-lg shadow-lg p-6 w-full max-w-[900px] relative">
          <DialogClose
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
            ref={closeButtonRef}
            onClick={handleClose}
          >
            <X size={20} />
          </DialogClose>

          <div className="flex justify-between items-center gap-4 p-8">
            {/* Left Side: Text Content */}
            <div className="flex flex-col">
              <p className="text-lg font-semibold text-black">
                Order Created Successfully
              </p>
              <p className="text-gray-600">
                <span className="mr-2 text-gray-600">•</span> Team will contact
                you regarding payment once documents are verified
              </p>

              <div className="w-full rounded-lg px-6 py-2 font-semibold text-gray-700 bg-[#F3F8FF]">
                Order ID: <span className="text-blue-700">OR - {order_id}</span>
              </div>

              {/* Order Details */}
              <div className="flex justify-between w-full bg-[#000] rounded-lg p-4 font-semibold text-white mt-2">
                <p className="text-left text-[#fff]">Total Estimation:</p>
                <span className="text-[#fff]">
                  INR {Number(cal_total_payable)?.toFixed(2)}
                </span>
              </div>
            </div>

            {/* Right Side: Success Icon */}
            <div className="flex flex-col items-center">
              <svg
                width="47"
                height="46"
                viewBox="0 0 47 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.5 0.5C11.06 0.5 0.92 10.64 0.92 23.08C0.92 35.52 11.06 45.66 23.5 45.66C35.94 45.66 46.08 35.52 46.08 23.08C46.08 10.64 35.94 0.5 23.5 0.5ZM34.29 17.89L21.49 30.69C21.17 31.01 20.75 31.19 20.29 31.19C19.84 31.19 19.41 31.01 19.1 30.69L12.71 24.3C12.05 23.64 12.05 22.56 12.71 21.91C13.36 21.25 14.45 21.25 15.1 21.91L20.29 27.1L31.9 15.49C32.55 14.84 33.64 14.84 34.29 15.49C34.95 16.15 34.95 17.21 34.29 17.89Z"
                  fill="#3FB73D"
                />
              </svg>
              <h2 className="text-xl font-bold text-green-600">Success</h2>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessfullyOrderModal;
