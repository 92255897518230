import React, { useEffect } from "react";

import { Input, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import {
  GetPurposeRequestType,
  getPurpose,
  GetCountryRequestType,
  getCountry,
  PostOrderCalcRequestType,
  postOrderCalc,
} from "service/apis/CreateOrderapi";
import { formatIndianNumber } from "../../../utils";
import { selectOptionType } from "components/SelectBox";
import {
  setDealID,
  setPurposeId,
  setCountry,
  setAmount,
  setType,
  setForeign_Bank_Charges,
  setCurrency,
  setAddOnRates,
  setcal_amount_currency,
  setcal_total_charges,
  setcal_gst,
  setcal_tt_charges,
  setcal_total_payable,
  setcal_foreign_bank_charges,
  setcal_amount_inr,
  setcal_your_rate,
  setcal_profit,
  setcal_customer_rate,
  setCustomer_rate,
  setForeign_Bank_Rates,
  setServicePartnerID,
  setSettlementRate,
  setTTRates,
  setCreatedBy,
  setFXCharges,
  setL1CommissionRate,
  setL2CommissionRate,
  setL3CommissionRate,
  setTcs,
} from "../../../redux/features/CreateOrder";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import SendQuote from "models/CreateOrder/SendQuote";
import { toast } from "sonner";
import {
  GetDealDetailsRequestType,
  getDealDetails,
} from "service/apis/DealDetails";
import { setEmail } from "redux/features/PartnerRegistration";
import { useDebounce } from "pages/CreateDeal/newdeal";

type countrydatatype = {
  country: string;
  currency: string;
};
const DealDetails: React.FC<{ setMargin2: (obj: any) => void }> = ({
  setMargin2,
}) => {
  const [purpose, setPurpose] = React.useState<selectOptionType[]>([]);
  const [deal, setDeal] = React.useState<selectOptionType[]>([]);
  const [countryoption, setCountryoption] = React.useState<selectOptionType[]>(
    [],
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currencydata, setCurrencydata] = React.useState<countrydatatype[]>([]);
  const [purposeName, setPurposeName] = React.useState<String>("");
  const [disabled, setDisabled] = React.useState(false);
  const [showProfit, setShowProfit] = React.useState(true);

  const [showSpinner, setShowSpinner] = React.useState(false);
  const [rate, setRate] = React.useState<number>(0);
  const [isDeal, setIsDeal] = React.useState<boolean>(true);
  const [editmargin, setEditmargin] = React.useState<boolean>(false);
  const [childLevel, setChildLevel] = React.useState<string | number>("");
  const [margin, setMargin] = React.useState({
    l1: "",
    l2: "",
    l3: "",
  });
  const dispatch = useAppDispatch();
  const {
    amount,
    cal_amount_inr,
    cal_customer_rate,
    cal_foreign_bank_charges,
    cal_gst,
    cal_profit,
    cal_total_charges,
    cal_total_payable,
    cal_tt_charges,
    cal_your_rate,
    country,
    created_by,
    currency,
    customer_rate,
    Foreign_Bank_Charges,
    add_on_rates,
    purpose_id,
    showerror,
    type,
    walkin_order,
    deal_id,
    foreign_bank_rates,
    tt_rates,
    settlement_rate,
    service_partner_id,
    fx_charges,
    l1_commission,
    l2_commission,
    l3_commission,
  } = useAppSelector((state) => state.CreateOrder);

  const level = localStorage.getItem("level");

  useEffect(() => {
    fetchCountry();
    fetchPurpose();
    fetchDealDetails(deal_id);
    // setMargin({
    //   l1:l1_commission,
    //   l2:l2_commission,
    //   l3:l3_commission
    // })
  }, [deal_id]);
  useEffect(() => {
    const coun = currencydata.find((item) => item.country === country);
    if (type === "remitter_sends") {
      dispatch(setCurrency("INR"));
    } else {
      if (coun?.currency) {
        dispatch(setCurrency(coun?.currency));
      }
    }
  }, [country, type]);

  useEffect(() => {
    let timeoutId: any = null;
    if (amount) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to call fetchCalc after 1 second
      timeoutId = setTimeout(() => {
        fetchCalc();
      }, 1000);
    } else {
      dispatch(setcal_total_charges(""));
      dispatch(setcal_amount_inr(""));
      dispatch(setcal_gst(""));
      dispatch(setcal_tt_charges(""));
      dispatch(setcal_total_payable(""));
      dispatch(setcal_foreign_bank_charges(""));
    }
    return () => {
      // Cleanup the timeout on component unmount or when amount changes
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    purpose_id,
    amount,
    type,
    created_by,
    add_on_rates,
    foreign_bank_rates,
    tt_rates,
    settlement_rate,
    service_partner_id,
  ]);

  useEffect(() => {
    if (currency !== "") {
      dispatch(setAddOnRates(0));
      fetchCalc();
    }
  }, [currency]);

  useEffect(() => {
    try {
      if (purpose_id !== "" && purpose && purpose.length > 0) {
        purpose.filter((item) => item.value === purpose_id);
        setPurposeName(
          purpose
            .filter((item) => item.value.toLocaleString() === purpose_id)[0]
            .label.toLocaleString(),
        );
      }
    } catch (err) {}
  }, [purpose, purpose_id]);

  useEffect(() => {
    if (country !== "") {
      if (type === "remitter_sends") {
        dispatch(setCurrency("INR"));
      }
    }
  }, [type]);

  const fetchPurpose = () => {
    const payload: AxiosRequestConfig<GetPurposeRequestType> = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    getPurpose(payload)
      .then((res) => {
        setPurpose(
          res.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  const fetchCountry = () => {
    const payload: AxiosRequestConfig<GetCountryRequestType> = {};
    getCountry(payload)
      .then((res) => {
        setCurrencydata(res.data);
        setCountryoption(
          res.data.map((item) => {
            return {
              label: item.country,
              value: item.country,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  const fetchDealDetails = (id: string | number) => {
    if (id === "") {
      return;
    }
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetDealDetailsRequestType> = {
      data: {
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);

    getDealDetails(payload)
      .then((res) => {
        setMargin({
          l1: res.data?.l1_commission_rates.toString(),
          l2: res.data?.l2_commission_rates.toString(),
          l3: res.data?.l3_commission_rates.toString(),
        });

        dispatch(setCreatedBy(res.data?.created_by.toLocaleString()));
        dispatch(setPurposeId(res.data?.purpose_id.toLocaleString()));
        dispatch(setCountry(res.data?.country));
        dispatch(setCurrency(res.data?.currency));
        dispatch(setAmount(res.data?.amount));
        dispatch(setForeign_Bank_Rates(res.data?.foregin_bank_rates));
        dispatch(setTTRates(res.data?.tt_charges));
        dispatch(setServicePartnerID(res.data?.service_partner_id));
        dispatch(setSettlementRate(res.data?.service_partner_rates));
        dispatch(
          setCustomer_rate(
            (
              Number(settlement_rate) +
              (Number(level) == 1
                ? Number(margin.l1)
                : Number(level) == 2
                  ? Number(margin.l1) + Number(margin.l2)
                  : Number(margin.l1) + Number(margin.l2) + Number(margin.l3))
            )?.toFixed(4),
          ),
        );
        dispatch(setFXCharges(res.data?.fx_charges));
        dispatch(setL1CommissionRate(res.data?.l1_commission_rates));
        dispatch(setL2CommissionRate(res.data?.l2_commission_rates));
        dispatch(setL3CommissionRate(res.data?.l3_commission_rates));

        setChildLevel(res.data?._channels.level);
        setTimeout(() => fetchCalc(), 0);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleUpdateMargin = (val: string, level: number): void => {
    // Convert to number and ensure minimum is 0
    const validValue = val === "" ? "0" : Math.max(0, Number(val)).toString();

    if (level === 0) {
      setMargin({
        l1: validValue,
        l2: margin.l2,
        l3: margin.l3,
      });
      setMargin2({
        l1: validValue,
        l2: margin.l2,
        l3: margin.l3,
      });
    } else if (level === 1) {
      setMargin({
        l1: margin.l1,
        l2: validValue,
        l3: margin.l3,
      });
      setMargin2({
        l1: margin.l1,
        l2: validValue,
        l3: margin.l3,
      });
    } else {
      setMargin({
        l1: margin.l1,
        l2: margin.l2,
        l3: validValue,
      });
      setMargin2({
        l1: margin.l1,
        l2: margin.l2,
        l3: validValue,
      });
    }
  };

  const handleRecalculate = () => {
    fetchCalc();
    dispatch(setL1CommissionRate(margin.l1));
    dispatch(setL2CommissionRate(margin.l2));
    dispatch(setL3CommissionRate(margin.l3));
  };

  const fetchCalc = (): void | null => {
    // get auth token from localstorage
    setDisabled(true);
    setShowSpinner(true);
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostOrderCalcRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        purpose_id: purpose_id === "" ? 1 : parseInt(purpose_id),
        currency: currency === "" ? "USD" : currency,
        amount: parseInt(amount),
        type: type,
        created_by: created_by,
        tcs_type: 1,
        discount: 0,
        add_on_rates: add_on_rates,
        service_partner_id: service_partner_id,
        settlement_rates: settlement_rate,
        tt_charges: tt_rates,
        foreign_bank_charges: foreign_bank_rates,
        deal_id: deal_id,
        l1_commission: margin.l1 == "" ? l1_commission : margin.l1,
        l2_commission: margin.l2 == "" ? l2_commission : margin.l2,
        l3_commission: margin.l3 == "" ? l3_commission : margin.l3,
      },
    };

    if (currency && purpose_id && amount) {
      postOrderCalc(payload)
        .then((res) => {
          dispatch(
            setcal_total_charges(
              parseFloat(res.data.payable_amount) -
                parseFloat(res.data.base_amount),
            ),
          );
          if (level === "1") {
            dispatch(setcal_profit(res.data.profit.l1));
          }
          if (level === "2") {
            dispatch(setcal_profit(res.data.profit.l2));
          }
          if (level === "3") {
            dispatch(setcal_profit(res.data.profit.l3));
          }
          dispatch(setcal_amount_inr(res.data.base_amount));
          dispatch(setcal_gst(res.data.gst));
          dispatch(setcal_tt_charges(res.data.tt_charges_with_gst));
          dispatch(setcal_total_payable(res.data.payable_amount));
          dispatch(setTcs(res.data.tcs));
          dispatch(
            setcal_foreign_bank_charges(res.data.foreign_bank_charges_with_gst),
          );
          dispatch(
            setcal_your_rate(
              (
                parseFloat(res?.data?.settlement_rates) +
                (level === "2" ? res?.data?.cumulative_commission.l1 : 0) +
                (level === "3" ? res?.data?.cumulative_commission.l2 : 0)
              )?.toFixed(4),
            ),
          );

          setRate(
            parseFloat(res?.data?.settlement_rates) +
              (res?.data?.cumulative_commission_without_addon.l3 > 0
                ? res?.data?.cumulative_commission_without_addon.l3
                : res?.data?.cumulative_commission_without_addon.l2 > 0
                  ? res?.data?.cumulative_commission_without_addon.l2
                  : res?.data?.cumulative_commission_without_addon.l1),
          );
          dispatch(
            setCustomer_rate(Number(res.data.customer_rate)?.toFixed(4)),
          );
          dispatch(
            setcal_customer_rate(
              parseFloat(res.data.customer_rate)?.toFixed(4)?.toLocaleString(),
            ),
          );

          setDisabled(false);
          setShowSpinner(false);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setMargin({
            l1: l1_commission.toString(),
            l2: l2_commission.toString(),
            l3: l3_commission.toString(),
          });
          setDisabled(false);
          setShowSpinner(false);
          setLoading(false);
        });
    }
  };

  const handleEditMarginCancel = (): void | null => {
    setEditmargin(false);
    setMargin({
      l1: l1_commission.toString(),
      l2: l2_commission.toString(),
      l3: l3_commission.toString(),
    });
  };

  const handleCustomerRate = (e: any) => {
    dispatch(setCustomer_rate(e?.target.value));
  };

  useDebounce(
    () => {
      if (margin?.l1 != "" || margin?.l2 != "" || margin.l3 != "") {
        handleRecalculate();
      }
    },
    1000,
    [margin.l1, margin.l2, margin.l3],
  );

  return (
    <>
      {loading && <Spinner />}
      <div className="flex flex-row justify-between w-full gap-4">
        <div className="bg-white-A700 rounded-lg flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start sm:min-h-[auto] md:px-5 w-full md:w-full">
          <div className="flex flex-col gap-8 rounded-[10px] px-[10px] py-[10px] bg-[#FFFFFF] items-start justify-start w-full sm:w-full">
            <div className="flex flex-col gap-[10px] items-start justify-start w-full sm:w-full ">
              {deal_id ? (
                <>
                  <div className="py-2 px-4 rounded-3xl bg-[#F0F6FF] w-fit text-blue-700">
                    Deal-ID {deal_id}
                  </div>
                  <div className="bg-[#FAFAFA] rounded-xl py-6 px-8 flex flex-col  gap-y-4 gap-x-6 w-full">
                    <div className="flex items-center gap-4">
                      <h3 className="text-gray-500">Purpose:</h3>
                      <h1 className="text-black font-medium">
                        {purposeName || "NA"}
                      </h1>
                    </div>
                    <div className="flex items-center gap-4">
                      <h3 className="text-gray-500">Currency:</h3>
                      <h1 className="text-black font-medium">
                        {currency || "NA"}
                      </h1>
                    </div>
                    {/* <div className="flex items-center gap-4">
                                            <h3 className="text-gray-500">Settlement Rate:</h3>
                                            <h1 className="text-black font-medium">
                                                {`INR ${(
                                                    Number(settlement_rate || 0) +
                                                    Number(Number(level) > 1 ? margin?.l1 || 0 : 0) +
                                                    Number(Number(level) > 2 ? margin?.l2 || 0 : 0) +
                                                    Number(Number(level) > 3 ? margin?.l3 || 0 : 0)
                                                )?.toFixed(4) || "NA"
                                                    }`}
                                            </h1>
                                        </div> */}
                    <div className="flex items-center gap-4">
                      <h3 className="text-gray-500">Amount (FCY):</h3>
                      <h1 className="text-black font-medium">{`${currency} ${formatIndianNumber(amount || 0) || "NA"}`}</h1>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex gap-2 items-center rounded-lg justify-center p-5 h-[350px] bg-[#FAFAFA] w-full">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.25782 0C5.89189 0 4.55664 0.405044 3.42091 1.16391C2.28518 1.92278 1.39999 3.00139 0.877273 4.26334C0.354555 5.52529 0.217788 6.91391 0.484267 8.25359C0.750746 9.59327 1.4085 10.8238 2.37436 11.7897C3.34022 12.7556 4.57079 13.4133 5.91047 13.6798C7.25015 13.9463 8.63877 13.8095 9.90072 13.2868C11.1627 12.7641 12.2413 11.8789 13.0002 10.7432C13.759 9.60743 14.1641 8.27218 14.1641 6.90625C14.1621 5.07519 13.4339 3.31968 12.1391 2.02493C10.8444 0.730175 9.08887 0.00193363 7.25782 0ZM6.99219 3.1875C7.1498 3.1875 7.30386 3.23424 7.43491 3.3218C7.56596 3.40936 7.66809 3.53381 7.72841 3.67942C7.78872 3.82503 7.8045 3.98526 7.77375 4.13984C7.74301 4.29442 7.66711 4.43641 7.55567 4.54785C7.44422 4.6593 7.30223 4.73519 7.14765 4.76594C6.99307 4.79669 6.83285 4.78091 6.68724 4.72059C6.54163 4.66028 6.41717 4.55814 6.32961 4.42709C6.24205 4.29605 6.19532 4.14198 6.19532 3.98438C6.19532 3.77303 6.27927 3.57034 6.42871 3.4209C6.57816 3.27146 6.78085 3.1875 6.99219 3.1875ZM7.78907 10.625C7.50727 10.625 7.23702 10.5131 7.03776 10.3138C6.83851 10.1145 6.72657 9.84429 6.72657 9.5625V6.90625C6.58567 6.90625 6.45054 6.85028 6.35091 6.75065C6.25129 6.65102 6.19532 6.5159 6.19532 6.375C6.19532 6.2341 6.25129 6.09898 6.35091 5.99935C6.45054 5.89972 6.58567 5.84375 6.72657 5.84375C7.00836 5.84375 7.27861 5.95569 7.47787 6.15495C7.67712 6.35421 7.78907 6.62446 7.78907 6.90625V9.5625C7.92996 9.5625 8.06509 9.61847 8.16472 9.7181C8.26435 9.81773 8.32032 9.95285 8.32032 10.0938C8.32032 10.2346 8.26435 10.3698 8.16472 10.4694C8.06509 10.569 7.92996 10.625 7.78907 10.625Z"
                      fill="#666666"
                    />
                  </svg>
                  <small>Search or Select Deal to see the details here</small>
                </div>
              )}
              <div className="grid sm:grid-cols-1 grid-cols-2 w-full gap-[10px] px-[16px] py-[14px] rounded-[10px]">
                <div
                  className={`${Number(childLevel) - Number(level) < 2 ? "hidden" : ""} flex-col gap-[8px] pb-[12px] border-b-2 border-indigo-50 border-solid w-full`}
                >
                  <div className="flex flex-row justify-between w-full">
                    <Text
                      className="text-[#404040] text-right text-md w-auto"
                      size="txtInterMedium14"
                    >
                      Sub Partner Margin
                    </Text>
                    {editmargin ? (
                      <Input
                        name="input"
                        value={Number(level) + 2 == 3 ? margin.l3 : 0}
                        onChange={(e: string) =>
                          handleUpdateMargin(e, Number(level) + 1)
                        }
                        placeholder="Enter Margin"
                        className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-[172px]"
                    `}
                        wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-2 rounded-lg w-[172px]`}
                        type="number"
                        disabled={disabled}
                      ></Input>
                    ) : (
                      <Text
                        className="text-[#0A0A0A] text-right font-[24px] text-md w-auto"
                        size="txtInterMedium14"
                      >
                        {Number(level) + 2 == 3 ? margin.l3 : 0}
                      </Text>
                    )}
                  </div>
                  <div className="flex flex-row justify-between w-full">
                    <Text
                      className="text-[#0A0A0A80] text-right text-md w-auto"
                      size="txtInterRegular14"
                    >
                      Rate
                    </Text>
                    <Text
                      className="text-[#0A0A0A80] text-right text-md w-auto"
                      size="txtInterRegular14"
                    >
                      INR{" "}
                      {(
                        Number(settlement_rate) +
                        (Number(level) + 2 == 1
                          ? Number(margin.l1)
                          : Number(level) + 2 == 2
                            ? Number(margin.l1) + Number(margin.l2)
                            : Number(margin.l1) +
                              Number(margin.l2) +
                              Number(margin.l3))
                      )?.toFixed(4)}
                    </Text>
                  </div>
                </div>

                <div
                  className={`${Number(childLevel) - Number(level) < 1 ? "hidden" : "flex"} flex-col pb-[12px] border-indigo-50 border-solid`}
                >
                  <div className="flex flex-col justify-between w-full gap-2">
                    <Text
                      className="text-[#404040] text-left text-md w-auto"
                      size="txtInterMedium14"
                    >
                      Partner Margin
                    </Text>

                    <div className="flex items-center border border-black-900_4c border-solid rounded-lg px-3 py-2">
                      <span className="bg-[#E1EBFC] border-solid rounded-lg text-blue-500 text-[15px] p-3">
                        INR
                      </span>
                      <input
                        className="flex-grow text-[15px] text-left pl-4 border-none focus:outline-none"
                        type="number"
                        value={Number(level) + 1 === 2 ? margin.l2 : margin.l3}
                        onChange={(e) =>
                          handleUpdateMargin(
                            Number(e.target.value),
                            Number(level),
                          )
                        }
                        // onBlur={() => handleRecalculate()}
                        placeholder="Enter Margin"
                      />
                    </div>

                    <div className="flex flex-row gap-2 bg-gray-200 mt-2 border  rounded-lg p-2">
                      <Text
                        className="text-[#0A0A0A80] text-right text-md w-auto mr-2"
                        size="txtInterRegular14"
                      >
                        Rate:
                      </Text>
                      <Text
                        className="text-[#0A0A0A80] text-right text-md w-auto"
                        size="txtInterRegular14"
                      >
                        INR{" "}
                        {(
                          Number(settlement_rate) +
                          (Number(level) + 1 == 1
                            ? Number(margin.l1)
                            : Number(level) + 1 == 2
                              ? Number(margin.l1) + Number(margin.l2)
                              : Number(margin.l1) +
                                Number(margin.l2) +
                                Number(margin.l3))
                        )?.toFixed(4)}
                      </Text>
                    </div>
                  </div>
                </div>

                <div
                  className={`${Number(childLevel) - Number(level) < 0 ? "hidden" : "flex"} flex-row gap-[10px] pb-[12px] w-full`}
                >
                  <div className="flex flex-col gap-2 w-full ">
                    <Text
                      className="text-[#404040] text-md w-auto"
                      size="txtInterMedium14"
                    >
                      Customer Rate
                    </Text>
                    <div className="flex items-center border border-black-900_4c border-solid rounded-lg px-3 py-2">
                      <span className="bg-[#E1EBFC] border-solid rounded-lg text-blue-500 text-[15px] p-3">
                        INR
                      </span>
                      <input
                        className="flex-grow text-[15px] text-left pl-4 border-none focus:outline-none"
                        type="number"
                        min={0}
                        value={customer_rate}
                        disabled
                      />
                    </div>
                    <span className="text-gray-500 text-[14px] mt-1">
                      A change in either my margin, partner margin, or the
                      customer rate will impact the other.
                    </span>
                  </div>
                  <div className="flex flex-col gap-2 w-full ">
                    <Text
                      className="text-[#404040] text-md w-auto"
                      size="txtInterMedium14"
                    >
                      My Margin
                    </Text>
                    <div className="flex items-center border border-black-900_4c border-solid rounded-lg px-3 py-2">
                      <span className="bg-[#E1EBFC] border-solid rounded-lg text-blue-500 text-[15px] p-3">
                        INR
                      </span>
                      <input
                        className="flex-grow text-[15px] text-left pl-4 border-none focus:outline-none"
                        type="number"
                        min={0}
                        value={
                          Number(level) === 1
                            ? margin.l1
                            : Number(level) === 2
                              ? margin.l2
                              : margin.l3
                        }
                        onChange={(e) =>
                          handleUpdateMargin(e.target.value, Number(level) - 1)
                        }
                        // onBlur={() => handleRecalculate()}
                        placeholder="Enter Margin"
                      />
                    </div>
                  </div>
                  {/* <div className="flex flex-col gap-2 w-full">
                                        <Text
                                            className="text-[#404040] text-md w-auto"
                                            size="txtInterMedium14"
                                        >
                                            Customer Rate
                                        </Text>
                                        <div className="flex items-center border gap-2 border-black-900_4c border-solid rounded-lg px-3 py-2">
                                            <span className="bg-[#E1EBFC] border-solid rounded-lg text-blue-500 text-[15px] p-3">
                                                INR
                                            </span>
                                            <Text
                                                className="text-[#0A0A0A80] text-right text-md w-auto"
                                                size="txtInterRegular14"
                                            >
                                                {customer_rate}
                                            </Text>
                                        </div>
                                        <span className="text-gray-500 text-[14px] mt-1">
                                            A change in either my margin, partner margin, or the
                                            customer rate will impact the other.
                                        </span>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealDetails;
