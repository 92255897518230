import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { ColumnDef } from "@tanstack/react-table";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import React, { useEffect, useMemo, useState } from "react";
import {
  setBeneficiaryAccountNumber,
  setBeneficiaryBankAddr,
  setBeneficiaryBankCountry,
  setBeneficiaryBankName,
  setBeneficiaryList,
  setBeneficiaryName,
  setBeneficiaryReferencenumber,
  setBeneficiarySwiftCode,
  setBeneficiaryType,
  setSelected_beneficiary_id,
  setSelectedTableBeneficiaryRowId,
} from "redux/features/CreateOrder";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import { AxiosRequestConfig } from "axios";
import { toast, ToastContainer } from "react-toastify";
import {
  getCustomer,
  GetCustomerRequestType,
  postBeneficiaryEntry,
  postBeneficiaryEntryRequestType,
} from "service/apis/CreateOrderapi";
import EducationModal from "./educationModal";
import NonEducationModal from "./nonEducationModal";

const countryMapping: Record<string, string> = {
  "United Kingdom": "SORT",
  Australia: "BSB",
  Canada: "Transit",
  "United States": "Routing number",
};

export const columns: ColumnDef<any>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Beneficiary Name" />
    ),
    cell: ({ row }) => <div>{row.getValue("name") || "N/A"}</div>,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "city",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Location" />
    ),
    cell: ({ row }) => {
      const city = row?.original?.bank_addr || "N/A";
      const country = row?.original?.bank_country || "N/A";
      return (
        <div className="flex flex-col gap-2">
          <p className="font-normal capitalize text-sm leading-[17px] text-black-label">
            {city}
          </p>
          <p className="font-normal capitalize text-sm leading-[14.5px] text-[#999999]">
            {country}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "account_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="IBAN / A/C number" />
    ),
    cell: ({ row }) => {
      const isAccType = row?.original?.bank_country
        ? countryMapping[row?.original?.bank_country] || "IBAN"
        : "IBAN";
      return (
        <div className="flex flex-col gap-2">
          <p className="font-normal capitalize text-sm leading-[17px] text-black-label">
            {row.getValue("account_number") || "N/A"}
          </p>
          {/* <p className="font-normal capitalize text-sm leading-[14.5px] text-[#999999]">
            {isAccType}
          </p> */}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "swift_code",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="SWIFT Code" />
    ),
    cell: ({ row }) => <div>{row.getValue("swift_code") || "N/A"}</div>,
    enableSorting: false,
  },
];

type FormState = {
  beneficiaryType: string;
  beneficiaryRelationship: string;
  beneficiaryCountry: string;
  beneficiaryName: string;
  beneficiaryAddress: string;
  beneficiarySwiftCode: string;
  beneficiaryBankName: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress: string;
  beneficiaryAccountNumber: string;
  beneficiaryReferenceNumber: string;
  [key: string]: string;
};

const Step8: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const swiftRegex = /^[A-Z0-9]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
  const [formStateVal, setFormStateVal] = useState<FormState>({
    beneficiaryType: "",
    beneficiaryRelationship: "",
    beneficiaryCountry: "",
    beneficiaryName: "",
    beneficiaryAddress: "",
    beneficiarySwiftCode: "",
    beneficiaryBankName: "",
    beneficiaryBankCountry: "",
    beneficiaryBankAddress: "",
    beneficiaryAccountNumber: "",
    beneficiaryReferenceNumber: "",
    BSBNumber: "",
    SORTNumber: "",
    TRANSITNumber: "",
    OTHERNumber: "",
    RoutingNumber: "",
  });

  const {
    beneficiary_name,
    beneficiary_bank_name,
    beneficiary_bank_addr,
    beneficiary_addr_line_1,
    beneficiary_addr_line_2,
    beneficiary_bank_country,
    beneficiary_swift_code,
    beneficiary_type,
    customers_id,
    beneficiary_city,
    beneficiary_state,
    beneficiary_country,
    beneficiary_zip,
    beneficiary_account_number,
    beneficiary_routing_number,
    beneficiary_reference_number,
    beneficiary_transit_code,
    beneficiary_bsbcode,
    beneficiary_short_code,
    beneficiary_national_id,
    beneficiary_iban_no,
    beneficiary_correspondant_bank_name,
    beneficiary_correspondant_bank_no,
    beneficiary_list,
    PAN,
    purpose_id,
    selected_table_beneficiary_row_id,
  } = useAppSelector((state) => state.CreateOrder);
  const [rowSelected, setRowSelected] = useState("");
  const [refernaceNumber, setRefernaceNumber] = useState<string | number>();
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const filteredBeneficiaries = [...(beneficiary_list || [])]
    .sort((a, b) => (b.id || 0) - (a.id || 0))
    .filter((beneficiary) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        beneficiary?.name?.toLowerCase()?.includes(searchLower) ||
        beneficiary?.city?.toLowerCase()?.includes(searchLower) ||
        beneficiary?.country?.toLowerCase()?.includes(searchLower)
      );
    });

  const handleAction = (rowData: any) => {
    setRowSelected(rowData?.id);
    dispatch(setSelectedTableBeneficiaryRowId(rowData));
    if (rowSelected === rowData?.id) {
      setRowSelected("");
      dispatch(setBeneficiaryType(""));
      dispatch(setBeneficiaryName(""));
      dispatch(setBeneficiaryAccountNumber(""));
      dispatch(setBeneficiaryBankName(""));
      dispatch(setBeneficiaryBankAddr(""));
      dispatch(setBeneficiaryBankCountry(""));
      dispatch(setBeneficiarySwiftCode(""));
      dispatch(setSelected_beneficiary_id(""));
      return;
    }
    dispatch(setSelected_beneficiary_id(rowData?.id));
    dispatch(setBeneficiaryType(rowData?.beneficiaryType));
    dispatch(setBeneficiaryName(rowData?.name));
    dispatch(setBeneficiaryAccountNumber(rowData?.account_number));
    dispatch(setBeneficiaryBankName(rowData?.bank_name));
    dispatch(setBeneficiaryBankAddr(rowData?.bank_addr));
    dispatch(setBeneficiaryBankCountry(rowData?.bank_country));
    dispatch(setBeneficiarySwiftCode(rowData?.swift_code));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    dispatch(setBeneficiaryType(""));
    dispatch(setBeneficiaryName(""));
    dispatch(setBeneficiaryAccountNumber(""));
    dispatch(setBeneficiaryBankName(""));
    dispatch(setBeneficiaryBankAddr(""));
    dispatch(setBeneficiaryBankCountry(""));
    dispatch(setBeneficiarySwiftCode(""));
  };

  const fetchCustomer = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetCustomerRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        pan_no: PAN.toUpperCase(),
      },
    };

    getCustomer(payload)
      .then((res) => {
        dispatch(setBeneficiaryList(res?.data?.beneficiary));
      })
      .catch((err) => {
        dispatch(setBeneficiaryList([]));
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  // postBeneficiaryEntry
  const fetchBeneficiaryEntry = (): void | null => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<postBeneficiaryEntryRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        name: formStateVal.beneficiaryName || beneficiary_name,
        addr_line_1: formStateVal.beneficiaryAddress || beneficiary_addr_line_1,
        addr_line_2: beneficiary_addr_line_2,
        city: beneficiary_city,
        state: beneficiary_state,
        country: formStateVal.beneficiaryCountry || beneficiary_country,
        zip: beneficiary_zip,
        account_number:
          formStateVal.beneficiaryAccountNumber || beneficiary_account_number,
        bank_name: formStateVal.beneficiaryBankName || beneficiary_bank_name,
        bank_addr: formStateVal.beneficiaryBankAddress || beneficiary_bank_addr,
        bank_country:
          formStateVal.beneficiaryBankCountry || beneficiary_bank_country,
        swift_code: formStateVal.beneficiarySwiftCode || beneficiary_swift_code,
        reference_no:
          formStateVal.beneficiaryReferenceNumber ||
          beneficiary_reference_number,
        routing_number:
          formStateVal.RoutingNumber || beneficiary_routing_number,
        transit_code: beneficiary_transit_code,
        bsbcode0: beneficiary_bsbcode,
        short_code: beneficiary_short_code,
        national_id: beneficiary_national_id,
        iban_no: beneficiary_iban_no,
        correspondant_bank_name: beneficiary_correspondant_bank_name,
        correspondant_bank_no: beneficiary_correspondant_bank_no,
        Nickname: beneficiary_name,
        beneficiaryType: formStateVal.beneficiaryType || beneficiary_type,
        BSB: formStateVal.BSBNumber || "",
        SORT: formStateVal.SORTNumber || "",
        TRANSIT: formStateVal.TRANSITNumber || "",
        OTHER: formStateVal.OTHERNumber || "",
        customers_id: customers_id,
        relation: formStateVal.beneficiaryRelationship || "",
      },
    };

    postBeneficiaryEntry(payload)
      .then((res) => {
        toast.success("Beneficiary Add successfully");
        fetchCustomer();
        handleCloseModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(setBeneficiaryType(""));
        dispatch(setBeneficiaryName(""));
        dispatch(setBeneficiaryAccountNumber(""));
        dispatch(setBeneficiaryBankName(""));
        dispatch(setBeneficiaryBankAddr(""));
        dispatch(setBeneficiaryBankCountry(""));
        dispatch(setBeneficiarySwiftCode(""));
      });
  };

  const handleOnSubmit = () => {
    if (formStateVal.beneficiaryReferenceNumber)
      dispatch(
        setBeneficiaryReferencenumber(formStateVal.beneficiaryReferenceNumber),
      );
    if (validateInputs()) {
      fetchBeneficiaryEntry();
    }
  };

  const validateInputs = (): boolean => {
    const isAccType = formStateVal.beneficiaryBankCountry
      ? countryMapping[formStateVal.beneficiaryBankCountry] || "IBAN"
      : "IBAN";

    if (["1", "2", "10", "11", "13", "14", "8", "9"].includes(purpose_id)) {
      if (
        !formStateVal.beneficiaryType ||
        !formStateVal.beneficiaryName ||
        !formStateVal.beneficiaryBankName ||
        !formStateVal.beneficiaryBankAddress ||
        !formStateVal.beneficiaryBankCountry ||
        !formStateVal.beneficiaryAccountNumber ||
        (isAccType === "Transit" && !formStateVal.TRANSITNumber) ||
        (isAccType === "SORT" && !formStateVal.SORTNumber) ||
        (isAccType === "Routing number" && !formStateVal.RoutingNumber) ||
        (isAccType === "BSB" && !formStateVal.BSBNumber) ||
        !swiftRegex.test(formStateVal.beneficiarySwiftCode)
      ) {
        toast.error("Please fill all required fields correctly.");
        return false;
      }
    } else if (["3", "4"].includes(purpose_id)) {
      if (
        !formStateVal.beneficiaryName ||
        !formStateVal.beneficiaryBankName ||
        !formStateVal.beneficiaryBankAddress ||
        !formStateVal.beneficiaryBankCountry ||
        !formStateVal.beneficiaryAccountNumber ||
        (isAccType === "Transit" && !formStateVal.TRANSITNumber) ||
        (isAccType === "SORT" && !formStateVal.SORTNumber) ||
        (isAccType === "Routing number" && !formStateVal.RoutingNumber) ||
        (isAccType === "BSB" && !formStateVal.BSBNumber) ||
        !swiftRegex.test(formStateVal.beneficiarySwiftCode)
      ) {
        toast.error("Please fill all required fields correctly.");
        return false;
      }
    } else {
      toast.error("Invalid purpose ID.");
      return false;
    }
    return true;
  };

  // for Referencenumber no condition
  const handleonReferenceNo = () => {
    if (purpose_id === "10" || purpose_id === "11") {
      if (!formStateVal.beneficiaryReferenceNumber) {
        toast.error("Please fill the Reference Number.");
        return false;
      } else {
        setRefernaceNumber(formStateVal.beneficiaryReferenceNumber);
        dispatch(
          setBeneficiaryReferencenumber(
            formStateVal.beneficiaryReferenceNumber,
          ),
        );
        toast.success("Reference No. added successfully");
        setIsModalOpen(false);
      }
    }
  };

  useEffect(() => {
    if (selected_table_beneficiary_row_id) {
      setRowSelected(selected_table_beneficiary_row_id);
    }
  }, [selected_table_beneficiary_row_id]);

  // for reference Number
  const referencecolumns: ColumnDef<any>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div>{row?.original?.id}</div>,
      enableHiding: false,
      enableSorting: false,
    },
    {
      accessorKey: "refernace_number",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Reference Number" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-2">
            <p className="font-normal capitalize text-sm leading-[17px] text-black-label">
              {row?.original?.refernace_number || "N/A"}
            </p>
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  const refrenceFinalData = [
    {
      id: 1,
      refernace_number: refernaceNumber || "N/A",
    },
  ];

  return (
    <div className="flex h-full gap-2 w-full flex-col">
      <div className="flex flex-row md:flex-col w-full justify-between items-center px-5 py-3 bg-white shadow-sm gap-2">
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Search beneficiary here"
            style={{ borderRadius: "20px" }}
            className="rounded-lg border border-gray-300 px-4 py-2 w-[488px] md: w-full focus:outline-none focus:ring focus:ring-blue-200"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          style={{ color: "#fff" }}
          className="text-white rounded-lg py-2 px-4 bg-blue-700 font-semibold text-sm hover:bg-blue-600"
          onClick={() => handleModalOpen()}
        >
          {["10", "11"].includes(purpose_id)
            ? "Add Reference No."
            : "Add Beneficiary"}
        </button>
      </div>
      {["10", "11"].includes(purpose_id) ? (
        <div className="rounded-lg flex-1 flex-col px-5 py-5 w-full shadow-sm">
          <h1 className="mb-2">Select from previous Reference Number</h1>
          <div className="overflow-auto">
            <div className=" h-full border p-1 rounded-lg">
              <DataTable
                onRowClick={handleAction}
                data={refrenceFinalData}
                columns={referencecolumns}
                pageName={"Add Beneficiary"}
                isToolbarVisible={false}
                highlightRow={!!rowSelected}
                isPageCountVisible={false}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="rounded-lg flex-1 flex-col px-5 py-5 w-full shadow-sm">
          <h1 className="mb-2">Select from previous beneficiaries</h1>
          {isLoading ? (
            <Spinner />
          ) : filteredBeneficiaries?.length > 0 ? (
            <div className="overflow-auto">
              <div className=" h-full border p-1 rounded-lg">
                <DataTable
                  onRowClick={handleAction}
                  data={filteredBeneficiaries}
                  columns={columns}
                  pageName={"Add Beneficiary"}
                  isToolbarVisible={false}
                  highlightRow={!!rowSelected}
                  isPaginationVisible={filteredBeneficiaries?.length > 5}
                  isPageCountVisible={false}
                  searchData={{
                    placeholder: "Search beneficiary",
                    columns: ["beneficiary_name"],
                  }}
                />
              </div>
            </div>
          ) : (
            <p>No beneficiaries found. Please add one.</p>
          )}
        </div>
      )}

      <div className="flex float-right absolute -top-3 -right-4 gap-2">
        <AlertDialog open={isModalOpen}>
          <AlertDialogPortal>
            <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
            <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl w-full animate-contentShow sm:max-w-[90%]">
              <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                  {["10", "11"].includes(purpose_id)
                    ? "Add Reference No."
                    : "Add Beneficiary"}
                </AlertDialogTitle>
              </AlertDialogHeader>
              <div className="h-[500px] overflow-auto w-full">
                {/* <AddBeneficiaryModal setIsEnable={setIsEnable} register={register} watch={watch} setValue={setValue} getValues={getValues} control={control}/> */}

                {["1", "2", "10", "11", "8", "9", "13", "14"].includes(
                  purpose_id,
                ) && (
                  <EducationModal
                    setIsEnable={setIsEnable}
                    setFormStateVal={setFormStateVal}
                  />
                )}
                {["3", "4"].includes(purpose_id) && (
                  <NonEducationModal
                    setIsEnable={setIsEnable}
                    setFormStateVal={setFormStateVal}
                  />
                )}
              </div>
              <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel
                  className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                  asChild
                >
                  <button onClick={handleCloseModal}>Cancel</button>
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  asChild
                >
                  <button
                    type="submit"
                    disabled={!isEnable}
                    onClick={
                      ["10", "11"].includes(purpose_id)
                        ? handleonReferenceNo
                        : handleOnSubmit
                    }
                  >
                    {["10", "11"].includes(purpose_id)
                      ? "Submit"
                      : "Add Beneficiary"}
                  </button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Step8;
