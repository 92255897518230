import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Option } from "components/Form/form";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  setProductPurpose,
  setQuoteProduct,
  setQuotePurposeProduct,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { toast } from "sonner";

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

const studyabroadProductPurposeOptions: Option[] = [
  { value: "31", label: "Consultation" },
];

const productPurposeOptions: Record<string, Option[]> = {
  remittance: remitProductPurposeOptions,
  forex: forexProductPurposeOptions,
  visa: visaProductPurposeOptions,
  travel: travelProductPurposeOptions,
  studyabroad: studyabroadProductPurposeOptions,
};

interface Step1Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setContactDetails?: React.Dispatch<React.SetStateAction<any>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
}
export const L1Step2Component: React.FC<Step1Props> = ({
  setCurrentStep,
  setSelectProduct,
  setProcessState,
}) => {
  const { product_purpose, quoteProduct } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const dispatch = useAppDispatch();

  const currentProductOptions = useMemo(() => {
    return (
      productPurposeOptions[
        quoteProduct as keyof typeof productPurposeOptions
      ] || []
    );
  }, [quoteProduct]);

  useEffect(() => {
    if (!quoteProduct) {
      dispatch(setQuoteProduct("remittance"));
    }
  }, [quoteProduct, dispatch]);

  const handleProductChoose = useCallback(
    (selectedValue: string) => {
      const selectedLabel = currentProductOptions.find(
        (tab: any) => tab.value === selectedValue,
      )?.label;
      if (selectedLabel) {
        dispatch(setProductPurpose(selectedValue));
        setSelectProduct(selectedLabel);
        dispatch(setQuotePurposeProduct(selectedLabel));
        setCurrentStep((prev) => prev + 1);
        setProcessState((prevState: any) => ({
          ...prevState,
          Product: selectedLabel,
        }));
      } else {
        toast.error("No matching label found for the selected value");
      }
    },
    [dispatch, setSelectProduct, setCurrentStep, currentProductOptions],
  );

  return (
    <div className="flex flex-col gap-6 max-w-md">
      <form className="max-w-md">
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <label className={`text-[18px] font-medium text-[#333]`}>
              Select any one to proceed.
            </label>
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                onValueChange={handleProductChoose}
              >
                {currentProductOptions?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab?.value}
                      htmlFor={tab?.value}
                      className={`${
                        product_purpose === tab?.value
                          ? "border-blue-700"
                          : "border-black-label"
                      } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab?.value}
                        id={tab?.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab?.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>
          <div className="flex flex-row gap-8">
            <button
              className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
              onClick={() => {
                setCurrentStep((prev) => prev - 1),
                  //  dispatch(setProductPurpose(""));
                  setProcessState((prevState: any) => ({
                    ...prevState,
                    Product: "",
                  }));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
