import React, { useEffect, useState } from "react";
import { Button, Img, SelectBox, Spinner, Text } from "../../components";
import { Link, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  getStaffDetails,
  GetStaffDetailsRequestType,
  PostSendForgotPasswordEmailRequestType,
  postSendForgotPasswordEmail,
  DeleteStaffRequestType,
  deleteStaff,
  ActivateStaffRequestType,
  activateStaff,
  UpdateStaffRequestType,
  updateStaff,
} from "service/apis/StaffDetailsapi";
import { GetRoleRequestType, getRole } from "service/apis/CreateStaff";
import { selectOptionType } from "components/SelectBox";
import { AxiosRequestConfig } from "axios";
import { Input } from "../../components";
import ReassignEmployee from "models/StaffDetails/ReassignEmployee";
import { Toaster, toast } from "sonner";
import { getAccessRoleGroup, GetAccessRoleRequestType } from "service/api";
const statusOptionsList = [
  { label: "In-Active", value: "inactive" },
  {
    label: "Active",
    value: "active",
  },
];

const StaffdetailsPage: React.FC = () => {
  const { id } = useParams();
  const [staffDetails, setStaffDetails] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [staffstatus, setstaffstatus] = React.useState("");
  const [joiningDate, setJoiningDate] = React.useState<any>();
  const [lastLogin, setLastLogin] = React.useState<any>();
  const [edit, setEdit] = React.useState<boolean>(false);
  const [roleOptions, setroleOptions] = React.useState<selectOptionType[]>([]);
  const [editName, setEditName] = React.useState<string>("");
  const [editEmail, setEditEmail] = React.useState<string>("");
  const [editPhoneNumber, setEditPhoneNumber] = React.useState<string>("");
  const [editRole, setEditRole] = React.useState<string | number>();
  const [reassignEmployee, showReassignEmployee] =
    React.useState<boolean>(false);
  const [partnerList, setPartnerList] = React.useState<any>({});
  const [accessRoleList, setAccessRoleList] = useState<any>();

  const fetchRole = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetRoleRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getRole(payload)
      .then((res) => {
        const role = res.data as Array<{ id: number; name: string }>;
        setroleOptions(
          role.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          }),
        );
      })
      .catch(() => {});
  };

  // fecth Access Role Based on Staff ID
  const fetchAccessRole = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetAccessRoleRequestType> = {
      data: {
        staff_id: Number(id),
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getAccessRoleGroup(payload)
      .then((res) => {
        const role = res?.data as Array<{ id: number; name: string }>;
        setroleOptions(
          role?.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          }),
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
        setLoading(false);
      });
  };

  //
  const getstaff = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: GetStaffDetailsRequestType = {
      data: {
        staff_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getStaffDetails(req)
      .then((res) => {
        setstaffstatus(res.data?.is_active ? "active" : "inactive");
        setStaffDetails(res.data);
        const date = new Date(res.data?.date_of_joining);
        setJoiningDate(date.toUTCString().substring(0, 16));
        if (res.data?.last_login) {
          const loginDate = new Date(res.data?.last_login);
          setLastLogin(loginDate.toUTCString().substring(0, 16));
        } else {
          setLastLogin("Not available");
        }
      })
      .catch((err) => {});
  };

  const handleStaffEdit = () => {
    if (!edit) {
      setEdit(true);
      setEditName(staffDetails?.fname);
      setEditEmail(staffDetails?.email);
      setEditPhoneNumber(staffDetails?.phone_number);
      const roleId = roleOptions.find(
        (obj) => obj.label === staffDetails?.role,
      );
      if (!roleId || !roleId?.value) {
        setEditRole(0);
      } else {
        setEditRole(roleId?.value);
      }
    }
  };

  const handleStaffSave = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const role = roleOptions.find((obj) => obj.value === editRole);

    const req: UpdateStaffRequestType = {
      data: {
        staff_id: id,
        fname: editName,
        email: editEmail,
        phone_number: editPhoneNumber,
        // role: role?.label ? role?.label : "",
        role: "",
        groupID: accessRoleList || [],
        branch: staffDetails?.branch,
        employeeID: staffDetails?.employeeID,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    updateStaff(req)
      .then((res) => {
        toast.success("Staff details updated successfully");
        setLoading(false);
        getstaff();
        setEdit(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
        setLoading(false);
      });
  };

  const handleSendPasswordResetLink = () => {
    setLoading(true);
    const req: PostSendForgotPasswordEmailRequestType = {
      data: {
        mail: staffDetails?.email,
      },
    };
    postSendForgotPasswordEmail(req)
      .then((res) => {
        toast.success("Password reset link sent successfully");
        getstaff();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
        setLoading(false);
      });
  };

  const handleondeleteStaff = (): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<DeleteStaffRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        staff_id: id,
      },
    };

    deleteStaff(req)
      .then((res) => {
        setLoading(false);
        getstaff();
        toast.success("Staff is Deactivated successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err?.response?.data?.message || "Something went wrong");
        if (err?.response?.data?.payload?.relationship_manager) {
          setPartnerList(err?.response?.data?.payload);
          showReassignEmployee(true);
        }
      });
  };

  const handleonActivateStaff = (): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<ActivateStaffRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        staff_id: id,
      },
    };

    activateStaff(req)
      .then((res) => {
        setLoading(false);
        getstaff();
        toast.success("Staff activated successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const status = staffDetails?.is_active;
  let statusbgColor;
  switch (status) {
    case true:
      statusbgColor = "bg-[#32CD32]";
      break;
    case false:
      statusbgColor = "bg-[#FFA500]";
      break;
  }

  // pre fetch data
  React.useEffect(() => {
    getstaff();
    fetchAccessRole();
  }, [id]);

  useEffect(() => {
    if (staffDetails?.groups) {
      const initialValues = staffDetails?.groups.map(
        (item: any) => item.groups_id,
      );
      setAccessRoleList(initialValues);
    }
  }, [staffDetails]);

  return (
    <>
      {reassignEmployee && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <ReassignEmployee
            branch_id={staffDetails?.branch}
            current_emp={staffDetails?.id}
            showReassignEmployee={showReassignEmployee}
            partnerList={partnerList}
            getstaff={getstaff}
          />
        </div>
      )}
      {loading && <Spinner />}
      <div className=" bg-white-A700 p-5 flex-grow h-full rounded-xl gap-8 flex-col flex sm:flex-col md:flex-col font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className=" flex w-full justify-between items-center pb-5">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Staff Details
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              Comprehensive details of {staffDetails?.employeeID}
            </p>
          </div>
          <div className=" flex gap-10">
            <button
              onClick={handleSendPasswordResetLink}
              className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
            >
              Send Password Reset Link
            </button>
            <AlertDialog>
              <AlertDialogTrigger>
                <button
                  onClick={handleStaffEdit}
                  className="rounded-lg py-[11px] px-4 border border-blue-700  text-blue-700  font-semibold text-sm"
                >
                  Edit
                </button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 py-5 bg-[#F9F9F9] rounded-xl px-10 ">
                    <AlertDialogTitle className="font-medium text-xl leading-7 text-black-label">
                      Edit Staff Details
                    </AlertDialogTitle>
                  </AlertDialogHeader>
                  <AlertDialogDescription className="pl-10 max-w-md">
                    <div className="flex flex-col py-5 px-10 gap-5">
                      <div className="flex relative flex-col sm:gap-10 gap-2 py-[3px] rounded-lg w-full">
                        <div
                          className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          Employee Name
                        </Text>
                        <Input
                          name="fname"
                          placeholder="Staff Name"
                          className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                          wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                          onChange={(e: any) => {
                            setEditName(e);
                          }}
                          value={editName}
                        ></Input>
                      </div>
                      <div className="flex relative flex-col sm:gap-10 gap-2 py-[3px] rounded-lg w-full">
                        <div
                          className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          Email address
                        </Text>
                        <Input
                          name="fname"
                          placeholder="Staff Email"
                          className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                          wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                          onChange={(e: any) => {
                            setEditEmail(e);
                          }}
                          value={editEmail}
                        ></Input>
                      </div>
                      <div className="flex relative flex-col sm:gap-10 gap-2 py-[3px] rounded-lg w-full">
                        <div
                          className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          Phone Number
                        </Text>
                        <Input
                          name="fname"
                          placeholder="Employee Phone Number"
                          className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                          wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                          onChange={(e: any) => {
                            setEditPhoneNumber(e);
                          }}
                          value={editPhoneNumber}
                        ></Input>
                      </div>
                      <div className="flex relative flex-col sm:gap-10 gap-2 py-[3px] rounded-lg w-full">
                        <div
                          className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          Access Group
                        </Text>
                        <ReactSelect
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              zIndex: 100,
                              borderRadius: ".5rem",
                              padding: ".6rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderColor: state.isFocused
                                ? "#165FE3"
                                : "#d2d2d2",
                              boxShadow: "none",
                            }),
                            menu: (provided, state) => ({
                              ...provided,
                              zIndex: 1000,
                            }),
                          }}
                          isMulti={true}
                          name="channels_id"
                          options={roleOptions}
                          isSearchable={true}
                          placeholder="Select Access Group"
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setAccessRoleList(selectedValues);
                          }}
                          value={roleOptions.filter((option) =>
                            accessRoleList?.includes(option.value),
                          )}
                        />
                      </div>
                    </div>
                  </AlertDialogDescription>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                    <AlertDialogCancel
                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                      asChild
                    >
                      <button>Go Back</button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={handleStaffSave}
                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                      asChild
                    >
                      <button className="">Save</button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          </div>
        </div>
        <div className="flex gap-10 p-3 flex-col w-full ">
          <div className="py-2 px-3 bg-black-900 w-fit rounded-lg">
            <div className="flex gap-2">
              <p className="text-white-A700 ">
                Employee ID:{" "}
                <span className="font-medium text-[18px] text-white-A700 leading-[14px]">
                  {staffDetails?.employeeID}
                </span>
              </p>
            </div>
          </div>
          <div className=" flex justify-between gap-10 w-full">
            <div className="bg-[#FCFCFC] p-5 h-min w-full rounded-[20px]">
              <div className="flex gap-5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.22457 3.71649C6.76715 3.12836 6.05276 2.75 5.25 2.75C3.86929 2.75 2.75 3.86929 2.75 5.25C2.75 6.06385 3.13889 6.78687 3.74097 7.24337"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.7734 3.7195C17.2307 3.12966 17.9461 2.75 18.7503 2.75C20.131 2.75 21.2503 3.86929 21.2503 5.25C21.2503 6.06148 20.8637 6.78265 20.2646 7.23937"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8V12L9 15"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 21.25L7 20M18 21.25L17 20"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="flex flex-col gap-5">
                  <div className="flex gap-5">
                    <p className="text-sm font-medium text-[#666666]">
                      Employee Name :
                    </p>
                    <p className="text-sm font-medium">{staffDetails?.fname}</p>
                    <div className="flex flex-col h-[34px] md:h-auto items-center justify-end  w-[150px]">
                      <SelectBox
                        className="bg-white-A700 border border-gray-200_01 border-solid font-medium p-1.5  rounded-lg text-[13px] text-blue_gray-700 text-left pl-[20px] w-[130px]"
                        placeholderClassName="bg-white-A700 text-blue_gray-700"
                        value={staffstatus}
                        // skipcq: JS-0417
                        onChange={(e) => {
                          if (e === "inactive") {
                            handleondeleteStaff();
                          } else if (e === "active") {
                            handleonActivateStaff();
                          }
                        }}
                        indicator={
                          <>
                            <div
                              className={`${statusbgColor} absolute top-[40%] left-[-10px] h-1.5 rounded-[50%] w-1.5`}
                            ></div>
                            <svg
                              width="22"
                              height="23"
                              viewBox="0 0 22 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                id="Background"
                                cx="11"
                                cy="11.75"
                                r="11"
                                fill="#2B2B2B"
                                fillOpacity="0.1"
                              />
                              <image
                                href="images/img_arrowdown.svg"
                                x="0"
                                y="0"
                                width="22"
                                height="23"
                              />
                            </svg>
                          </>
                        }
                        placeholder="Change Status"
                        isMulti={false}
                        options={statusOptionsList}
                        name="status"
                      />
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <p className="text-sm font-medium text-[#666666]">
                      Created by
                    </p>
                    <Link to="#" className="text-blue-700">
                      N/A
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="border p-5 gap-5 flex flex-col bg-[#FFFFFF] rounded-[20px] w-full">
              <div className="flex gap-3 items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.25 21.25C7.04086 21.25 5.25 19.4591 5.25 17.25V6.75C5.25 4.54086 7.04086 2.75 9.25 2.75H16.25C18.4591 2.75 20.25 4.54086 20.25 6.75V17.25C20.25 19.4591 18.4591 21.25 16.25 21.25H9.25Z"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M12.75 11.5C13.8546 11.5 14.75 10.6046 14.75 9.5C14.75 8.39543 13.8546 7.5 12.75 7.5C11.6454 7.5 10.75 8.39543 10.75 9.5C10.75 10.6046 11.6454 11.5 12.75 11.5Z"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M9.25 16.5C9.25 14.8431 10.5931 13.5 12.25 13.5H13.25C14.9069 13.5 16.25 14.8431 16.25 16.5"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3.75 8H6.75"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3.75 12H6.75"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3.75 16H6.75"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <h5>Employee Information</h5>
              </div>
              <div className="p-5 gap-5 flex bg-[#FCFCFC] rounded-[20px] flex-col">
                <div className="flex gap-5">
                  <p className="text-sm font-medium text-[#666666]">Phone :</p>
                  <p className="text-sm font-medium">
                    {staffDetails?.phone_number}
                  </p>
                </div>
                <div className="flex gap-5">
                  <p className="text-sm font-medium text-[#666666]">Email :</p>
                  <p className="text-sm font-medium">{staffDetails?.email}</p>
                </div>
                <div className="flex flex-wrap gap-2">
                  <p className="text-sm font-medium text-[#666666]">
                    Access Group:
                  </p>
                  {staffDetails?.groups?.map((item: any) => (
                    <p className="text-sm font-medium" key={item?.groups_id}>
                      {item?.name} ,{" "}
                    </p>
                  ))}
                </div>
              </div>
              <div className="p-5 gap-5 bg-[#FCFCFC] rounded-[20px] flex flex-col">
                <div className="flex gap-5">
                  <p className="text-sm font-medium text-[#666666]">Branch :</p>
                  <p className="text-sm font-medium">{staffDetails?.branch}</p>
                </div>
                <div className="flex gap-5">
                  <p className="text-sm font-medium text-[#666666]">
                    Date of Joining :
                  </p>
                  <p className="text-sm font-medium">{joiningDate}</p>
                </div>
                <div className="flex gap-5">
                  <p className="text-sm font-medium text-[#666666]">
                    Last Login :
                  </p>
                  <p className="text-sm font-medium">{lastLogin}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster theme="dark" position="top-right" />
    </>
  );
};

export default StaffdetailsPage;
