import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { editGroupSteps, groups, OptionsExtended } from "./utils";
import SelectProducts from "./create group/step3";
import { Spinner } from "components";
import Stepper from "components/Stepper/newstepper";
import { toast, Toaster } from "sonner";
import {
  resetState,
  setGroupDescription,
  setGroupName,
  setOffice,
  setOffices,
  setRole,
} from "redux/features/CreateGroup";
import { useDispatch } from "react-redux";
import { AxiosRequestConfig } from "axios";
import { editGroup, EditGroupRequestType } from "service/apis/CreateStaff";
import {
  getBranchList,
  GetBranchRegistrationRequestType,
} from "service/apis/BranchRegistration";

interface EditGroupDetailsProps {
  dialogOpen: boolean;
  handleDialogOpen: (value: boolean) => void;
  groupId: string;
}
export default function EditGroupDetails({
  dialogOpen,
  groupId,
  handleDialogOpen,
}: Readonly<EditGroupDetailsProps>) {
  const [currentStep, setCurrentStep] = useState(1);
  const state = useAppSelector((state) => state.CreateGroups);
  const [name, setName] = useState(() => state.group_name);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    if (currentStep === editGroupSteps.length) {
      dispatch(resetState());
      setCurrentStep(1);
      handleSubmit();
      return;
    }
    if (currentStep < editGroupSteps.length) {
      setCurrentStep((prev) => prev + 1);
    }
  };
  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const auth_token = localStorage.getItem("authToken");
    if (auth_token === null) return;
    const authToken = JSON.parse(auth_token);
    let payloadData = {
      groups_id: groupId,
      roles_id: state.role.toString(),
      branch_access: state.office.toString(),
      product_id: [
        ...state.products.remittance.map((product) => product.id),
        ...state.products.forex.map((product) => product.id),
        ...state.products.visa.map((product) => product.id),
        ...state.products.travel.map((product) => product.id),
        ...state.products.studyabroad.map((product) => product.id),
      ],
      description: state.group_description,
    };
    if (state.group_name !== name) {
      payloadData = Object.assign(payloadData, { name: state.group_name });
    }
    const payload: AxiosRequestConfig<EditGroupRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        ...payloadData,
      },
    };

    try {
      await editGroup(payload);
      setLoading(false);
      dispatch(resetState());
      toast.success("Group updated successfully");
      handleDialogOpen(false);
      navigate("/group");
    } catch (error: any) {
      setLoading(false);
      handleDialogOpen(false);
      toast.error("Failed to update group", { description: error.message });
    }
  };
  const buttonState = () => {
    switch (currentStep) {
      case 1:
        return state.role && state.office && state.group_name;
      case 2:
        return (
          state.products.remittance?.length ||
          state.products.forex?.length ||
          state.products.visa?.length ||
          state.products.travel?.length
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    if (dialogOpen) {
      setName(state.group_name);
    }
  }, [dialogOpen]);

  return (
    <AlertDialog
      open={dialogOpen}
      onOpenChange={(val) => {
        if (!val) {
          dispatch(resetState());
          setCurrentStep(1);
        }
        handleDialogOpen(val);
      }}
    >
      <AlertDialogTrigger></AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
        <AlertDialogContent className="bg-white-A700 flex flex-col rounded-lg p-0 h-[80vh] sm:h-[90vh] max-w-2xl animate-contentShow">
          <AlertDialogHeader className=" text-left border-b rounded-t h-min py-5 px-10 ">
            <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
              Edit group
            </AlertDialogTitle>
          </AlertDialogHeader>
          {/* //content */}
          <div className="h-full overflow-auto">
            <FormWithSteps loading={loading} currentStep={currentStep} />
          </div>
          <AlertDialogFooter className="flex justify-start sm:justify-start h-min items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
            <AlertDialogCancel
              className=" m-0 h-full rounded-lg text-sm font-semibold border-blue-700 border py-[11px] px-4 text-blue-700"
              asChild
            >
              <button>Cancel</button>
            </AlertDialogCancel>
            <div className="flex gap-3">
              {currentStep !== 1 && (
                <button
                  type="button"
                  onClick={handlePrevious}
                  className={`px-4 py-[11px] w-fit text-sm font-semibold border border-blue-700 rounded-lg text-blue-700 ${true ? "" : "opacity-40"}`}
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                disabled={!buttonState()}
                onClick={handleNext}
                className={`px-4 py-[11px] whitespace-nowrap text-sm font-semibold rounded-lg border border-blue-700 bg-blue-700 text-white-A700 disabled:opacity-40`}
              >
                {currentStep === editGroupSteps.length ? "Submit" : "Next"}
              </button>
            </div>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

function Step1() {
  const dottedBorderWidth = "after:h-28 after:border-blue-700";
  const dispatch = useDispatch();
  const state = useAppSelector((state) => state.CreateGroups);
  const fetchStaffBranches = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetBranchRegistrationRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        type: "",
      },
    };

    try {
      const res = await getBranchList(payload);
      if (res.status === 200) {
        const branches = res.data;
        const filterActiveBranches = branches.filter(
          (branch: any) => branch.status === "active",
        );
        const options = filterActiveBranches.map((branch) => ({
          value: branch?.id?.toString(),
          label: branch.name,
          type: branch.branch_type,
        }));
        dispatch(setOffices(options as OptionsExtended[]));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchStaffBranches();
  }, []);
  return (
    <form className="max-w-sm flex flex-col gap-6">
      <div className="flex relative top-0 flex-col sm:pl-0  gap-10 w-full max-w-md ">
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="group_name" className="text-sm text-black-label">
              Group name
            </label>
            <input
              type="text"
              placeholder="Enter the group name"
              onChange={(e) => dispatch(setGroupName(e.target.value))}
              id="group_name"
              value={state.group_name}
              className="border border-black-label h-12 rounded-lg p-4 w-full"
            />
          </div>
        </div>
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label
              htmlFor="group_description"
              className="text-sm text-black-label"
            >
              Description
            </label>
            <input
              type="text"
              placeholder="Enter the description"
              onChange={(e) => dispatch(setGroupDescription(e.target.value))}
              id="group_description"
              value={state.group_description}
              className="border border-black-label h-12 rounded-lg p-4 w-full"
            />
          </div>
        </div>
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="role" className="text-sm">
              Select the role
            </label>
            <ReactSelect
              className="w-full"
              placeholder="Select any one role"
              menuPlacement="bottom"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  zIndex: 0,
                  borderRadius: ".5rem",
                  padding: ".3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: state.isFocused ? "#165FE3" : "#333333",
                  boxShadow: "none",
                  height: "3rem",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19.6px",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000,
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#EFF4FF" : "#fff",
                  color: state.isSelected ? "#165FE3" : "#333333",
                  "&:hover": {
                    backgroundColor: "#EFF4FF",
                    color: "#165FE3",
                  },
                  padding: "14px 1rem",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19.6px",
                }),
              }}
              value={groups.find((group) => group.value === state.role)}
              options={groups}
              onChange={(group) => {
                dispatch(setRole(group?.value as string));
              }}
              name="role"
              id="role"
            />
          </div>
        </div>
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="office" className="text-sm">
              Select the office
            </label>
            <ReactSelect<OptionsExtended>
              className="w-full"
              placeholder="Select any one office"
              menuPlacement="top"
              menuPosition="absolute"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  zIndex: 0,
                  borderRadius: ".5rem",
                  padding: ".3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: state.isFocused ? "#165FE3" : "#333333",
                  boxShadow: "none",
                  height: "3rem",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19.6px",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 10000,
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#EFF4FF" : "#fff",
                  color: state.isSelected ? "#165FE3" : "#333333",
                  "&:hover": {
                    backgroundColor: "#EFF4FF",
                    color: "#165FE3",
                  },
                  padding: "14px 1rem",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19.6px",
                }),
              }}
              getOptionLabel={(option) => `${option.label} ${option.type}`}
              value={state.offices.find(
                (office) => office.value === state.office,
              )}
              options={state.offices}
              onChange={(group) => {
                dispatch(setOffice(group?.value as string));
              }}
              name="office"
              id="office"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

interface FormWithStepsProps {
  currentStep: number;
  loading: boolean;
}

function FormWithSteps({ currentStep, loading }: Readonly<FormWithStepsProps>) {
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 />;
      case 2:
        return <SelectProducts isEditing={true} />;
      default:
        return null;
    }
  };
  return (
    <div className="bg-[#EBEEF5] flex flex-col h-full overflow-hidden gap-3">
      {loading && <Spinner />}
      <div className="sm:p-1 bg-white-A700 h-full flex p-3 flex-col font-inter items-start justify-start mx-auto w-full">
        <div className="gap-2 w-full flex h-full flex-col">
          <div className=" flex items-start w-full gap-2 px-5 pt-2.5 pb-0">
            <div className="flex-1">
              <Stepper
                steps={editGroupSteps.map((val) => val.name)}
                currentStep={currentStep - 1}
              />
            </div>
          </div>
          <div className="rounded-xl flex flex-col justify-between h-full overflow-auto gap-5 w-full sm:px-3 px-5 py-2.5">
            <div className="flex relative flex-col gap-5 w-full flex-grow">
              {renderStep()}
            </div>
            {/* <div className="flex gap-3 sm:flex-col sm:items-start">
                            {currentStep !== 1 && (
                                <button
                                    type="button"
                                    onClick={handlePrevious}
                                    className={`px-4 py-[11px] w-fit text-sm font-semibold border border-blue-700 rounded-lg text-blue-700 ${true ? "" : "opacity-40"}`}
                                >
                                    Previous
                                </button>
                            )}
                            <button
                                type="button"
                                disabled={!buttonState()}
                                onClick={handleNext}
                                className={`px-4 py-[11px] whitespace-nowrap text-sm font-semibold rounded-lg border border-blue-700 bg-blue-700 text-white-A700 disabled:opacity-40`}
                            >
                                {currentStep === editGroupSteps.length ? "Submit" : "Next"}
                            </button>
                        </div> */}
          </div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </div>
  );
}
