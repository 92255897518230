import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AxiosRequestConfig } from "axios";
import {
  ArrowUpRight,
  EllipsisVertical,
  Eye,
  Package,
  Store,
  Users,
} from "lucide-react";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetMyCompanyDetailsRequestType,
  getMyCompanyDetails,
} from "service/apis/Company";
import { Spinner, Text } from "../../components";
import { toast } from "sonner";

const OtherDetailsTabs: React.FC<{ companyDetails: any }> = ({
  companyDetails,
}) => {
  const [activeSubTab, setActiveSubTab] = useState("outlets");

  const subTabs = [
    {
      id: "outlets",
      label: "Outlets",
      icon: Store,
      content: <OutletsComponent companyDetails={companyDetails} />,
      disabled: false,
    },
    {
      id: "products",
      label: "Products",
      icon: Package,
      content: "Coming Soon: Product details will be available soon.",
      disabled: true,
    },
    {
      id: "team",
      label: "Team",
      icon: Users,
      content: "Coming Soon: Team management features are under development.",
      disabled: true,
    },
  ];

  return (
    <TooltipProvider>
      <div className="flex w-full h-full gap-4 items-start">
        {/* Sidebar Tabs */}
        <Tabs
          value={activeSubTab}
          onValueChange={setActiveSubTab}
          className="flex flex-col w-[200px]"
        >
          <TabsList className="flex flex-col border-r border-gray-300 w-full h-full bg-[#FBFBFB]">
            {subTabs.map((tab) => (
              <div key={tab.id} className="w-full">
                {tab.disabled ? (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="cursor-not-allowed opacity-50">
                        <TabsTrigger
                          value={tab.id}
                          className="flex items-center gap-2 p-3 w-full rounded-[8px] text-left"
                          disabled
                        >
                          <tab.icon size={16} className="text-gray-600" />
                          {tab.label}
                        </TabsTrigger>
                      </div>
                    </TooltipTrigger>
                  </Tooltip>
                ) : (
                  <TabsTrigger
                    value={tab.id}
                    className={`flex items-center gap-2 p-3 w-full rounded-[8px] text-left 
                                          ${
                                            activeSubTab === tab.id
                                              ? "bg-[#fff] shadow-md font-semibold text-[#165FE3]"
                                              : "hover:bg-gray-100"
                                          }`}
                    onClick={() => setActiveSubTab(tab.id)}
                  >
                    <tab.icon size={16} className="text-[#165FE3]" />
                    {tab.label}
                  </TabsTrigger>
                )}
              </div>
            ))}
          </TabsList>
        </Tabs>

        {/* Main Content */}
        <div className="w-[90%] bg-gray-100 p-5 rounded-lg flex items-start">
          {subTabs.find((tab) => tab.id === activeSubTab)?.content}
        </div>
      </div>
    </TooltipProvider>
  );
};

const OutletsComponent = ({ companyDetails }: any) => {
  return (
    <>
      <div className="flex flex-col gap-4 p-2 w-full h-full">
        <div className="flex justify-between items-center w-full pb-4">
          <Text className="text-[#666666] text-[16px] font-medium">
            Outlet details
          </Text>
          <button
            className="bg-[#165FE3] text-[#fff] rounded-lg px-4 py-2"
            disabled
          >
            + Add Outlet
          </button>
        </div>
        {companyDetails?.branch?.map((item: any, index: number) => {
          return (
            <div
              className="flex justify-between items-center gap-4 w-full"
              key={index}
            >
              <div className="flex flex-col gap-6">
                <div className="flex gap-2 items-center">
                  <Text className="bg-[#E1EBFC] text-black text-[14px] px-2 py-1 rounded-md flex items-center justify-center min-w-[62px] h-[22px]">
                    Branch
                  </Text>
                  {/* <Text className="bg-[#F1F1F1] text-[#165FE3] text-[14px] px-2 py-1 rounded-md flex items-center justify-center min-w-[66px] h-[22px]">
                                            Primary
                                        </Text> */}
                </div>
                <Text className="text-[14px] text-[#666666]">
                  {item?.address_line_1}
                </Text>
              </div>

              <div className="flex gap-2 text-[#666666] text-[14px] items-center">
                <div className="flex items-center gap-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.835 19.67C15.2667 19.67 19.67 15.2667 19.67 9.835C19.67 4.40328 15.2667 0 9.835 0C4.40328 0 0 4.40328 0 9.835C0 15.2667 4.40328 19.67 9.835 19.67Z"
                      fill="#F1F1F1"
                    />
                    <path
                      d="M9.83656 4.85938C7.80072 4.85938 6.14844 6.51165 6.14844 8.5475C6.14844 10.544 7.7122 12.1668 9.78739 12.2258C9.81689 12.2258 9.85623 12.2258 9.8759 12.2258C9.89557 12.2258 9.92508 12.2258 9.94475 12.2258C9.95458 12.2258 9.96442 12.2258 9.96442 12.2258C11.9511 12.1569 13.5149 10.544 13.5247 8.5475C13.5247 6.51165 11.8724 4.85938 9.83656 4.85938Z"
                      fill="#333333"
                    />
                    <path
                      d="M16.5081 17.0582C14.7575 18.6711 12.4168 19.6645 9.84001 19.6645C7.26324 19.6645 4.92251 18.6711 3.17188 17.0582C3.40791 16.1632 4.04719 15.3469 4.98152 14.7175C7.66647 12.9275 12.0332 12.9275 14.6985 14.7175C15.6427 15.3469 16.2721 16.1632 16.5081 17.0582Z"
                      fill="#333333"
                    />
                  </svg>
                  <Text>{item?.name || "N/A"} |</Text>
                </div>
                <Text>{item?.phone || "N/A"} |</Text>
                <Text>{item?.email || "N/A"}</Text>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const MyCompanyDetailsPage: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [activeTab, setTab] = useState<string>("overview");
  const [companyDetails, setCompanydetails] = useState<any>();

  const fetchMyCompanyResults = (
    page: number,
    per_page: number,
    offset: number,
  ) => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetMyCompanyDetailsRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page: 0,
        per_page: 0,
        offset: 0,
      },
    };
    getMyCompanyDetails(payload)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setCompanydetails(response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  React.useEffect(() => {
    fetchMyCompanyResults(0, 0, 0);
  }, []);

  const tabsdata = [
    {
      id: 1,
      label: "Overview",
      value: "overview",
      content: "Make changes to your account here.",
    },
    {
      id: 2,
      label: "Bank Details",
      value: "bank-details",
      content: "Change your password here.",
    },
    {
      id: 3,
      label: "Other Details",
      value: "other-details",
      content: "Change your password here.",
    },
  ];

  const onTabChange = (value: string) => {
    setTab(value);
  };

  const DocumentSection = ({ title, document }: any) => {
    return document ? (
      <div className="flex flex-row gap-4 items-center w-full">
        <span className="text-gray-600_02 text-sm w-auto">{title}:</span>
        <div className="flex items-center gap-2">
          <Eye size={16} className="text-[#165FE3] cursor-pointer" />
          <a
            href={document.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#165FE3] underline"
          >
            View
          </a>
        </div>
      </div>
    ) : null;
  };

  const BusinessKYC = ({ companyDetails }: any) => {
    const documents = [
      { title: "PAN Card", document: companyDetails?.pan },
      { title: "Aadhar Front", document: companyDetails?.aadhar_front },
      { title: "Aadhar Back", document: companyDetails?.aadhar_back },
    ];

    return (
      <div className="flex flex-col gap-4 w-full">
        <div>
          <span className="text-[#666666] text-[14px] w-auto">
            Business KYC
          </span>
        </div>

        <div className="bg-[#F9F9F9] flex flex-1 flex-col gap-4 h-auto items-start justify-start md:px-10 sm:px-5 px-6 py-6 rounded-lg w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
            {documents.map((doc, index) => (
              <DocumentSection
                key={index}
                title={doc.title}
                document={doc.document}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      {loading && <Spinner />}
      <div className="p-2.5 gap-[30px] flex rounded-lg  bg-white-A700 flex-col w-full overflow-auto">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-[18px] w-full">
            <div className="flex gap-2">
              <div className="flex items-start gap-2">
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <h4 className="text-xl leading-[30px] capitalize text-black-label font-semibold">
                      {/* {companyDetails?.name} */}
                      {"N/A"}
                    </h4>
                  </div>
                  <p className="text-sm leading-[21px]  mt-1 font-normal">
                    <div className="flex items-center gap-4 text-[#999999]">
                      Partner ID : {companyDetails?.id} |{" "}
                      <p className="text-[#C4C4C4] text-sm font-normal leading-[21px] flex items-center">
                        {new Date(companyDetails?.created_at)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace(",", "")}
                      </p>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="justify-between w-full flex items-center flex-wrap gap-3">
          <div className="flex gap-2.5 items-center">
            <div className="flex gap-2.5 items-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.835 19.67C15.2667 19.67 19.67 15.2667 19.67 9.835C19.67 4.40328 15.2667 0 9.835 0C4.40328 0 0 4.40328 0 9.835C0 15.2667 4.40328 19.67 9.835 19.67Z"
                  fill="#F1F1F1"
                />
                <path
                  d="M9.83656 4.85938C7.80072 4.85938 6.14844 6.51165 6.14844 8.5475C6.14844 10.544 7.7122 12.1668 9.78739 12.2258C9.81689 12.2258 9.85623 12.2258 9.8759 12.2258C9.89557 12.2258 9.92508 12.2258 9.94475 12.2258C9.95458 12.2258 9.96442 12.2258 9.96442 12.2258C11.9511 12.1569 13.5149 10.544 13.5247 8.5475C13.5247 6.51165 11.8724 4.85938 9.83656 4.85938Z"
                  fill="#333333"
                />
                <path
                  d="M16.5081 17.0582C14.7575 18.6711 12.4168 19.6645 9.84001 19.6645C7.26324 19.6645 4.92251 18.6711 3.17188 17.0582C3.40791 16.1632 4.04719 15.3469 4.98152 14.7175C7.66647 12.9275 12.0332 12.9275 14.6985 14.7175C15.6427 15.3469 16.2721 16.1632 16.5081 17.0582Z"
                  fill="#333333"
                />
              </svg>
              <p className="text-sm leading-[21px]  mt-1 font-normal">
                <div className="flex items-center gap-4 text-[#999999]">
                  RM: {companyDetails?.rm_detail?.fname || "N/A"} |{" "}
                  <p className="text-[#C4C4C4] text-sm font-normal leading-[21px] flex items-center">
                    {companyDetails?.rm_detail?.city}
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
        <Tabs
          value={activeTab}
          onValueChange={onTabChange}
          className="sm:max-w-7xl sm:overflow-hidden"
        >
          <TabsList className="border-b border-[#C4D7F9] w-full hide-scrollbar flex justify-between rounded-none gap-2 h-12">
            {tabsdata.map((tab) => (
              <TabsTrigger
                key={tab.id}
                value={tab.value}
                className={`px-6 py-3.5 text-sm ${tab.value === activeTab && "border-t h-11 rounded-none font-semibold border-blue-700 text-blue-700 w-[215px] shadow bg-[#EFF4FF]"}`}
              >
                <div className="flex gap-2 items-center">{tab.label}</div>
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent
            className="p-3 gap-10 flex flex-col rounded-xl w-full overflow-auto"
            value="overview"
          >
            <div className="flex flex-col gap-4 w-full h-full">
              <div className="bg-[#F9F9F9] flex flex-1 flex-col gap-2.5 h-auto items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
                <div className="flex flex-row gap-2.5 items-center w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    Email ID :
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {companyDetails?.email}
                  </Text>
                </div>
                <div className="flex flex-row gap-2.5 items-center  w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    Organization Type :
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {/* {companyDetails?.type} */}
                    {"N/A"}
                  </Text>
                </div>
                <div className="flex flex-row gap-2.5 items-center  w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    My Trade
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {/* {companyDetails?.tradeProducts?.[0]?.name} */}
                    {companyDetails?.type}
                  </Text>
                </div>
              </div>

              {/* contract */}
              <div>
                <Text className="text-[#666666] text-[14px] w-auto">
                  My Contract
                </Text>
              </div>

              <div className="bg-[#F9F9F9] flex flex-1 flex-col gap-2.5 h-auto items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
                <div className="flex flex-row justify-between w-full">
                  <div className="flex flex-row gap-2.5 items-center">
                    <Text className="text-gray-600_02 text-sm w-auto">
                      My Contract :
                    </Text>
                    {companyDetails?.signed_contract ? (
                      <Text className="text-[#23AB28] border border-[#23AB28] rounded px-2 py-1 text-sm w-auto">
                        Signed
                      </Text>
                    ) : (
                      <Text className="text-[#FF0000] border border-[#FF0000] rounded px-2 py-1 text-sm w-auto">
                        Pending
                      </Text>
                    )}
                  </div>

                  {companyDetails?.signed_contract ? (
                    <a
                      href={companyDetails.signed_contract.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#165FE3] underline"
                    >
                      View Contract
                    </a>
                  ) : (
                    <button className="text-[#165FE3] underline flex items-center gap-1">
                      Sign Contract <ArrowUpRight size={16} />
                    </button>
                  )}
                </div>
              </div>

              {/* Business KYC */}
              <BusinessKYC companyDetails={companyDetails} />
            </div>
          </TabsContent>
          <TabsContent value="bank-details">
            <div className="flex flex-col gap-4 w-full h-full">
              {companyDetails?.bank_account?.items?.length > 0 ? (
                companyDetails.bank_account.items.map(
                  (item: any, index: number) => (
                    <div
                      key={index}
                      className="bg-[#F9F9F9] relative flex flex-1 flex-col gap-2.5 h-auto items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full"
                    >
                      <div className="absolute top-6 right-6 flex flex-row gap-4 items-center">
                        <div
                          className={`text-[#000] text-sm font-medium px-2 py-1 rounded-[6px] ${
                            item?.status === "incomplete"
                              ? "bg-gray-300"
                              : "bg-[#E1FFE2]"
                          }`}
                        >
                          {item?.status}
                        </div>
                        <button className="text-[#165FE3]">
                          <EllipsisVertical
                            size={18}
                            className="text-[#165FE3]"
                          />
                        </button>
                      </div>
                      <div className="w-full">
                        <div className="flex flex-row gap-2.5 items-center w-full flex-nowrap">
                          <Text className="text-[#000] font-[600] text-sm w-auto whitespace-nowrap">
                            {item?.bank_name}
                          </Text>
                          {item?.primary_account && (
                            <Text className="text-[#165FE3] text-[12px] font-[400] w-auto whitespace-nowrap">
                              Primary Bank
                            </Text>
                          )}
                        </div>

                        <div className="flex flex-row gap-2.5 items-center w-full">
                          <Text className="text-gray-600_02 text-sm w-auto whitespace-nowrap">
                            Bank Account number :
                          </Text>
                          <Text className="text-gray-900_01 text-sm w-auto whitespace-nowrap">
                            {item?.account_number}
                          </Text>
                        </div>

                        <div className="flex flex-row gap-2.5 items-center w-full">
                          <Text className="text-gray-600_02 text-sm w-auto whitespace-nowrap">
                            IFSC Code :
                          </Text>
                          <Text className="text-gray-900_01 text-sm w-auto whitespace-nowrap">
                            {item?.ifsc_code}
                          </Text>
                        </div>
                      </div>
                    </div>
                  ),
                )
              ) : (
                <div className="text-gray-500 text-sm">
                  No bank data available
                </div>
              )}
            </div>
          </TabsContent>
          <TabsContent value="other-details">
            <div className="flex flex-row gap-2 w-full h-full">
              <OtherDetailsTabs companyDetails={companyDetails} />
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MyCompanyDetailsPage;
