import { Circle } from "lucide-react";

export interface MilestoneItem {
  milestone: "Order Created" | "Order Processed" | "Order Fulfilled";
  added_at: number;
  added_by: number;
  id: string;
}

interface MilestoneTrackerProps {
  milestones: MilestoneItem[];
}

const ALL_MILESTONES: MilestoneItem["milestone"][] = [
  "Order Created",
  "Order Processed",
  "Order Fulfilled",
];

export default function MilestoneTracker({
  milestones,
}: MilestoneTrackerProps) {
  const getStatusColor = (
    milestone: MilestoneItem["milestone"],
    isCompleted: boolean,
  ) => {
    if (!isCompleted) {
      return <Circle className="w-4 h-4 text-gray-300 fill-gray-300" />;
    }

    switch (milestone) {
      case "Order Created":
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="7.88631" cy="8.13826" r="7.63826" fill="#00A82D" />
            <ellipse
              cx="3.89216"
              cy="5.35772"
              rx="1.6145"
              ry="3.10192"
              transform="rotate(32.6622 3.89216 5.35772)"
              fill="white"
            />
            <path
              d="M10.2133 13.7858C11.6969 12.9292 12.0063 10.6886 12.4522 11.4608C12.898 12.233 12.0567 13.5534 10.5731 14.41C9.08943 15.2665 7.5253 15.3349 7.07947 14.5627C6.63364 13.7905 8.72969 14.6424 10.2133 13.7858Z"
              fill="white"
            />
          </svg>
        );
      case "Order Processed":
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="7.88631" cy="8.13826" r="7.63826" fill="#083990" />
            <ellipse
              cx="3.89216"
              cy="5.35772"
              rx="1.6145"
              ry="3.10192"
              transform="rotate(32.6622 3.89216 5.35772)"
              fill="white"
            />
            <path
              d="M10.2133 13.7858C11.6969 12.9292 12.0063 10.6886 12.4522 11.4608C12.898 12.233 12.0567 13.5534 10.5731 14.41C9.08943 15.2665 7.5253 15.3349 7.07947 14.5627C6.63364 13.7905 8.72969 14.6424 10.2133 13.7858Z"
              fill="white"
            />
          </svg>
        );
      case "Order Fulfilled":
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="7.88631" cy="8.13826" r="7.63826" fill="#00A82D" />
            <ellipse
              cx="3.89216"
              cy="5.35772"
              rx="1.6145"
              ry="3.10192"
              transform="rotate(32.6622 3.89216 5.35772)"
              fill="white"
            />
            <path
              d="M10.2133 13.7858C11.6969 12.9292 12.0063 10.6886 12.4522 11.4608C12.898 12.233 12.0567 13.5534 10.5731 14.41C9.08943 15.2665 7.5253 15.3349 7.07947 14.5627C6.63364 13.7905 8.72969 14.6424 10.2133 13.7858Z"
              fill="white"
            />
          </svg>
        );
    }
  };

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const getCompletedMilestones = () => {
    return ALL_MILESTONES.filter((milestone) =>
      milestones.some((item) => item.milestone === milestone),
    );
  };

  const completedMilestones = getCompletedMilestones();

  return (
    <div className="max-w-md py-6">
      <div className="relative">
        {ALL_MILESTONES.map((milestone, index) => {
          const milestoneData = milestones.find(
            (item) => item.milestone === milestone,
          );
          const isCompleted = completedMilestones.includes(milestone);

          return (
            <div key={index} className="flex gap-3">
              <div className="flex flex-col items-center">
                {getStatusColor(milestone, isCompleted)}
                {index < ALL_MILESTONES.length - 1 && (
                  <div
                    className={`w-0.5 h-full my-1 ${
                      isCompleted &&
                      completedMilestones.includes(ALL_MILESTONES[index + 1])
                        ? "bg-[#00A82D]"
                        : "bg-gray-200"
                    }`}
                  />
                )}
              </div>
              <div className="pb-8">
                <h3
                  className={`font-medium ${isCompleted ? "text-blue-600" : "text-gray-400"}`}
                >
                  {milestone}
                </h3>
                {milestoneData && (
                  <>
                    <p className="text-sm text-gray-400">
                      {formatTimestamp(milestoneData.added_at)}
                    </p>
                    <p
                      className={`mt-1 text-sm ${isCompleted ? "text-gray-600" : "text-gray-400"}`}
                    >
                      Order ID - {milestoneData.id}
                    </p>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
