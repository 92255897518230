import { AxiosRequestConfig } from "axios";
import { SelectBox } from "components";
import { selectOptionType } from "components/SelectBox";
import { useCallback, useEffect, useState } from "react";
import { setProductCourrencyVal } from "redux/features/CreateOpportunity";
import { useAppDispatch } from "redux/hooks";
import { getCountry, GetCountryRequestType } from "service/apis/CreateDealapi";
import { toast } from "sonner";
import ReactSelect from "react-select";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  handlePrevious: () => void;
}

type countrydatatype = {
  country: string;
  currency: string;
};

export const L2Step5: React.FC<Step2Props> = ({
  handlePrevious,
  setPageTitle,
  setProcessState,
  setCurrentStep,
}) => {
  const [currencydata, setCurrencydata] = useState<countrydatatype[]>([]);
  const [countryoption, setCountryoption] = useState<selectOptionType[]>([]);
  const [country, setCountry] = useState<string>("");
  const dispatch = useAppDispatch();

  const fetchCountry = () => {
    const payload: AxiosRequestConfig<GetCountryRequestType> = {};
    getCountry(payload)
      .then((res) => {
        setCurrencydata(res.data);
        setCountryoption(
          res.data.map((item) => {
            return {
              label: item.country,
              value: item.country,
            };
          }),
        );
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleCountrySelect = useCallback(
    (e: any) => {
      setCountry(e);
      setPageTitle("Amount");
      const coun = currencydata.find((item) => item.country === e);
      if (e === "India") {
        dispatch(setProductCourrencyVal("INR"));
      } else if (coun?.currency) {
        dispatch(setProductCourrencyVal(coun.currency));
      }
      setProcessState((prevState: any) => ({
        ...prevState,
        Country: e,
        Currency: coun?.currency,
      }));
      setCurrentStep((prev) => prev + 1);
    },
    [currencydata, dispatch, setProcessState],
  );

  useEffect(() => {
    fetchCountry();
  }, []);

  return (
    <form className="w-full">
      <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-[450px] ">
        <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
          <ReactSelect<selectOptionType>
            name="country"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 0,
                borderRadius: "0.5rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#d2d2d2",
                boxShadow: "none",
                width: "100%",
              }),
            }}
            isMulti={false}
            isSearchable={true}
            placeholder="Select Country"
            options={countryoption}
            onChange={(value) => {
              handleCountrySelect(value?.value || "");
            }}
            value={countryoption.find((option) => option.value === country)}
            className="w-full"
          />
        </div>
        <button
          className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
          onClick={() => {
            handlePrevious();
            setPageTitle("Select Country");
            setCountry("");
            setProcessState((prevState: any) => ({
              ...prevState,
              Country: "",
              "Offer letter": "",
              Currency: "",
            }));
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </button>
      </div>
    </form>
  );
};
