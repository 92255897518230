import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useEffect, useMemo, useState } from "react";
import { DataTablePagination } from "./data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSelectedBeneficiarieRowId } from "redux/features/CreateOrder";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isToolbarVisible?: boolean;
  isPaginationVisible?: boolean;
  columnsBackground?: string;
  onRowClick?: (row: TData) => void;
  highlightRow?: boolean;
  isPageCountVisible?: boolean;
  dealUtilizationStatus?: string;
  setDealUtilizationStatus?: (status: string) => void;
  searchData?: {
    placeholder: string;
    columns: string[];
  };
  defaultSorting?: SortingState;
  onClickNextPage?: () => void;
  onClickPreviousPage?: () => void;
  onClickFirstPage?: () => void;
  onClickLastPage?: () => void;
  totalPages?: number;
  currentPage?: number;
  onClickPageSize?: (pageSize: number) => void;
  pageSize?: number;
  pageIndex?: number;
  canNextPage?: boolean;
  canPreviousPage?: boolean;
  pageCount?: number;
  getPageCount?: () => number;
  pageName: string;
  onGotoPage?: (page: number) => void;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  isPaginationVisible = true,
  columnsBackground,
  isToolbarVisible = true,
  isPageCountVisible = true,
  dealUtilizationStatus,
  setDealUtilizationStatus,
  onRowClick,
  searchData,
  highlightRow = false,
  defaultSorting = [],
  onClickFirstPage,
  onClickLastPage,
  onClickNextPage,
  onClickPageSize,
  onClickPreviousPage,
  onGotoPage,
  totalPages,
  currentPage,
  pageSize,
  pageIndex,
  canNextPage,
  canPreviousPage,
  getPageCount,
  pageName = "",
}: Readonly<DataTableProps<TData, TValue>>) {
  const dispatch = useAppDispatch();
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const { selected_beneficiarie_row_id } = useAppSelector(
    (state) => state.CreateOrder,
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
    enableGlobalFilter: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "includesString",
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const filteredRows = useMemo(() => {
    if (!globalFilter) return table.getRowModel().rows;

    return table.getRowModel().rows.filter((row) => {
      return searchData?.columns.some((columnName) => {
        const cellValue = row.getValue(columnName);
        return cellValue
          ?.toString()
          .toLowerCase()
          .includes(globalFilter.toLowerCase());
      });
    });
  }, [table?.getRowModel()?.rows, globalFilter, searchData?.columns]);

  useEffect(() => {}, [filteredRows]);

  useEffect(() => {
    if (
      !selectedRowId &&
      selected_beneficiarie_row_id &&
      pageName === "Add Beneficiary"
    ) {
      setSelectedRowId(selected_beneficiarie_row_id);
    }
  }, [selectedRowId, selected_beneficiarie_row_id, pageName]);

  return (
    <div className="space-y-5 ">
      {isToolbarVisible && (
        <DataTableToolbar
          searchData={searchData}
          dealUtilizationStatus={dealUtilizationStatus}
          setDealUtilizationStatus={setDealUtilizationStatus}
          table={table}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <Table className="w-full ">
        <TableHeader
          className={`${
            columnsBackground ? "bg-black-label" : "bg-custom-table-header"
          }`}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead className="text-white-A700" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {filteredRows?.length ? (
            filteredRows.map((row) => (
              <TableRow
                className={`border-none cursor-pointer ${
                  highlightRow && selectedRowId === row.id
                    ? "bg-[#e5eff3]"
                    : "hover:bg-blue_gray-lite-bg"
                }`}
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => {
                  setSelectedRowId(row.id);
                  if (pageName === "Add Beneficiary") {
                    dispatch(setSelectedBeneficiarieRowId(row.id));
                  }
                  onRowClick && onRowClick(row.original);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className="border-none">
              <TableCell colSpan={columns?.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {isPaginationVisible && (
        <DataTablePagination
          isPageCountVisible={isPageCountVisible}
          table={table}
          pageCount={table.getPageCount()}
          onClickNextPage={onClickNextPage}
          onClickPreviousPage={onClickPreviousPage}
          onClickFirstPage={onClickFirstPage}
          onClickLastPage={onClickLastPage}
          totalPages={totalPages}
          currentPage={currentPage}
          onClickPageSize={onClickPageSize}
          pageSize={pageSize}
          pageIndex={pageIndex}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          getPageCount={getPageCount}
          onGotoPage={onGotoPage}
        />
      )}
    </div>
  );
}
