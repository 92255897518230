import { Button } from "@/components/ui/button";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { AxiosRequestConfig } from "axios";
import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import { Spinner } from "components";
import { Option } from "components/Form/form";
import { Check, ChevronDown, User } from "lucide-react";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { ContactData } from "pages/Dashboard/dashboard-data";
import { formatUnixTimestamp } from "pages/Opportunities/utils/featureRule";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setPurposeId } from "redux/features/CreateDeal";
import {
  setContactPersonPhone,
  setCountryCode,
  setMediumOfContact,
  setMeetingType,
  setPrepareQuoteContactData,
  setProduct,
  setProductPurpose,
  setQuoteProduct,
  setQuotePurposeProduct,
} from "redux/features/CreateOpportunity";
import { setIsReferralMade } from "redux/features/CustomerRegistration";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getContactDetailsPrepareQuote,
  getPrepareQuoteNotes,
  GetPrepareQuoteNotesRequestType,
  GetSearchConatctForPrepareQuoteRequestType,
  PutUpdatePrepareQuoteNotes,
  PutUpdatePrepareQuoteNotesReqType,
} from "service/apis/CreateForexQuotation";
import {
  getQuoteforCustomer,
  GetQuoteLeadRequestType,
} from "service/apis/CustomerDetailsapi";
import { toast, Toaster } from "sonner";
import { L1ProductCard } from "./L2Step1ProductGroup";
import { L1Step2Component } from "./L2StepProductList";
import { L1Step3Component } from "./L2ProductCard";
import { L1ModeStep } from "./L2ModeStep";
import { L2EducationLoanStep3 } from "./L1EducationLoan";

const productOptions: Option[] = [
  { value: "remittance", label: "Remittance" },
  { value: "forex", label: "Forex" },
  { value: "visa", label: "Visa" },
  { value: "travel", label: "Travel" },
  { value: "studyabroad", label: "Study Abroad" },
];

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

// remittence mode
const remitProductModeOptions: Option[] = [
  { value: "Flywire", label: "Flywire" },
  { value: "WesternUnion", label: "Western Union" },
  { value: "CIBC", label: "CIBC" },
  { value: "PayMyTuition", label: "PayMyTuition" },
  { value: "ViaTransfer", label: "Via Transfer" },
];

const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

const remittenceProductLoantakenOptions: Option[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

interface Step1Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  setContactDetails?: React.Dispatch<React.SetStateAction<any>>; // Note the '?'
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  setPageTitle: React.Dispatch<React.SetStateAction<any>>;
}
interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setProductMode: React.Dispatch<React.SetStateAction<string>>;
  selectProduct: string;
  productMode: string;
  connectedContactList: any[];
  setIsProductLoan: React.Dispatch<React.SetStateAction<string>>;
  isProductLoan: string;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
}

interface Step3Props {
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setProductMode: React.Dispatch<React.SetStateAction<string>>;
  selectProduct: string;
  productMode: string;
  connectedContactList: any[];
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
}

const productPurposeOptions: Record<string, Option[]> = {
  remittance: remitProductPurposeOptions,
  forex: forexProductPurposeOptions,
  visa: visaProductPurposeOptions,
  travel: travelProductPurposeOptions,
};

const ComboBoxCurrency = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [countryCodeList, setCountryCodeList] = useState<string[]>([]);
  const { country_code } = useAppSelector((state) => state.CreateOpportunity);
  const [value, setValue] = useState("");
  const URL =
    "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json";

  const fetchCurrency = useCallback(async () => {
    try {
      const res = await fetch(URL);
      const data = await res.json();
      setCountryCodeList(data?.map((item: any) => item?.dial_code));
    } catch (error) {
      console.error(error);
    }
  }, [open]);

  return (
    <Popover
      open={open}
      onOpenChange={(val) => {
        setOpen(val);
        if (val) fetchCurrency();
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          aria-expanded={open}
          className="justify-between h-12 py-4 px-1 flex border-r-0 border-black-label rounded-tr-none rounded-br-none"
        >
          {country_code ? (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              {country_code}
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          ) : (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              Select
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-28 py-2">
        <Command className="p-0">
          <CommandInput placeholder="Code" />
          <CommandList>
            <CommandGroup>
              {countryCodeList?.map((framework, index) => (
                <CommandItem
                  key={index.toFixed(2)}
                  value={framework}
                  className="hover:bg-[#EFF4FF] py-3.5 w-full"
                  onSelect={(currentValue) => {
                    dispatch(setCountryCode(currentValue));
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === framework ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {framework}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

// for searching contact number based data customer or contact
export const Step1Component: React.FC<Step1Props> = ({
  setCurrentStep,
  setContactDetails = () => {},
  setProcessState,
  setPageTitle,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Partial<ContactData[]>>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [customerDetails, setcustomerDetails] = useState<any>();
  const [notesInputValue, setNotesInputValue] = useState<string>(
    customerDetails?.notes || "",
  );
  const [isSelectedNumber, setISSelectedNumber] = useState<boolean>(false);
  const ContactDetailsInfo = options?.[0];
  const [selectdConatcted, setSelectedContatcted] = useState<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleEditClick = () => {
    setNotesInputValue(customerDetails?.notes || "");
    setIsEditing(true);
  };

  // updated  notes
  const handlePrepareQuoteNotesUpdated = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    let payload: AxiosRequestConfig<PutUpdatePrepareQuoteNotesReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    payload.data = {
      phone_number: ContactDetailsInfo?.contactPhone as string,
      notes: notesInputValue || "",
    };

    try {
      const res = (await PutUpdatePrepareQuoteNotes(payload)) as any;
      if (res.status === 200) {
        toast.success("Customer notes updated successfully");
        setcustomerDetails(res?.data);
        setLoading(false);
        setIsEditing(false);
      }
    } catch (error) {
      toast.error("Failed to Customer notes updated");
      setLoading(false);
    }
  };

  const fetchGetQuoteNotes = async () => {
    if (!inputValue) {
      return;
    }
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetPrepareQuoteNotesRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        phone_number: selectdConatcted?.contactPhone
          ? selectdConatcted?.contactPhone
          : inputValue,
      },
    };
    try {
      if (inputValue === "") return;
      const response = (await getPrepareQuoteNotes(payload)) as any;
      if (response?.data?.length === 0) {
        return;
      }
      if (response.status === 200) {
        setcustomerDetails(response?.data);
        setLoading(false);
        setIsEditing(false);
        setNotesInputValue(response?.data?.notes);
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error("Search limit exceeded.", {
          description:
            "You have exceeded the limit of requests. Please try again later.",
        });
      }
      toast.error("Error fetching options");
    }
  };

  // search contact number
  const fetchSearchResults = async () => {
    if (!inputValue) {
      return;
    }
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetSearchConatctForPrepareQuoteRequestType> =
      {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          phone_number: inputValue,
        },
      };
    try {
      if (inputValue === "") return;
      const response = (await getContactDetailsPrepareQuote(payload)) as any;
      if (response?.data?.length === 0) {
        dispatch(setContactPersonPhone(inputValue));
        setOptions(() => []);
        setShowDropdown(true);
        setSelectedNumber(null);
        return;
      }
      let newOptions: ContactData[];
      newOptions = response?.data?.map((item: any) => ({
        value: item?.id,
        label: item?.contactName,
        contactName: item?.contactName,
        contactPhone: item?.contactNumber,
        email: item?.contactEmail,
        location: item?.contactAddress[1],
        type: item?.contactPersonType,
        contactType: item?.contactPersonType,
      }));

      const startingWithInput = newOptions.filter((item) =>
        item.contactPhone.startsWith(inputValue),
      );
      const containingInput = newOptions.filter(
        (item) =>
          !item.contactPhone.startsWith(inputValue) &&
          item.contactPhone.includes(inputValue),
      );

      // Prioritize numbers that start with inputValue
      const sortedOptions = [...startingWithInput, ...containingInput];

      setShowDropdown(true);
      setOptions(sortedOptions);
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error("Search limit exceeded.", {
          description:
            "You have exceeded the limit of requests. Please try again later.",
        });
      }
      toast.error("Error fetching options");
    }
  };

  // conatct Search
  useDebounce(
    () => {
      if (!isSelectedNumber) {
        fetchSearchResults();
      }
    },
    500,
    [inputValue, isSelectedNumber],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotesInputValue(e.target.value);
  };

  const handleSave = () => {
    if (notesInputValue) {
      handlePrepareQuoteNotesUpdated();
    }
  };

  const handleSelectList = (
    option: any,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    setInputValue(option.contactPhone);
    setSelectedNumber(option.contactPhone);
    setContactDetails(option);
    dispatch(setPrepareQuoteContactData(option));
    setShowDropdown(false);
    setSelectedContatcted(option);
    setISSelectedNumber(true);
  };

  useEffect(() => {
    if (customerDetails?.notes) {
      setNotesInputValue(customerDetails.notes);
    }
  }, [customerDetails]);

  // get notes
  useEffect(() => {
    if (inputValue) {
      fetchGetQuoteNotes();
    }
  }, [inputValue]);

  return (
    <div
      className={`max-w-md flex flex-col ${showDropdown ? "gap-4" : "gap-8"}`}
    >
      <label
        htmlFor="contact_person_phone"
        className={`text-sm font-medium text-[#333]`}
      >
        What is the Mobile Number ?
      </label>
      <div className="relative w-full h-12 flex rounded-md">
        <ComboBoxCurrency />
        <input
          type="number"
          placeholder="Enter the mobile number"
          onChange={(e) => {
            const value = e.target.value;
            setInputValue(value);
            if (value.trim() === "") {
              setOptions([]);
              setShowDropdown(false);
              setSelectedNumber(null);
              setISSelectedNumber(false);
            }
          }}
          value={inputValue}
          className="text-sm font-medium border border-black-label h-12 rounded-lg rounded-tl-none border-l-0 rounded-bl-none p-4 w-full"
        />
      </div>
      {showDropdown && (
        <div
          className={`${showDropdown ? "block" : "hidden"} relative w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg max-h-48 overflow-y-auto`}
        >
          {options?.length > 0
            ? options.map((option: any) => (
                <button
                  key={option.value}
                  className="flex w-full justify-between items-center p-3 text-sm font-medium cursor-pointer hover:bg-gray-100"
                  onClick={(event) => handleSelectList(option, event)}
                >
                  <span className="text-gray-600">{option.contactPhone}</span>
                  <span className="font-medium">{option.contactName}</span>
                </button>
              ))
            : !checkLevel(LEVEL_2) && (
                <div className="p-3">
                  <div className="text-sm font-medium flex justify-between items-center p-3">
                    <span className="text-gray-600">No Contact found</span>
                  </div>
                  {inputValue.length >= 10 && (
                    <button
                      className="flex w-full text-sm font-medium justify-between items-center p-3 cursor-pointer bg-[#165FE3] text-[#fff] rounded-md hover:bg-[#144AB6]"
                      onClick={() =>
                        navigate(`/lead/createcontact`, {
                          state: {
                            pageName: "prepareQuote",
                          },
                        })
                      }
                    >
                      <span className="font-medium w-full text-center">
                        + Add Contact
                      </span>
                    </button>
                  )}
                </div>
              )}
        </div>
      )}

      {/* contact deatils */}
      {selectedNumber && (
        <>
          <div className="flex flex-col gap-3 w-full">
            <div className="bg-[#F9F9F9] rounded-xl py-8 px-10 flex flex-col gap-6 w-full">
              <div className="flex flex-col gap-3 text-sm font-medium">
                <div className="flex items-center gap-2.5">
                  <img
                    src="/images/createorder/profileIcon.svg"
                    alt="Remitter Name"
                    className="w-5 h-5"
                  />
                  <small className="text-gray-900 text-[14px] font-medium">
                    {selectdConatcted?.contactName}
                  </small>
                </div>
                <div className="flex text-sm font-medium flex-col gap-3">
                  <div className="flex items-center gap-2.5">
                    <img
                      src="/images/createorder/call.svg"
                      alt="Phone"
                      className="w-5 h-5"
                    />
                    <small className="text-gray-900 text-[14px] font-medium">
                      {selectdConatcted?.contactPhone !== ""
                        ? selectdConatcted?.contactPhone
                        : "NA"}
                    </small>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <img
                      src="/images/createorder/sms.svg"
                      alt="Email"
                      className="w-5 h-5"
                    />
                    <small className="text-gray-900 text-[14px] font-medium">
                      {selectdConatcted?.email === ""
                        ? "NA"
                        : selectdConatcted?.email}
                    </small>
                  </div>
                </div>
                <div className="flex items-center gap-2.5">
                  <img
                    src="/images/createorder/location.svg"
                    alt="Address"
                    className="w-5 h-5"
                  />
                  <small className="max-w-xs text-gray-900 text-[14px] font-medium">
                    {selectdConatcted?.location !== ""
                      ? selectdConatcted?.location
                      : "NA"}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#fafafa] p-5 flex sm:flex-row gap-3 w-full md:w-full sm: w-full rounded-xl relative">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  {isEditing ? (
                    <div className="relative flex flex-col gap-2 items-start">
                      <textarea
                        value={notesInputValue}
                        onChange={handleInputChange}
                        className="text-[14px] font-medium border rounded p-2 sm:w-full w-[400px] resize-none focus:outline-none overflow-auto "
                        placeholder="Enter your notes here"
                        rows={4}
                        style={{ minHeight: "100px", maxHeight: "200px" }}
                      />
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={handleSave}
                          className="text-white-A700 cursor-pointer bg-blue-700 text-sm rounded-lg py-1.5 px-3 hover:bg-blue-700"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setIsEditing(false)}
                          className="text-gray-600 bg-gray-300 cursor-pointer text-sm rounded-lg py-1.5 px-3 hover:bg-gray-400"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[400px] h-[150px]">
                      {customerDetails?.notes ? (
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row gap-2">
                            <img
                              src="/images/intialNotes.svg"
                              width={15}
                              height={15}
                            />
                            <TooltipProvider>
                              <Tooltip>
                                {!checkLevel(LEVEL_2) && (
                                  <TooltipTrigger
                                    className="flex cursor-pointer items-center w-full md:w-auto"
                                    asChild
                                  >
                                    <div className="flex items-center space-x-1">
                                      <p className="text-[14px] font-medium md:text-base leading-[22px] md:leading-[25px] text-black-label">
                                        Internal notes
                                      </p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8-3a1 1 0 100 2 1 1 0 000-2zm-1 4a1 1 0 012 0v2a1 1 0 01-2 0v-2z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                  </TooltipTrigger>
                                )}
                                <TooltipContent
                                  side="bottom"
                                  className="bg-black-900 text-white-A700 max-w-[200px] whitespace-normal break-words border-0"
                                >
                                  <p className="text-[14px] font-medium md:text-base leading-[22px] md:leading-[25px]">
                                    Visible to the partner
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <p className="whitespace-normal break-words h-[70px] text-[14px] font-medium overflow-auto overflow-ellipsis">
                            {customerDetails?.notes || "N/A"}
                          </p>
                          <p className="font-normal text-[14px] font-medium leading-[18px] text-[#999999] mt-3">
                            {customerDetails?.updated_at && (
                              <>
                                Edited on{" "}
                                {formatUnixTimestamp(
                                  customerDetails?.updated_at,
                                )}{" "}
                                by {customerDetails?._staff?.fname}
                              </>
                            )}
                          </p>
                        </div>
                      ) : (
                        <p className="whitespace-normal text-[14px] font-medium break-words h-full overflow-hidden overflow-ellipsis">
                          No notes available
                        </p>
                      )}
                    </div>
                  )}
                </TooltipTrigger>
              </Tooltip>

              {/* Right div - Edit button */}
              <div className="flex ml-auto cursor-pointer">
                {!isEditing && !checkLevel(LEVEL_2) && (
                  <svg
                    onClick={handleEditClick}
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 cursor-pointer"
                  >
                    <g clipPath="url(#clip0_1689_36824)">
                      <path
                        d="M3.83594 4.82812H3.0026C2.56058 4.82813 2.13665 5.00372 1.82409 5.31628C1.51153 5.62884 1.33594 6.05276 1.33594 6.49479V13.9948C1.33594 14.4368 1.51153 14.8607 1.82409 15.1733C2.13665 15.4859 2.56058 15.6615 3.0026 15.6615H10.5026C10.9446 15.6615 11.3686 15.4859 11.6811 15.1733C11.9937 14.8607 12.1693 14.4368 12.1693 13.9948V13.1615"
                        stroke="#165FE3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.3333 3.16676L13.8333 5.66676M14.9875 4.48759C15.3157 4.15938 15.5001 3.71424 15.5001 3.25009C15.5001 2.78594 15.3157 2.34079 14.9875 2.01259C14.6593 1.68438 14.2142 1.5 13.75 1.5C13.2858 1.5 12.8407 1.68438 12.5125 2.01259L5.5 9.00009V11.5001H8L14.9875 4.48759Z"
                        stroke="#165FE3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1689_36824">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            </TooltipProvider>
          </div>
        </>
      )}
      <button
        className={`w-[150px] p-3 rounded-lg text-center text-[14px] font-medium ${selectedNumber ? "bg-[#165FE3] text-[#fff]" : "bg-gray-400 text-gray-200"}`}
        onClick={() => {
          setCurrentStep((prev) => prev + 1);
          setPageTitle("Select Product");
        }}
        disabled={!selectedNumber}
      >
        Select Product
      </button>
    </div>
  );
};

export const Step2Component: React.FC<Step1Props> = ({
  setCurrentStep,
  setSelectProduct,
  setProcessState,
}) => {
  const { product_purpose, quoteProduct } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const dispatch = useAppDispatch();

  const currentProductOptions = useMemo(() => {
    return (
      productPurposeOptions[
        quoteProduct as keyof typeof productPurposeOptions
      ] || []
    );
  }, [quoteProduct]);

  useEffect(() => {
    if (!quoteProduct) {
      dispatch(setQuoteProduct("remittance"));
    }
  }, [quoteProduct, dispatch]);

  const handleProductChoose = useCallback(
    (selectedValue: string) => {
      const selectedLabel = currentProductOptions.find(
        (tab: any) => tab.value === selectedValue,
      )?.label;
      if (selectedLabel) {
        dispatch(setProductPurpose(selectedValue));
        setSelectProduct(selectedLabel);
        dispatch(setQuotePurposeProduct(selectedLabel));
        setCurrentStep((prev) => prev + 1);
        setProcessState((prevState: any) => ({
          ...prevState,
          Product: selectedLabel,
        }));
      } else {
        toast.error("No matching label found for the selected value");
      }
    },
    [dispatch, setSelectProduct, setCurrentStep, currentProductOptions],
  );

  return (
    <div className="flex flex-col gap-6 max-w-md">
      <form className="max-w-md">
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <Tabs
              className="flex rounded-lg gap-2"
              value={quoteProduct}
              onValueChange={async (tab) => {
                if (["remittance", "forex"].includes(tab)) {
                  dispatch(setQuoteProduct(tab));
                  dispatch(setProductPurpose(""));
                  dispatch(setProduct(tab));
                }
              }}
            >
              <TabsList className="sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                {productOptions?.map(
                  (tab: { label: string; value: string }, index) => (
                    <TabsTrigger
                      key={tab?.value}
                      value={tab?.value}
                      disabled={index > 1}
                      className={`py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg 
                                                        ${tab?.value === quoteProduct ? "bg-navbar-menu-bg text-blue-700" : "text-black-label bg-[#F1F1F1]"}
                                                        ${index > 1 ? "opacity-50 cursor-not-allowed" : ""}
                                                    `}
                    >
                      {tab?.label}
                    </TabsTrigger>
                  ),
                )}
              </TabsList>
            </Tabs>
          </div>
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <label className={`text-[18px] font-medium text-[#333]`}>
              Select any one to proceed.
            </label>
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                onValueChange={handleProductChoose}
              >
                {currentProductOptions?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab?.value}
                      htmlFor={tab?.value}
                      className={`${
                        product_purpose === tab?.value
                          ? "border-blue-700"
                          : "border-black-label"
                      } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab?.value}
                        id={tab?.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab?.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export const Step3Component: React.FC<Step2Props> = ({
  setCurrentStep,
  currentStep,
  setProductMode,
  selectProduct,
  productMode,
  connectedContactList,
  setIsProductLoan,
  isProductLoan,
  setProcessState,
}) => {
  const { product_purpose, quoteProduct, product, prepare_quote_contact_data } =
    useAppSelector((state) => state.CreateOpportunity);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
      forex: forexProductPurposeOptions,
      visa: visaProductPurposeOptions,
      travel: travelProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === selectProduct,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleProductChoose = useCallback(
    (e: string) => {
      setProcessState((prevState: any) => ({
        ...prevState,
        Product: e,
      }));
      dispatch(setProductPurpose(e));
    },
    [dispatch],
  );

  const handleCardSelect = useCallback(
    (item: any) => setSelectedCardId(item.id),
    [],
  );

  const handleProductMode = useCallback(
    (value: string) => {
      setProductMode(value);
      setProcessState((prevState: any) => ({
        ...prevState,
        Mode: value,
      }));
    },
    [setProductMode],
  );

  const handleProductLoan = useCallback(
    (e: string) => {
      setIsProductLoan(e);
      setProcessState((prevState: any) => ({
        ...prevState,
        "Education Loan": e,
      }));
    },
    [setIsProductLoan],
  );

  const handlePrepareQuoteDirection = useCallback(
    (item: any, event: React.FormEvent) => {
      event.preventDefault();
      if (
        !productMode &&
        finalProductValue?.[0]?.label === "Education Payment"
      ) {
        toast.error("Please select Product Mode");
      } else if (quoteProduct === "remittance") {
        dispatch(setPurposeId(item?.purposeDetail?.purpose_id));
        navigate("/L2CreateRemittanceQuotation", {
          state: {
            contact_name: item?._contacts?.contactName,
            product_id: product_purpose,
            product_name: item?._product?.name,
            opportunity_id: item?.id,
            contact_id: location?.state?.id,
            channel_of_interaction_id: item?._channel_of_interaction?.[0]?.id,
            note: "Prepare Quote",
            ed_payment_mode: productMode,
            loan: isProductLoan === "true" ? true : false,
            purpose_id: item?.purposeDetail?.purpose_id,
            getQuoteFlow: "getQuoteremittanceFlow",
          },
        });
      }
    },
    [
      navigate,
      quoteProduct,
      product_purpose,
      productMode,
      isProductLoan,
      location,
      finalProductValue,
      connectedContactList,
    ],
  );

  // Prepare quote for a new lead redirection
  const handlePrepareQuote = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (quoteProduct === "remittance") {
        dispatch(setIsReferralMade(false));
        if (!product) {
          dispatch(setProduct(quoteProduct));
        }
        dispatch(
          setPurposeId(connectedContactList?.[0]?.purposeDetail?.purpose_id),
        );
        navigate("/lead/createlead", {
          state: {
            id: prepare_quote_contact_data?.value,
            contact_name: location?.state?.contact_name,
            contact_number: location?.state?.contact_number,
            contact_email: location?.state?.contact_email,
            currentpath: "customerdetails",
            quoteProduct: quoteProduct,
            product_purpose: product_purpose,
            getQuoteFlow: "getQuoteremittanceFlow",
            purpose_id: null,
            selectedProductname: finalProductValue?.[0]?.label,
            mode: productMode,
            took_loan: isProductLoan === "true" ? true : false,
          },
        });
      }
    },
    [
      navigate,
      quoteProduct,
      location,
      connectedContactList,
      isProductLoan,
      productMode,
      finalProductValue,
    ],
  );

  // state updated
  useEffect(() => {
    const productLabel = finalProductValue?.[0]?.label;
    const modeMapping: any = {
      "Gift / Family Maintenance": "Gift",
      "Study Maintenance": "StudyMaintenance",
    };

    if (productLabel !== "Education Payment") {
      const finalMode = modeMapping[productLabel] || productLabel;
      const finalLoan = ["Education Payment", "GIC"].includes(productLabel)
        ? isProductLoan
        : "false";
      if (productMode !== finalMode) {
        setProductMode(finalMode);
      }
      if (isProductLoan !== finalLoan) {
        setIsProductLoan(finalLoan);
      }
    }
  }, [finalProductValue, isProductLoan, productMode]);

  return (
    <div className="flex flex-col gap-6 max-w-[850px]">
      <form className="w-full">
        <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex items-end justify-between gap-10">
            <label className="text-[18px] font-medium text-[#333]">
              Select any one to proceed.
            </label>

            <button
              className="text-[#165FE3] text-[18px] font-bold underline"
              onClick={() => {
                setCurrentStep(currentStep - 1);
                setProcessState((prevState: any) => ({
                  ...prevState,
                  Product: "",
                  Mode: "",
                  "Education Loan": "",
                }));
              }}
            >
              Change
            </button>
          </div>

          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                onValueChange={handleProductChoose}
              >
                {finalProductValue?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        product_purpose === tab.value
                          ? " focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>

          {/* Mode Selection for Remittance */}
          {quoteProduct === "remittance" &&
            finalProductValue?.[0]?.label === "Education Payment" && (
              <div>
                <label className="text-[14px] font-medium text-[#000]">
                  Mode
                </label>
                <RadioGroup
                  className="flex w-full flex-row gap-4 mt-2"
                  value={productMode}
                  onValueChange={handleProductMode}
                >
                  {remitProductModeOptions?.map((tab) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        productMode === tab.value
                          ? "focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex w-auto items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
                    >
                      <RadioGroupItem
                        id={tab.value}
                        value={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ))}
                </RadioGroup>
              </div>
            )}

          {/* show here Loan about For Education and GIC */}
          {(finalProductValue?.[0]?.label === "Education Payment" ||
            finalProductValue?.[0]?.label === "GIC") && (
            <div>
              <label className="text-[14px] font-medium text-[#000]">
                Have you taken an education loan?
              </label>
              <RadioGroup
                className="flex w-full flex-row gap-4 mt-3"
                value={isProductLoan}
                onValueChange={handleProductLoan}
              >
                {remittenceProductLoantakenOptions?.map((tab) => (
                  <label
                    key={tab.value}
                    htmlFor={tab.value}
                    className={`${
                      isProductLoan === tab.value
                        ? "focus-within:border-blue-700 border-black-label"
                        : ""
                    } flex w-[250px] items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
                  >
                    <RadioGroupItem
                      id={tab.value}
                      value={tab.value}
                      className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                    />
                    <p className="text-[14px] font-medium w-full">
                      {tab.label}
                    </p>
                  </label>
                ))}
              </RadioGroup>
            </div>
          )}

          {connectedContactList?.length > 0 && (
            <>
              <div>
                <label className="text-[14px] font-medium text-[#000]">
                  We found an open lead for {selectProduct}!
                </label>
              </div>
              {connectedContactList?.map((item: any) => {
                return (
                  <div
                    className={`flex flex-row w-[650px] justify-between p-5 mb-4 rounded-lg cursor-pointer ${
                      selectedCardId === item.id
                        ? "border-2 border-blue-500"
                        : "border-1 border-[#88AEF3]"
                    }`}
                    style={{
                      background:
                        "linear-gradient(89.99deg, #FFFFFF 0.01%, #E1EBFC 99.99%)",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleCardSelect(item)}
                    key={item?.id}
                  >
                    <div
                      className="flex flex-col gap-4"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <h2 className="text-left">
                        <Label className="text-[18px] ">
                          {item?._product?.name}
                        </Label>
                        <span className="text-[#999999] px-2">|</span>
                        <Label className="text-[14px] text-[#999999] leading-[140%]">
                          {item?.mode_opp || "N/A"}
                        </Label>
                      </h2>
                      <div className="flex flex-row  items-center gap-2">
                        <Label className="text-[#999999] font-noraml text-[14px]">
                          Lead ID: OP - {item?.id} |
                        </Label>
                        <Label className="text-[#999999] text-[14px] font-noraml">
                          {new Date(item?.created_at)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace(",", "  |")
                            .replace(/ /g, " - ")
                            .replace(" - |", " |")
                            .replace(/-\s/g, " ")
                            .toUpperCase()}
                        </Label>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src="/images/ProfileIConNew.svg" />
                        <Label className="text-[16px] font-normal">
                          {item?._contacts?.contactName || "N/A"}
                        </Label>
                      </div>
                    </div>
                    <div
                      className="flex flex-col"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                        style={{ borderRadius: "10px", textAlign: "center" }}
                      >
                        {" "}
                        {item?._contacts?.customer ? "Customer" : "Contact"}
                      </Label>
                      <button
                        className="text-[#165FE3] text-[14px] font-bold underline"
                        onClick={(e) => handlePrepareQuoteDirection(item, e)}
                      >
                        Prepare Quote
                      </button>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          <button
            className={`w-[350px] text-[14px] text-[#fff] p-3 ${
              (finalProductValue?.[0]?.label === "Education Payment" &&
                (!productMode || isProductLoan === "")) ||
              (finalProductValue?.[0]?.label === "GIC" && isProductLoan === "")
                ? "bg-blue-200"
                : "bg-[#165FE3]"
            } rounded-lg text-center`}
            onClick={(e) => handlePrepareQuote(e)}
            disabled={
              (finalProductValue?.[0]?.label === "Education Payment" &&
                (!productMode || isProductLoan === "")) ||
              (finalProductValue?.[0]?.label === "GIC" && isProductLoan === "")
            }
          >
            Prepare quote for a new lead
          </button>
        </div>
      </form>
    </div>
  );
};

export const Step4Component: React.FC<Step3Props> = ({
  setCurrentStep,
  currentStep,
  selectProduct,
  productMode,
  connectedContactList,
  setProcessState,
}) => {
  const { product_purpose, quoteProduct, prepare_quote_contact_data, product } =
    useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
      forex: forexProductPurposeOptions,
      visa: visaProductPurposeOptions,
      travel: travelProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === selectProduct,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleProductChoose = useCallback(
    (e: string) => dispatch(setProductPurpose(e)),
    [dispatch],
  );

  const handleCardSelect = useCallback(
    (item: any) => setSelectedCardId(item.id),
    [],
  );

  const handlePrepareQuoteDirection = useCallback(
    (item: any) => {
      if (quoteProduct === "forex") {
        navigate("/createforexquotation", {
          state: {
            contact_name: item?._contacts?.contactName,
            product_id: product_purpose,
            product_name: item?._product?.name,
            opportunity_id: item?.id,
            contact_id: location?.state?.id,
            channel_of_interaction_id: item?._channel_of_interaction?.[0]?.id,
            note: "Prepare Quote",
            remarks: "",
            getQuoteFlow: "getQuoteremittanceFlow",
          },
        });
      }
    },
    [navigate, quoteProduct, product_purpose, productMode, location],
  );

  // Prepare quote for a new lead redirection
  const handlePrepareQuote = useCallback(() => {
    if (quoteProduct === "forex") {
      if (!product) {
        dispatch(setProduct(quoteProduct));
      }
      navigate("/lead/createlead", {
        state: {
          id: prepare_quote_contact_data?.value,
          contact_name: location?.state?.contact_name,
          contact_number: location?.state?.contact_number,
          contact_email: location?.state?.contact_email,
          quoteProduct: quoteProduct,
          product_purpose: product_purpose,
          currentpath: "customerdetails",
          getQuoteFlow: "getQuoteforexFlow",
          purpose_id: null,
          selectedProductname: finalProductValue?.[0]?.label,
          mode: "",
          took_loan: false,
        },
      });
    }
  }, [navigate, quoteProduct, location, finalProductValue]);

  return (
    <form className="w-full">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
        <div className="flex items-end justify-between gap-10">
          <label className="text-[18px] text-[#6B7280]">
            Select any one to proceed.
          </label>
        </div>

        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          {quoteProduct && (
            <RadioGroup
              className="flex w-full flex-col gap-4"
              value={product_purpose}
              onValueChange={handleProductChoose}
            >
              {finalProductValue?.map(
                (tab: { label: string; value: string }) => (
                  <label
                    key={tab.value}
                    htmlFor={tab.value}
                    className={`${
                      product_purpose === tab.value
                        ? " focus-within:border-blue-700 border-black-label"
                        : ""
                    } flex w-auto items-center rounded-lg border p-3 space-x-2`}
                  >
                    <RadioGroupItem
                      value={tab.value}
                      className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4
                       checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500
                      dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                    />
                    <p className="w-full">{tab.label}</p>
                  </label>
                ),
              )}
            </RadioGroup>
          )}
        </div>

        {connectedContactList?.length > 0 && (
          <>
            <div>
              <label className="text-[18px] text-[#000]">
                We found an open lead for {selectProduct}!
              </label>
            </div>
            {connectedContactList?.map((item: any) => {
              return (
                <div
                  className={`flex flex-row justify-between p-5 mb-4 rounded-lg cursor-pointer ${
                    selectedCardId === item.id
                      ? "border-2 border-blue-500"
                      : "border-1 border-[#88AEF3]"
                  }`}
                  style={{
                    background:
                      "linear-gradient(89.99deg, #FFFFFF 0.01%, #E1EBFC 99.99%)",
                    justifyContent: "space-between",
                  }}
                  onClick={() => handleCardSelect(item)}
                  key={item?.id}
                >
                  <div
                    className="flex flex-col gap-4"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Label className="text-[gray] text-[16px]">
                      Lead ID: OP - {item?.id}
                    </Label>
                    <Label>
                      {new Date(item?.created_at)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })
                        .replace(",", "  |")
                        .replace(/ /g, " - ")
                        .replace(" - |", " |")
                        .replace(/-\s/g, " ")
                        .toUpperCase()}
                    </Label>
                    <Label className="text-[18px]">
                      {item?._product?.name}
                    </Label>
                    <div className="flex flex-row">
                      <User />
                      <Label className="text-[16px]">
                        {item?._contacts?.contactName}
                      </Label>
                    </div>
                  </div>
                  <div
                    className="flex flex-col"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                      style={{ borderRadius: "10px", textAlign: "center" }}
                    >
                      {" "}
                      {item?._contacts?.customer ? "Customer" : "Contact"}
                    </Label>
                    <button
                      className="text-[#165FE3] font-bold underline"
                      disabled={!product_purpose}
                      onClick={() => handlePrepareQuoteDirection(item)}
                    >
                      Prepare Quote
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
        <div className="flex flex-row gap-8">
          <button
            className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
            onClick={() => {
              setCurrentStep((prev) => prev - 1), dispatch(setQuoteProduct(""));
              setProcessState((prevState: any) => ({
                ...prevState,
                Product: "",
              }));
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Back
          </button>
          <button
            className={`w-[350px] text-[14px] text-[#fff] p-3 bg-blue-500 rounded-lg text-center`}
            onClick={(e) => handlePrepareQuote(e)}
          >
            {connectedContactList?.length > 0
              ? "Prepare quote for a new lead"
              : "Prepare Quote"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default function PrepareOuoteL1FlowSteps({ setPageTitle }) {
  const { product_purpose, quoteProduct } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [productMode, setProductMode] = useState<string>("");
  const [selectProduct, setSelectProduct] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [connectedContactList, setConneectedList] = useState([]);
  const [contactDetails, setContactDetails] = useState<any>();
  const [isProductLoan, setIsProductLoan] = useState<string>("");
  const [processState, setProcessState] = useState<any>({
    "Product Group": "",
    Product: "",
    Mode: "",
    "Education Loan": "",
    "Offer Letter": "",
    Country: "",
  });

  useEffect(() => {
    dispatch(setProductPurpose(""));
    dispatch(setProduct(""));
    dispatch(setMediumOfContact(""));
    dispatch(setMeetingType(""));
  }, [location.pathname, dispatch]);

  const fetchGetQuoteDetails = useCallback(async () => {
    try {
      setLoading(true);
      const auth = localStorage.getItem("authToken");
      if (!auth) throw new Error("Authorization token is missing.");

      const authToken = JSON.parse(auth);
      const contactId = location?.state?.id || contactDetails?.value;
      if (!contactId) throw new Error("Contact ID is missing.");

      const payload: AxiosRequestConfig<GetQuoteLeadRequestType> = {
        data: {
          product_id: product_purpose,
          contacts_id: contactId,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const res: any = await getQuoteforCustomer(payload);

      if (!res?.data || res?.data?.length === 0) {
        setConneectedList([]);
        return;
      }

      // based on Mode
      // if(productMode === "StudyMaintenance" || productMode === "Gift" || productMode === "GIC"){
      setConneectedList(res?.data);
      // }else {
      //   const finalContactList = res?.data?.filter(
      //     (item: any) => item?.mode_opp === productMode,
      //   );
      //   setConneectedList(finalContactList);
      // }
    } catch (err: any) {
      toast.error(err.message || "Error fetching partner details.");
    } finally {
      setLoading(false);
    }
  }, [
    product_purpose,
    productMode,
    contactDetails?.value,
    location?.state?.id,
    isProductLoan,
  ]);

  useEffect(() => {
    // if (product_purpose && isProductLoan && productMode) {
    if (currentStep === 4 && product_purpose) {
      fetchGetQuoteDetails();
    }

    // }
  }, [product_purpose, currentStep]);
  // }, [product_purpose, productMode, fetchGetQuoteDetails, isProductLoan]);

  return (
    <>
      {isLoading && <Spinner />}
      <div className="sm:p-1 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
        <div className="gap-5 w-full flex h-full flex-col">
          <div className="rounded-xl flex h-full gap-5 w-full sm:px-0 px-5 justify-between py-2.5 overflow-auto">
            <div className="flex relative flex-col gap-5 overflow-y-auto flex-grow">
              {/* select contact */}
              {currentStep === 1 && (
                <Step1Component
                  setCurrentStep={setCurrentStep}
                  setContactDetails={setContactDetails}
                  setSelectProduct={setSelectProduct}
                  setProcessState={setProcessState}
                  setPageTitle={setPageTitle}
                />
              )}
              {/* product group list */}
              {currentStep === 2 && (
                <L1ProductCard
                  setCurrentStep={setCurrentStep}
                  setSelectProduct={setSelectProduct}
                  setProcessState={setProcessState}
                  setPageTitle={setPageTitle}
                />
              )}
              {/* product */}
              {currentStep === 3 && (
                <L1Step2Component
                  setCurrentStep={setCurrentStep}
                  setSelectProduct={setSelectProduct}
                  setProcessState={setProcessState}
                  setPageTitle={setPageTitle}
                />
              )}
              {/* product card */}
              {currentStep === 4 && quoteProduct === "remittance" && (
                <>
                  <L1Step3Component
                    setCurrentStep={setCurrentStep}
                    productMode={productMode}
                    currentStep={currentStep}
                    setProductMode={setProductMode}
                    selectProduct={selectProduct}
                    connectedContactList={connectedContactList}
                    setIsProductLoan={setIsProductLoan}
                    isProductLoan={isProductLoan}
                    setProcessState={setProcessState}
                    setSelectProduct={setSelectProduct}
                  />
                </>
              )}

              {currentStep === 4 && quoteProduct === "forex" && (
                <>
                  <Step4Component
                    setCurrentStep={setCurrentStep}
                    productMode={productMode}
                    currentStep={currentStep}
                    setProductMode={setProductMode}
                    selectProduct={selectProduct}
                    connectedContactList={connectedContactList}
                    setSelectProduct={setSelectProduct}
                    setProcessState={setProcessState}
                  />
                </>
              )}
              {/* product Mode */}
              {currentStep === 5 && (
                <L1ModeStep
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                  setPageTitle={setPageTitle}
                  setProcessState={setProcessState}
                  setProductMode={setProductMode}
                  processState={processState}
                  isProductLoan={isProductLoan}
                  productMode={productMode}
                  selectProduct={selectProduct}
                  contactDetails={contactDetails}
                  connectedContactList={connectedContactList}
                />
              )}
              {/* product loan */}
              {currentStep === 6 && (
                <L2EducationLoanStep3
                  setIsProductLoan={setIsProductLoan}
                  setCurrentStep={setCurrentStep}
                  setPageTitle={setPageTitle}
                  setProcessState={setProcessState}
                  isProductLoan={isProductLoan}
                  processState={processState}
                  productMode={productMode}
                  selectProduct={selectProduct}
                  connectedContactList={connectedContactList}
                />
              )}
            </div>
            {Object.keys(processState).some((key) => processState[key]) && (
              <div className="flex flex-col gap-5 sm:hidden w-[20%] overflow-y-auto">
                <h3 className="font-semibold text-lg text-black">
                  <span className="capitalize">You Selected</span>
                </h3>
                <div className="flex flex-col gap-4">
                  {Object.keys(processState)
                    .filter((key) => processState[key])
                    .map((key) => (
                      <div
                        key={key}
                        className="flex flex-col items-start gap-2"
                      >
                        <span className="text-[14px] font-semibold">{key}</span>
                        <h1 className="w-full bg-[#F9F9F9] text-[#333] text-[14px] font-medium py-1 px-2 rounded-md text-center">
                          {processState[key]}
                        </h1>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </>
  );
}
