import { Circle } from "lucide-react";

const getIdType = (milestoneData: Readonly<MilestoneItem>): string => {
  switch (milestoneData?.milestone?.toLowerCase()) {
    case "lead created":
      return "Lead ID";
    case "deal created":
      return "Deal ID";
    default:
      return "Order ID";
  }
};

export interface MilestoneItem {
  milestone:
    | "Lead Created"
    | "Deal created"
    | "Order created"
    | "Order Processed"
    | "Order Fulfilled";
  added_at: number;
  added_by: number;
  id: string;
}

interface MilestoneTrackerProps {
  milestones: MilestoneItem[];
  lead_id: string;
}

const ALL_MILESTONES: MilestoneItem["milestone"][] = [
  "Lead Created",
  "Deal created",
  "Order created",
  "Order Processed",
  "Order Fulfilled",
];

export default function MilestoneTracker({
  lead_id,
  milestones,
}: Readonly<MilestoneTrackerProps>) {
  const getStatusColor = (
    milestone: MilestoneItem["milestone"],
    isCompleted: boolean,
    isCurrentMilestone: boolean,
  ) => {
    if (!isCompleted) {
      return <Circle className="w-4 h-4 text-gray-300 fill-gray-300" />;
    }
    if (milestone === "Lead Created") {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7.88631" cy="8.13826" r="7.63826" fill={"#00A82D"} />
          <ellipse
            cx="3.89216"
            cy="5.35772"
            rx="1.6145"
            ry="3.10192"
            transform="rotate(32.6622 3.89216 5.35772)"
            fill="white"
          />
          <path
            d="M10.2133 13.7858C11.6969 12.9292 12.0063 10.6886 12.4522 11.4608C12.898 12.233 12.0567 13.5534 10.5731 14.41C9.08943 15.2665 7.5253 15.3349 7.07947 14.5627C6.63364 13.7905 8.72969 14.6424 10.2133 13.7858Z"
            fill="white"
          />
        </svg>
      );
    }

    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="7.88631"
          cy="8.13826"
          r="7.63826"
          fill={isCurrentMilestone ? "#083990" : "#00A82D"}
        />
        <ellipse
          cx="3.89216"
          cy="5.35772"
          rx="1.6145"
          ry="3.10192"
          transform="rotate(32.6622 3.89216 5.35772)"
          fill="white"
        />
        <path
          d="M10.2133 13.7858C11.6969 12.9292 12.0063 10.6886 12.4522 11.4608C12.898 12.233 12.0567 13.5534 10.5731 14.41C9.08943 15.2665 7.5253 15.3349 7.07947 14.5627C6.63364 13.7905 8.72969 14.6424 10.2133 13.7858Z"
          fill="white"
        />
      </svg>
    );
  };

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const getCompletedMilestones = () => {
    return ALL_MILESTONES.filter((milestone) =>
      milestones?.some(
        (item) => item?.milestone?.toLowerCase() === milestone?.toLowerCase(),
      ),
    );
  };

  const completedMilestones = getCompletedMilestones();
  const currentMilestoneIndex = completedMilestones.length - 1;

  return (
    <div className="max-w-md py-6">
      <div className="relative">
        {ALL_MILESTONES.map((milestone, index) => {
          const milestoneData = milestones?.find(
            (item) => item?.milestone.toLowerCase() === milestone.toLowerCase(),
          );
          const isCompleted = completedMilestones.includes(milestone);
          const isCurrentMilestone = index === currentMilestoneIndex;

          return (
            <div key={`milestone-${index}`} className="flex gap-3">
              <div className="flex flex-col items-center">
                {getStatusColor(milestone, isCompleted, isCurrentMilestone)}
                {index < ALL_MILESTONES.length - 1 && (
                  <div
                    className={`w-0.5 h-full my-1 ${
                      isCompleted && index < currentMilestoneIndex
                        ? "bg-[#00A82D]"
                        : isCompleted && index === currentMilestoneIndex
                          ? "bg-[#083990]"
                          : "bg-gray-200"
                    }`}
                  />
                )}
              </div>
              <div className="pb-8">
                <h3
                  className={`font-medium ${isCompleted ? "text-blue-600" : "text-gray-400"}`}
                >
                  {milestone}
                </h3>
                {milestoneData && (
                  <>
                    <p className="text-[#999999] font-normal text-xs leading-[13.2px]">
                      {formatTimestamp(milestoneData.added_at)}
                    </p>
                    <p
                      className={`mt-1 text-sm ${isCompleted ? "text-gray-600" : "text-gray-400"}`}
                    >
                      {getIdType(milestoneData)} :{" "}
                      {getIdType(milestoneData) === "Lead ID"
                        ? lead_id
                        : milestoneData.id}
                    </p>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
