import { AxiosPromise, AxiosRequestConfig } from "axios";
import { apis } from "service";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";
const get_country_List = `${BASE_URL}:M4Re3bq8/getCountryName`;
const get_university_list = `${BASE_URL}:M4Re3bq8/getUniversity`;

// get Country List
export type GetCountryListRequestType = Partial<{
  headers: {
    Authorization: string;
  };
}>;
export type GetCountryListResponseType = Partial<{}>;

export const getAllCountryList = (): AxiosPromise => apis.get(get_country_List);

// get_university_list
export type PostUniversityRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  country: string;
}>;
export type PostUniversityResponseType = Partial<{}>;

export const getUniversity = (
  payload: AxiosRequestConfig<PostUniversityRequestType>,
): AxiosPromise => apis.post(get_university_list, payload);
