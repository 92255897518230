import { Option } from "components/Form/form";
import { z } from "zod";

export const CreatePartnerLeadSchema = z.object({
  partner_type: z.string(),
  partner_subtype: z.string(),
  name: z.string(),
  partner_state: z.string(),
  partner_city: z.string(),
  services: z.array(z.string()),
  branch: z.string(),
  assigned_to: z.string(),
  contacted_type: z.enum(["i_contacted", "partner_contacted"]),
  channelofcontact_type: z.string(),
  meeting_time: z.string(),
  meeting_members: z.array(z.string()),
  contacted_to: z.string(),
  notes: z.string(),
  channel_of_interaction_id: z.number(),
  contact_id: z.number(),
  relationship_with_partner: z.string() || "owner",
});

export const step1Schema = z.object({
  partne_type: z.string(),
  partner_subtype: z.string(),
  name: z.string(),
  partner_state: z.string(),
  partner_city: z.string(),
});

export const step2Schema = z.object({
  services: z.array(z.string()),
  branch: z.string(),
  assigned_to: z.string(),
});

export const step3Schema = z.object({
  contacted_type: z.enum(["i_contacted", "partner_contacted"]),
  channelofcontact_type: z.string(),
  meeting_time: z.string(),
  meeting_members: z.array(z.string()),
  contacted_to: z.string(),
  notes: z.string(),
  channel_of_interaction_id: z.number(),
  office_photo: z.string(),
});

export type CreatePartnerLeadType = z.infer<typeof CreatePartnerLeadSchema>;

export const defaultValues: CreatePartnerLeadType = {
  partner_type: "",
  partner_subtype: "",
  name: "",
  partner_state: "",
  partner_city: "",
  services: [],
  branch: "",
  assigned_to: "",
  contacted_type: "i_contacted",
  channelofcontact_type: "",
  meeting_time: new Date().toISOString(),
  meeting_members: [],
  contacted_to: "",
  notes: "",
  channel_of_interaction_id: 0,
  contact_id: 0,
  relationship_with_partner: "",
};

export const partner_subtype_options: { [key: string]: Option[] } = {
  entity: [
    { label: "Education Consultant", value: "education_consultant" },
    { label: "Ed tech", value: "ed_tech" },
    { label: "Travel agent", value: "travel_agent" },
    { label: "Other", value: "other" },
  ],
  freelancer: [{ label: "Freelancer", value: "freelancer" }],
};

export const services_options: Option[] = [
  { label: "Remittance", value: "remit" },
  { label: "Visa", value: "visa" },
  { label: "Travel", value: "travel" },
];

export const channel_of_contact: { [key: string]: Option[] } = {
  i_contacted: [
    { label: "Meeting", value: "meeting" },
    { label: "Call", value: "phone" },
    { label: "Email", value: "email" },
    { label: "Chat", value: "in_person" },
  ],
  partner_contacted: [
    { label: "Email", value: "email" },
    { label: "Call", value: "phone" },
    { label: "Meeting", value: "meeting" },
  ],
};
