import { useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { CreateCorporateLeadType } from "./schema";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { getCity, getState } from "service/apis/PartnerRegistrationapi";
import { useEffect, useState } from "react";

const Step1 = () => {
  const methods = useFormContext<CreateCorporateLeadType>();
  const [cityOptions, setCityOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [stateOptions, setStateOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const {
    company_city,
    corporate_type,
    company_name,
    company_website,
    company_state,
  } = methods.watch();

  function fetchCity(state_id: string) {
    if (!state_id) return;
    const req = { params: { state_id } };
    getCity(req)
      .then((res) => {
        const cities = res.data as Array<{ id: number; name: string }>;
        setCityOptions(
          cities.map((city) => ({
            label: city.name,
            value: city.id.toString(),
          })),
        );
      })
      .catch(() => {});
  }

  function fetchState() {
    getState({})
      .then((res) => {
        const states = res.data as Array<{ id: string; name: string }>;
        setStateOptions(
          states.map((state) => ({ label: state.name, value: state.id })),
        );
      })
      .catch(() => {});
  }

  useEffect(() => {
    fetchState();
  }, []);
  useEffect(() => {
    if (stateOptions.length > 0 || methods.getValues("company_state")) {
      const selectedState =
        stateOptions.find((state) => state.label === company_state)?.value ||
        methods.getValues("company_state") ||
        "";

      fetchCity(selectedState);
    }
  }, [stateOptions, company_state]);

  console.log("Print methods.getValues", methods.getValues("company_state"));

  useEffect(() => {
    const loggedInUserState = localStorage.getItem("state");
    const defaultUser = stateOptions?.find(
      (stateVal) => stateVal.label === loggedInUserState,
    );
    if (defaultUser) {
      methods.setValue("company_state", defaultUser.label, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [stateOptions]);

  return (
    <form className="max-w-sm">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${
              corporate_type ? "after:h-28 after:border-blue-700" : "after:h-0"
            }  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="customer_contact_phone"
            className={`text-sm ${
              !corporate_type ? "text-[#C4C4C4]" : "text-[#6B7280]"
            } `}
          >
            Select corporate type
          </label>
          <RadioGroup
            className="flex w-full items-center gap-4"
            onValueChange={(e) => {
              methods.setValue("corporate_type", e, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
            value={corporate_type}
          >
            <label
              htmlFor="Small Business"
              className={`${
                corporate_type === "SMB"
                  ? " focus-within:border-blue-700 border-black-label"
                  : ""
              } flex w-full items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="SMB"
                id="Small Business"
                className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p className="w-full">Small Business</p>
            </label>
            <label
              htmlFor="enterprise"
              className={`${
                corporate_type === "Enterprise"
                  ? " focus-within:border-blue-700 border-black-label"
                  : ""
              } flex w-full items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="Enterprise"
                id="enterprise"
                className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p className="w-full">Enterprise</p>
            </label>
          </RadioGroup>
        </div>
        {corporate_type && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute after:h-28 after:border-blue-700 after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="company_name" className="text-sm text-[#6B7280]">
              What is the name of the company?
            </label>
            <input
              type="text"
              onWheel={(e) => e.currentTarget.blur()}
              placeholder="What is the name of the company?"
              id="company_name"
              onChange={(e) => {
                methods.setValue("company_name", e.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              disabled={!!company_name}
              value={company_name}
              className="border border-black-label h-12 disabled:opacity-40 disabled:cursor-not-allowed rounded-lg p-4 w-full"
            />
          </div>
        )}
        {corporate_type && company_name && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute after:h-28 after:border-blue-700 after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="company_website" className="text-sm text-[#6B7280]">
              Company website (Optional)
            </label>
            <input
              type="text"
              onWheel={(e) => e.currentTarget.blur()}
              placeholder="What is the name of the company?"
              id="company_website"
              onChange={(e) => {
                methods.setValue("company_website", e.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              value={company_website}
              className="border border-black-label h-12 rounded-lg p-4 w-full"
            />
          </div>
        )}
        {corporate_type && company_name && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute after:relative ${company_state ? "after:h-28 after:border-blue-700" : "after:h-0"} after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label
              htmlFor="company_location"
              className="text-sm text-[#6B7280]"
            >
              Select company state
            </label>
            <ReactSelect
              className="w-full"
              menuPlacement="auto"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  zIndex: 0,
                  borderRadius: ".5rem",
                  padding: ".3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: state.isFocused ? "#165FE3" : "#333333",
                  boxShadow: "none",
                  height: "3rem",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000,
                }),
              }}
              placeholder="Select the state"
              options={stateOptions}
              onChange={(e) => {
                methods.setValue("company_state", e?.label || "", {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              isClearable
              value={stateOptions.find(
                (state) => state.label === company_state,
              )}
              name="company_location"
              id="company_location"
            />
          </div>
        )}
        {corporate_type && company_name && company_state && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label
              htmlFor="company_location"
              className="text-sm text-[#6B7280]"
            >
              Select company city
            </label>
            <ReactSelect
              className="w-full"
              menuPlacement="auto"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  zIndex: 0,
                  borderRadius: ".5rem",
                  padding: ".3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: state.isFocused ? "#165FE3" : "#333333",
                  boxShadow: "none",
                  height: "3rem",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000,
                }),
              }}
              placeholder="Select the city"
              options={cityOptions}
              onChange={(e) => {
                methods.setValue("company_city", e?.label || "", {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              isClearable
              value={cityOptions.find((city) => city.label === company_city)}
              name="company_location"
              id="company_location"
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default Step1;
