import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Skeleton } from "@/components/ui/skeleton";
import { AxiosRequestConfig } from "axios";
import { Button, Img, Input, SelectBox, Spinner, Text } from "components";
import Navbar from "components/Navbar/Index";
import { selectOptionType } from "components/SelectBox";
import { useDebounce } from "pages/CreateDeal/newdeal";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  GetChannelRequestType,
  GetCountryRequestType,
  GetPurposeRequestType,
  GetServicePartnersRequestType,
  PostDealCalcRequestType,
  ShareQuoteRequestType,
  getChannel,
  getCountry,
  getPurpose,
  getServicePartners,
  postDealCalcWithMargin,
  shareQuoteAPI,
} from "service/apis/CreateDealapi";
import {
  GetInfoRequestType,
  GetRemittancePDFURLRequestType,
  getInfo,
  getRemittancePDFURL,
} from "service/apis/CreateForexQuotation";
import {
  PostCorporateInteractionsReqType,
  PutUpdateInteractionStatusReqType,
  postCorporateInteractions,
  putUpdateInteractionStatus,
} from "service/apis/Opportunities";
import { Toaster, toast } from "sonner";
import {
  setAddOnRates,
  setAmount,
  setCountry,
  setCreatedBy,
  setCurrency,
  setCustomer_rate,
  setCustomers_id,
  setDiscount,
  setDocs,
  setFXCharges,
  setForeignBankRates,
  setForeign_Bank_Charges,
  setPartnerDeal,
  setPartnerMargin,
  setPartnerStatus,
  setPartnerWalkinMargin,
  setPurposeId,
  setRemitterDOB,
  setRemitterPAN,
  setServicePartnerId,
  setServicePartnerMode,
  setSettlementRates,
  setStudentName,
  setTTRates,
  setTcs,
  setType,
  setcal_amount_inr,
  setcal_customer_rate,
  setcal_foreign_bank_charges,
  setcal_gst,
  setcal_profit,
  setcal_total_charges,
  setcal_total_payable,
  setcal_tt_charges,
  setcal_your_rate,
  setshowerror,
} from "../../redux/features/CreateDeal";
import { formatIndianNumber } from "../../utils";
import Step2 from "./Step2";
import { Mail, MessageCircle } from "lucide-react";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";

type countrydatatype = {
  country: string;
  currency: string;
};

export interface NewStateLocation extends PostCorporateInteractionsReqType {
  product_id: string;
  product_name: string;
  product_category: string;
  contact_name: string;
  contact_email: string;
  contact_number: string;
  interaction_id: string;
  partner_name: string;
  partner_id: string;
  contact_id: string;
  took_loan: string;
  productMode: string;
  product_purpose: string;
  selectedProductname: string;
}

interface CustomeStateLocation extends Location {
  state: NewStateLocation | null;
}

const L2CreateRemittanceQuotation: React.FC = () => {
  const { state } = useLocation() as CustomeStateLocation;
  const [isCalcLoading, setIsCalcLoading] = useState<boolean>(false);
  const [purpose, setPurpose] = useState<selectOptionType[]>([]);
  const [countryoption, setCountryoption] = useState<selectOptionType[]>([]);
  const [servicePartners, setServicePartners] = useState<selectOptionType[]>(
    [],
  );
  const [customerDetailsPopup, setCustomerDetailsPopup] =
    useState<boolean>(false);
  const [servicePartner, setServicePartner] = useState<string | number>("");
  const [currencydata, setCurrencydata] = useState<countrydatatype[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [showProfit, setShowProfit] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [partnerMarginError, setPartnerMarginError] = useState<string>("");
  const [editingMarginValue, setEditingMarginValue] = useState<string>("");
  const [rate, setRate] = useState<number>(0);
  const [editCustomerRate, setEditCustomerRate] = useState<boolean>(false);
  const [isEditingCustomerRate, setIsEditingCustomerRate] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isEditingMargin, setIsEditingMargin] = useState<boolean>(false);
  const [hasManualMargin, setHasManualMargin] = useState<boolean>(false);
  const [editingCustomerRate, setEditingCustomerRate] = useState<string>("");
  const [customerRateError, setCustomerRateError] = useState<string>("");
  const [partneroption, setPartneroption] = useState<
    { label: string; value: number }[]
  >([]);
  const [partnerName, setPartnerName] = useState<string>("");
  const [generate, setGenerate] = useState<boolean>(false);
  const [myMargin, setMyMargin] = useState<string>("0.5");
  const [isQuoteDownload, setIsQuoteDownload] = useState<boolean>(false);
  const [customerName, setCustomerName] = useState<string>("");
  const [customerNumber, setCustomerNumber] = useState<string>("");
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [isBookDeal, setISBookDeal] = useState<string>("");

  const toggleWhatsapp = () => setWhatsappChecked((prev) => !prev);
  const toggleEmail = () => setEmailChecked((prev) => !prev);
  const isButtonVisible = whatsappChecked || emailChecked;
  const [isShareNow, setIsShareNow] = useState(false);

  const dispatch = useAppDispatch();

  const mystate = { ...(state as NewStateLocation) };

  const {
    amount,
    country,
    created_by,
    currency,
    customer_rate,
    add_on_rates,
    purpose_id,
    showerror,
    type,
    cal_profit,
    cal_customer_rate,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    service_partner_id,
    partner_status,
    partner_walkin_margin,
    partner_margin,
    tcs,
    fx_charges,
    partnerDeal,
  } = useAppSelector((state) => state.CreateDeal);

  const level = localStorage.getItem("level");

  useEffect(() => {
    fetchPartner();
    fetchPurpose();
    fetchCountry();
    fetchCalc();
    if (!created_by) {
      handleonWalkinOrder();
    }
  }, []);

  useEffect(() => {
    dispatch(setcal_total_charges(""));
    dispatch(setcal_amount_inr(""));
    dispatch(setcal_gst(""));
    dispatch(setcal_tt_charges(""));
    dispatch(setcal_total_payable(""));
    dispatch(setcal_foreign_bank_charges(""));
    dispatch(setcal_profit(""));
  }, [created_by]);

  useEffect(() => {
    const coun = currencydata.find((item) => item.country === country);
    if (type === "remitter_sends") {
      dispatch(setCurrency("INR"));
    } else {
      if (coun?.currency) {
        dispatch(setCurrency(coun?.currency));
      }
    }
  }, [country, type]);

  useEffect(() => {
    let timeoutId: any = null;
    if (amount && currency != "" && !isEditingCustomerRate) {
      // Add this condition
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to call fetchCalc after 1 second
      timeoutId = setTimeout(() => {
        // Only fetch if we have the necessary data
        if (currency && purpose_id && created_by !== "-1") {
          fetchCalc();
        }
      }, 1000);
    } else {
      dispatch(setcal_total_charges(""));
      dispatch(setcal_amount_inr(""));
      dispatch(setcal_gst(""));
      dispatch(setcal_tt_charges(""));
      dispatch(setcal_total_payable(""));
      dispatch(setcal_foreign_bank_charges(""));
      dispatch(setcal_profit(""));
      dispatch(setcal_total_charges(""));
      dispatch(setcal_amount_inr(""));
    }
    return () => {
      // Cleanup the timeout on component unmount or when amount changes
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    purpose_id,
    amount,
    type,
    created_by,
    fx_charges,
    currency,
    servicePartner,
    partner_walkin_margin,
  ]);

  useEffect(() => {
    fetchServicePartners();
  }, [purpose_id, currency]);

  const channel_id = localStorage.getItem("channel_id");

  const fetchServicePartners = (): void | null => {
    if (purpose_id == "" || currency == "") {
      dispatch(setServicePartnerId(""));
      return null;
    }

    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    let purposeId = parseInt(purpose_id, 10);
    if (isNaN(purposeId)) {
      purposeId = location?.state?.purpose_id;
    }
    const payload: AxiosRequestConfig<GetServicePartnersRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: purposeId,
        currency: currency,
      },
    };

    getServicePartners(payload)
      .then((res) => {
        if (res.data.length > 0) {
          dispatch(setServicePartnerId(res.data[0].id));
          setServicePartner(res.data[0].id);
          fetchCalc();
        } else {
          dispatch(setServicePartnerId(""));
          setServicePartner("");
        }
        setServicePartners(
          res.data.map((item) => {
            return {
              label: item.display_name,
              value: item.id,
            };
          }),
        );
        if (res.data.length < 1) {
          toast.error("Service Partners not available");
        }
      })
      .catch((err) => {
        dispatch(setServicePartnerId(""));
        setServicePartner("");
        toast.error(err.response.data.message);
      });
  };

  const fetchCalc = (): void | null => {
    // get auth token from localstorage

    if (fx_charges === "") {
      return;
    }
    if (!amount || !purpose_id || !country || !currency || !service_partner_id)
      return;

    setDisabled(true);
    setShowSpinner(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostDealCalcRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        // purpose_id: purpose_id === "" ? 1 : parseInt(purpose_id),
        // currency: currency === "" ? "USD" : currency,
        // amount: parseInt(amount) ? parseInt(amount) : 0,
        // type: type,
        // created_by: created_by,
        // tcs_type: 1,
        // discount: 0,
        // addon_rates: add_on_rates,
        // country: country,
        // service_partner_id: servicePartner,
        // partner_margin:
        //   partner_status === "pending"
        //     ? partner_margin === ""
        //       ? "0"
        //       : partner_margin
        //     : "",
        // partner_walkin_margin:
        //   partner_status === "pending"
        //     ? partner_walkin_margin === ""
        //       ? "0"
        //       : partner_walkin_margin
        //     : "",
        // fx_charges: fx_charges,
        purpose_id: purpose_id === "" ? 1 : parseInt(purpose_id),
        currency: currency === "" ? "USD" : currency,
        amount: parseInt(amount) ? parseInt(amount) : 0,
        type: type,
        created_by: created_by,
        tcs_type: 1,
        discount: 0,
        addon_rates: add_on_rates,
        country: country,
        service_partner_id: servicePartner,
        partner_walkin_margin:
          partner_status === "pending"
            ? ((partner_walkin_margin === ""
                ? "0"
                : partner_walkin_margin) as string)
            : "",
        fx_charges: fx_charges,
        l1_commission: myMargin,
        l2_commission: level === "1" ? (partner_margin as string) : "",
        l3_commission: "0",
      },
    };

    if (currency && purpose_id && created_by !== "-1") {
      postDealCalcWithMargin(payload)
        .then((res: any) => {
          dispatch(setTcs(res?.data.tcs));
          dispatch(setServicePartnerId(res.data.service_partner));
          dispatch(setForeignBankRates(res.data.foreign_bank_charges));
          dispatch(setTTRates(res.data.tt_charges));
          dispatch(setServicePartnerMode(res.data.service_partner_mode));
          if (res.data.service_partner_mode === "online") {
            dispatch(setSettlementRates(res.data.settlement_rates));
          } else {
            dispatch(setSettlementRates(""));
          }
          dispatch(
            setcal_total_charges(
              parseFloat(res.data.payable_amount) -
                parseFloat(res.data.base_amount),
            ),
          );
          if (level === "1") {
            dispatch(setcal_profit(res.data.profit.l1));
          }
          if (level === "2") {
            dispatch(setcal_profit(res.data.profit.l2));
          }
          if (level === "3") {
            dispatch(setcal_profit(res.data.profit.l3));
          }

          if (state?.partner_id) {
            dispatch(setPartnerMargin(res.data.commission.l2.toFixed(4)));
          }

          dispatch(setcal_amount_inr(res.data.base_amount));
          dispatch(setcal_gst(res.data.gst));
          dispatch(setcal_tt_charges(res.data.tt_charges_with_gst));
          dispatch(setcal_total_payable(res.data.payable_amount));
          dispatch(
            setcal_foreign_bank_charges(res.data.foreign_bank_charges_with_gst),
          );
          dispatch(
            setcal_your_rate(
              (
                parseFloat(res?.data?.settlement_rates) +
                (level === "2" ? res?.data?.cumulative_commission.l1 : 0) +
                (level === "3" ? res?.data?.cumulative_commission.l2 : 0)
              )?.toFixed(4),
            ),
          );

          setRate(
            parseFloat(res?.data?.settlement_rates) +
              (res?.data?.cumulative_commission_without_addon.l3 > 0
                ? res?.data?.cumulative_commission_without_addon.l3
                : res?.data?.cumulative_commission_without_addon.l2 > 0
                  ? res?.data?.cumulative_commission_without_addon.l2
                  : res?.data?.cumulative_commission_without_addon.l1),
          );

          localStorage.setItem(
            "service_partner_id",
            JSON.stringify(res.data.service_partner),
          );

          dispatch(
            setCustomer_rate(Number(res.data.customer_rate)?.toFixed(4)),
          );
          setEditingCustomerRate(Number(res.data.customer_rate)?.toFixed(4));
          dispatch(
            setcal_customer_rate(
              parseFloat(res.data.customer_rate)?.toFixed(4)?.toLocaleString(),
            ),
          );
          setDisabled(false);
          setShowSpinner(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setDisabled(false);
          setShowSpinner(false);
        });
    } else {
      setDisabled(false);
      setShowSpinner(false);
    }
  };

  const handleonWalkinOrder = (): void | null => {
    // get channel id from localstorage
    if (state?.partner_id) {
      dispatch(setCreatedBy(state?.partner_id as string));
    } else {
      if (channel_id === null) return null;
      dispatch(setPartnerDeal(false));
      dispatch(setCreatedBy(channel_id));
      dispatch(setPartnerStatus(""));
      dispatch(setPartnerMargin(""));
    }
  };

  const fetchPartner = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };

    getChannel(payload)
      .then((res) => {
        if (res.data && res.data.items) {
          let partner = res.data.items.filter(
            (item) =>
              (item.status === "active" || item.status === "pending") &&
              item.approval_status === "approved",
          );

          setPartneroption(
            partner.map((item) => {
              return {
                label: item.name,
                value: item.id,
                status: item.status,
                style: {
                  color: "red",
                },
              };
            }),
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const fetchCountry = () => {
    const payload: AxiosRequestConfig<GetCountryRequestType> = {};
    getCountry(payload)
      .then((res) => {
        setCurrencydata(res.data);
        setCountryoption(
          res.data.map((item) => {
            return {
              label: item.country,
              value: item.country,
            };
          }),
        );
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const fetchPurpose = () => {
    const payload: AxiosRequestConfig<GetPurposeRequestType> = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        product_id: 0,
      },
    };
    getPurpose(payload)
      .then((res) => {
        const filteredPurpose = res.data.filter((item) => item.id !== 13);
        setPurpose(
          filteredPurpose?.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          }),
        );
        if (state?.product_id) {
          dispatch(
            setPurposeId(
              purposeMapping[state?.product_id as string]
                ? purposeMapping[state?.product_id as string]
                : purpose_id,
            ),
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useDebounce(
    () => {
      if (editingMarginValue) fetchCalc();
    },
    1000,
    [editingMarginValue],
  );

  useEffect(() => {
    if (Number(customer_rate) >= rate) setCustomerRateError("");
  }, [customer_rate, add_on_rates]);

  useDebounce(
    () => {
      if (customer_rate) fetchCalc();
    },
    1000,
    [add_on_rates],
  );

  useDebounce(
    () => {
      if (myMargin) fetchCalc();
    },
    1000,
    [myMargin],
  );

  const handleCancel = () => {
    dispatch(setPurposeId(""));
    dispatch(setCountry(""));
    dispatch(setshowerror(false));
    dispatch(setAmount(""));
    dispatch(setCreatedBy(""));
    dispatch(setCurrency(""));
    dispatch(setStudentName(""));
    dispatch(setCustomer_rate(""));
    dispatch(setAddOnRates(0));
    dispatch(setcal_your_rate(""));
    dispatch(setcal_customer_rate(""));
    dispatch(setcal_total_charges(""));
    dispatch(setcal_amount_inr(""));
    dispatch(setcal_gst(""));
    dispatch(setcal_tt_charges(""));
    dispatch(setcal_total_payable(""));
    dispatch(setcal_foreign_bank_charges(""));
    dispatch(setcal_profit(""));
    dispatch(setType("beneficiary_receives"));
    dispatch(setCustomers_id(""));
    dispatch(setForeign_Bank_Charges(""));
    dispatch(setDiscount(""));
    dispatch(setRemitterPAN(""));
    dispatch(setRemitterDOB(""));
    dispatch(setDocs([]));
    dispatch(setPartnerMargin(""));
    dispatch(setFXCharges("BEN"));
    navigate(-1);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setPurposeId(""));
    dispatch(setCountry(""));
    dispatch(setshowerror(false));
    dispatch(setAmount(""));
    dispatch(setCurrency(""));
    dispatch(setStudentName(""));
    dispatch(setCustomer_rate(""));
    dispatch(setAddOnRates(0));
    dispatch(setcal_your_rate(""));
    dispatch(setcal_customer_rate(""));
    dispatch(setcal_total_charges(""));
    dispatch(setcal_amount_inr(""));
    dispatch(setcal_gst(""));
    dispatch(setcal_tt_charges(""));
    dispatch(setcal_total_payable(""));
    dispatch(setcal_foreign_bank_charges(""));
    dispatch(setcal_profit(""));
    dispatch(setType("beneficiary_receives"));
    dispatch(setForeign_Bank_Charges(""));
    dispatch(setDiscount(""));
    dispatch(setRemitterPAN(""));
    dispatch(setRemitterDOB(""));
    dispatch(setDocs([]));
    dispatch(setPartnerMargin(""));
    dispatch(setFXCharges("BEN"));
  }, [pathname]);

  const purposeMapping = {
    17: 1,
    18: 3,
    19: 4,
    20: 4,
  };

  // for Downlaod Button
  useEffect(() => {
    getDetails();
    setCustomerName(mystate?.contact_name);
    setCustomerNumber(mystate?.contact_number);
  }, []);

  const getDetails = () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetInfoRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    getInfo(payload)
      .then((res: any) => {
        setPartnerName(res?.data?.name as string);
      })
      .catch((err) => {});
  };

  const handleUpdateInteractionStatus = async (pdf_url: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PutUpdateInteractionStatusReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        id: mystate?.interaction_id?.[0]?.id?.toString(),
        isOpen: "false",
        quotation_pdf: pdf_url,
      },
    };
    try {
      const res = (await putUpdateInteractionStatus(payload)) as any;
      if (res.status === 200) {
        !checkLevel(LEVEL_2) &&
          toast.success(
            `Interaction added successfully for Lead-ID ${mystate?.opportunity_id}`,
          );
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update interaction status");
      setLoading(false);
    }
  };

  const handleAddInteraction = async (pdfUrl: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const mystate = { ...(state as NewStateLocation) };

    if (!mystate?.opportunity_id) {
      setLoading(false);
      return;
    }

    const payload: AxiosRequestConfig<PostCorporateInteractionsReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        opportunity_id: mystate?.opportunity_id as string,
        remarks: "",
        meeting_time: mystate?.meeting_time,
        meetingStaff_id: mystate?.meetingStaff_id,
        note: mystate?.note,
        channel_of_interaction_id: mystate?.channel_of_interaction_id as string,
        quotation_pdf: pdfUrl,
        ed_payment_mode: mystate?.ed_payment_mode,
        loan: mystate?.loan,
      },
    };
    try {
      const res = (await postCorporateInteractions(payload)) as any;
      if (res.status === 200) {
        !checkLevel(LEVEL_2) &&
          toast.success(
            `Interaction added successfully for id ${mystate?.opportunity_id}`,
          );
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to add interaction");
      setLoading(false);
    }
  };

  const downloadPDFfromUrl = async (pdf_url: string) => {
    try {
      const response = await fetch(pdf_url);
      const blob = await response.blob();

      // const blob = base64toBlob(pdf_url);
      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Remittance Quotation.pdf";

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // // Clean up: remove the link
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Failed to download PDF");
    }
  };

  const handleSubmit = async (type: string): Promise<string | undefined> => {
    try {
      // Retrieve and validate the auth token
      const auth = localStorage.getItem("authToken");
      if (!auth) {
        console.error("No auth token found");
        return;
      }

      const authToken = JSON.parse(auth);

      // Format the current date
      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(2, "0")}-${String(
        today.getMonth() + 1,
      ).padStart(2, "0")}-${String(today.getFullYear()).slice(-2)}`;

      // Prepare the payload
      const mystate = { ...(state as NewStateLocation) };
      const payload: AxiosRequestConfig<GetRemittancePDFURLRequestType> = {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          data: {
            date: formattedDate,
            customer_name: mystate?.contact_name,
            customer_number: mystate?.contact_number,
            purpose: purpose_id,
            currency: currency,
            remitter_send: `₹ ${formatIndianNumber(
              parseFloat(cal_total_payable)?.toFixed(2),
            )}`,
            beneficiary_received: `${
              currency || "USD"
            } ${formatIndianNumber(parseFloat(amount)?.toFixed(4))}`,
            customer_rate: cal_customer_rate
              ? `₹ ${cal_customer_rate}`
              : "₹ 0.00",
            total_charges: `₹ ${Number(cal_total_charges)?.toFixed(2)}`,
            gst: `₹ ${Number(cal_gst)?.toFixed(2)}`,
            fb_charges: `₹ ${Number(cal_foreign_bank_charges)?.toFixed(2)}`,
            tt_charges: `₹ ${Number(cal_tt_charges)?.toFixed(2)}`,
            payable_amount: `₹${formatIndianNumber(
              parseFloat(cal_total_payable)?.toFixed(2),
            )}`,
            partner_name: partnerName,
          },
          opportunity_id: mystate?.opportunity_id?.toString() as string,
        },
      };

      // Set loading state and make the API call
      setLoading(true);
      const res = (await getRemittancePDFURL(payload)) as any;

      if (!res?.data) {
        toast.error("Failed to generate PDF");
        return;
      }

      // Handle different types of actions
      switch (type) {
        case "saveandclose":
          if (mystate?.interaction_id) {
            await handleUpdateInteractionStatus(res.data);
          } else {
            await handleAddInteraction(res.data);
          }
          if (isBookDeal === "Book Deal" || isBookDeal === "Book Rate") {
            navigate("/bookdeal", {
              state: {
                partnerId: mystate?.partner_id,
                purposeID: purpose_id,
                took_loan: state?.took_loan,
                productMode: state?.productMode,
                product_purpose: state?.product_purpose,
                selectedProductname: state?.selectedProductname,
                opportunityId: state?.opportunity_id,
                contactId: state?.contact_id || "",
              },
            });
          } else {
            navigate("/remit/leads");
          }
          break;

        case "download":
          if (mystate?.interaction_id) {
            await handleUpdateInteractionStatus(res.data);
          } else {
            await handleAddInteraction(res.data);
          }
          downloadPDFfromUrl(res.data);
          if (isBookDeal === "Book Deal" || isBookDeal === "Book Rate") {
            navigate("/bookdeal", {
              state: {
                partnerId: mystate?.partner_id,
                purposeID: purpose_id,
                took_loan: state?.took_loan,
                productMode: state?.productMode,
                product_purpose: state?.product_purpose,
                selectedProductname: state?.selectedProductname,
                opportunityId: state?.opportunity_id,
                contactId: state?.contact_id || "",
              },
            });
          } else {
            navigate("/remit/leads");
          }

          break;

        case "onload":
          setPdfUrl(res.data as string);
          return res.data;

        default:
          console.warn("Unknown action type:", type);
          break;
      }

      return res.data;
    } catch (err: any) {
      console.error("Error in handleSubmit:", err);
      toast.error(err.message || "An error occurred");
    } finally {
      setLoading(false); // Ensure loading is always turned off
    }
  };

  useEffect(() => {
    if (location?.state?.purpose_id) {
      dispatch(setPurposeId(location?.state?.purpose_id));
    }
  }, [location?.state?.purpose_id]);

  const handleSend = async (channel: string, url: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    let data;
    if (checkLevel(LEVEL_2)) {
      data = {
        link: url,
        channel: channel,
        toSend: "customer",
        customers_id: mystate?.contact_id,
      };
    }
    if (mystate?.contact_id && !checkLevel(LEVEL_2)) {
      data = {
        link: url,
        channel: channel,
        toSend: "customer",
        customers_id: mystate?.contact_id,
      };
    }
    if (mystate?.partner_id && !checkLevel(LEVEL_2)) {
      data = {
        link: url,
        channel: channel,
        toSend: "partner",
        channels_id: mystate?.partner_id,
      };
    }

    const payload: AxiosRequestConfig<ShareQuoteRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        ...data,
      },
    };
    try {
      await shareQuoteAPI(payload);
      setLoading(false);
      toast.success("Quote shared successfully");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to share quote");
    }
  };

  const handleShareNow = async () => {
    try {
      const res = await handleSubmit("onload");
      if (!res) {
        toast.error("Failed to generate PDF. Please try again.");
        return;
      }

      const sharePromises = [];

      if (whatsappChecked) {
        sharePromises.push(handleSend("whatsapp", res));
      }
      if (emailChecked) {
        sharePromises.push(handleSend("email", res));
      }

      if (sharePromises.length > 0) {
        const results = await Promise.allSettled(sharePromises);
        const allSuccessful = results.every(
          (result) => result.status === "fulfilled",
        );

        if (allSuccessful) {
          toast.success("Calculation shared successfully!");
        } else {
          toast.warning("Some sharing methods failed. Please check logs.");
        }
      }
    } catch (error) {
      toast.error("Failed to share calculation. Please try again.");
    }
  };

  useEffect(() => {
    if (isBookDeal) {
      handleSubmit(isBookDeal === "Book Deal" ? "download" : "saveandclose");
    }
  }, [isBookDeal]);

  return (
    <>
      {loading && <Spinner />}
      <div className="p-4 bg-gray-100 flex flex-col h-screen font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="flex md:flex-col flex-col gap-4 h-full sm:h-auto md:gap-5 items-start justify-start w-full">
          <Navbar>
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">Remit Quotation</span>
                  <span className="text-xs font-normal text-[#999999]">
                    Create the remittance quotation
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="gap-2.5 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger>
                    <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                      Cancel
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                          This action cannot be undone. This will permanently
                          delete your progress and remove the actions preformed.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button>Close</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          asChild
                        >
                          <button className="" onClick={handleCancel}>
                            Continue
                          </button>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              </div>
            </div>
          </Navbar>
          <div className="gap-5 w-full flex h-full  sm:flex-col flex-1">
            <div
              className={`flex overflow-hidden bg-white-A700 md:flex-1 rounded-xl flex-col gap-8 p-5 md:h-auto items-start justify-start sm:min-h-[auto] md:px-5 ${currentStep === 2 ? "w-full" : "w-8/12 md:w-full sm:w-full"}`}
            >
              <div className=" hidden flex-row  items-start justify-between  w-full">
                {level === "1" && (
                  <div className="hidden flex-row items-center justify-center h-full w-[30%]">
                    <SelectBox
                      className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                      placeholderClassName="text-black-900_60"
                      indicator={
                        <Img
                          className="h-4 w-4"
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                        />
                      }
                      value={service_partner_id}
                      onChange={(e) => {
                        setServicePartner(e);
                        if (e == "7") {
                          dispatch(
                            setFXCharges(
                              fx_charges == "SHA" ? "BEN" : fx_charges,
                            ),
                          );
                        }

                        if (e == "17") {
                          dispatch(
                            setFXCharges(
                              fx_charges != "OUR" ? "OUR" : fx_charges,
                            ),
                          );
                        }
                      }}
                      isMulti={false}
                      name="inputbox"
                      errors={
                        created_by == "" && showerror ? ["Select anyone"] : []
                      }
                      options={servicePartners}
                      isSearchable={true}
                      placeholder="Service Partner"
                    />
                  </div>
                )}
              </div>

              {currentStep === 2 ? (
                <Step2 state={state} />
              ) : (
                <div className="flex overflow-auto sm:pl-0 pl-10 flex-col gap-[15px] items-start justify-start w-full sm:w-full">
                  <div className="w-2/4 sm:w-full md:w-10/12 h-full">
                    {state?.partner_id ? (
                      <div className="flex relative flex-col gap-2.5 h-auto md:h-auto items-start justify-start">
                        <div
                          className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <div className="flex flex-col w-full mb-4">
                          <Text className="text-sm font-medium text-[#666666] mb-2">
                            Partner Details
                          </Text>
                          <div className="flex items-center gap-2 bg-gray-50 p-3 rounded-md">
                            <Text className="text-sm font-semibold text-[#333333]">
                              {state?.partner_name || "No Partner Selected"}
                            </Text>
                            {state?.partner_id &&
                              state?.partner_id !== "0" &&
                              state?.partner_id !== undefined && (
                                <Text className="text-xs text-gray-500">
                                  (ID: {state.partner_id})
                                </Text>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10 "
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Where do you want to send the money ?
                      </Text>
                      <SelectBox
                        className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                        placeholderClassName="text-black-900_60"
                        indicator={
                          <Img
                            className="h-4 w-4"
                            src="images/img_arrowdown.svg"
                            alt="arrow_down"
                          />
                        }
                        isMulti={false}
                        name="inputboxone"
                        errors={
                          country == "" && showerror
                            ? ["Country is required"]
                            : [""]
                        }
                        options={countryoption}
                        value={country}
                        onChange={(e) => dispatch(setCountry(e))}
                        isSearchable={true}
                        placeholder="Type and Search Country"
                      />
                    </div>
                    {partner_status === "pending" && (
                      <div className="flex flex-col relative gap-2.5 items-start justify-start w-full">
                        <div
                          className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text
                          className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                          size="txtInterBold15Black900dd"
                        >
                          3. Partner Margin{" "}
                        </Text>
                        <div className="flex flex-row w-full items-center justify-between">
                          <div className="flex flex-row gap-1 w-full">
                            <div className="flex flex-col w-full">
                              <Input
                                name="input"
                                value={partner_margin}
                                onChange={(e: string) =>
                                  dispatch(setPartnerMargin(e))
                                }
                                errors={
                                  amount == "" && showerror
                                    ? ["Partner Margin is required"]
                                    : [""]
                                }
                                placeholder="Enter Partner Margin"
                                className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left ${
                                  currency == "" ? "w-full" : "w-[180px]"
                                }`}
                                wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                                type="text"
                                disabled={disabled}
                              ></Input>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {partner_status === "pending" && (
                      <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                        <div
                          className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-32 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          4. Partner Walkin Margin{" "}
                        </Text>
                        <div className="flex flex-row w-full items-center justify-between">
                          <div className="flex flex-row gap-1 w-full">
                            <div className="flex flex-col w-full">
                              <Input
                                name="input"
                                value={partner_walkin_margin}
                                onChange={(e: string) =>
                                  dispatch(setPartnerWalkinMargin(e))
                                }
                                errors={
                                  amount == "" && showerror
                                    ? ["Partner Walkin Margin is required"]
                                    : [""]
                                }
                                placeholder="Enter Partner Walkin Margin"
                                className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left  "w-full"
                                    `}
                                wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                                type="text"
                                disabled={disabled}
                              ></Input>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {purpose_id && country && (
                      <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                        <div
                          className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-32 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          {/* {partner_status === "pending" ? "5." : "3."} */}
                          Enter amount you want to send{" "}
                        </Text>
                        <div className="flex flex-row w-full items-center justify-between">
                          <div className="flex flex-row gap-1">
                            <div className="flex flex-col w-[15%] sm:w-full">
                              <Input
                                name="input"
                                value={currency}
                                placeholder="Currency"
                                className={`w-full p-0 text-blue-700_01 flex flex-col placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left`}
                                wrapClassName="w-full bg-white-A700 text-blue-700_01 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[11px] py-4 rounded-lg"
                                type="text"
                                disabled={true}
                              ></Input>
                            </div>
                            <div className="flex flex-col w-[85%]">
                              <Input
                                name="input"
                                value={amount}
                                onWheel={(e) => e.currentTarget.blur()}
                                type="number"
                                onChange={(e: string) => dispatch(setAmount(e))}
                                errors={
                                  amount == "" && showerror
                                    ? ["Amount is required"]
                                    : [""]
                                }
                                placeholder="Enter Amount"
                                className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left ${
                                  currency == "" ? "w-full" : "w-[180px]"
                                }`}
                                wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                                disabled={disabled}
                                suffix={
                                  <div>
                                    {showSpinner && (
                                      <TailSpin
                                        height="20"
                                        color="#3892F3"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperClass=""
                                        visible={showSpinner}
                                      />
                                    )}
                                  </div>
                                }
                              ></Input>
                            </div>
                          </div>
                        </div>
                        {showProfit && (
                          <Text
                            className="text-black-900_8c text-sm w-auto"
                            size="txtInterRegular12Black9008c"
                          >
                            My profit (approx.) :{" "}
                            <span
                              className={`${
                                parseFloat(cal_profit) >= 0
                                  ? "text-[#4fa94d]"
                                  : "text-[#FF0000]"
                              }`}
                            >
                              INR{" "}
                              {cal_profit === ""
                                ? "0.00"
                                : formatIndianNumber(
                                    parseFloat(cal_profit)?.toFixed(4),
                                  )}{" "}
                            </span>
                          </Text>
                        )}
                      </div>
                    )}
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                        <div
                          className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-20 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10 "
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px] mt-2.5">
                          {!editCustomerRate
                            ? partner_status === "pending"
                              ? purpose && country
                                ? "7."
                                : "5."
                              : purpose && country
                                ? "4."
                                : "3."
                            : partner_status === "pending"
                              ? purpose && country
                                ? "7."
                                : "5."
                              : purpose && country
                                ? "5."
                                : "3."}{" "}
                          FX Charges
                        </Text>
                        <div className="flex flex-row w-full items-center">
                          <div className="flex flex-row gap-2.5 h-[46px] md:h-auto items-start justify-start sm:w-full">
                            <div className="flex flex-row gap-2 items-center justify-start w-[33%]">
                              <RadioGroup
                                className="flex"
                                onValueChange={(e) => dispatch(setFXCharges(e))}
                                defaultValue="BEN"
                              >
                                <label
                                  htmlFor="BEN"
                                  className={` ${fx_charges === "BEN" ? " border-blue-700" : " "} flex items-center rounded-lg border p-3 space-x-2`}
                                >
                                  <RadioGroupItem
                                    disabled={servicePartner == "17"}
                                    value="BEN"
                                    id="BEN"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                                  />
                                  <p>BEN</p>
                                </label>
                                <label
                                  htmlFor="OUR"
                                  className={` ${fx_charges === "OUR" ? "border-blue-700" : ""} flex items-center rounded-lg border p-3 space-x-2`}
                                >
                                  <RadioGroupItem
                                    value="OUR"
                                    id="OUR"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                                  />
                                  <p>OUR</p>
                                </label>
                              </RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                        <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                          <div
                            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${!state?.partner_id ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                          >
                            <img
                              className="relative -left-10 "
                              src={`images/overview/ellipse.svg`}
                              alt=""
                            />
                          </div>
                          <Text className="text-sm font-medium leading-[17px] mt-4">
                            {!editCustomerRate
                              ? partner_status === "pending"
                                ? "8."
                                : "5."
                              : partner_status === "pending"
                                ? "8."
                                : "5."}{" "}
                            Enter Customer Rate
                          </Text>
                          <div className="flex flex-col w-full">
                            <div className="relative z-50">
                              <Input
                                name="input"
                                onWheel={(e) => e.currentTarget.blur()}
                                value={customer_rate}
                                onFocus={(e) => {
                                  e.target.select();
                                }}
                                onChange={(e: string) => {
                                  if (Number(e) < rate - 1) {
                                    setCustomerRateError(
                                      `Customer rate cannot be less than ${rate}`,
                                    );
                                  } else {
                                    setCustomerRateError(``);
                                  }
                                  dispatch(setCustomer_rate(e));
                                  if (!e) {
                                    dispatch(setAddOnRates(0));
                                  } else {
                                    dispatch(
                                      setAddOnRates(
                                        parseFloat(e) -
                                          parseFloat(rate.toString()),
                                      ),
                                    );
                                    dispatch(setPartnerMargin(""));
                                    setEditingMarginValue("");
                                  }
                                }}
                                errors={
                                  amount == "" && showerror
                                    ? ["Amount is required"]
                                    : [""]
                                }
                                placeholder="Enter Customer Rate"
                                className="flex w-full bg-background px-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-12 max-w-[440px] pl-[70px]"
                                wrapClassName={`bg-white-A700 flex justify-between border ${customerRateError ? "border-red-500" : "border-black-900_4c"} border-solid rounded-lg w-full sm:w-full relative`}
                                type="number"
                                disabled={
                                  !amount ||
                                  !purpose_id ||
                                  !country ||
                                  !currency
                                }
                                prefix={
                                  <div className="absolute left-[11px] top-1/2 -translate-y-1/2">
                                    <span className="bg-[#e1ebfc] px-2 py-1 rounded-[6px] text-blue-700_01 text-[15px]">
                                      INR
                                    </span>
                                  </div>
                                }
                                suffix={
                                  <div className="pr-[11px] mt-2">
                                    {isCalcLoading && (
                                      <TailSpin
                                        height="20"
                                        color="#3892F3"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    )}
                                  </div>
                                }
                              />
                            </div>
                            {customerRateError && (
                              <Text className="text-sm text-red-500 mt-1">
                                {customerRateError}
                              </Text>
                            )}
                            <Text className="text-xs text-gray-500 mt-1">
                              Settlement Rate: INR {rate.toFixed(2)}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    {state?.partner_id ? (
                      <div className="flex gap-4 flex-col w-full">
                        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                          <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                            <div
                              className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${!isEditingMargin ? "after:h-0" : "after:h-24 after:border-blue-700"} absolute after:relative after:content-[''] after:border after:border-dashed`}
                            >
                              <img
                                className="relative -left-10"
                                src={`images/overview/ellipse.svg`}
                                alt=""
                              />
                            </div>
                            {isEditingMargin ? (
                              // Edit mode
                              <div className="flex flex-col w-full mt-2">
                                <Text className="text-sm font-medium leading-[17px] mb-2">
                                  Edit Partner Margin
                                </Text>
                                <div className="relative z-50">
                                  <Input
                                    name="partnerMargin"
                                    onFocus={(e) => {
                                      e.target.select();
                                    }}
                                    value={partner_margin}
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e: string) => {
                                      dispatch(setPartnerMargin(e));
                                      dispatch(setAddOnRates(0));
                                      setEditingMarginValue(e);
                                    }}
                                    placeholder="Enter Partner Margin"
                                    className="flex w-full bg-background px-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-12 max-w-[440px] pl-[70px]"
                                    wrapClassName={`bg-white-A700 flex justify-between border ${partnerMarginError ? "border-red-500" : "border-black-900_4c"} border-solid rounded-lg w-full sm:w-full relative`}
                                    type="number"
                                    prefix={
                                      <div className="absolute left-[11px] top-1/2 -translate-y-1/2">
                                        <span className="bg-[#e1ebfc] px-2 py-1 rounded-[6px] text-blue-700_01 text-[15px]">
                                          INR
                                        </span>
                                      </div>
                                    }
                                    suffix={
                                      <div className="pr-[11px] mt-2">
                                        {isCalcLoading && (
                                          <TailSpin
                                            height="20"
                                            color="#3892F3"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperClass=""
                                            visible={true}
                                          />
                                        )}
                                      </div>
                                    }
                                  />
                                </div>
                                {/* Error message */}
                                {partnerMarginError && (
                                  <Text className="text-sm text-red-500 mt-1">
                                    {partnerMarginError}
                                  </Text>
                                )}
                              </div>
                            ) : (
                              <div className="flex items-center justify-between w-full pt-2">
                                <div className="flex items-center gap-2">
                                  <Text className="text-sm font-medium text-[#666666] ">
                                    Current Partner Margin:
                                  </Text>
                                  {isCalcLoading ? (
                                    <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                                  ) : (
                                    <Text className="text-sm font-semibold text-[#333333]">
                                      INR{" "}
                                      {Number(partner_margin).toFixed(2) ||
                                        "0.00"}
                                    </Text>
                                  )}
                                </div>
                                <button
                                  onClick={() => {
                                    setIsEditingMargin(true);
                                    setEditingMarginValue(partner_margin);
                                    dispatch(setPartnerMargin(""));
                                    setEditingMarginValue(partner_margin);
                                  }}
                                  className="text-sm text-blue-700 hover:text-blue-800"
                                >
                                  Change
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                          <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                            <div
                              className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : "after:h-0"} absolute after:relative after:content-[''] after:border after:border-dashed`}
                            >
                              <img
                                className="relative -left-10"
                                src={`images/overview/ellipse.svg`}
                                alt=""
                              />
                            </div>
                            {isEditingMargin ? (
                              // Edit mode
                              <div className="flex flex-col w-full mt-2">
                                <Text className="text-sm font-medium leading-[17px] mb-2">
                                  Edit My Margin
                                </Text>
                                <div className="relative z-50">
                                  <Input
                                    name="partnerMargin"
                                    value={myMargin}
                                    onFocus={(e) => {
                                      e.target.select();
                                    }}
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e: string) => {
                                      setMyMargin(e);
                                    }}
                                    placeholder="Enter Partner Margin"
                                    className="flex w-full bg-background px-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-12 max-w-[440px] pl-[70px]"
                                    wrapClassName={`bg-white-A700 flex justify-between border ${partnerMarginError ? "border-red-500" : "border-black-900_4c"} border-solid rounded-lg w-full sm:w-full relative`}
                                    type="number"
                                    prefix={
                                      <div className="absolute left-[11px] top-1/2 -translate-y-1/2">
                                        <span className="bg-[#e1ebfc] px-2 py-1 rounded-[6px] text-blue-700_01 text-[15px]">
                                          INR
                                        </span>
                                      </div>
                                    }
                                    suffix={
                                      <div className="pr-[11px] mt-2">
                                        {isCalcLoading && (
                                          <TailSpin
                                            height="20"
                                            color="#3892F3"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperClass=""
                                            visible={true}
                                          />
                                        )}
                                      </div>
                                    }
                                  />
                                </div>
                                {partnerMarginError && (
                                  <Text className="text-sm text-red-500 mt-1">
                                    {partnerMarginError}
                                  </Text>
                                )}
                              </div>
                            ) : (
                              <div className="flex items-center justify-between w-full pt-2">
                                <div className="flex items-center gap-2">
                                  <Text className="text-sm font-medium text-[#666666] ">
                                    Current My Margin:
                                  </Text>
                                  {isCalcLoading ? (
                                    <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                                  ) : (
                                    <Text className="text-sm font-semibold text-[#333333]">
                                      INR{" "}
                                      {Number(myMargin).toFixed(2) || "0.00"}
                                    </Text>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="flex gap-2 pl-10 items-center">
                {currentStep > 1 && (
                  <Button
                    className="common-pointer sm:ml-0 bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                  >
                    <div className="flex flex-row gap-[10px] items-center">
                      Back to Quotation
                    </div>
                  </Button>
                )}
                {
                  <Button
                    className="disabled:opacity-40 sm:ml-0  cursor-pointer h-10 border-blue-700 border py-2.5 rounded-md text-sm text-center text-blue-700 w-auto px-5"
                    onClick={() => navigate(-1)}
                  >
                    <div className="flex flex-row gap-[10px] items-center">
                      Previous
                    </div>
                  </Button>
                }
                {/* {!isQuoteDownload && currentStep !== 2 && (
                  <Button
                    className="common-pointer disabled:opacity-40 sm:ml-0 bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                    onClick={() => {
                      setIsQuoteDownload(true);
                    }}
                    disabled={
                      !customer_rate ||
                      !amount ||
                      !currency ||
                      !country ||
                      !purpose_id ||
                      !!customerRateError
                    }
                  >
                    <div className="flex flex-row gap-[10px] items-center">
                      Generate Quote
                    </div>
                  </Button>
                )} */}
                {!checkLevel(LEVEL_2) ? (
                  <Button
                    className="common-pointer disabled:opacity-40 sm:ml-0 bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                    onClick={() => {
                      handleSubmit("download");
                    }}
                    disabled={!amount || !country}
                  >
                    <div className="flex flex-row gap-[10px] items-center">
                      Download and Close
                    </div>
                  </Button>
                ) : (
                  <Button
                    className="common-pointer disabled:opacity-40 sm:ml-0 bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                    onClick={() => {
                      handleSubmit("saveandclose");
                    }}
                    disabled={!amount || !country}
                  >
                    <div className="flex flex-row gap-[10px] items-center">
                      Save and Close
                    </div>
                  </Button>
                )}
                {!checkLevel(LEVEL_2) ? (
                  <Button
                    className="common-pointer disabled:opacity-40 sm:ml-0 bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                    onClick={() => setISBookDeal("Book Deal")}
                    disabled={!amount || !country}
                  >
                    <div className="flex flex-row gap-[10px] items-center">
                      Book Deal
                    </div>
                  </Button>
                ) : (
                  <Button
                    className="common-pointer disabled:opacity-40 sm:ml-0 bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                    onClick={() => setISBookDeal("Book Rate")}
                    disabled={!amount || !country}
                  >
                    <div className="flex flex-row gap-[10px] items-center">
                      Book Rate
                    </div>
                  </Button>
                )}
              </div>
            </div>
            {currentStep === 1 && (
              <section className="flex-1 bg-white-A700 px-3 py-5 rounded-xl gap-4 flex flex-col">
                <p className="text-lg leading-4 font-semibold">Calculations</p>
                <div className="pt-5 pb-10 px-5 border-b-8 rounded-xl bg-custom-calc-gradient border-blue-700 gap-10">
                  <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                    <div className="flex flex-col gap-4 w-full">
                      <div className="flex justify-between w-full">
                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                          Customer Rate
                        </span>
                        {isCalcLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {cal_customer_rate === ""
                              ? "0.00"
                              : `₹ ${cal_customer_rate}`}
                          </span>
                        )}
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                          Recipient gets
                        </span>
                        {isCalcLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {cal_amount_inr == ""
                              ? "0.00"
                              : (currency === "" || currency === undefined
                                  ? "USD "
                                  : `${currency} `) +
                                formatIndianNumber(
                                  parseFloat(amount)?.toFixed(4),
                                ).toString()}
                          </span>
                        )}
                      </div>
                      <div className="w-full border-[.3px]" />
                      <div className="flex flex-col w-full gap-4">
                        <div className="flex justify-between w-full">
                          <span className="text-sm leading-[17px] indent-3.5 font-normal">
                            Total Charges
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_total_charges === "" || amount === ""
                                ? "0.00"
                                : `INR ${parseFloat(Number(cal_total_charges)?.toFixed(2))}`}
                            </span>
                          )}
                        </div>
                        <div className="ml-4 border-l border-gray-300 flex flex-col gap-4 mt-2">
                          <div className="flex justify-between w-full mb-2">
                            <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                              GST on FX conversion
                            </span>
                            {isCalcLoading ? (
                              <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                            ) : (
                              <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                                {cal_gst === "" || amount === ""
                                  ? "0.00"
                                  : `INR ${Number(cal_gst)?.toFixed(2)}`}
                              </span>
                            )}
                          </div>
                          <div className="flex justify-between w-full mb-2">
                            <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                              Foreign Bank charges{" "}
                              <span className="text-xs text-gray-500">
                                (incl. GST)
                              </span>
                            </span>
                            {isCalcLoading ? (
                              <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                            ) : (
                              <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                                {cal_foreign_bank_charges == "" || amount === ""
                                  ? "0.00"
                                  : `INR ${Number(cal_foreign_bank_charges)?.toFixed(2)}`}
                              </span>
                            )}
                          </div>
                          <div className="flex justify-between w-full">
                            <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                              TT charges{" "}
                              <span className="text-xs text-gray-500">
                                (incl. GST)
                              </span>
                            </span>
                            {isCalcLoading ? (
                              <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                            ) : (
                              <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                                {cal_tt_charges == "" || amount === ""
                                  ? "0.00"
                                  : `INR ${Number(cal_tt_charges)?.toFixed(2)}`}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                      <span className="text-xs leading-[14.5px] indent-3.5 font-semibold">
                        Total estimation
                      </span>
                      {isCalcLoading ? (
                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                      ) : (
                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                          {cal_total_payable == "" || amount === ""
                            ? "0.00"
                            : `INR ${formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}`}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {
                  <div className="flex flex-col  gap-5">
                    <h1 className="flex items-center gap-2 font-inter font-medium text-[16px] leading-[160%] tracking-[-0.02em] text-gray-800">
                      <img
                        src="/images/ShareArrow.svg"
                        className="w-5 h-5"
                        alt="Share"
                      />
                      Share calculation with customer
                    </h1>
                    <div className="flex gap-4">
                      <button
                        onClick={() => setWhatsappChecked(!whatsappChecked)}
                        className={`flex-1 flex flex-col items-center gap-2 p-10 border-2 rounded-[8px] cursor-pointer transition ${
                          whatsappChecked
                            ? "border-[#165FE3] bg-white"
                            : "border-gray-300"
                        } hover:shadow-md`}
                      >
                        <img
                          className="w-[30px] h-[30px]"
                          src="/images/WhatsappIcon.svg"
                          alt="WhatsApp"
                        />
                        <span className="text-sm text-gray-700">WhatsApp</span>
                      </button>

                      <button
                        onClick={() => setEmailChecked(!emailChecked)}
                        className={`flex-1 flex flex-col items-center gap-2 p-10 border-2 rounded-[8px] cursor-pointer transition ${
                          emailChecked
                            ? "border-[#165FE3] bg-white"
                            : "border-gray-300"
                        } hover:shadow-md`}
                      >
                        <img
                          className="w-[30px] h-[30px]"
                          src="/images/GmailIcon.svg"
                          alt="Email"
                        />
                        <span className="text-sm text-gray-700">Email</span>
                      </button>
                    </div>

                    <button
                      className="w-full flex items-center disabled:opacity-40 justify-between px-4 bg-blue-600 text-[#fff] py-3 rounded-[8px] mt-4 hover:bg-blue-700 transition"
                      onClick={handleShareNow}
                      disabled={
                        (!whatsappChecked && !emailChecked) ||
                        !country ||
                        !amount
                      }
                    >
                      <span>Share Now</span>
                      <img
                        className="w-5 h-5"
                        src="/images/ShareNow.svg"
                        alt="Share Icon"
                      />
                    </button>
                  </div>
                }
              </section>
            )}
          </div>
        </div>
        <div className=" hidden bg-white-A700 bottom-[0] flex gap-[33px] h-[73px] md:h-auto items-center justify-end md:px-5 px-[83px] py-[27px] shadow-bs1 sticky w-full">
          <div className="flex flex-row flex-wrap gap-5 items-center justify-center sm:items-end"></div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </>
  );
};

export default L2CreateRemittanceQuotation;
