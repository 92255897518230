import { useFormContext } from "react-hook-form";
import { FormData } from ".";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";

export default function Step4() {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();

  const offerletterFile = watch("offerletterFile");
  // convert base64 to file

  return (
    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
      <p className={`text-[18px] font-medium text-[#333]`}>
        {watch("product") === "30" ? "GIC letter" : "Upload Offer Letter"}
      </p>
      <div className="flex gap-5 flex-col w-full">
        <div className="py-1.5 px-3.5 justify-between rounded-md flex gap-8 border">
          <div className="flex gap-3 items-center overflow-hidden">
            <img src="/images/remit/file.svg" alt="" />
            <p className="truncate">
              {offerletterFile ? offerletterFile.name : "No file choosen"}
            </p>
          </div>
          <label
            htmlFor="offerletter"
            className="text-[#165FE3] border cursor-pointer border-[#165FE3] text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
          >
            {offerletterFile ? "Re-upload" : "Upload"}
          </label>
          <input
            type="file"
            id="offerletter"
            name="offerletter"
            className="hidden"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                setValue("offerletterFile", file);
                convertFileToBase64(file).then((base64) => {
                  setValue("offerletter", base64);
                });
              }
            }}
            accept="image/*,application/pdf"
          />
        </div>
        {watch("product") !== "30" && (
          <label className="flex items-center gap-2" htmlFor="consent">
            <input
              className="rounded-[4px]"
              checked={watch("offerletterConsent")}
              type="checkbox"
              onChange={(e) => setValue("offerletterConsent", e.target.checked)}
              name="consent"
              id="consent"
            />
            <p className="select-none">I do not have the offer letter</p>
          </label>
        )}
      </div>
    </div>
  );
}
