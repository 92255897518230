import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PaymentType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { order_status_options } from "common/filters";
import { Button, Img, Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import UploadDocuments from "models/OrderDetails/CaptureDocuments";
import AddDeclaration from "models/OrderDetails/CapturePayment";
import SettlementRate from "models/OrderDetails/SettlementRate";
import { convertFileToBase64 } from "pages/CreateRemit/payment";
import { formatUnixTimestamp } from "pages/Opportunities/utils/featureRule";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddOrderNotesAPI,
  PaymentStatusAPI,
  POSTAddNotesOrderRequestType,
  POSTeditPaymentStatusRequestType,
} from "service/apis/CreateOrderapi";
import {
  CapturePaymentRequestType,
  GetPartnerDetailsRequestType,
  PutOrderStatusRequestType,
  capturePayment,
  capturePaymentL2,
  getPartnerDetails,
  putOrderStatus,
} from "service/apis/Orderdetails";
import { Toaster, toast } from "sonner";
import { formatCurrency } from "utils";
import OrderDocuments from "./documents";
import PaymentReview from "./new-payment";
import MilestoneTracker, { MilestoneItem } from "./mile-stone";
import { useAppDispatch } from "redux/hooks";
import { setIsDealflowCheck } from "redux/features/CreateOrder";
import ChallanDialog from "./challan";
import { TooltipGlobal } from "components/GlobalTooltip";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";
import { RefundDialog } from "./OrderRefund/refund";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type OrderdetailsType = {
  id: string;
  status: string;
  payment_status: string;
  created_at: string;
  country: string;
  type: string;
  note: string;
  note_last_edited: number;
  _order_customers: {
    full_name: string;
    email: string;
    phone: string;
    pan_no: string;
    aadhaar_no: string;
    pan_soft_copy: {
      url: string;
    };
    aadhaar_soft_copy_front: {
      url: string;
    };
  };
  supporting_documents: [];
  _order_channels: {
    id: string;
    name: string;
    phone: string;
    email: string;
    address: string;
  };
  _beneficiary: {
    account_number: string;
    bank_addr: string;
    bank_country: string;
    bank_name: string;
    correspondant_bank_name: string;
    correspondant_bank_no: string;
    iban_no: string;
    id: number;
    name: string;
    swift_code: string;
  };
  partner_detail: {
    city: string;
    id: number;
    name: string;
    state: string;
    type: string;
  };
  amount: number;
  currency: string;
  gst: number;
  tcs: number;
  tt_charges_inclgst: number;
  foreign_bank_rates_inclgst: number;
  opportunity_id: number;
  payable_amount: number;
  l1_commission_rates: number;
  l2_commission_rates: number;
  l3_commission_rates: number;
  l1_channel: number;
  l2_channel: number;
  l3_channel: number;
  service_partner_rates: number;
  eforex_rates: number;
  _payments_of_order: {
    id: string;
    acknowledgement_no: string;
    amount: string;
    receipt?: {
      url: string;
    };
  }[];
  _order_service_partner: {
    id: string;
    display_name: string;
    email: string;
    legal_addr_city: string;
    legal_addr_state: string;
  };
  _order_created_by: {
    id: number;
    fname: string;
    phone_number: string;
    email: string;
  };
  order_mileston: [];
  swift_document: {
    url: string;
  };
  invoice_document: {
    url: string;
  };
  _purpose: {
    id: string;
    name: string;
  };
};

type ChannelDetails = {
  id: number;
  Type: string;
  Channel: string;
  category: string;
  source: string;
};

const NewOrderDetailsPage: React.FC = () => {
  const {
    register,
    getValues,
    setValue,
    reset,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paidAmount, setPaidAmount] = useState<number>();
  const [challanState, setChallanState] = useState({});
  const [verifyDocs, setVerifyDocs] = useState(false);
  const [isopen, setIsopen] = useState(false);
  const [rowSelected, setRowSelected] = useState<any>();
  const { id } = useParams();
  const param = useParams();
  const dispatch = useAppDispatch();
  const [orderdetails, setOrderDetails] = useState<OrderdetailsType>();
  const [payAmountVal, setPayAmountVal] = useState<number>(0);
  const [servicePartnerBanks, setServicePartnerBanks] = useState<any[]>([]);
  const [createdate, setCreateDate] = useState<any>();
  const [showcapturepayment, setShowCapturePayment] = useState(false);
  const [showUploadDocumentType, setShowUploadDocumentType] = useState("");
  const [showSettlementRate, setShowSettlementRate] = useState(false);
  const [showimg, setShowimg] = useState(false);
  const [imgsrc, setImgsrc] = useState("");
  const [orderstatus, setOrderStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState<number>();
  const [activeTab, setTab] = useState<string>("overview");
  const [paymentData, setPaymentData] = useState<PaymentType[]>([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [paymnetFile, setPaymentFile] = useState<File | null>(null);
  const [dealID, setDealID] = useState<number>();
  const [studentName, setStudentName] = useState<string>("");
  const [channelDetails, setChannelDetails] = useState<ChannelDetails>();
  const [margin, setMargin] = useState({
    l1: "",
    l2: "",
    l3: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState<string>("");
  const [tempNote, setTempNote] = useState<string>(note);
  const [isTCSEditing, setIsTCSEditing] = useState(false);
  const [tcsValue, setTcsValue] = useState<string>(
    orderdetails?.tcs?.toFixed(2) || "",
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const openDetailNote = () => {
    setIsEditing(true);
  };

  const handleSave = async (order_id: number, note: string) => {
    setNote(tempNote);
    setIsEditing(false);

    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTAddNotesOrderRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id,
        note,
      },
    };
    try {
      const response = await AddOrderNotesAPI(payload);
      if (response?.status === 200) {
        OrderDetails();
        console.log("API resp ====?>>>>", response?.data);
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    } finally {
      setLoading(false);
    }
  };

  const handleTCSCancel = () => {
    setTcsValue("0.00");
    setIsTCSEditing(false);
  };

  // for TCS
  const handleTCSSave = async (
    order_id: Number,
    note: string,
    tcs_amount: Number,
  ) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTAddNotesOrderRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id,
        note,
        tcs_amount,
      },
    };
    try {
      const response = await AddOrderNotesAPI(payload);
      if (response?.status === 200) {
        OrderDetails();
        setIsTCSEditing(false);
        setTcsValue("0.00");
        setIsChecked(false);
        toast.success("TCS updated successfully");
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    } finally {
      setLoading(false);
    }
  };

  const handleDocReview = async (isReview: boolean) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTAddNotesOrderRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id: Number(id),
        doc_status: isReview ? "Approved" : "Review",
      },
    };
    try {
      const response = await AddOrderNotesAPI(payload);
      if (response?.status === 200) {
        OrderDetails();
        setVerifyDocs(false);
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files?.[0]) {
      setPaymentFile(event.target.files[0]);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    OrderDetails();
  }, [id, shouldRefetch]);

  useEffect(() => {
    if (orderdetails) {
      const date = new Date(orderdetails?.created_at);
      setCreateDate(date.toUTCString().substring(0, 16));
    }

    const amount = orderdetails?._payments_of_order.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.amount),
      0,
    );
    setRemainingAmount(Number(orderdetails?.payable_amount) - Number(amount));
  }, [orderdetails]);

  const level = localStorage.getItem("level");
  const channel_id = localStorage.getItem("channel_id");
  const orderChildLevel =
    orderdetails?.l2_channel == 0
      ? "1"
      : orderdetails?.l3_channel == 0
        ? "2"
        : "3";

  const OrderDetails = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetPartnerDetailsRequestType> = {
      data: {
        order_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getPartnerDetails(payload)
      .then((res: any) => {
        console.log(res.data);
        setDealID(res?.data?.dealID);
        setPaidAmount(res?.data?.amount_paid);
        setChannelDetails(res?.data?.channel?.[0]);
        setStudentName(res?.data?.student_name);
        setPayAmountVal(res?.data?.amount_paid);
        setOrderDetails(res.data?.result_1);
        setChallanState({
          status: res?.data?.result_1?.challan_generated,
          challan_url: res?.data?.result_1?.challan_url,
          timestamp: res?.data?.result_1?.challan_timestamp,
          challan_generated_by_staff:
            res?.data?.result_1?.challan_generated_by_staff,
          challan_generated_by_AD: res?.data?.result_1?.challan_generated_by_AD,
        });
        setServicePartnerBanks(res?.data?.bank_list);
        setNote(res.data?.result_1?.note);
        setMargin({
          l1: res.data?.result_1?.l1_commission_rates.toString(),
          l2: res.data?.result_1?.l2_commission_rates.toString(),
          l3: res.data?.result_1?.l3_commission_rates.toString(),
        });
        setPaymentData(
          res.data?.result_1?._payments_of_order.map(
            (payment: any, index: number) => ({
              id: index + 1,
              acknowledgement: payment.acknowledgement_no,
              createdBy: "N/A",
              creationDate: new Date().toDateString(),
              amount: parseInt(payment.amount),
              receipt: payment.receipt,
              supporting_doc: payment.supporting_doc,
              paymentMode: "Offline",
              status: payment?.status_by_AD2,
              notes: payment?.notes,
              created_by: res?.data?.paymets_staff?.find((val) => {
                return val.id === payment.id;
              })?._staff?.fname,
            }),
          ) || [],
        );
        if (res.data.result_1.status) {
          setOrderStatus(res.data?.result_1.status);
        }
      })
      .catch((err) => console.log(err));
  };

  const generateChallan = async () => {};

  const handleonorderstatuschange = (e: string): void => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutOrderStatusRequestType> = {
      data: {
        status: e,
        order_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    putOrderStatus(payload)
      .then((res: any) => {
        setLoading(false);
        setOrderStatus(res.data.status);
        OrderDetails();
        toast.success("Order Status Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const downloadFilefromUrl = async (pdf_url: string, name: string) => {
    try {
      const response = await fetch(pdf_url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {}
  };

  const status = orderdetails?.payment_status;
  let paymentborderColor;
  switch (status) {
    case "pending":
      paymentborderColor = "bg-[#FFA500]"; // Gray
      break;
    case "partially recieved":
      paymentborderColor = "bg-[#0000FF]"; // Light Green
      break;
    case "recieved":
      paymentborderColor = "bg-[#008000]"; // Green
      break;
    default:
  }

  let statusbgcolor = orderstatus;
  switch (statusbgcolor) {
    case "pending":
      statusbgcolor = "bg-[#808080]"; // Gray
      break;
    case "processing":
      statusbgcolor = "bg-[#32CD32]"; // Light Green
      break;
    case "fulfilled":
      statusbgcolor = "bg-colors1"; // Green
      break;
    case "pre processing":
      statusbgcolor = "bg-[#888888]";
      break;
    case "cancelled":
      statusbgcolor = "bg-[#FF0000]";
      break;
    default:
  }

  const getPaymentStatus = (status: string): string => {
    if (status === "recieved") {
      return "received";
    } else if (status === "partially recieved") {
      return "partially received";
    }
    return status;
  };

  const tabsdata = [
    {
      id: 1,
      label: "Overview",
      value: "overview",
      content: "Make changes to your account here.",
    },
    {
      id: 2,
      label: "Amount & Charges",
      value: "amount-charges",
      content: "Change your password here.",
    },
    {
      id: 3,
      label: "Documentations",
      value: "documents",
      content: "Change your password here.",
    },
    {
      id: 4,
      label: "Payments",
      value: "payments",
      content: "Change your password here.",
    },
    {
      id: 5,
      label: "Other Details",
      value: "other-details",
      content: "Change your password here.",
    },
  ];
  const columns: ColumnDef<PaymentType>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Ref ID" />
      ),
    },
    {
      accessorKey: "acknowledgement",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Acknowledgement" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "createdBy",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created By" />
      ),
      cell: ({ row }) => {
        return (
          <p className="text-[#333333] font-medium text-sm leading-[21px]">
            {row.original.created_by || "N/A"}
          </p>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "creationDate",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Date Created" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "Receipt",
      cell: ({ row }) => {
        const receiptUrl = row.original.receipt;
        const supportingDocUrl = row.original.supporting_doc;
        const handleApprove = async (status_AD2: string, notes: string) => {
          setLoading(true);
          const auth = localStorage.getItem("authToken");
          if (!auth) return;
          const authToken = JSON.parse(auth);
          const payload: AxiosRequestConfig<POSTeditPaymentStatusRequestType> =
            {
              headers: { Authorization: `Bearer ${authToken}` },
              data: {
                order_id: Number(id),
                ack_no: row.original.acknowledgement,
                status_AD2,
                notes,
              },
            };
          try {
            const response = await PaymentStatusAPI(payload);
            if (response?.status === 200) {
              setLoading(false);
              if (status_AD2 === "rejected") {
                toast.success("Payment rejected!!");
              } else toast.success("Payment approved successfully");
            }
          } catch (error) {
            toast.error("Failed to update partner details");
            setLoading(false);
          }
        };
        if (row?.original?.status === "accept") {
          return (
            <div className="flex items-center gap-1.5">
              <p className="max-w-[200px] py-1 bg-[#E1FFE2] px-2 rounded-md text-[#00A82D] whitespace-normal break-words">
                Approved
              </p>
              <button
                onClick={() => window.open(row.original.receipt.url, "_blank")}
                className=""
              >
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3221 5.28375C16.5501 5.60325 16.6641 5.76375 16.6641 6C16.6641 6.237 16.5501 6.39675 16.3221 6.71625C15.2976 8.15325 12.6808 11.25 9.16406 11.25C5.64656 11.25 3.03056 8.1525 2.00606 6.71625C1.77806 6.39675 1.66406 6.23625 1.66406 6C1.66406 5.763 1.77806 5.60325 2.00606 5.28375C3.03056 3.84675 5.64731 0.75 9.16406 0.75C12.6816 0.75 15.2976 3.8475 16.3221 5.28375Z"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4141 6C11.4141 5.40326 11.177 4.83097 10.7551 4.40901C10.3331 3.98705 9.7608 3.75 9.16406 3.75C8.56733 3.75 7.99503 3.98705 7.57307 4.40901C7.15112 4.83097 6.91406 5.40326 6.91406 6C6.91406 6.59674 7.15112 7.16903 7.57307 7.59099C7.99503 8.01295 8.56733 8.25 9.16406 8.25C9.7608 8.25 10.3331 8.01295 10.7551 7.59099C11.177 7.16903 11.4141 6.59674 11.4141 6Z"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          );
        }
        if (row?.original?.status === "reject") {
          return (
            <div className="flex items-center gap-1.5">
              <p className="max-w-[200px] bg-red-200 px-2 py-1 flex items-center gap-x-2 rounded-md text-red-500 whitespace-normal break-words">
                Rejected
                <TooltipGlobal
                  content={row?.original?.notes}
                  triggerClassName=""
                  contentClassName="bg-[#000] border-none text-white-A700"
                >
                  <button>
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5 0.09375C5.33186 0.09375 4.18994 0.440146 3.21867 1.08913C2.24739 1.73812 1.49037 2.66055 1.04334 3.73978C0.596309 4.819 0.479346 6.00655 0.70724 7.15225C0.935134 8.29795 1.49765 9.35035 2.32365 10.1763C3.14966 11.0024 4.20205 11.5649 5.34775 11.7928C6.49345 12.0207 7.681 11.9037 8.76023 11.4567C9.83945 11.0096 10.7619 10.2526 11.4109 9.28134C12.0599 8.31006 12.4063 7.16815 12.4063 6C12.4046 4.43407 11.7818 2.93276 10.6745 1.82548C9.56725 0.718198 8.06593 0.0954036 6.5 0.09375ZM6.27284 2.81971C6.40762 2.81971 6.53938 2.85968 6.65145 2.93456C6.76353 3.00945 6.85087 3.11588 6.90245 3.24041C6.95403 3.36493 6.96753 3.50196 6.94123 3.63415C6.91494 3.76635 6.85003 3.88778 6.75472 3.98309C6.65942 4.0784 6.53799 4.1433 6.40579 4.1696C6.27359 4.19589 6.13657 4.1824 6.01204 4.13082C5.88752 4.07924 5.78108 3.99189 5.7062 3.87982C5.63132 3.76775 5.59135 3.63599 5.59135 3.5012C5.59135 3.32046 5.66315 3.14712 5.79095 3.01932C5.91876 2.89151 6.0921 2.81971 6.27284 2.81971ZM6.95433 9.18029C6.71334 9.18029 6.48222 9.08456 6.31181 8.91415C6.14141 8.74374 6.04568 8.51262 6.04568 8.27164V6C5.92518 6 5.80962 5.95213 5.72442 5.86693C5.63921 5.78173 5.59135 5.66617 5.59135 5.54567C5.59135 5.42518 5.63921 5.30962 5.72442 5.22442C5.80962 5.13921 5.92518 5.09135 6.04568 5.09135C6.28667 5.09135 6.51779 5.18708 6.68819 5.35748C6.8586 5.52789 6.95433 5.75901 6.95433 6V8.27164C7.07482 8.27164 7.19038 8.3195 7.27559 8.4047C7.36079 8.48991 7.40866 8.60547 7.40866 8.72596C7.40866 8.84646 7.36079 8.96202 7.27559 9.04722C7.19038 9.13242 7.07482 9.18029 6.95433 9.18029Z"
                        fill="#FF0000"
                      />
                    </svg>
                  </button>
                </TooltipGlobal>
              </p>
            </div>
          );
        }
        return (
          <div className="flex flex-col gap-2">
            {row?.original?.paymentMode === "Offline" ? (
              <PaymentReview
                paymentProofUrl={receiptUrl}
                refetchOrder={OrderDetails}
                onApprove={handleApprove}
                supportingDocUrl={supportingDocUrl}
                amount={row?.original?.amount}
              >
                {checkLevel(LEVEL_2) ? (
                  <button
                    style={{
                      display: "inline-block",
                      padding: "10px 20px",
                      backgroundColor: "inherit",
                      color: "#333333",
                      textDecoration: "none",
                      borderRadius: "5px",
                      border: "1px solid #333333",
                      fontWeight: "bold",
                      textAlign: "center",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                    }}
                    disabled={true}
                  >
                    Approval Pending
                  </button>
                ) : (
                  <button
                    style={{
                      display: "flex",
                      padding: "10px 20px",
                      backgroundColor: "inherit",
                      color: "#333333",
                      textDecoration: "none",
                      borderRadius: "5px",
                      border: "1px solid #333333",
                      fontWeight: "bold",
                      textAlign: "center",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                    }}
                    onClick={() => {
                      setIsopen(true);
                    }}
                  >
                    Review
                  </button>
                )}
              </PaymentReview>
            ) : (
              <>
                <p
                  style={{
                    backgroundColor: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.bgColor,
                    color: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.textColor,
                  }}
                  className="py-[4px] text-sm leading-4 capitalize font-medium  px-2 text-center rounded-md"
                >
                  {orderdetails?.payment_status}
                </p>
              </>
            )}
          </div>
        );
      },
    },
  ];
  const labelStyle = "font-medium text-[#666666] text-sm leading-[21px]";
  const valueStyle = "font-medium text-[#333333] text-sm leading-[21px]";

  const onTabChange = (value: string) => {
    setTab(value);
  };

  const onSaveClick = async (data: any, type = "") => {
    const formData = {
      ...data,
      payment_proof: data.payment_proof_base64,
      supporting_document: data.supporting_document_base64 || null,
    };

    try {
      if (type === "approve") {
        const paymentResult = await handleCapturePayment(formData); // Ensure this completes first
        const approvalResult = await handleApprove(
          "accept",
          data.notes,
          formData.acknowledgement_no,
        ); // Run only if payment succeeds

        await Promise.all([paymentResult, approvalResult]); // Ensure both resolve successfully

        setPaymentFile(null);
        setSelectedFile(null);
        reset(); // Reset the form
        OrderDetails();
      } else {
        await handleCapturePayment(formData);
        setPaymentFile(null);
        setSelectedFile(null);
        reset(); // Reset the form
        OrderDetails();
      }
    } catch (error) {
      console.error("Error in processing:", error);
    }
  };

  const handlePaymentProofChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setPaymentFile(file);
      const base64 = await convertFileToBase64(file);
      setValue("payment_proof_base64", base64);
    }
  };

  const handleSupportingFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const base64 = await convertFileToBase64(file);
      setValue("supporting_document_base64", base64);
    }
  };

  const handleCapturePayment = async (formData: any) => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        acknowledgement_no: formData.acknowledgement_no,
        amount: formData.amount,
        receipt: formData.payment_proof,
        supporting_doc: formData.supporting_document,
        order_id: id,
      } as CapturePaymentRequestType,
    };
    try {
      if (checkLevel(LEVEL_2)) {
        await capturePaymentL2(payload);
      } else {
        await capturePayment(payload);
      }
      toast.success("Payment Captured Successfully");
      setDialogOpen(false);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleSwiftDocument = () => {
    if (orderdetails?.swift_document?.url) {
      downloadFilefromUrl(orderdetails?.swift_document.url, "swift_document");
      return;
    }
    setShowUploadDocumentType("SWIFT");
  };

  const handleInvoiceDocument = () => {
    if (orderdetails?.invoice_document?.url) {
      downloadFilefromUrl(
        orderdetails?.invoice_document.url,
        "invoice_document",
      );
      return;
    }
    setShowUploadDocumentType("Invoice");
  };
  const calculateTotalAmount = (payments: any[] = []) => {
    return payments.reduce(
      (total, payment) => total + (payment.amount || 0),
      0,
    );
  };
  const totalAmount = calculateTotalAmount(orderdetails?._payments_of_order);
  const balance =
    (Number(orderdetails?.payable_amount.toFixed(0)) || 0) - totalAmount;

  // customer rate
  const serviceRate = Number(orderdetails?.service_partner_rates) || 0;
  const marginLevel1 = Number(margin.l1) || 0;
  const marginLevel2 = Number(margin.l2) || 0;
  const marginLevel3 = Number(margin.l3) || 0;

  const totalMargin =
    Number(level) === 1
      ? marginLevel1
      : Number(level) === 2
        ? marginLevel1 + marginLevel2
        : marginLevel1 + marginLevel2 + marginLevel3;

  // Partner rate
  const partnerValue = (
    serviceRate + (Number(level) + 1 === 2 ? marginLevel2 : marginLevel3)
  ).toFixed(2);
  // for customer rate
  const totalValue =
    channelDetails?.id === 12
      ? (Number(partnerValue) + totalMargin).toFixed(2)
      : Number(partnerValue).toFixed(2);

  const StepIndicator = ({ orderdetails, margin, level }: any) => {
    const serviceRate = Number(orderdetails?.service_partner_rates) || 0;
    const marginLevel1 = Number(margin.l1) || 0;
    const marginLevel2 = Number(margin.l2) || 0;
    const marginLevel3 = Number(margin.l3) || 0;

    // Calculate total margin based on level
    const levelNum = Number(level);

    const totalMargin =
      levelNum === 1
        ? marginLevel1
        : levelNum === 2
          ? marginLevel1 + marginLevel2
          : marginLevel1 + marginLevel2 + marginLevel3;

    // Calculate margin rate for the specific level
    const marginRate =
      levelNum === 1
        ? marginLevel1
        : levelNum === 2
          ? marginLevel2
          : marginLevel3;
    // Calculate partner rate correctly
    const partnerRateValue = (serviceRate + marginRate).toFixed(2);

    // Calculate partner margin for the next level
    const PartnerMarginVal =
      levelNum === 1
        ? marginLevel2
        : levelNum === 2
          ? marginLevel2
          : marginLevel3;
    // Final customer rate value
    const CustomerRateValue =
      channelDetails?.id === 12
        ? (PartnerMarginVal + Number(partnerRateValue)).toFixed(2)
        : (serviceRate + marginRate).toFixed(2);

    let steps = [
      { label: "Customer rate", value: `INR ${CustomerRateValue}` },
      { label: "Partner rate", value: `INR ${partnerRateValue}` },
      {
        label: "Settlement rate",
        value: `INR ${Number(serviceRate).toFixed(2)}`,
      },
    ];

    let steps1 = [
      { label: "Customer rate", value: `INR ${CustomerRateValue}` },
      { label: "My rate", value: `INR ${partnerRateValue}` },
    ];

    let finalSteps = checkLevel(LEVEL_2) ? steps1 : steps;

    if (channelDetails?.id !== 12) {
      const labelToRemove = checkLevel(LEVEL_2) ? "My rate" : "Partner rate";
      finalSteps = finalSteps.filter((step) => step.label !== labelToRemove);
    }

    return (
      <div className="flex flex-col gap-2 py-4">
        {finalSteps?.map((item, index) => (
          <div key={index} className="relative flex items-start gap-4">
            {/* Step Indicator with Connecting Line */}
            <div className="relative flex flex-col items-center">
              <img
                className="w-3 h-3"
                src="/images/overview/ellipse.svg"
                alt="Step"
              />
              {index < finalSteps?.length - 1 && (
                <div className="w-[2px] h-20 bg-blue-700 border-l-2 border-dashed" />
              )}
            </div>

            {/* Step Content */}
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium leading-[17px]">
                {item.label}: {item.value}
              </span>

              {/* Partner Margin */}
              {index === 0 && channelDetails?.id === 12 && (
                <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                  <span className="text-sm text-[#666666] font-medium leading-[17px]">
                    {checkLevel(LEVEL_2) ? "My margin" : "Partner margin"}: INR{" "}
                    {PartnerMarginVal}
                  </span>
                </div>
              )}

              {/* MoneeFlo Margin */}
              {index === (channelDetails?.id === 12 ? 1 : 0) &&
                !checkLevel(LEVEL_2) && (
                  <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                    <span className="text-sm text-[#666666] font-medium leading-[17px]">
                      MoneeFlo margin: INR {marginRate}
                    </span>
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleDealRedirect = (event) => {
    event.preventDefault();
    dispatch(setIsDealflowCheck(true));
    navigate(`/remit/dealdetails/${dealID}`);
  };

  const isCaptureBtnStatus =
    parseInt(orderdetails?.payable_amount?.toString()) - payAmountVal;

  const handleApprove = async (
    status_AD2: string,
    notes: string,
    ack_no: number,
  ) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTeditPaymentStatusRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id: Number(id),
        ack_no: Number(ack_no),
        status_AD2,
        notes,
      },
    };
    try {
      const response = await PaymentStatusAPI(payload);
      if (response?.status === 200) {
        setLoading(false);
        if (status_AD2 === "rejected") {
          toast.success("Payment rejected!!");
        } else toast.success("Payment approved successfully");
      }
    } catch (error) {
      toast.error("Failed to update partner details");
      setLoading(false);
    }
  };

  const handleAction = (rowData: any) => {
    setRowSelected(rowData?.id);
    if (rowSelected === rowData?.id) {
      // setRowSelected("");
      // dispatch(setBeneficiaryType(""));
      // dispatch(setBeneficiaryName(""));
      // dispatch(setBeneficiaryAccountNumber(""));
      // dispatch(setBeneficiaryBankName(""));
      // dispatch(setBeneficiaryBankAddr(""));
      // dispatch(setBeneficiaryBankCountry(""));
      // dispatch(setBeneficiarySwiftCode(""));
      return;
    }
    // dispatch(setBeneficiaryType(rowData?.beneficiaryType));
    // dispatch(setBeneficiaryName(rowData?.name));
    // dispatch(setBeneficiaryAccountNumber(rowData?.account_number));
    // dispatch(setBeneficiaryBankName(rowData?.bank_name));
    // dispatch(setBeneficiaryBankAddr(rowData?.bank_addr));
    // dispatch(setBeneficiaryBankCountry(rowData?.country));
    // dispatch(setBeneficiarySwiftCode(rowData?.swift_code));
  };

  const handleLeadRedirect = (event: any) => {
    event.preventDefault();
    navigate(`/remit/leads`, {
      state: {
        LeadID: orderdetails?.opportunity_id,
        leadModalState: true,
      },
    });
  };

  return (
    <>
      {loading && <Spinner />}
      <AlertDialog open={isTCSEditing} onOpenChange={setIsTCSEditing}>
        <AlertDialogPortal>
          <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
          <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-md animate-contentShow">
            <form className="max-w-md">
              <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                  Edit TCS charges
                </AlertDialogTitle>
                <div className="border-b-2 border-gray-300 my-2 w-full"></div>
                <div className="flex flex-col gap-4">
                  <label
                    htmlFor="newTcs"
                    className="text-[14px] font-500 text-[#333333]"
                  >
                    New TCS charges
                  </label>
                  <div className="flex items-center border border-black/30 rounded-lg px-3 py-2">
                    <span className="bg-gray-100 border border-gray-300 rounded-lg text-gray-500 text-[15px] p-3">
                      INR
                    </span>
                    <input
                      className="flex-grow text-[15px] pl-4 border-none focus:outline-none"
                      type="number"
                      value={tcsValue}
                      onChange={(e) => setTcsValue(e.target.value)}
                      placeholder="Enter new TCS charges"
                    />
                  </div>

                  <div className="bg-gray-100 p-2 rounded-md">
                    <label htmlFor="currenttcs" className="text-sm font-medium">
                      Current TCS:{" "}
                      <span className="font-semibold">
                        {orderdetails?.tcs || 0}
                      </span>
                    </label>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="tcsConfirmation"
                      className="w-4 h-4 cursor-not-allowed disabled:cursor-not-allowed"
                      required
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      disabled={!tcsValue}
                    />
                    <label htmlFor="tcsConfirmation" className="text-sm">
                      I hereby declare the TCS charges are updated as confirmed
                      by {orderdetails?._order_service_partner?.display_name}.
                    </label>
                  </div>
                </div>
              </AlertDialogHeader>
              <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel
                  className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                  asChild
                >
                  <button onClick={handleTCSCancel}>Close</button>
                </AlertDialogCancel>
                {(orderdetails?.tcs || 0) > parseInt(tcsValue) ? (
                  <TCSWarning
                    tcs_amount={orderdetails?.tcs}
                    order_id={param?.id as string}
                    tcsValue={tcsValue}
                    handleSubmit={handleTCSSave}
                  >
                    <AlertDialogAction
                      className={`rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700 ${
                        !isChecked ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      Save
                    </AlertDialogAction>
                  </TCSWarning>
                ) : (
                  <AlertDialogAction
                    type="submit"
                    onClick={() =>
                      handleTCSSave(
                        parseInt(param?.id as string),
                        "",
                        parseInt(tcsValue),
                      )
                    }
                    className={`rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700 ${
                      !isChecked ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={!isChecked || !tcsValue}
                  >
                    Save
                  </AlertDialogAction>
                )}
              </AlertDialogFooter>
            </form>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
      {
        <div>
          {showcapturepayment && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <AddDeclaration
                orderID={id}
                setShowCapturePayment={setShowCapturePayment}
                OrderDetails={OrderDetails}
                remainingAmount={remainingAmount}
              />
            </div>
          )}
          {showUploadDocumentType !== "" && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
            >
              <UploadDocuments
                showUploadDocumentType={showUploadDocumentType}
                orderID={id}
                setShowUploadDocumentType={setShowUploadDocumentType}
                OrderDetails={OrderDetails}
              />
            </div>
          )}
          {showSettlementRate && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <SettlementRate
                setShowSettlementRate={setShowSettlementRate}
                id={id}
                orderdetails={orderdetails}
                OrderDetails={OrderDetails}
              />
            </div>
          )}
        </div>
      }

      {showimg && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Img src={imgsrc} className="h-[300px] w-[400px]" alt="Loading...." />
          <button
            className="relative top-[-150px] left-[10px]"
            onClick={() => setShowimg(!showimg)}
          >
            <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
          </button>
        </div>
      )}
      <div className="flex-1 sm:flex-col overflow-hidden gap-x-3 w-full flex border-[#D9E0F7]">
        <div className="flex flex-col gap-5 h-full w-[80%] overflow-y-scroll overflow-x-hidden p-3 bg-white-A700 border rounded-xl">
          <div className=" flex flex-col w-full gap-8 border-b-2 pb-5">
            <div className="flex justify-between items-center">
              <div className=" flex flex-col gap-2">
                <div className="flex flex-row items-center gap-2">
                  <h3 className="font-semibold leading-4 text-lg text-black-label">
                    {orderdetails?._order_customers?.full_name}
                  </h3>
                  <span className="text-gray-400">•</span>
                  <h3 className="font-semibold leading-4 text-lg text-black-label">
                    {orderdetails?._purpose?.name}
                  </h3>
                  <span className="text-gray-400">•</span>
                  <span className="text-gray-500">Order ID: {id}</span>
                </div>

                <div>
                  <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
                    {orderdetails?.created_at &&
                      new Date(orderdetails.created_at)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                        .replace(",", "")
                        .replace(" at", "")}{" "}
                    by {orderdetails?._order_created_by?.fname || "N/A"}
                    {!checkLevel(LEVEL_2) && (
                      <>
                        |{" "}
                        {channelDetails?.Channel === "Refferal"
                          ? "Referral"
                          : channelDetails?.Channel}{" "}
                        - {orderdetails?.partner_detail?.city || "N/A"}
                      </>
                    )}
                  </p>
                </div>
              </div>
              {level === "1" &&
                (orderdetails?.payment_status === "recieved" ||
                  orderdetails?.status === "fulfilled" ||
                  orderdetails?.status !== "processing") && (
                  <div className="flex gap-3">
                    <Button
                      onClick={handleSwiftDocument}
                      className="rounded-lg py-[11px] flex gap-2 px-4 text-[#165FE3] font-semibold text-sm"
                    >
                      {orderdetails?.swift_document?.url
                        ? "Download SWIFT"
                        : "Upload SWIFT"}
                      <img
                        alt="upload icon"
                        src="/images/remit/uploadIcon.svg"
                        style={{ height: "20px", width: "20px" }}
                        className={`${orderdetails?.swift_document?.url ? " rotate-180" : " rotate-0"}`}
                      />
                    </Button>
                    <Button
                      onClick={handleInvoiceDocument}
                      className="rounded-lg py-[11px] px-4 text-[#165FE3] flex gap-2 font-semibold text-sm"
                    >
                      {orderdetails?.invoice_document?.url
                        ? "Download Invoice"
                        : "Upload Invoice"}
                      <img
                        alt="upload icon"
                        src="/images/remit/uploadIcon.svg"
                        style={{ height: "20px", width: "20px" }}
                        className={`${orderdetails?.invoice_document?.url ? " rotate-180" : " rotate-0"}`}
                      />
                    </Button>
                  </div>
                )}
            </div>
          </div>

          {/* all tabs */}
          <Tabs
            value={activeTab}
            onValueChange={onTabChange}
            className=" sm:max-w-7xl sm:overflow-hidden"
          >
            <TabsList className="border-b border-[#C4D7F9] w-full hide-scrollbar flex justify-between rounded-none gap-2 h-12">
              {tabsdata.map((tab) => (
                <TabsTrigger
                  key={tab.id}
                  value={tab.value}
                  className={`px-6 py-3.5 text-sm ${tab.value === activeTab && "border-t h-11 rounded-none font-semibold border-blue-700 text-blue-700 w-[215px] shadow bg-[#EFF4FF]"}`}
                >
                  <div className="flex gap-2 items-center">
                    {tab.label}
                    {(() => {
                      const documentStatus =
                        orderdetails?.supporting_documents?.every(
                          (doc) => doc.status === "Verified",
                        );
                      if (["payments", "documents"].includes(tab.value)) {
                        if (
                          tab.value === "payments" &&
                          (isCaptureBtnStatus < 0 || isCaptureBtnStatus === 0)
                        ) {
                          return (
                            <span className="">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM11.824 6.16L7.288 10.696C7.176 10.808 7.024 10.872 6.864 10.872C6.704 10.872 6.552 10.808 6.44 10.696L4.176 8.432C3.944 8.2 3.944 7.816 4.176 7.584C4.408 7.352 4.792 7.352 5.024 7.584L6.864 9.424L10.976 5.312C11.208 5.08 11.592 5.08 11.824 5.312C12.056 5.544 12.056 5.92 11.824 6.16Z"
                                  fill="#00A82D"
                                />
                              </svg>
                            </span>
                          );
                        } else if (tab.value === "documents" && documentStatus)
                          return (
                            <span className="">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM11.824 6.16L7.288 10.696C7.176 10.808 7.024 10.872 6.864 10.872C6.704 10.872 6.552 10.808 6.44 10.696L4.176 8.432C3.944 8.2 3.944 7.816 4.176 7.584C4.408 7.352 4.792 7.352 5.024 7.584L6.864 9.424L10.976 5.312C11.208 5.08 11.592 5.08 11.824 5.312C12.056 5.544 12.056 5.92 11.824 6.16Z"
                                  fill="#00A82D"
                                />
                              </svg>
                            </span>
                          );
                        else {
                          return (
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.80078 0C4.39278 0 0.800781 3.592 0.800781 8C0.800781 12.408 4.39278 16 8.80078 16C13.2088 16 16.8008 12.408 16.8008 8C16.8008 3.592 13.2088 0 8.80078 0ZM12.6248 6.16L8.08878 10.696C7.97678 10.808 7.82478 10.872 7.66478 10.872C7.50478 10.872 7.35278 10.808 7.24078 10.696L4.97678 8.432C4.74478 8.2 4.74478 7.816 4.97678 7.584C5.20878 7.352 5.59278 7.352 5.82478 7.584L7.66478 9.424L11.7768 5.312C12.0088 5.08 12.3928 5.08 12.6248 5.312C12.8568 5.544 12.8568 5.92 12.6248 6.16Z"
                                fill="#D2D2D2"
                              />
                            </svg>
                          );
                        }
                      }
                      return null;
                    })()}
                  </div>
                </TabsTrigger>
              ))}
            </TabsList>
            <TabsContent
              className="p-3 gap-10 flex flex-col rounded-xl w-full overflow-auto"
              value="overview"
            >
              {/* <div className="pb-10 border-b w-full flex flex-col gap-8"> */}
              {/* <div className="flex items-center w-full"> */}
              {/* <div className="py-2 px-3  rounded-lg w-fit">
                    <div className="flex gap-2">
                      <p
                        style={{ backgroundColor: "#F3FFF7", color: "#00A82D" }}
                        className="py-2 text-sm leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl"
                      >
                        <Img src="/images/orderdetails/green-circle.svg" />
                        Fullfilled
                      </p>
                    </div>
                  </div> */}
              {/* <div className="py-2 px-3 rounded-lg w-fit sm:w-full">
                    <div className="flex sm:w-full gap-2">
                      <p
                        style={{ backgroundColor: "#EBF1FB", color: "#165FE3" }}
                        className="py-2 text-sm leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl capitalize"
                      >
                        <Img src="/images/orderdetails/blue-circle.svg" />
                        Processing
                      </p>
                    </div>
                  </div>
                  <div className="sm:w-full">
                    <svg
                      width="144"
                      height="3"
                      viewBox="0 0 144 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="144"
                        y="2.5"
                        width="144"
                        height="2"
                        rx="1"
                        transform="rotate(180 144 2.5)"
                        fill="url(#paint0_linear_1105_32921)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1105_32921"
                          x1="144"
                          y1="3.5"
                          x2="288"
                          y2="3.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop
                            offset="0.467887"
                            stop-color={
                              orderdetails?.status === "processing"
                                ? "#999999"
                                : order_status_options.find(
                                    (status) =>
                                      orderdetails?.status === status.value,
                                  )?.textColor
                            }
                          />
                          <stop offset="0.534294" stop-color="#165FE3" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="py-2 px-3  rounded-lg w-fit sm:w-full">
                    <div
                      className={`flex sm:w-full gap-2 ${orderdetails?.status === "processing" ? "grayscale" : ""}`}
                    >
                      <p
                        style={{
                          backgroundColor: order_status_options.find(
                            (status) => orderdetails?.status === status.value,
                          )?.bgColor,
                          color: order_status_options.find(
                            (status) => orderdetails?.status === status.value,
                          )?.textColor,
                        }}
                        className="py-2 text-sm sm:w-full leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl"
                      >
                        <Img
                          src="/images/orderdetails/green-circle.svg"
                          className={
                            orderdetails?.status !== "processing"
                              ? "grayscale"
                              : ""
                          }
                        />
                        {orderdetails?.status === "processing"
                          ? "Fullfilled"
                          : orderdetails?.status}
                      </p>
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
              {/* <div className="flex justify-between gap-8 w-full"> */}
              {/* <div className="flex w-2/3 flex-col gap-5">
                  <div className="bg-[#FCFCFC] p-5 rounded-[20px]">
                    <div className="flex gap-5">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
                          stroke="#165FE3"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.22457 3.71649C6.76715 3.12836 6.05276 2.75 5.25 2.75C3.86929 2.75 2.75 3.86929 2.75 5.25C2.75 6.06385 3.13889 6.78687 3.74097 7.24337"
                          stroke="#165FE3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.7734 3.7195C17.2307 3.12966 17.9461 2.75 18.7503 2.75C20.131 2.75 21.2503 3.86929 21.2503 5.25C21.2503 6.06148 20.8637 6.78265 20.2646 7.23937"
                          stroke="#165FE3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 8V12L9 15"
                          stroke="#165FE3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 21.25L7 20M18 21.25L17 20"
                          stroke="#165FE3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5">
                          <p className="">Created at</p>
                          <p className="">
                            {new Date(
                              orderdetails?.created_at as string,
                            ).toDateString()}
                          </p>
                        </div>
                        <div className="flex gap-5">
                          <p className="">Created by</p>
                          <Link to="#" className="text-blue-700">
                            {orderdetails?._order_channels.name} &#8599;
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#FCFCFC] p-5 flex flex-col gap-5 rounded-[20px]">
                    <div className="flex gap-5">
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5">
                          <Img src="/images/orderdetails/copy.svg" />
                          <p className="text-blue-700">Document Uploaded</p>
                        </div>
                        <div className="flex pl-10 gap-1">
                          <span className="font-medium text-[40px] leading-9 text-blue-700">
                            {orderdetails?.supporting_documents.length}
                          </span>
                          <span className="font-medium text-xl leading-6 text-[#98BDFF] flex items-end">
                            / {orderdetails?.supporting_documents.length}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Link
                      to="#"
                      onClick={() => setTab("documents")}
                      className=" underline text-blue-700 text-right w-full"
                    >
                      View details
                    </Link>
                  </div>
                </div> */}
              {/* <div className="flex flex-wrap gap-5 flex-auto w-full">
                  <div className="border-gray-border-gray-lite border flex flex-grow p-5 rounded-[20px]">
                    <div className="flex gap-5">
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5 w-full">
                          <Img src="/images/orderdetails/house.svg" />
                          <p className="text-blue-700">Remitter</p>
                          <p className="text-blue-700">&#8599;</p>
                        </div>
                        <div className="flex gap-5">
                          <p className="">Name</p>
                          <p className="">
                            {orderdetails?._order_customers?.full_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                    <div className="flex gap-5">
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5">
                          <Img src="/images/orderdetails/paperplane.svg" />
                          <p className="text-blue-700">Purpose</p>
                        </div>
                        <div className="flex gap-5">
                          {orderdetails?._purpose?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                    <div className="flex gap-5">
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5">
                          <Img src="/images/orderdetails/percent.svg" />
                          <p className="text-blue-700">FCY Amount</p>
                        </div>
                        <div className="flex gap-5">
                          <p className="">
                            {orderdetails?.amount &&
                              formatCurrency(
                                parseInt(orderdetails?.amount.toFixed(0)),
                                orderdetails?.currency as string,
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                    <div className="flex gap-5">
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5">
                          <Img src="/images/orderdetails/piechart.svg" />
                          <p className="text-blue-700">Balance</p>
                        </div>
                        <div className="flex gap-5">
                          <p className="">
                            {orderdetails?.payable_amount &&
                              formatCurrency(
                                Number(orderdetails?.payable_amount.toFixed(0)),
                                "INR",
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-border-gray-lite border w-full flex-col flex p-5 rounded-[20px]">
                    <div className="flex gap-5">
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5">
                          <Img src="/images/orderdetails/badgedollar.svg" />
                          <p className="">Total amount payable</p>
                        </div>
                        <div className="flex text-blue-700 gap-5">
                          <p className="">
                            {orderdetails?.payable_amount &&
                              formatCurrency(
                                Number(orderdetails?.payable_amount.toFixed(0)),
                                "INR",
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link
                      to="#"
                      onClick={() => setTab("amount-charges")}
                      className=" underline text-blue-700 text-right w-full"
                    >
                      View details
                    </Link>
                  </div>
                </div> */}
              {/* </div> */}
              <div className="flex flex-row gap-4 w-full h-full">
                {/* Left Section */}
                <div className="w-[70%] flex flex-col gap-4">
                  <div className="rounded-lg p-2 bg-[#FFEAC9] w-[400px]">
                    <h1 className="text-[#000]">
                      This order has been created from deal{" "}
                      <a
                        href={`/remit/dealdetails/${dealID}`}
                        className="text-blue-600 underline"
                        onClick={handleDealRedirect}
                      >
                        ID - {dealID}
                      </a>
                    </h1>
                  </div>
                  <div className="rounded-lg p-5 bg-[#FAFAFA] w-full">
                    <div className="flex gap-4">
                      <h1 className="text-gray-400">Beneficiary:</h1>
                      <h1 className="text-black">
                        {" "}
                        {orderdetails?._beneficiary?.name || "N/A"}
                      </h1>
                    </div>
                    <div className="flex gap-4">
                      <h1 className="text-gray-400">Purpose:</h1>
                      <h1 className="text-black">
                        {orderdetails?._purpose?.name}
                      </h1>
                    </div>
                    <div className="flex gap-4">
                      <h1 className="text-gray-400">Student:</h1>
                      <h1 className="text-black">
                        {studentName?.value || "N/A"}{" "}
                      </h1>
                    </div>
                  </div>

                  <div className="rounded-lg p-5 bg-[#FAFAFA] w-full flex flex-col gap-2">
                    <div className="flex justify-between items-center p-3 rounded-lg">
                      <h1 className="text-gray-400">FCY Amount</h1>
                      <div className="flex gap-5">
                        <p className="">
                          {orderdetails?.amount &&
                            formatCurrency(
                              parseInt(orderdetails?.amount.toFixed(0)),
                              orderdetails?.currency as string,
                            )}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-between items-center p-3 bg-white rounded-lg">
                      <h1 className="text-gray-400">
                        Total Amount Payable{" "}
                        <span
                          className="text-blue-500 underline cursor-pointer"
                          onClick={() => setTab("amount-charges")}
                        >
                          View Breakup
                        </span>
                      </h1>
                      <div className="flex text-blue-700 gap-5">
                        <p className="">
                          {formatCurrency(
                            parseInt(
                              orderdetails?.payable_amount.toFixed(2) as string,
                            ),
                            "INR",
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-between items-center p-3 bg-[#FF000012] rounded-lg">
                      <h1 className="text-red-400">Balance:</h1>
                      <div className="flex gap-5">
                        <p className="">{`INR ${balance.toFixed(3)}`}</p>
                      </div>
                    </div>

                    <div className="flex justify-between items-center p-3 bg-white rounded-lg">
                      <h1 className="text-gray-400">Customer Rate:</h1>
                      <h1 className="text-black">INR {totalValue}</h1>
                    </div>
                  </div>
                  <div className="rounded-lg p-2 bg-[#FAFAFA] w-full flex flex-col gap-2">
                    {!checkLevel(LEVEL_2) && (
                      <div className="flex justify-between items-center p-3 rounded-lg">
                        <h1 className="text-gray-400">My Margin:</h1>
                        <div className="flex items-center rounded-lg px-3">
                          <span className="text-black-500 text-[15px] p-1">
                            INR
                          </span>
                          <h1 className="text-black">
                            {level === "1"
                              ? margin.l1
                              : level === "2"
                                ? margin.l2
                                : margin.l3}
                          </h1>
                        </div>
                      </div>
                    )}
                    <div className="flex justify-between items-center p-3 bg-white rounded-lg">
                      <h1 className="text-gray-400">
                        {checkLevel(LEVEL_2) ? "My margin" : "Partner’s margin"}
                        :
                      </h1>
                      <h1 className="text-black">INR {margin?.l2}</h1>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-[30%] h-full relative space-y-4">
                  <div className="h-full flex flex-col">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger
                          className="flex cursor-pointer items-center w-full md:w-auto"
                          asChild
                        >
                          <div className="flex items-center space-x-1">
                            <p className="text-[14px] font-500 md:text-base leading-[22px] md:leading-[25px] text-black-label">
                              Internal notes
                            </p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-gray-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8-3a1 1 0 100 2 1 1 0 000-2zm-1 4a1 1 0 012 0v2a1 1 0 01-2 0v-2z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent
                          side="bottom"
                          className="bg-black-900 text-white-A700 max-w-[200px] whitespace-normal break-words border-0"
                        >
                          <p className="text-[14px] font-medium md:text-base leading-[22px] md:leading-[25px]">
                            Visible to the partner
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    {!isEditing ? (
                      <div className="flex items-center gap-5 w-full">
                        <div className="bg-[#FAFAFA] w-full flex items-center gap-2 py-2.5 px-2 rounded-lg text-left">
                          <div
                            className="bg-[#FAFAFA] w-full flex items-center gap-2 py-2.5 px-2 rounded-lg text-left relative"
                            style={{ border: "1px solid #D2D2D2" }}
                          >
                            <div className="flex flex-col gap-1 max-h-[300px] overflow-y-scroll overflow-x-hidden pr-6">
                              <p className="font-400 text-[12px] leading-[18px] text-[#999999]">
                                {orderdetails?.note_last_edited ? (
                                  <>
                                    Last edited on{" "}
                                    {formatUnixTimestamp(
                                      orderdetails?.note_last_edited,
                                    )}
                                  </>
                                ) : (
                                  "Click on icon to add note"
                                )}
                              </p>
                              <p className="font-normal text-base leading-[150%] break-words text-[#333333] whitespace-pre-wrap">
                                {orderdetails?.note}
                              </p>
                            </div>

                            {!checkLevel(LEVEL_2) && (
                              <button
                                onClick={openDetailNote}
                                className="absolute top-[10px] right-2"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.33594 4.24609H2.5026C2.06058 4.24609 1.63665 4.42169 1.32409 4.73425C1.01153 5.04681 0.835938 5.47073 0.835938 5.91276V13.4128C0.835938 13.8548 1.01153 14.2787 1.32409 14.5913C1.63665 14.9038 2.06058 15.0794 2.5026 15.0794H10.0026C10.4446 15.0794 10.8686 14.9038 11.1811 14.5913C11.4937 14.2787 11.6693 13.8548 11.6693 13.4128V12.5794"
                                    stroke="#165FE3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.8333 2.58472L13.3333 5.08472M14.4875 3.90556C14.8157 3.57735 15.0001 3.13221 15.0001 2.66806C15.0001 2.20391 14.8157 1.75876 14.4875 1.43056C14.1593 1.10235 13.7142 0.917969 13.25 0.917969C12.7858 0.917969 12.3407 1.10235 12.0125 1.43056L5 8.41806V10.9181H7.5L14.4875 3.90556Z"
                                    stroke="#165FE3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-full flex flex-col gap-2">
                        <textarea
                          value={tempNote || note}
                          placeholder="Enter note"
                          className="font-normal text-base leading-[150%] border border-gray-400 rounded-lg p-4 w-full h-[250px] bg-white"
                          onChange={(e) => setTempNote(e.target.value)}
                          rows={5}
                        />
                        <div className="flex justify-end gap-2">
                          <button
                            onClick={() =>
                              handleSave(
                                parseInt(param?.id as string),
                                tempNote,
                              )
                            }
                            className="text-white-A700 bg-blue-700 text-sm rounded-lg py-1.5 px-3 hover:bg-blue-700"
                          >
                            Save
                          </button>
                          <button
                            onClick={() => setIsEditing(false)}
                            className="text-gray-600 bg-gray-300 text-sm rounded-lg py-1.5 px-3 hover:bg-gray-400"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Cancel Order Button */}
                  {/* <button
                    style={{ border: "1px solid blue" }}
                    className="w-full px-4 py-3 text-blue-500 rounded-lg border-2"
                  >
                    Cancel Order
                  </button> */}
                </div>
              </div>
            </TabsContent>
            <TabsContent value="amount-charges">
              <section className="bg-white-A700 px-3 py-5 rounded-xl gap-4 flex flex-col">
                <p className="text-[#666666] leading-4 font-semibold">
                  Order Summary
                </p>
                <div className="pt-5 pb-10 px-5 rounded-xl shadow-order-summary gap-10">
                  <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                    <div className="flex flex-col gap-4 w-full">
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          FX Amount
                        </span>
                        <div>
                          <span className="text-sm font-medium">
                            {formatCurrency(
                              orderdetails?.amount as number,
                              orderdetails?.currency as string,
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          Total Charges
                        </span>
                        <div className="text-sm font-medium">
                          <span>
                            {formatCurrency(
                              parseFloat(
                                Number(
                                  Number(orderdetails?.gst) +
                                    Number(orderdetails?.tcs) +
                                    Number(orderdetails?.tt_charges_inclgst) +
                                    Number(
                                      orderdetails?.foreign_bank_rates_inclgst,
                                    ),
                                )?.toFixed(4),
                              ),
                              "INR",
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-full border-[.3px]" />
                      <div className="flex flex-col w-full gap-4">
                        <div className="flex justify-between w-full">
                          <span className="text-sm indent-3.5 text-[#666666]">
                            GST on FX conversion
                          </span>
                          <span className="text-sm font-medium">
                            {formatCurrency(
                              Number(orderdetails?.gst?.toFixed(2) as string),
                              "INR",
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-sm indent-3.5 text-[#666666]">
                            TCS{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          {/* <span className="text-sm font-medium">
                            {formatCurrency(
                              Number(orderdetails?.tcs?.toFixed(2) as string),
                              "INR",
                            )}
                          </span> */}
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium">
                              {orderdetails?.tcs &&
                                `INR ${parseInt(orderdetails?.tcs.toFixed(2))}`}
                            </span>

                            {!checkLevel(LEVEL_2) && (
                              <button
                                onClick={() => setIsTCSEditing(true)}
                                disabled={challanState?.status === true}
                                className="text-gray-500 hover:text-black disabled:opacity-40"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.33594 4.24609H2.5026C2.06058 4.24609 1.63665 4.42169 1.32409 4.73425C1.01153 5.04681 0.835938 5.47073 0.835938 5.91276V13.4128C0.835938 13.8548 1.01153 14.2787 1.32409 14.5913C1.63665 14.9038 2.06058 15.0794 2.5026 15.0794H10.0026C10.4446 15.0794 10.8686 14.9038 11.1811 14.5913C11.4937 14.2787 11.6693 13.8548 11.6693 13.4128V12.5794"
                                    stroke="#165FE3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.8333 2.58472L13.3333 5.08472M14.4875 3.90556C14.8157 3.57735 15.0001 3.13221 15.0001 2.66806C15.0001 2.20391 14.8157 1.75876 14.4875 1.43056C14.1593 1.10235 13.7142 0.917969 13.25 0.917969C12.7858 0.917969 12.3407 1.10235 12.0125 1.43056L5 8.41806V10.9181H7.5L14.4875 3.90556Z"
                                    stroke="#165FE3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-between w-full">
                          <span className="text-sm indent-3.5 text-[#666666]">
                            TT charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          <span className="text-sm font-medium">
                            {formatCurrency(
                              Number(
                                orderdetails?.tt_charges_inclgst?.toFixed(
                                  2,
                                ) as string,
                              ),
                              "INR",
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between w-full">
                          <span className="text-sm indent-3.5 text-[#98BDFF]">
                            Discount <span className="text-xs">%</span>
                          </span>
                        </div>
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-sm indent-3.5 text-[#666666]">
                            Foreign Bank charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          <span className="text-sm font-medium">
                            {orderdetails?.foreign_bank_rates_inclgst?.toFixed(
                              2,
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                      <span className="text-md indent-3.5 font-semibold">
                        Total amount payable
                      </span>
                      <div className="flex gap-1 items-center ">
                        <span className="text-md font-medium">
                          {formatCurrency(
                            parseInt(
                              orderdetails?.payable_amount.toFixed(2) as string,
                            ),
                            "INR",
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <Accordion
                    className="w-full sm:w-full"
                    type="single"
                    collapsible
                  >
                    <AccordionItem value="address" className="sm:w-full">
                      <AccordionTrigger className="disabled:opacity-40 px-4 py-3 rounded-lg bg-gray-300">
                        <div className="flex justify-between items-center w-full">
                          <div className="flex items-center gap-2">
                            <span className="text-base font-medium">
                              View Internal Transaction Breakup
                            </span>
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="w-full pl-6 sm:w-full">
                        {/* <div className="flex flex-col gap-6 py-4">
                          {[
                            {
                              label: "Customer rate",
                              value: `INR ${totalValue}`,
                            },
                            { label: "Partner rate", value: `INR ${Number(orderdetails?.service_partner_rates)}` },
                            {
                              label: "Settlement rate",
                              value: `INR ${Number(orderdetails?.service_partner_rates)}`,
                            },
                          ].map((item, index) => (
                            <div
                              key={index}
                              className="relative flex items-start gap-4"
                            >
                              <div className="relative flex flex-col items-center">
                                <img
                                  className="w-5 h-5"
                                  src="/images/overview/ellipse.svg"
                                  alt="Step"
                                />
                                {index < 2 && (
                                  <div className="w-[2px] h-10 bg-blue-700" />
                                )}
                              </div>
                              <div className="flex flex-col gap-2">
                                <span className="text-sm font-medium leading-[17px]">
                                  {item.label}: {item.value}
                                </span>
                                {index === 0 && (
                                  <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                                    <span className="text-sm text-[#666666] font-medium leading-[17px]">
                                      Partner margin: INR {level === "1" ? margin.l1 : level === "2" ? margin.l2 : margin.l3}
                                    </span>
                                  </div>
                                )}
                                {index === 1 && (
                                  <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                                    <span className="text-sm text-[#666666] font-medium leading-[17px]">
                                      MoneeFlo margin: INR{" "}
                                      {level === "1"
                                        ? margin.l1
                                        : level === "2"
                                          ? margin.l2
                                          : margin.l3}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div> */}
                        <StepIndicator
                          orderdetails={orderdetails}
                          margin={margin}
                          level={level}
                        />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
              </section>
            </TabsContent>
            <TabsContent value="documents">
              <OrderDocuments
                refetchOrderDetails={
                  OrderDetails as unknown as () => Promise<void>
                }
                order_id={id}
                lrs_list={orderdetails?.customer_discloser_id?.map(
                  (doc: any, i: number) => {
                    return {
                      id: doc?.id as string,
                      ref_no: i + 1,
                      doc_url: doc?.supporting_document?.url,
                      amount: doc?.amount,
                      createdAt: doc?.date,
                    };
                  },
                )}
                doc_list={orderdetails?.supporting_documents.map((doc: any) => {
                  return {
                    doc_name: doc?.filename as string,
                    doc_status: doc?.status as string,
                    upload_date: doc?.created_at as string,
                    doc_id: doc?.document_id as string,
                    doc_list_id: doc?.doc_list_id as string,
                    doc_url: doc?.file?.url as string,
                    type: doc?.file?.type as string,
                    // url: doc?.file?.url as string,
                  };
                })}
                doc_status="Review"
              />
            </TabsContent>
            <TabsContent value="payments">
              <section className="bg-white-A700 px-3 pb-5 rounded-xl gap-5 flex flex-col">
                <div className="flex justify-between">
                  <div className="flex gap-5 items-center">
                    <p className="leading-4 font-semibold">Payments</p>
                    <p
                      style={{
                        backgroundColor: order_status_options.find(
                          (status) => orderdetails?.status === status.value,
                        )?.bgColor,
                        color: order_status_options.find(
                          (status) => orderdetails?.status === status.value,
                        )?.textColor,
                      }}
                      className="py-[4px] text-sm leading-4 capitalize font-medium  px-2 text-center rounded-md"
                    >
                      {!(isCaptureBtnStatus === 0 || isCaptureBtnStatus < 0)
                        ? "Pending"
                        : "Received"}
                    </p>
                  </div>
                  {challanState?.status === false ? (
                    <ChallanDialog
                      refechOrderdetails={OrderDetails}
                      order_id={id?.toString() as string}
                      amountDetails={{
                        fxAmount: formatCurrency(
                          orderdetails?.amount as number,
                          "" as string,
                        ),
                        totalCharges: parseFloat(
                          Number(
                            Number(orderdetails?.gst) +
                              Number(orderdetails?.tcs) +
                              Number(orderdetails?.tt_charges_inclgst) +
                              Number(orderdetails?.foreign_bank_rates_inclgst),
                          )?.toFixed(4),
                        ),
                        gstOnFx: Number(
                          orderdetails?.gst?.toFixed(2) as string,
                        ),
                        tcs: `${orderdetails?.tcs ? parseInt(orderdetails.tcs.toFixed(2)) : 0}`,
                        tt_charges: Number(
                          orderdetails?.tt_charges_inclgst?.toFixed(
                            2,
                          ) as string,
                        ),
                        foreign_bank_charges:
                          orderdetails?.foreign_bank_rates_inclgst?.toFixed(2),
                        discount: 0,
                        totalAmountPayable: parseInt(
                          orderdetails?.payable_amount.toFixed(2) as string,
                        ),
                      }}
                      setTcsEditing={setIsTCSEditing}
                      service_partner_banks={servicePartnerBanks}
                    >
                      <button
                        onClick={() => {}}
                        disabled={
                          checkLevel(LEVEL_2)
                            ? true
                            : !orderdetails?.supporting_documents?.every(
                                (doc) => doc.status === "Verified",
                              )
                        }
                        className="rounded-lg disabled:opacity-40 py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
                      >
                        Generate Challan
                      </button>
                    </ChallanDialog>
                  ) : (
                    orderdetails?.status !== "cancelled" &&
                    challanState?.status === true && (
                      <div className="flex items-center gap-x-5">
                        {challanState?.status === true && (
                          <Button
                            onClick={() => {
                              window.open(
                                challanState?.challan_url as string,
                                "_blank",
                              );
                            }}
                            className="rounded-lg py-[11px] flex gap-2 px-4 text-[#165FE3] font-semibold text-sm"
                          >
                            Download Challan
                            <img
                              alt="upload icon"
                              src="/images/remit/uploadIcon.svg"
                              className="rotate-180"
                              style={{ height: "20px", width: "20px" }}
                            />
                          </Button>
                        )}
                        {false ? (
                          <RefundDialog handleSubmit={() => {}}>
                            <button className="rounded-lg py-[11px] disabled:opacity-40 px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                              Initiate Refund
                            </button>
                          </RefundDialog>
                        ) : (
                          <AlertDialog
                            open={dialogOpen}
                            onOpenChange={setDialogOpen}
                          >
                            <AlertDialogTrigger className="">
                              <button
                                disabled={
                                  isCaptureBtnStatus === 0 ||
                                  isCaptureBtnStatus < 0 ||
                                  !orderdetails?.supporting_documents?.every(
                                    (doc) => doc.status === "Verified",
                                  )
                                }
                                className="rounded-lg py-[11px] disabled:opacity-40 px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
                              >
                                Capture Payment
                              </button>
                            </AlertDialogTrigger>
                            <AlertDialogPortal>
                              <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                              <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-md animate-contentShow">
                                <AlertDialogHeader className="flex text-left gap-4 ">
                                  <AlertDialogTitle className="font-bold border-b w-full text-xl pt-8 pb-4 px-10 leading-7 tracking-[2%] text-black-label">
                                    Add Payment Details
                                  </AlertDialogTitle>
                                </AlertDialogHeader>
                                <form className="max-w-md">
                                  <div className="flex flex-col text-left gap-4 px-10">
                                    <div className="flex gap-2 flex-shrink flex-col w-full">
                                      <label
                                        htmlFor="acknowledgement_no"
                                        className=" text-sm font-medium leading-4"
                                      >
                                        Acknowledgement Number
                                      </label>
                                      <input
                                        className="border focus:border-blue-700 p-3 rounded-lg"
                                        id="acknowledgement_no"
                                        type="number"
                                        {...register("acknowledgement_no", {
                                          required:
                                            "Acknowledgement Number is required",
                                        })}
                                        placeholder="Enter acknowledgement number"
                                      />
                                      {errors.acknowledgement_no && (
                                        <p className="text-red-500 text-xs mt-1">
                                          {
                                            errors.acknowledgement_no
                                              .message as string
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="flex gap-2 flex-col w-full">
                                      <label
                                        htmlFor="amount"
                                        className=" text-sm font-medium leading-4"
                                      >
                                        Amount
                                      </label>
                                      <input
                                        id="amount"
                                        type="number"
                                        className="border focus:border-blue-700 p-3 rounded-lg"
                                        {...register("amount", {
                                          required: "Amount is required",
                                          validate: (value) =>
                                            value <= isCaptureBtnStatus ||
                                            `Amount should be less than or equal to ${isCaptureBtnStatus}`,
                                        })}
                                        placeholder="Enter amount"
                                      />
                                      {errors.amount && (
                                        <p className="text-red-500 text-xs mt-1">
                                          {errors.amount.message as string}
                                        </p>
                                      )}
                                    </div>
                                    <div className="flex gap-2 flex-1 flex-grow flex-col w-full">
                                      <h3 className="text-sm font-medium leading-4">
                                        Payment Proof Document
                                      </h3>
                                      <div
                                        className={`py-1.5 px-3.5 justify-between rounded-md flex border border-[#DCDEE2] ${paymnetFile?.name ? "bg-[#F2F7FF]" : "bg-[#fff]"}`}
                                      >
                                        <div className="flex gap-3 items-center overflow-hidden">
                                          <Img
                                            src="/images/remit/file.svg"
                                            alt=""
                                          />
                                          <p className="text-ellipsis truncate">
                                            {paymnetFile?.name ||
                                              "No file selected"}
                                          </p>
                                        </div>
                                        <label
                                          htmlFor="payment-proof-document"
                                          className="cursor-pointer text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                                        >
                                          {paymnetFile ? "Re-upload" : "Upload"}
                                        </label>
                                        <input
                                          {...register(
                                            "payment_proof_document",
                                            {
                                              required:
                                                "Payment proof document is required",
                                              onChange:
                                                handlePaymentProofChange,
                                            },
                                          )}
                                          type="file"
                                          id="payment-proof-document"
                                          className="hidden"
                                          accept="image/*"
                                        />
                                      </div>
                                      {errors["payment_proof_document"] && (
                                        <p className="text-red-500 text-xs mt-1">
                                          {
                                            errors["payment_proof_document"]
                                              .message as string
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="flex gap-2 flex-1 flex-grow flex-col w-full">
                                      <h3 className=" text-sm font-medium leading-4">
                                        Supporting document (optional)
                                      </h3>
                                      <div
                                        className={`py-1.5 px-3.5 justify-between rounded-md flex border border-[#DCDEE2] ${selectedFile?.name ? "bg-[#F2F7FF]" : "bg-[#fff]"}`}
                                      >
                                        <div className="flex gap-3 items-center overflow-hidden">
                                          <Img
                                            src="/images/remit/file.svg"
                                            alt=""
                                          />
                                          <p className=" text-ellipsis truncate">
                                            {selectedFile?.name ||
                                              "No file selected"}
                                          </p>
                                        </div>
                                        <label
                                          htmlFor="supporting-document"
                                          className=" cursor-pointer text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                                        >
                                          {selectedFile
                                            ? "Re-upload"
                                            : "Upload"}
                                        </label>
                                        <input
                                          {...register("supporting-document", {
                                            onChange:
                                              handleSupportingFileChange,
                                          })}
                                          type="file"
                                          id="supporting-document"
                                          className="hidden"
                                          accept="image/*"
                                        />
                                      </div>
                                      {errors["supporting-document"] && (
                                        <p className="text-red-500 text-xs mt-1">
                                          {
                                            errors["supporting-document"]
                                              .message as string
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="text-[14px] font-400 text-[#666666] py-3">
                                      Eg: In case of name mismatch.
                                    </div>
                                  </div>
                                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                                    <AlertDialogCancel
                                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                                      asChild
                                    >
                                      <button
                                        onClick={() => {
                                          reset();
                                          setSelectedFile(null);
                                        }}
                                      >
                                        Close
                                      </button>
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                      type="submit"
                                      onClick={handleSubmit((data) =>
                                        onSaveClick(data, "approve"),
                                      )}
                                      className="rounded-lg border-blue-700 py-[11px] px-4 border text-blue-700"
                                    >
                                      Submit and approve
                                    </AlertDialogAction>
                                    <AlertDialogAction
                                      type="submit"
                                      onClick={handleSubmit((data) =>
                                        onSaveClick(data, ""),
                                      )}
                                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                                    >
                                      Capture
                                    </AlertDialogAction>
                                  </AlertDialogFooter>
                                </form>
                              </AlertDialogContent>
                            </AlertDialogPortal>
                          </AlertDialog>
                        )}
                      </div>
                    )
                  )}
                </div>
                <div className="flex flex-col gap-4">
                  {/* Total Payable Row */}
                  <div className="flex justify-between items-center">
                    <span className="text-gray-500 text-lg">Total Payable</span>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-500 text-sm">INR</span>
                      <span className="text-gray-800 text-lg">
                        {parseInt(orderdetails?.payable_amount?.toString())}
                      </span>
                    </div>
                  </div>

                  {/* Total Paid Row */}
                  <div className="flex justify-between items-center">
                    <span className="text-gray-500 text-lg">Total Paid</span>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-500 text-sm">INR</span>
                      <span className="text-gray-800 text-lg">
                        {parseInt(
                          orderdetails?._payments_of_order.reduce(
                            (acc, item) => {
                              if (item?.status_by_AD2 === "reject") return 0;
                              return acc + item?.amount;
                            },
                            0,
                          ),
                        )}
                      </span>
                    </div>
                  </div>

                  {/* Balance Row */}
                  <div className="flex justify-between items-center bg-red-50 p-4 rounded">
                    <span className="text-red-500 text-lg">Balance</span>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-500 text-sm">INR</span>
                      <span className="text-gray-800 text-lg">
                        {parseInt(isCaptureBtnStatus)}
                      </span>
                    </div>
                  </div>
                </div>
                <DataTable
                  isToolbarVisible={false}
                  isPaginationVisible={false}
                  columnsBackground="#333333"
                  data={paymentData}
                  columns={columns}
                  // highlightRow={!!rowSelected}
                />
              </section>
            </TabsContent>
            <TabsContent value="other-details">
              <div className="grid grid-cols-2 gap-8 w-full">
                <div className="w-full flex flex-col flex-grow gap-2">
                  <h5 className="text-[#999999] leading-4 font-medium text-base">
                    Remitter Details
                  </h5>
                  <div className="rounded-lg bg-[#FBFBFB] flex flex-col justify-between w-full p-2">
                    <div className="flex flex-row gap-2 items-center">
                      <h1>{orderdetails?._order_customers?.full_name}</h1>
                    </div>
                    <div className="flex text-gray-400 items-center gap-2">
                      <h1>Email:</h1>
                      <span className={valueStyle} style={{ color: "gray" }}>
                        {orderdetails?._order_customers?.email}
                      </span>
                    </div>
                    <div className="flex text-gray-400 items-center gap-2">
                      <h1>Mobile:</h1>
                      <span className={valueStyle} style={{ color: "gray" }}>
                        {orderdetails?._order_customers?.phone_number
                          ? orderdetails?._order_customers?.phone_number
                          : " N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                {studentName?.value && (
                  <div className="w-full flex flex-col flex-grow gap-2">
                    <h5 className="text-[#999999] leading-4 font-medium text-base">
                      Student details
                    </h5>
                    <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                      <div className="rounded-lg bg-[#FBFBFB] flex flex-col justify-between w-full">
                        <div className="flex text-gray-400 item-center gap-2">
                          <h1 className="font-medium text-base leading-[25.6px] text-black-label">
                            {studentName?.value || "N/A"}
                          </h1>
                        </div>
                        <div className="flex text-gray-400 item-center gap-2">
                          <h1>Passport:</h1>
                          <span
                            className={valueStyle}
                            style={{ color: "gray" }}
                          >
                            {orderdetails?._order_service_partner.passport_no ||
                              "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col flex-grow gap-5 my-8">
                <h5 className="text-[#999999] leading-4 font-medium text-base">
                  Beneficiary details
                </h5>
                <div className="flex gap-4 p-5 flex-col rounded-xl bg-[#FBFBFB]">
                  <div className="rounded-lg  flex flex-col justify-between w-full gap-3">
                    <div className="flex flex-row gap-2 items-center">
                      <h1 className="font-medium text-base leading-[25.6px] text-black-label">
                        {orderdetails?._beneficiary?.name || "N/A"}
                      </h1>
                    </div>
                    <div className="flex text-gray-400 item-center gap-4">
                      <h1>Bank name:</h1>
                      <span className={valueStyle}>
                        {orderdetails?._beneficiary?.bank_name}
                      </span>
                    </div>
                    <div className="flex text-gray-400 item-center gap-4">
                      <h1>Account number / IBAN:</h1>
                      {(orderdetails?._beneficiary?.account_number ||
                        orderdetails?._beneficiary?.iban_no) && (
                        <span className={valueStyle}>
                          {orderdetails?._beneficiary?.account_number ||
                            orderdetails?._beneficiary?.iban_no}
                        </span>
                      )}
                    </div>
                    <div className="flex text-gray-400 item-center gap-4">
                      <h1>SWIFT Code:</h1>
                      <span className={valueStyle}>
                        {orderdetails?._beneficiary?.swift_code || "N/A"}
                      </span>
                    </div>
                    <div className="flex text-gray-400 item-center gap-4">
                      <h1>Reference number:</h1>
                      <span className={valueStyle}>
                        {orderdetails?.reference_no || "N/A"}
                      </span>
                    </div>
                    {/* <div className="flex text-gray-400 item-center gap-4">
                      <h1>Correspondent bank name :</h1>
                      <span className={valueStyle}>
                        {orderdetails?._beneficiary?.correspondant_bank_name ||
                          "N/A"}
                      </span>
                    </div>
                    <div className="flex text-gray-400 item-center gap-4">
                      <h1>Correspondent bank number :</h1>
                      <span className={valueStyle}>
                        {orderdetails?._beneficiary?.correspondant_bank_no ||
                          "N/A"}
                      </span>
                    </div> */}
                    <div className="flex text-gray-400 item-center gap-4">
                      <h1>Bank Address:</h1>
                      <span className={valueStyle}>
                        {orderdetails?._beneficiary?.bank_addr || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* partner details */}
              {channelDetails?.id === 12 && !checkLevel(LEVEL_2) && (
                <div className="w-full flex flex-col flex-grow gap-5 my-8">
                  <h5 className="text-[#999999] leading-4 font-medium text-base">
                    Partner Details
                  </h5>
                  <div className="flex gap-5 p-5 bg-[#FBFBFB] flex-col rounded-xl border border-white-A700">
                    <div className="rounded-lg flex flex-col justify-between w-full gap-3">
                      <div className="flex flex-row gap-2 items-center">
                        <span
                          className="bg-[#EFF4FF] text-black-200 rounded-lg p-2"
                          style={{ fontWeight: "400" }}
                        >
                          {orderdetails?.partner_detail?.type.toUpperCase()}
                        </span>
                        <h1 className="font-medium text-base leading-[25.6px] text-black-label">
                          {orderdetails?.partner_detail?.name || "N/A"}
                        </h1>
                      </div>
                      <div className="flex gap-2">
                        <p className={labelStyle}>
                          Partner Trade:{" "}
                          <span className={valueStyle}>
                            {orderdetails?.partner_detail?.type || "N/A"}
                          </span>
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <p className={labelStyle}>
                          Branch Location:{" "}
                          <span className={valueStyle}>
                            {orderdetails?.partner_detail?.city || "N/A"},{" "}
                            {orderdetails?.partner_detail?.state || "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Service provider details */}
              {!checkLevel(LEVEL_2) && (
                <div className="w-full flex flex-col flex-grow gap-5 my-8">
                  <h5 className="text-[#999999] leading-4 font-medium text-base">
                    Service provider details
                  </h5>
                  <div className="flex gap-5 p-5 bg-[#FBFBFB] flex-col rounded-xl border border-white-A700">
                    <div className="rounded-lg flex flex-col justify-between w-full gap-3">
                      <div className="flex flex-row gap-2 items-center">
                        <h1 className="font-medium text-base leading-[25.6px] text-black-label">
                          {orderdetails?._order_service_partner?.display_name}
                        </h1>
                      </div>
                      <div className="flex gap-2">
                        <p className={labelStyle}>
                          Email:{" "}
                          <span className={valueStyle}>
                            {orderdetails?._order_service_partner?.email}
                          </span>
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <p className={labelStyle}>
                          Service ID:{" "}
                          <span className={valueStyle}>
                            {orderdetails?._order_service_partner?.id}
                          </span>
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <p className={labelStyle}>
                          Location:{" "}
                          <span className={valueStyle}>
                            {orderdetails?._order_service_partner
                              ?.legal_addr_city &&
                            orderdetails?._order_service_partner
                              ?.legal_addr_state
                              ? `${orderdetails?._order_service_partner?.legal_addr_city}, ${orderdetails?._order_service_partner?.legal_addr_state}`
                              : orderdetails?._order_service_partner
                                  ?.legal_addr_city ||
                                orderdetails?._order_service_partner
                                  ?.legal_addr_state ||
                                "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="w-full flex flex-col flex-grow gap-2"> */}
              {/* <h5>Partner Details</h5> */}
              {/* <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]"> */}
              {/* <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Name: <span className={valueStyle}></span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Parner ID: <span className={valueStyle}></span>
                      </p>
                    </div>
                  </div> */}

              <div className="rounded-lg p-2 bg-[#FFEAC9] w-[450px]">
                <h1 className="text-[#000]">
                  This order has been created from Lead ID{" "}
                  <a
                    // href={`/remit/leads?dealid=${orderdetails?.opportunity_id}`}
                    onClick={handleLeadRedirect}
                    className="text-blue-600 underline cursor-pointer"
                  >
                    ID - {orderdetails?.opportunity_id}
                  </a>
                </h1>
              </div>
              {/* <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}></p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}></p>
                    </div>
                  </div> */}
              {/* </div> */}
              {/* </div> */}
            </TabsContent>
          </Tabs>
        </div>
        <section className=" rounded-xl bg-white-A700 border p-3 w-[20%] h-full">
          <MilestoneTracker milestones={orderdetails?.order_mileston || []} />
        </section>
      </div>
      {/* <div className="">
        <AlertDialog open={isOpen}>
          <AlertDialogTrigger onClick={() => setIsopen(false)}>
            <div className=" cursor-pointer ">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1689_36824)">
                  <path
                    d="M3.83594 4.82812H3.0026C2.56058 4.82813 2.13665 5.00372 1.82409 5.31628C1.51153 5.62884 1.33594 6.05276 1.33594 6.49479V13.9948C1.33594 14.4368 1.51153 14.8607 1.82409 15.1733C2.13665 15.4859 2.56058 15.6615 3.0026 15.6615H10.5026C10.9446 15.6615 11.3686 15.4859 11.6811 15.1733C11.9937 14.8607 12.1693 14.4368 12.1693 13.9948V13.1615"
                    stroke="#165FE3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.3333 3.16676L13.8333 5.66676M14.9875 4.48759C15.3157 4.15938 15.5001 3.71424 15.5001 3.25009C15.5001 2.78594 15.3157 2.34079 14.9875 2.01259C14.6593 1.68438 14.2142 1.5 13.75 1.5C13.2858 1.5 12.8407 1.68438 12.5125 2.01259L5.5 9.00009V11.5001H8L14.9875 4.48759Z"
                    stroke="#165FE3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1689_36824">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </AlertDialogTrigger>
          <AlertDialogPortal>
            <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
            <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl w-full animate-contentShow sm:max-w-[90%]">
              <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                  Review Documents
                </AlertDialogTitle>
              </AlertDialogHeader>
              <div className="h-[500px] overflow-auto w-full">
                <PaymentReviewModal
                  setIsopen={setIsopen}
                  paymentData={paymentData}
                />
              </div>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div> */}
      <Toaster position="top-right" />
    </>
  );
};

export default NewOrderDetailsPage;

const TCSWarning = ({
  children,
  handleSubmit,
  order_id,
  tcsValue,
  tcs_amount,
}: {
  children: React.ReactNode;
  handleSubmit: (order_id: number, notes: string, tcs_amount: number) => void;
  order_id: string;
  tcsValue: string;
  tcs_amount: string;
}) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
        <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
          <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
            <AlertDialogTitle className="font-bold text-xl text-red-500 leading-7 tracking-[2%]">
              Warning
            </AlertDialogTitle>
            <AlertDialogDescription className="font-medium text-base -tracking-[3%] text-black-900 leading-[150%]  ">
              TCS of Rs {tcs_amount} was calculated by the system which is
              greater than {tcsValue}
              <p className="pt-6">Do you still want to continue?</p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
            <AlertDialogCancel className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
              onClick={() => {
                handleSubmit(
                  parseInt(order_id),
                  "TCS amount is greater than AD updated amount",
                  parseInt(tcsValue),
                );
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};
