import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Img } from "components/Img";
import Navbar from "components/Navbar/Index";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotificationPopover from "./Notification";

export interface DashboardNavbarProps {
  navLinks: {
    current: {
      label: string;
      icon: string;
    };
    left: ILinks[];
    right: ILinks[];
  };
  children?: React.ReactNode;
  routename?: string;
}

export interface ILinks {
  label: string;
  href: string;
  icon: string;
  logo?: string;
  visible?: boolean;
  submenu?: ILinks[];
  enabled?: boolean;
}

const DashboardNavbar = ({
  navLinks,
  children,
  routename = "",
}: DashboardNavbarProps) => {
  const [activeLeftLink, setActiveLeftLink] = useState<string | null>(
    navLinks.current.label,
  );

  const name = localStorage.getItem("name");
  const handleLeftLinkClick = (label: string) => {
    setActiveLeftLink(label);
  };
  return (
    <Navbar>
      <div className="flex gap-5 h-11">
        <h4 className="flex items-center text-lg font-semibold">
          {routename || `Hi ${name}`}
        </h4>
        <ul className="flex gap-2">
          <li className="flex bg-navbar-menu-bg rounded-lg justify-center items-center">
            {children}
          </li>
        </ul>
      </div>
      <ul className="flex gap-3">
        {navLinks["right"].map((linkData: ILinks, index: number) => {
          return linkData?.submenu?.length ? (
            <li
              key={index}
              aria-hidden
              className={`flex select-none h-11 justify-center items-center rounded-lg  ${linkData.label && linkData.label !== "plus" && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${linkData.label && linkData.label !== "plus" && activeLeftLink === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
              onClick={() => handleLeftLinkClick(linkData.label)}
            >
              <Popover>
                <PopoverTrigger asChild>
                  <div className="flex justify-center items-center gap-2.5 py-3 px-4 ">
                    <Img
                      className="max-w-none"
                      style={{}}
                      src={linkData.icon}
                      alt={linkData.label}
                    />
                    {linkData?.label && linkData.visible && (
                      <span>{linkData.label}</span>
                    )}
                  </div>
                </PopoverTrigger>
                <PopoverContent
                  align="end"
                  side="bottom"
                  sideOffset={12}
                  className=" gap-0 w-auto p-0 will-change-[transform,opacity] animate-slideDownAndFade rounded-lg"
                >
                  <div
                    style={{
                      boxShadow: "0px 4px 4px 0px rgba(112, 112, 112, 0.2)",
                    }}
                    className=" rounded-xl w-60 bg-white-A700 flex flex-col"
                  >
                    {linkData.submenu.map((submenu) => {
                      return (
                        <Link
                          key={crypto.randomUUID()}
                          to={submenu?.href || ""}
                          className="py-3.5 hover:bg-[#E8ECF9] font-semibold text-sm text-center items-center rounded-lg gap-3 flex px-5"
                        >
                          <Img
                            className="max-w-none"
                            src={submenu.icon}
                            alt=""
                          />
                          {submenu.label}
                        </Link>
                      );
                    })}
                  </div>
                </PopoverContent>
              </Popover>
            </li>
          ) : linkData.label === "Notification" ? (
            <NotificationPopover>
              <div
                className={`flex h-11 justify-center items-center gap-2.5 px-4 rounded-lg  ${linkData.label && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${(linkData.label && activeLeftLink) === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
                onClick={() => handleLeftLinkClick(linkData.label)}
              >
                <Img
                  className="max-w-none"
                  style={{}}
                  src={linkData.icon}
                  alt={linkData.label}
                />
                {linkData?.label && linkData.visible && (
                  <span>{linkData.label}</span>
                )}
              </div>
            </NotificationPopover>
          ) : (
            <li
              key={index}
              aria-hidden
              className={`flex h-11 justify-center items-center gap-2.5 px-4 rounded-lg  ${linkData.label && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${(linkData.label && activeLeftLink) === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
              onClick={() => handleLeftLinkClick(linkData.label)}
            >
              <Img
                className="max-w-none"
                src={linkData.icon}
                alt={linkData.label}
              />
              {linkData?.label && linkData.visible && (
                <span>{linkData.label}</span>
              )}
            </li>
          );
        })}
      </ul>
    </Navbar>
  );
};

export default DashboardNavbar;
