import React, { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Option } from "components/Form/form";
import {
  setProduct,
  setProductPurpose,
  setQuoteProduct,
  setQuotePurposeProduct,
} from "redux/features/CreateOpportunity";
import { toast } from "sonner";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

const productPurposeOptions: Record<string, Option[]> = {
  remittance: remitProductPurposeOptions,
};

interface Step1Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setContactDetails?: React.Dispatch<React.SetStateAction<any>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
}

export const L2Step1: React.FC<Step1Props> = ({
  setCurrentStep,
  setProcessState,
}) => {
  const { product_purpose, quoteProduct } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const dispatch = useAppDispatch();

  const currentProductOptions = useMemo(() => {
    return (
      productPurposeOptions[
        quoteProduct as keyof typeof productPurposeOptions
      ] || []
    );
  }, [quoteProduct]);

  useEffect(() => {
    if (!quoteProduct) {
      dispatch(setQuoteProduct("remittance"));
      dispatch(setProduct("remittance"));
    }
  }, [quoteProduct, dispatch]);

  const handleProductChoose = useCallback(
    (selectedValue: string) => {
      const selectedLabel = currentProductOptions.find(
        (tab: any) => tab.value === selectedValue,
      )?.label;
      if (selectedLabel) {
        dispatch(setProductPurpose(selectedValue));
        dispatch(setQuotePurposeProduct(selectedLabel));
        if (quoteProduct === "remittance") {
          if (selectedLabel === "GIC") {
            setCurrentStep(3);
          } else if (
            selectedLabel === "Study Maintenance" ||
            selectedLabel === "Gift / Family Maintenance"
          ) {
            setCurrentStep(7);
          } else {
            setCurrentStep((prev) => prev + 1);
          }
        }
        setProcessState((prevState: any) => ({
          ...prevState,
          Product: selectedLabel,
        }));
      } else {
        toast.error("No matching label found for the selected value");
      }
    },
    [dispatch, setCurrentStep, currentProductOptions],
  );

  return (
    <div className="flex flex-col gap-6 max-w-md">
      <form className="max-w-md">
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <label className={`text-[18px] font-medium text-[#333]`}>
              Select any one to proceed.
            </label>
            <RadioGroup
              className="flex w-full flex-col gap-4"
              value={product_purpose}
              onValueChange={handleProductChoose}
            >
              {currentProductOptions?.map(
                (tab: { label: string; value: string }) => (
                  <label
                    key={tab?.value}
                    htmlFor={tab?.value}
                    className={`${
                      product_purpose === tab?.value
                        ? "border-blue-700"
                        : "border-black-label"
                    } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                  >
                    <RadioGroupItem
                      value={tab?.value}
                      id={tab?.value}
                      className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                    />
                    <p className="text-[14px] font-medium w-full">
                      {tab?.label}
                    </p>
                  </label>
                ),
              )}
            </RadioGroup>
          </div>
        </div>
      </form>
    </div>
  );
};
