import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useCallback, useEffect } from "react";
import { Option } from "components/Form/form";
import { useAppDispatch } from "redux/hooks";
import {
  setProductLoanType,
  setProductMode,
  setProductPurpose,
} from "redux/features/CreateOpportunity";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setIsProductLoan: React.Dispatch<React.SetStateAction<string>>;
  isProductLoan: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  handlePrevious: () => void;
  processState: any;
}

const remittenceProductLoantakenOptions: Option[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
export const L2Step3: React.FC<Step2Props> = ({
  setCurrentStep,
  setPageTitle,
  setIsProductLoan,
  setProcessState,
  isProductLoan,
  handlePrevious,
  processState,
}) => {
  const dispatch = useAppDispatch();
  const handleProductLoan = useCallback(
    (e: string) => {
      setIsProductLoan(e);
      setProcessState((prevState: any) => ({
        ...prevState,
        "Education Loan": e,
      }));
      dispatch(setProductLoanType(e === "true" ? true : false));
      setCurrentStep((prev) => prev + 1);
    },
    [setIsProductLoan],
  );
  useEffect(() => {}, [setPageTitle]);

  return (
    <form className="w-full">
      <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
        <div>
          <label className="text-[14px] font-medium text-[#000]">
            Have you taken an education loan?
          </label>
          <RadioGroup
            className="flex w-full flex-row gap-4 mt-3"
            value={isProductLoan}
            onValueChange={handleProductLoan}
          >
            {remittenceProductLoantakenOptions?.map((tab) => (
              <label
                key={tab.value}
                htmlFor={tab.value}
                className={`${
                  isProductLoan === tab.value
                    ? "focus-within:border-blue-700 border-black-label"
                    : ""
                } flex w-[250px] items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
              >
                <RadioGroupItem
                  id={tab.value}
                  value={tab.value}
                  className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <p className="text-[14px] font-medium w-full">{tab.label}</p>
              </label>
            ))}
          </RadioGroup>
        </div>
        <button
          className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
          onClick={() => {
            if (processState?.Product === "GIC") {
              setCurrentStep(1);
              dispatch(setProductPurpose(""));
              setProcessState((prevState: any) => ({
                ...prevState,
                Product: "",
              }));
            } else {
              handlePrevious();
            }
            setIsProductLoan("");
            dispatch(setProductMode(""));
            setProcessState((prevState: any) => ({
              ...prevState,
              "Education Loan": "",
              Mode: "",
            }));
            setPageTitle("Select Mode");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </button>
      </div>
    </form>
  );
};
