import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { AxiosRequestConfig } from "axios";
import { LEVEL_2 } from "common/constants";
import { deal_status_options } from "common/filters";
import { checkLevel } from "common/rules";
import { Img, Spinner, Text } from "components";
import { selectOptionType } from "components/SelectBox";
import { ArrowUpRight } from "lucide-react";
import Cancel from "models/Cancel";
import CancellationCharges from "models/DealDetails/CancellationCharges";
import SetServicePartner from "models/DealDetails/SetServicePartner";
import SettlementRate from "models/DealDetails/SettlementRate";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setDealID } from "redux/features/CreateOrder";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getExtraFields,
  GetExtraFieldsRequestType,
  getServicePartners,
  GetServicePartnersRequestType,
} from "service/apis/CreateDealapi";
import {
  CancelDealRequestType,
  GetDealDetailsRequestType,
  PutServicePartnerRequestType,
  cancelDeal,
  cancelDealCharges,
  getDealDetails,
  putServicePartner,
} from "service/apis/DealDetails";
import { Toaster, toast } from "sonner";
import { formatIndianNumber } from "utils";
import SetCancellationChargesModal from "./LinkLeadModal";
import FieldRenderer, { IFieldProps } from "pages/CreateDeal/fieldRenderer";

type DealDetailsType = {
  purpose_id: string;
  id: string;
  created_at: string;
  currency: string;
  country: string;
  amount: string | number;
  customer_name: string;
  customer_email: string;
  customer_pan: string;
  customer_phone: string;
  utilization_status: string;
  service_partner_id: any;
  status: string;
  service_partner_rates: string | number;
  l1_commission_rates: string | number;
  l2_commission_rates: string | number;
  l3_commission_rates: string | number;
  service_partner_cancellation_charge: string | number;
  channel_partner_cancellaton_charge: string | number;
  _channels: {
    id: string;
    name: string;
    phone: string;
    email: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    pincode: string;
  };
  _purpose: {
    id: string;
    name: string;
  };
  _service_partner: {
    id: string;
    display_name: string;
    email: string;
    legal_addr_city: string;
    legal_addr_state: string;
  };
};

const NewDealDetailsPage: FC = () => {
  const [dealDetails, setDealDetails] = useState<DealDetailsType>();
  const [status, setStatus] = useState<string>("");
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [showSettlementRate, setShowSettlementRate] = useState(false);
  const [showServicePartner, setShowServicePartner] = useState(false);
  const [showCancellationCharges, setShowCancellationCharges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createdate, setCreateDate] = useState<any>();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading1, setLoading1] = useState(false);
  const [totalCharges, setTotalCharges] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [servicePartners, setServicePartners] = useState<selectOptionType[]>(
    [],
  );
  const [extraFields, setExtraFields] = useState<IFieldProps[]>([]);
  const [fileBinaries, setFileBinaries] = useState<{ [key: string]: string }>(
    {},
  );
  const [extraFieldValues, setExtraFieldValues] = useState<{
    [key: string]: string;
  }>({});
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [isSetCancellationCharges, setIsSetCancellationCharges] =
    useState<boolean>(false);
  const [form, setForm] = useState<any>({
    service_partner_id: dealDetails?.service_partner_id,
  });

  useEffect(() => {
    setForm((prevForm: any) => ({
      ...prevForm,
      service_partner_id: dealDetails?.service_partner_id,
    }));
  }, [dealDetails?.service_partner_id]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const { is_deal_flow_Check } = useAppSelector((state) => state.CreateOrder);

  const navigate = useNavigate();

  const updateTotalCharges = () => {
    const servicePartnerCharges =
      parseFloat(getValues("servicePartnerCharges")) || 0;
    const channelPartnerCharges =
      parseFloat(getValues("channelPartnerCharges")) || 0;
    setTotalCharges(servicePartnerCharges + channelPartnerCharges);
  };

  const onSubmit = (data: any) => {
    setLoading1(true);

    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);

    const payload = {
      data: {
        service_partner_cancellation_charges: data.servicePartnerCharges,
        channel_partner_cancellation_charges: data.channelPartnerCharges,
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    cancelDealCharges(payload)
      .then(() => {
        toast.success("Cancellation Charges updated successfully");
        setLoading1(false);
        DealDetails();
      })
      .catch((err) => {
        setLoading1(false);
        toast.error(err?.response?.data?.message || "An error occurred");
      });
  };

  useEffect(() => {
    if (dealDetails) {
      const date = new Date(dealDetails?.created_at);
      setCreateDate(date.toUTCString().substring(0, 16));
    }
  }, [dealDetails]);

  const level = localStorage.getItem("level");
  // const isEmployee = localStorage.getItem("isEmployee") == "false";
  const isOwner = localStorage.getItem("is_owner");
  const channel_id = localStorage.getItem("channel_id");

  const DealDetails = (): void => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetDealDetailsRequestType> = {
      data: {
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getDealDetails(payload)
      .then((res: any) => {
        setDealDetails(res?.data);
        setStatus(res.data.status);
      })
      .catch();
  };

  useEffect(() => {
    DealDetails();
  }, [id]);

  const cancelDealFunction = (): void => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<CancelDealRequestType> = {
      data: {
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);

    cancelDeal(payload)
      .then(() => {
        toast.success("Deal Status Updated Successfully");
        setLoading(false);
        DealDetails();
        // window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const createOrder = (): void | null => {
    dispatch(setDealID(dealDetails?.id as string));
    navigate(`/createOrder`);
  };

  const fetchServicePartners = (): void => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetServicePartnersRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: parseInt(dealDetails?.purpose_id as string),
        currency: dealDetails?.currency,
      },
    };

    getServicePartners(payload)
      .then((res) => {
        if (res.data.length > 0) {
          setServicePartners(
            res?.data.map((item) => {
              return {
                label: item?.display_name,
                value: item?.id,
              };
            }),
          );
        }

        if (res.data.length < 1) {
          toast.warning("Service Partners not available");
        }
      })
      .catch((err) => {
        toast.warning(err.response.data.message);
      });
  };
  const labelStyle = "font-medium text-[#666666] text-sm leading-[21px]";
  const valueStyle = "font-medium text-[#333333] text-sm leading-[21px]";

  const handleLeadRedirect = (event: any) => {
    event.preventDefault();
    navigate(`/remit/leads`, {
      state: {
        LeadID: dealDetails?.leadDetail?.id,
        leadModalState: true,
      },
    });
  };

  // call service provider API
  const handleReAssignServiceProvider = (event: any) => {
    event.preventDefault();
    if (dealDetails?.purpose_id && dealDetails?.currency) {
      fetchServicePartners();
    }
    setIsDropdownOpen(true);
  };

  const handleonSettlement = (): void | null => {
    setLoading(true);
    if (form.service_partner_id === "") {
      setLoading(false);
      return;
    }

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutServicePartnerRequestType> = {
      data: {
        service_partner_id: form.service_partner_id,
        deal_id: id,
        documents: Object.entries(fileBinaries).map(([key, value]) => ({
          label: key,
          doc: value,
        })),
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    putServicePartner(payload)
      .then(() => {
        toast.success("Service Provider Updated Successfully");
        setIsDropdownOpen(false);
        setLoading(false);
        DealDetails();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const fetchFields = async (service_partner_id: string) => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetExtraFieldsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: dealDetails?.purpose_id,
        service_partner_id: service_partner_id,
      },
    };

    try {
      const res = await getExtraFields(payload);
      if (res.status === 200) {
        setExtraFields(res.data);
      }
    } catch (error) {}
  };

  const extraFieldsValidation = () => {
    if (extraFields.length > 0) {
      for (const field of extraFields) {
        switch (field.type) {
          case "file":
            if (!files[field.name]) {
              return true;
            }
            break;
          case "text":
            if (!extraFieldValues[field.name]) {
              return true;
            }
            break;
          case "number":
            if (!extraFieldValues[field.name]) {
              return true;
            }
            break;
          case "date":
            if (!extraFieldValues[field.name]) {
              return true;
            }
            break;
          default:
            break;
        }
      }
    }
    return false;
  };

  return (
    <>
      {(loading || loading1) && <Spinner />}
      {
        <div>
          {showSettlementRate && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <SettlementRate
                setShowSettlementRate={setShowSettlementRate}
                id={id}
                dealDetails={dealDetails}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {
        <div>
          {showServicePartner && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
            >
              <SetServicePartner
                setShowPopup={setShowServicePartner}
                id={id}
                dealDetails={dealDetails}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {
        <div>
          {showCancellationCharges && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <CancellationCharges
                setShowCancellationCharges={setShowCancellationCharges}
                id={id}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title={`Do you really want to cancel this deal? Please note that cancellation charges may apply`}
            setConfirmCancel={setConfirmCancel}
            handleCancel={cancelDealFunction}
          />{" "}
        </div>
      )}

      <div className="flex w-full flex-col gap-5 py-5 px-6 h-full">
        <div className=" flex w-full justify-between items-center border-b-2 pb-5">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Deals Details
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              Comprehensive details of all deals
            </p>
          </div>
          <div className="flex items-center gap-5">
            {/* {dealDetails?.status === "unconfirmed" && level === "1" && (
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                Set Settlement Charges
              </button>
            )} */}
            {/* {dealDetails?.status === "unconfirmed" && level === "1" && (
              <button
                onClick={() => setShowServicePartner(true)}
                className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
              >
                Set Service Partner
              </button>
            )} */}

            {!checkLevel(LEVEL_2) && (
              <>
                {!dealDetails?.leadDetail &&
                  dealDetails?.status !== "cancelled" && (
                    <button
                      className="rounded-lg py-[11px] px-8 border border-[#165FE3] bg-white-700 text-blue-700 font-semibold text-sm"
                      onClick={() => setIsSetCancellationCharges(true)}
                    >
                      Link Lead
                    </button>
                  )}
              </>
            )}

            {dealDetails?.status === "confirmed" && (
              <button
                className="rounded-lg py-[11px] px-8 border font-semibold text-sm transition-colors bg-blue-700 text-[#fff] hover:bg-blue-800 cursor-pointer"
                onClick={() => {
                  dispatch(setDealID(dealDetails?.id));
                  navigate("/createorder", {
                    state: {
                      leadID: dealDetails?.leadDetail?.id || "",
                      dealID: dealDetails?.id,
                      pagePath: "dealDetails",
                    },
                  });
                }}
              >
                Create Order
              </button>
            )}

            {dealDetails?.status === "hold" && level === "1" && isOwner && (
              <AlertDialog>
                <AlertDialogTrigger>
                  <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                    Set Cancellation Charges
                  </button>
                </AlertDialogTrigger>
                <AlertDialogPortal>
                  <AlertDialogOverlay className="bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                  <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-4xl animate-contentShow">
                    <div className=" flex items-center bg-[#EFF4FF] py-5 px-10 rounded-t-lg">
                      <div className="flex gap-5 items-center">
                        <span className="text-sm text-[#374151]">Remit</span>
                        <svg
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292787 10.2006C0.105316 10.013 0 9.75874 0 9.49357C0 9.22841 0.105316 8.9741 0.292787 8.78657L3.58579 5.49357L0.292787 2.20057C0.110629 2.01197 0.00983372 1.75937 0.0121121 1.49717C0.0143906 1.23497 0.11956 0.984161 0.304968 0.798753C0.490376 0.613345 0.741189 0.508176 1.00339 0.505897C1.26558 0.503619 1.51818 0.604414 1.70679 0.786572L5.70679 4.78657C5.89426 4.9741 5.99957 5.22841 5.99957 5.49357C5.99957 5.75874 5.89426 6.01304 5.70679 6.20057L1.70679 10.2006C1.51926 10.388 1.26495 10.4934 0.999786 10.4934C0.734622 10.4934 0.480314 10.388 0.292787 10.2006Z"
                            fill="#374151"
                          />
                        </svg>
                        <span className="text-sm text-[#374151]">
                          New Remit
                        </span>
                        <svg
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292787 10.2006C0.105316 10.013 0 9.75874 0 9.49357C0 9.22841 0.105316 8.9741 0.292787 8.78657L3.58579 5.49357L0.292787 2.20057C0.110629 2.01197 0.00983372 1.75937 0.0121121 1.49717C0.0143906 1.23497 0.11956 0.984161 0.304968 0.798753C0.490376 0.613345 0.741189 0.508176 1.00339 0.505897C1.26558 0.503619 1.51818 0.604414 1.70679 0.786572L5.70679 4.78657C5.89426 4.9741 5.99957 5.22841 5.99957 5.49357C5.99957 5.75874 5.89426 6.01304 5.70679 6.20057L1.70679 10.2006C1.51926 10.388 1.26495 10.4934 0.999786 10.4934C0.734622 10.4934 0.480314 10.388 0.292787 10.2006Z"
                            fill="#374151"
                          />
                        </svg>
                        <span className="text-sm text-[#6B7280]">
                          Cancellation Charges
                        </span>
                      </div>
                    </div>
                    <AlertDialogHeader className="flex flex-row justify-between gap-4 pb-4 px-10">
                      <div className="w-full items-start flex flex-col">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Cancellation Charges
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E]">
                          Fill the required details
                        </AlertDialogDescription>
                      </div>
                      <div className="py-2 px-3 bg-black-label rounded-lg flex justify-between w-64">
                        <div className="flex gap-2">
                          <p className="text-white-A700 ">
                            Total Charges:{" "}
                            <span className="font-medium text-[18px] leading-[14px]">
                              {totalCharges}
                            </span>
                          </p>
                        </div>
                      </div>
                    </AlertDialogHeader>
                    <form onSubmit={handleSubmit(onSubmit)} className="">
                      <div className="flex justify-between gap-4 mb-4 px-10 py-4">
                        <div className="flex-1">
                          <label
                            htmlFor="servicePartner"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Service Provider Charges
                          </label>
                          <input
                            {...register("servicePartnerCharges", {
                              required: "This field is required",
                              onChange: updateTotalCharges,
                            })}
                            type="number"
                            step="0.01"
                            className="w-full p-2 border rounded"
                            placeholder="Enter Service Partner Rates"
                          />
                          {errors.servicePartnerCharges && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.servicePartnerCharges.message as string}
                            </p>
                          )}
                        </div>
                        <div className="flex-1">
                          <label
                            htmlFor="channelPartnerCharges"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Channel Partner Charges
                          </label>
                          <input
                            {...register("channelPartnerCharges", {
                              required: "This field is required",
                              onChange: updateTotalCharges,
                            })}
                            type="number"
                            step="0.01"
                            className="w-full p-2 border rounded"
                            placeholder="Enter Foreign Bank Rates"
                          />
                          {errors.channelPartnerCharges && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.channelPartnerCharges.message as string}
                            </p>
                          )}
                        </div>
                      </div>
                      <AlertDialogFooter className="flex items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                          Cancel
                        </AlertDialogCancel>
                        <button
                          type="submit"
                          className="rounded-lg text-sm font-medium border-blue-700 py-[11px] px-4 bg-blue-700 text-white-A700"
                        >
                          {loading1
                            ? "processing..."
                            : "Set Cancellation Charges"}
                        </button>
                      </AlertDialogFooter>
                    </form>
                  </AlertDialogContent>
                </AlertDialogPortal>
              </AlertDialog>
            )}
          </div>
        </div>

        {/* for Lead */}
        {dealDetails?.leadDetail?.id && (
          <div className="flex flex-row justify-between gap-3 rounded-lg p-2 bg-[#FFEAC9] w-full px-4">
            <h1 className="text-[#666666] text-[13px] flex flex-row gap-2 items-center">
              This Deal has been created from{" "}
              <a
                className="text-blue-500 flex items-center gap-1 cursor-pointer"
                onClick={handleLeadRedirect}
              >
                Lead ID-{dealDetails?.leadDetail?.id} <ArrowUpRight />
              </a>
            </h1>
            {!checkLevel(LEVEL_2) &&
              dealDetails?.status != "confirmed" &&
              dealDetails?.status != "fulfilled" &&
              dealDetails?.status != "cancelled" && (
                <div
                  className="flex gap-2 cursor-pointer"
                  onClick={() => setIsSetCancellationCharges(true)}
                >
                  <p className="text-[#165FE3] text-[16px] font-semibold underline underline-offset-2">
                    Re-assign
                  </p>
                </div>
              )}
          </div>
        )}

        <div className="flex justify-between gap-x-10 w-full">
          <div className="w-full flex flex-col gap-2">
            <h5 className="text-[#999999]">Overview</h5>
            <div className="flex flex-1 gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
              <div className="py-2 px-3 bg-black-900 rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className="text-white-A700 ">
                    Deal ID:{" "}
                    <span className="font-medium text-[18px] leading-[14px]">
                      {dealDetails?.id}
                    </span>
                  </p>
                </div>
                <p
                  style={{
                    backgroundColor: deal_status_options.find(
                      (status) => dealDetails?.status === status.value,
                    )?.bgColor,
                    color: deal_status_options.find(
                      (status) => dealDetails?.status === status.value,
                    )?.textColor,
                  }}
                  className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md capitalize"
                >
                  {dealDetails?.status}
                </p>
              </div>
              <div className="py-2 px-3 rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Created at: <span className={valueStyle}>{createdate}</span>
                  </p>
                </div>
                <div className="flex flex-row justify-between gap-2">
                  <p className={`${labelStyle} flex items-center gap-1`}>
                    Created by:
                    {/* {dealDetails?.leadDetail?.partnerName?.name &&
                      dealDetails?.leadDetail?.refferedBy_partner ? (
                      <a
                        className="text-blue-500 flex items-center gap-1 cursor-pointer"
                        href={`/partnerDetails/${dealDetails.createdByStaff?.[0].refferedBy_partner}`}
                      >
                        {dealDetails.leadDetail.partnerName.name.charAt(0).toUpperCase() +
                          dealDetails.leadDetail.partnerName.name.slice(1).toLowerCase()}
                        <ArrowUpRight />
                      </a>
                    ) : (
                      <span className="text-gray-500">N/A</span>
                    )} */}
                    {dealDetails?.createdByStaff ? (
                      // <a
                      //   className="text-blue-500 flex items-center gap-1 cursor-pointer"
                      //   href={`/partnerDetails/${dealDetails.createdByStaff.id}`}
                      // >
                      //   {dealDetails.createdByStaff?.fname.toUpperCase()}
                      //   <ArrowUpRight />
                      // </a>
                      <span className={valueStyle}>
                        {dealDetails.createdByStaff?.fname.toUpperCase()}
                      </span>
                    ) : (
                      <span className="text-gray-500">N/A</span>
                    )}
                    {/* {dealDetails?.createdByStaff ? (
                      <a
                        className="text-blue-500 flex items-center gap-1 cursor-pointer"
                        href={`/partnerDetails/${dealDetails.createdByStaff.id}`}
                      >
                        {dealDetails.createdByStaff?.fname.toUpperCase()}
                        <ArrowUpRight />
                      </a>
                    ) : (
                      <span className="text-gray-500">N/A</span>
                    )} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h5 className="text-[#999999]">Deal Details</h5>
            <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Purpose:{" "}
                    <span className={valueStyle}>
                      {dealDetails?._purpose?.name}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Country:{" "}
                    <span className={valueStyle}>{dealDetails?.country}</span>
                  </p>
                </div>
              </div>
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Amount (FCY):
                    <span className={valueStyle}>
                      {" "}
                      <span className="bg-[#F5F5F5] indent-3.5 border border-gray-100 rounded-full px-2 py-0.5">
                        {dealDetails?.currency}
                      </span>{" "}
                      {formatIndianNumber(dealDetails?.amount ?? 0)}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Customer Rate:
                    <span className={valueStyle}>
                      {" "}
                      <span className="bg-[#F5F5F5] indent-3.5 border border-gray-100 rounded-full px-2 py-0.5">
                        INR
                      </span>{" "}
                      {`${(
                        Number(dealDetails?.service_partner_rates?.toFixed(4)) +
                        Number(dealDetails?.l2_commission_rates?.toFixed(4)) +
                        Number(dealDetails?.l1_commission_rates?.toFixed(4)) +
                        Number(dealDetails?.l3_commission_rates?.toFixed(4))
                      )?.toFixed(4)}`}
                    </span>
                  </p>
                </div>
              </div>
              <div className="rounded-lg flex justify-between w-full">
                {/* {!checkLevel(LEVEL_2) && ( */}
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    {checkLevel(LEVEL_2) ? "IBR Rate:" : " Settlement Rate:"}
                    <span className={valueStyle}>
                      {" "}
                      <span className="bg-[#F5F5F5] indent-3.5 border border-gray-100 rounded-full px-2 py-0.5">
                        INR
                      </span>{" "}
                      {(
                        Number(dealDetails?.service_partner_rates) +
                        (level == "2"
                          ? Number(dealDetails?.l1_commission_rates)
                          : 0) +
                        (level == "3"
                          ? Number(dealDetails?.l1_commission_rates) +
                            Number(dealDetails?.l2_commission_rates)
                          : 0)
                      )?.toFixed(4)}
                    </span>
                  </p>
                </div>
                {/* )} */}
                {!checkLevel(LEVEL_2) && (
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      IBR rate:{" "}
                      <span className={valueStyle}>
                        <span className="bg-[#F5F5F5] indent-3.5 border border-gray-100 rounded-full px-2 py-0.5">
                          INR
                        </span>{" "}
                        {`${dealDetails?.eforex_rates?.toFixed(4)}`}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-x-10 w-full">
          {/* {!checkLevel(LEVEL_2) && ( */}
          <div className="w-1/2 flex flex-col gap-2">
            <h5 className="text-[#999999]">Service Provider Details</h5>
            <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    {/* Name:{" "} */}
                    <span
                      className={valueStyle}
                      style={{
                        fontSize: "18px",
                        color: "#333333",
                        fontWeight: "600",
                      }}
                    >
                      {dealDetails?._service_partner?.display_name
                        ? dealDetails?._service_partner?.display_name
                            ?.slice(0, 1)
                            ?.toUpperCase() +
                          dealDetails?._service_partner?.display_name
                            ?.slice(1)
                            ?.toLowerCase()
                        : "N/A"}
                    </span>
                  </p>
                </div>
                {!checkLevel(LEVEL_2) &&
                  dealDetails?.status != "confirmed" &&
                  dealDetails?.status != "fulfilled" &&
                  dealDetails?.status != "cancelled" && (
                    <div
                      className="flex gap-2 cursor-pointer"
                      onClick={(e) => handleReAssignServiceProvider(e)}
                    >
                      <p className="text-[#165FE3] text-[16px] font-semibold underline underline-offset-2">
                        Re-assign
                      </p>
                    </div>
                  )}
              </div>
              {!checkLevel(LEVEL_2) && (
                <>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        City:
                        <span className={valueStyle}>
                          {" "}
                          {`${
                            dealDetails?._service_partner?.legal_addr_city
                              ? dealDetails?._service_partner?.legal_addr_city
                                  .charAt(0)
                                  .toUpperCase() +
                                dealDetails?._service_partner?.legal_addr_city
                                  .slice(1)
                                  .toLowerCase()
                              : "N/A"
                          }, ${
                            dealDetails?._service_partner?.legal_addr_state
                              ? dealDetails?._service_partner?.legal_addr_state
                                  .charAt(0)
                                  .toUpperCase() +
                                dealDetails?._service_partner?.legal_addr_state
                                  .slice(1)
                                  .toLowerCase()
                              : "N/A"
                          }`}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Service ID:{" "}
                        <span className={valueStyle}>
                          {dealDetails?._service_partner?.id ?? "N/A"}
                        </span>
                      </p>
                    </div>
                    {/* <div className="flex gap-2">
                  <p className={labelStyle}>
                    State:
                    <span className={valueStyle}>
                      {" "}
                      {dealDetails?._service_partner?.legal_addr_state
                        ? dealDetails?._service_partner?.legal_addr_state
                          ?.charAt(0)
                          ?.toUpperCase() +
                        dealDetails?._service_partner?.legal_addr_state
                          ?.slice(1)
                          ?.toLowerCase()
                        : "N/A"}
                    </span>
                  </p>
                </div> */}
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Email:
                        <span className={valueStyle}>
                          {" "}
                          {dealDetails?._service_partner?.email ?? "N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* )} */}
          {/* {dealDetails?._channels?.id != channel_id && !checkLevel(LEVEL_2) && (
            <div className="flex flex-col gap-2">
              <h5 className="text-[#999999]">Partner Details</h5>
              <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                <div className="rounded-lg flex justify-between w-full">
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Name:{" "}
                      <span className={valueStyle}>
                        {dealDetails?._channels?.name
                          ? dealDetails?._channels?.name
                            ?.charAt(0)
                            ?.toUpperCase() +
                          dealDetails?._channels?.name
                            ?.slice(1)
                            ?.toLowerCase()
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Service ID:{" "}
                      <span className={valueStyle}>
                        {dealDetails?._channels?.id ?? "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="rounded-lg flex justify-between w-full">
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Email:
                      <span className={valueStyle}>
                        {" "}
                        {dealDetails?._channels?.email ?? "N/A"}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Mobile:
                      <span className={valueStyle}>
                        {" "}
                        {dealDetails?._channels?.phone
                          ? dealDetails?._channels?.phone
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
        {!is_deal_flow_Check && (
          <div className="flex text-blue-700 gap-8">
            {(dealDetails?.status === "confirmed" ||
              dealDetails?.status === "unconfirmed") &&
              !checkLevel(LEVEL_2) && (
                <AlertDialog>
                  <AlertDialogTrigger>
                    <button className="py-[11px] px-4 rounded-md">
                      Cancel this deal?
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                          This action cannot be undone.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button>Go Back</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          asChild
                        >
                          <button onClick={cancelDealFunction} className="">
                            Cancel Deal
                          </button>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              )}
            <Link to="/remit/deals">
              <button className="py-[11px] px-4 rounded-md border border-blue-700">
                Back to Deals
              </button>
            </Link>
          </div>
        )}

        {/* Service Provider Re assign */}
        <AlertDialog open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          <AlertDialogPortal>
            <AlertDialogOverlay className="bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
            <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-md animate-contentShow">
              <form className="max-w-md">
                <AlertDialogHeader className="flex flex-col text-left gap-4 pt-8 pb-4 px-10">
                  <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                    Re-assign Service Provider
                  </AlertDialogTitle>
                  <div className="relative w-full space-y-5">
                    <label className="text-[15px] text-black-900_60">
                      Service Provider
                    </label>
                    <div className="relative">
                      <select
                        value={form.service_partner_id}
                        onChange={(e) => {
                          setForm((prev: any) => ({
                            ...prev,
                            service_partner_id: e.target.value,
                          }));
                          fetchFields(e.target.value);
                        }}
                        className={`bg-white-A700 border w-full border-black-900_4c border-solid h-[52px] pl-2.5 pr-10 py-4 rounded-md text-[15px] text-left tracking-[0.50px] appearance-none ${
                          form.service_partner_id
                            ? "text-black"
                            : "text-[#999999]"
                        }`}
                      >
                        <option value="" disabled className="text-[#999999]">
                          Select service provider
                        </option>
                        {servicePartners.map((partner) => (
                          <option
                            disabled={
                              partner.value.toString() ===
                              dealDetails?.service_partner_id?.toString()
                            }
                            key={partner.value}
                            value={partner.value}
                          >
                            {partner.label}
                          </option>
                        ))}
                      </select>
                      <img
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 pointer-events-none"
                        src="/images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    </div>
                    <div className="flex flex-col gap-5 relative">
                      {extraFields.map((field, index) => {
                        return (
                          <FieldRenderer
                            key={field.name}
                            fileBinaries={fileBinaries}
                            extraFieldValues={extraFieldValues}
                            setFileBinaries={setFileBinaries}
                            setExtraFieldValues={setExtraFieldValues}
                            isLast={index === extraFields.length - 1}
                            field={field}
                            files={files}
                            setFiles={setFiles}
                          />
                        );
                      })}
                    </div>
                  </div>
                </AlertDialogHeader>
                <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                  <AlertDialogCancel
                    className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                    asChild
                  >
                    <button onClick={() => setIsDropdownOpen(false)}>
                      Close
                    </button>
                  </AlertDialogCancel>
                  <AlertDialogAction
                    type="button"
                    onClick={handleonSettlement}
                    disabled={extraFieldsValidation()}
                    className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  >
                    Save
                  </AlertDialogAction>
                </AlertDialogFooter>
              </form>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
      {isSetCancellationCharges && (
        <SetCancellationChargesModal
          contact_name=""
          showLinkPartnerButton={true}
          otherProps={{
            contact_id: dealDetails?.contacts_id,
            dealID: dealDetails?.id,
            purposeID: dealDetails?.purpose_id,
          }}
          setIsSetCancellationCharges={setIsSetCancellationCharges}
          isSetCancellationCharges={isSetCancellationCharges}
          DealDetails={DealDetails}
        />
      )}
      <Toaster theme="dark" position="top-right" />
    </>
  );
};

export default NewDealDetailsPage;
