import { useFormContext } from "react-hook-form";
import { FormData } from ".";
import { Input } from "@/components/ui/input";

export default function Step6() {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();
  return (
    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
      <p className={`text-[18px] font-medium text-[#333]`}>Enter amount</p>
      <div className="relative w-full rounded-md">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
          <span className=" font-medium leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
            INR
          </span>
        </div>
        <Input
          type="number"
          id="amount"
          name="amount"
          placeholder="Enter amount"
          onChange={(e) => setValue("amount", e.target.value)}
          className="m-0 h-12 max-w-[340px] pl-[70px] border border-gray-border-gray-lite py-1.5 pr-[7px] rounded-lg"
        />
      </div>
    </div>
  );
}
