import NavbarWithActions from "components/Navbar/navbarwithactions";
import { Outlet } from "react-router-dom";

export default function OrderDetails() {
  return (
    <div className="flex flex-col h-full overflow-hidden gap-2.5 font-inter">
      <NavbarWithActions header="Remittances" />
      <div className="flex overflow-auto flex-col h-full">
        <Outlet />
      </div>
    </div>
  );
}
