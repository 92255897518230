import * as React from "react";
import { ChevronLeft, ChevronRight, Plus, RefreshCcw, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { useParams } from "react-router-dom";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import { Spinner } from "components";
import RequestOrderDoc from "./request_doc";
import { toast } from "sonner";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";

interface Document {
  id: string;
  name: string;
  type: "pdf" | "image";
  status: "Unverified" | "Verified" | "Requested";
  url: string;
}

interface DocumentReviewProps {
  documents: Document[];
  onApprove?: (id: string, name: string, file: File) => Promise<void>;
  onRequest?: (id: string) => Promise<void>;
  onReUpload: (id: string, file: string, name: string) => Promise<void>;
  onAddDocument?: (name: string) => Promise<void>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refetchOrderDetails: () => void;
}

export default function DocumentReview({
  documents,
  onApprove,
  onRequest,
  onReUpload,
  onAddDocument,
  isOpen,
  setIsOpen,
  refetchOrderDetails,
}: Readonly<DocumentReviewProps>) {
  const [currentDocIndex, setCurrentDocIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isAddingDoc, setIsAddingDoc] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadedFile, setLoadedFile] = React.useState<File | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const currentDoc = documents[currentDocIndex];
  const { id } = useParams();

  const handlePrevious = () => {
    if (currentDocIndex > 0) {
      setCurrentDocIndex(currentDocIndex - 1);
      setCurrentPage(1);
    }
  };

  const handleNext = () => {
    if (currentDocIndex < documents.length - 1) {
      setCurrentDocIndex(currentDocIndex + 1);
      setCurrentPage(1);
    }
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      await onApprove?.(currentDoc?.id, currentDoc?.name, loadedFile as File);
      if (currentDocIndex < documents.length - 1) {
        setCurrentDocIndex(currentDocIndex + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRequest = async () => {
    setLoading(true);
    try {
      await onRequest?.(currentDoc?.id);
    } finally {
      setLoading(false);
    }
  };

  const handleReUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.size >= 5 * 1024 * 1024) {
      toast.error("File size should be less than or equal to 5MB");
      return;
    }
    const base64 = await convertFileToBase64(file as File);
    if (file) {
      setLoading(true);
      try {
        await onReUpload(currentDoc?.id, base64, currentDoc?.name);
      } finally {
        setLoading(false);
      }
    }
  };

  const suggestedDocs = ["College ID", "College ID", "College ID"];
  const allDocsVerified = documents?.every((doc) => doc?.status === "Verified");

  return (
    <>
      {loading && <Spinner />}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogPortal>
          <DialogOverlay className="bg-black-900" />
          <DialogContent className="max-w-4xl py-6 px-4 bg-white-A700 rounded-md">
            <DialogHeader className="flex flex-row items-center justify-between">
              <DialogTitle>
                {checkLevel(LEVEL_2)
                  ? "Re-Upload Documents"
                  : "Review Documents"}
              </DialogTitle>
              <DialogClose asChild>Close</DialogClose>
            </DialogHeader>
            <div className="grid grid-cols-[300px_1fr] gap-4 h-full min-h-[500px]">
              {/* Sidebar */}
              <div className=" space-y-2">
                {documents.map((doc, index) => (
                  <div
                    role="button"
                    key={doc.id}
                    className={cn(
                      "flex items-center justify-between p-2 rounded-lg cursor-pointer",
                      currentDocIndex === index && "bg-muted text-blue-700",
                    )}
                    onClick={() => {
                      if (loadedFile) {
                        setLoadedFile(null);
                      }
                      setCurrentDocIndex(index);
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <span className="text-sm font-medium font-inter leading-[16.9px] border rounded-md py-1 px-2.5 border-[#EFF4FF]">
                        {index + 1}
                      </span>
                      <span className="text-sm font-medium font-inter leading-[16.9px]">
                        {doc.name}
                      </span>
                    </div>
                    {doc.status === "Verified" && (
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.76758 0.5C3.46158 0.5 0.767578 3.194 0.767578 6.5C0.767578 9.806 3.46158 12.5 6.76758 12.5C10.0736 12.5 12.7676 9.806 12.7676 6.5C12.7676 3.194 10.0736 0.5 6.76758 0.5ZM9.63558 5.12L6.23358 8.522C6.14958 8.606 6.03558 8.654 5.91558 8.654C5.79558 8.654 5.68158 8.606 5.59758 8.522L3.89958 6.824C3.72558 6.65 3.72558 6.362 3.89958 6.188C4.07358 6.014 4.36158 6.014 4.53558 6.188L5.91558 7.568L8.99958 4.484C9.17358 4.31 9.46158 4.31 9.63558 4.484C9.80958 4.658 9.80958 4.94 9.63558 5.12Z"
                          fill="#165FE3"
                        />
                      </svg>
                    )}
                    {doc.status === "Requested" && (
                      <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.29688 13H6.69695M3.99743 10V1M3.99743 1L6.62243 3.625M3.99743 1L1.37244 3.625"
                          stroke="#165FE3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                ))}
                {!checkLevel(LEVEL_2) && (
                  <Button
                    variant="ghost"
                    disabled
                    className="w-full justify-start gap-2"
                    onClick={() => setIsAddingDoc(true)}
                  >
                    <Plus className="h-4 w-4" />
                    Add Additional Document
                  </Button>
                )}
              </div>

              {/* Main Content */}
              <div className="flex flex-col h-full">
                {currentDoc?.status === "Requested" ? (
                  loadedFile?.name ? (
                    <div className="bg-[#F9F9F9] flex items-center flex-col justify-center h-full border rounded-lg cursor-pointer flex-1 overflow-hidden">
                      {loadedFile.type === "application/pdf" ? (
                        <iframe
                          src={URL.createObjectURL(loadedFile)}
                          width="100%"
                          height="100%"
                          title={loadedFile.name}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(loadedFile)}
                          alt={loadedFile.name}
                          className=" w-full h-full object-contain"
                        />
                      )}
                      <button
                        onClick={() => {
                          setLoadedFile(null);
                        }}
                        className="text-blue-700 text-sm font-medium font-inter leading-[16.9px] p-2"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <div className="bg-[#F9F9F9] flex items-center flex-col justify-center h-full border rounded-lg cursor-pointer">
                      <div className="flex items-center flex-col justify-center">
                        <svg
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.50001 0.445312C5.40139 0.445313 4.32745 0.771089 3.41398 1.38145C2.50052 1.9918 1.78856 2.85933 1.36814 3.87431C0.947719 4.8893 0.837718 6.00616 1.05205 7.08367C1.26638 8.16117 1.79541 9.15092 2.57225 9.92776C3.34908 10.7046 4.33883 11.2336 5.41634 11.448C6.49384 11.6623 7.61071 11.5523 8.62569 11.1319C9.64068 10.7114 10.5082 9.99949 11.1186 9.08602C11.7289 8.17256 12.0547 7.09862 12.0547 6C12.0531 4.52728 11.4674 3.11533 10.426 2.07396C9.38468 1.03259 7.97272 0.446868 6.50001 0.445312ZM6.28636 3.00902C6.41313 3.00902 6.53704 3.04661 6.64244 3.11703C6.74784 3.18746 6.82999 3.28756 6.8785 3.40467C6.92701 3.52178 6.9397 3.65065 6.91497 3.77498C6.89024 3.89931 6.8292 4.01351 6.73957 4.10314C6.64993 4.19278 6.53573 4.25382 6.4114 4.27855C6.28707 4.30328 6.15821 4.29059 6.04109 4.24208C5.92398 4.19357 5.82388 4.11142 5.75345 4.00602C5.68303 3.90062 5.64544 3.7767 5.64544 3.64994C5.64544 3.47996 5.71296 3.31694 5.83316 3.19674C5.95336 3.07654 6.11638 3.00902 6.28636 3.00902ZM6.92729 8.99099C6.70064 8.99099 6.48328 8.90096 6.32302 8.74069C6.16276 8.58043 6.07272 8.36307 6.07272 8.13642V6C5.9594 6 5.85072 5.95499 5.77059 5.87485C5.69045 5.79472 5.64544 5.68604 5.64544 5.57272C5.64544 5.4594 5.69045 5.35072 5.77059 5.27058C5.85072 5.19045 5.9594 5.14544 6.07272 5.14544C6.29937 5.14544 6.51673 5.23547 6.67699 5.39573C6.83725 5.55599 6.92729 5.77336 6.92729 6V8.13642C7.04061 8.13642 7.14929 8.18144 7.22942 8.26157C7.30956 8.3417 7.35457 8.45038 7.35457 8.56371C7.35457 8.67703 7.30956 8.78571 7.22942 8.86584C7.14929 8.94597 7.04061 8.99099 6.92729 8.99099Z"
                            fill="#A6ABB4"
                          />
                        </svg>
                        <p className="">
                          The file can be a pdf or image (png, jpg, jpeg) with
                          max size of 5MB.
                        </p>
                        <p className="">
                          Only a single file upload is allowed.
                        </p>
                      </div>
                      <label
                        htmlFor="file"
                        className=" border border-blue-700 rounded-md px-4 py-2 mt-4 cursor-pointer"
                      >
                        <input
                          type="file"
                          name=""
                          id="file"
                          className="hidden"
                          onChange={(e) => {
                            const file = e?.target?.files?.[0];
                            if (file && file?.size >= 5 * 1024 * 1024) {
                              toast.error(
                                "File size should be less than or equal to 5MB",
                              );
                              return;
                            }
                            setLoadedFile(e.target.files?.[0] as File);
                          }}
                          // accept pdf and image ( png jpeg jpg)
                          accept=".pdf,image/*"
                        />
                        <span className="text-blue-700">Upload Document</span>
                      </label>
                    </div>
                  )
                ) : (
                  <div className="flex-1 relative border rounded-lg overflow-hidden bg-muted">
                    {currentDoc?.type === "pdf" ? (
                      <iframe
                        src={currentDoc?.url}
                        width="100%"
                        height="100%"
                        title={currentDoc?.name}
                      />
                    ) : (
                      <img
                        src={currentDoc?.url || "/placeholder.svg"}
                        alt={currentDoc?.name}
                        className="w-full max-h-[500px] object-contain"
                      />
                    )}
                  </div>
                )}

                {/* Actions */}
                {currentDoc?.status === "Requested" ? (
                  <div className="flex items-center justify-end mt-4">
                    <Button
                      className="bg-blue-700 rounded-md text-white-A700 border border-blue-700"
                      onClick={handleApprove}
                      disabled={loadedFile === null}
                    >
                      Save
                    </Button>
                  </div>
                ) : (
                  <div className="flex items-center justify-between mt-4">
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={handlePrevious}
                        disabled={currentDocIndex === 0}
                      >
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={handleNext}
                        disabled={currentDocIndex === documents.length - 1}
                      >
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                    <div className="flex items-center gap-4">
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={handleReUpload}
                        accept=".pdf,image/*"
                      />
                      <Button
                        className={`text-blue-700 ${checkLevel(LEVEL_2) ? "border border-blue-700 rounded-lg" : " border-none"}`}
                        onClick={() => fileInputRef.current?.click()}
                        disabled={currentDoc?.status === "Verified"}
                      >
                        <RefreshCcw className="h-4 w-4 mr-2 border-none" />
                        Re-Upload
                      </Button>
                      {!checkLevel(LEVEL_2) && (
                        <RequestOrderDoc
                          refetchOrderDetails={refetchOrderDetails}
                          order_id={id as string}
                        >
                          <Button
                            variant="outline"
                            className="border rounded-md border-blue-700 text-blue-700"
                          >
                            Request
                          </Button>
                        </RequestOrderDoc>
                      )}
                      {!checkLevel(LEVEL_2) && (
                        <Button
                          className="bg-blue-700 disabled:opacity-40 rounded-md text-white-A700 border border-blue-700"
                          onClick={() => {
                            if (allDocsVerified) {
                              setIsOpen(false);
                              return;
                            }
                            handleApprove();
                          }}
                          disabled={
                            allDocsVerified
                              ? false
                              : currentDoc?.status === "Verified"
                          }
                        >
                          {allDocsVerified ? "Submit" : "Approve"}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </DialogPortal>

        {/* Add Document Dialog */}
        <Dialog open={isAddingDoc} onOpenChange={setIsAddingDoc}>
          <DialogContent className="sm:max-w-[425px] bg-white-A700">
            <DialogHeader>
              <DialogTitle>Add Additional Document</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="space-y-4">
                <div className="relative">
                  <Input
                    placeholder="Enter the document name"
                    className="pr-10"
                  />
                  <Button
                    size="icon"
                    variant="ghost"
                    className="absolute right-0 top-0 h-full px-3 hover:bg-transparent"
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                </div>
                <div className="flex flex-wrap gap-2">
                  {suggestedDocs.map((doc, i) => (
                    <div
                      key={i}
                      className="flex items-center gap-1 bg-blue-50 text-blue-700 px-3 py-1 rounded-full text-sm"
                    >
                      {doc}
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-4 w-4 hover:bg-transparent p-0"
                      >
                        <X className="h-3 w-3" />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </Dialog>
    </>
  );
}
