import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Option } from "components/Form/form";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  setProduct,
  setProductLoanType,
} from "redux/features/CreateOpportunity";
import { setPurposeId } from "redux/features/CreateOrder";
import { setIsReferralMade } from "redux/features/CustomerRegistration";
import { useAppDispatch, useAppSelector } from "redux/hooks";
interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  processState: any;
  productMode: any;
  setProductMode: React.Dispatch<React.SetStateAction<any>>;
  isProductLoan: string;
  selectProduct: string;
  contactDetails: any;
  connectedContactList: any[];
}

// remittence mode
const remitProductModeOptions: Option[] = [
  { value: "ViaTransfer", label: "Via Transfer" },
  { value: "Flywire", label: "Flywire" },
  { value: "WesternUnion", label: "Western Union" },
  { value: "CIBC", label: "CIBC" },
  { value: "PayMyTuition", label: "PayMyTuition" },
];

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

export const L1ModeStep: React.FC<Step2Props> = ({
  setCurrentStep,
  setPageTitle,
  setProcessState,
  processState,
  setProductMode,
  productMode,
  isProductLoan,
  selectProduct,
  contactDetails,
  connectedContactList,
}) => {
  const {
    product_mode,
    product_purpose,
    quoteProduct,
    product,
    prepare_quote_contact_data,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
      forex: forexProductPurposeOptions,
      visa: visaProductPurposeOptions,
      travel: travelProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === selectProduct,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleProductPrepareQuote = useCallback((val: any) => {
    dispatch(setProductLoanType(false));
    if (quoteProduct === "remittance") {
      dispatch(setIsReferralMade(false));
      if (!product) {
        dispatch(setProduct(quoteProduct));
      }
      dispatch(
        setPurposeId(connectedContactList?.[0]?.purposeDetail?.purpose_id),
      );

      navigate("/lead/createlead", {
        state: {
          id: prepare_quote_contact_data?.value,
          contact_name: contactDetails?.contact_name,
          contact_number: contactDetails?.contact_number,
          contact_email: contactDetails?.contact_email,
          currentpath: "customerdetails",
          quoteProduct: quoteProduct,
          product_purpose: product_purpose,
          getQuoteFlow: "getQuoteremittanceFlow",
          purpose_id: null,
          selectedProductname: finalProductValue?.[0]?.label,
          mode: val,
          took_loan: isProductLoan === "true" ? true : false,
        },
      });
    }
  }, []);

  const handleProductMode = useCallback(
    (value: string) => {
      setProductMode(value);
      setPageTitle("Education Loan");
      setProcessState((prevState: any) => ({
        ...prevState,
        Mode: value,
      }));
      if (processState?.Product === "Education Payment") {
        if (value === "ViaTransfer") {
          handleProductPrepareQuote("ViaTransfer");
          // setCurrentStep((prev) => prev + 2);
        } else {
          setCurrentStep((prev) => prev + 1);
        }
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    },
    [setProductMode, setProcessState, setPageTitle, processState],
  );

  useEffect(() => {
    setPageTitle("Select Mode");
  }, [setPageTitle]);

  return (
    <form className="w-full">
      <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
        <div>
          <RadioGroup
            className="flex w-full flex-col w-[350px] gap-4 mt-2"
            value={product_mode}
            onValueChange={handleProductMode}
          >
            {remitProductModeOptions.map((tab) => (
              <label
                key={tab.value}
                htmlFor={tab.value}
                className={`${
                  product_mode === tab.value
                    ? "focus-within:border-blue-700 border-black-label"
                    : ""
                } flex w-auto items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
              >
                <RadioGroupItem
                  id={tab.value}
                  value={tab.value}
                  className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <p className="text-[14px] font-medium w-full">{tab.label}</p>
              </label>
            ))}
          </RadioGroup>
        </div>
        <button
          className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
          onClick={() => {
            setCurrentStep((prev) => prev - 1);
            setProductMode("");
            setPageTitle("Education Loan");
            setProcessState((prevState: any) => ({
              ...prevState,
              Mode: "",
            }));
            setPageTitle("Select product");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </button>
      </div>
    </form>
  );
};
