import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { AlertDialogAction } from "@radix-ui/react-alert-dialog";
import { AxiosRequestConfig } from "axios";
import ReactSelect from "react-select";
import { useEffect, useState } from "react";
import {
  getDocumentMapping,
  GetDocumentMappingReqType,
  getReqDocList,
  GetReqDocListReqType,
} from "service/apis/Opportunities";
import { Plus } from "lucide-react";
import { toast } from "sonner";
import {
  postOrderDocuments,
  POSTOrderDocumentsRequestType,
} from "service/apis/CreateOrderapi";
import { OrderDocumentType } from "@/lib/validations/schema";

interface PropsType {
  children?: React.ReactNode;
  order_id: string;
  refetchOrderDetails?: () => void;
  doc_list?: (OrderDocumentType & { type: "image" | "pdf" })[];
}

export default function RequestOrderDoc({
  children,
  order_id,
  refetchOrderDetails,
  doc_list,
}: Readonly<PropsType>) {
  const [docMap, setDocMap] = useState<
    {
      label: string;
      value: string;
      uploaded?: boolean;
      last_updated?: string;
      url?: string;
    }[]
  >([]);
  const [popupnotes, setPopupNotes] = useState<string>("");
  const [openAddDoc, setOpenAddDoc] = useState<boolean>(false);
  const [addDocName, setAddDocName] = useState<string>("");
  const [additionalDoc, setAdditionalDoc] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedDoc, setSelectedDoc] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const fetchDocMap = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetReqDocListReqType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        order_id: order_id,
      },
    };
    try {
      const response = (await getReqDocList(payload)) as any;

      setDocMap(() =>
        response?.data?.map((doc: any) => ({
          label: doc?.filename,
          value: doc?.id,
        })),
      );
      // else {
      //     setDocMap(() =>
      //         response?.data?.map((doc: any) => ({
      //             label: doc?.filename,
      //             value: doc?.doc_id,
      //             uploaded: doc?.uploaded,
      //             last_updated: doc?.uploadedAt,
      //             url: doc?.filePath,
      //         })),
      //     );
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddDocument = () => {
    if (addDocName.trim() === "") return;
    if (additionalDoc?.find((val) => val.label === addDocName)) {
      toast.error("Document already added");
      return;
    }
    // Add the new document to the redux store
    setAdditionalDoc((prev) => {
      return [...(prev ?? []), { label: addDocName, value: addDocName }];
    });
    setAddDocName("");
  };

  const removeChannel = (label: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setAdditionalDoc((prev) => {
      return prev.filter((doc) => doc.label !== label);
    });
  };

  const handRequestDoc = async (doc_id: string, filename: string) => {
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    let payload: AxiosRequestConfig<POSTOrderDocumentsRequestType> = {};
    if (additionalDoc?.length > 0) {
      payload = {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          order_id: order_id,
          status: "Requested",
          filename: filename,
        },
      };
    } else {
      payload = {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          order_id: order_id,
          status: "Requested",
          doc_list_id: doc_id,
        },
      };
    }
    try {
      const response = await postOrderDocuments(payload);
      if (response.status === 200) {
        // refetchOrderDetails?.()
        toast.success("Document requested successfully");
        refetchOrderDetails?.();
        setPopupNotes("");
      }
    } catch (error) {
      toast.error("Failed to request documents");
    }
  };

  useEffect(() => {
    fetchDocMap();
  }, []);

  return (
    <AlertDialog>
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
        <AlertDialogContent className="bg-white-A700 rounded-lg gap-0 p-0 max-w-2xl max-h-fit overflow-auto animate-contentShow">
          <AlertDialogHeader className="py-5 px-10 flex border-b">
            <AlertDialogTitle className="text-xl text-left font-bold">
              Request Documents
            </AlertDialogTitle>
          </AlertDialogHeader>
          <div className="flex flex-col gap-6 p-5">
            <form className="flex flex-col gap-10" onSubmit={() => {}}>
              <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full max-w-lg ">
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${"after:h-28"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label htmlFor="medium_of_conatc" className="text-sm">
                    Which documents need to be collected ?
                  </label>
                  <ReactSelect
                    menuPlacement="auto"
                    menuPosition="absolute"
                    placeholder="Select the document"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        zIndex: 0,
                        borderRadius: ".5rem",
                        padding: ".4rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: state.isFocused ? "#165FE3" : "#333333",
                        boxShadow: "none",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 1000,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        zIndex: 1000,
                        maxHeight: "300px",
                        overflowY: "auto",
                      }),
                      option: (provided) => ({
                        ...provided,
                        zIndex: 1000,
                        height: "100%",
                      }),
                    }}
                    options={docMap}
                    value={selectedDoc}
                    onChange={(selected) => {
                      if (selected?.label === selectedDoc?.label) {
                        setSelectedDoc(null);
                        return;
                      }
                      setSelectedDoc(selected);
                    }}
                    name="staff_select"
                    id="staff_select"
                    className="w-full"
                    isDisabled={additionalDoc?.length > 0}
                  />
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${!openAddDoc ? "after:h-12" : "after:h-32"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  {openAddDoc && (
                    <div className="flex justify-between items-center w-full">
                      <label htmlFor="">
                        {/* {addDocName || "Additional Documents"} */}
                        Name the document
                      </label>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenAddDoc(!openAddDoc);
                          setAddDocName("");
                          setAdditionalDoc([]);
                        }}
                        className="text-blue-700"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {!openAddDoc ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenAddDoc(!openAddDoc);
                      }}
                      disabled={!!selectedDoc?.label}
                      className="text-blue-700"
                    >
                      + Additional Documents
                    </button>
                  ) : (
                    <div className="flex mb-4 border w-full border-black rounded-md overflow-hidden md:w-[300px]">
                      <input
                        type="text"
                        disabled={!!selectedDoc?.label}
                        className="w-full px-3 py-2.5 focus:outline-none border-none h-11"
                        placeholder="Enter document name"
                        value={addDocName}
                        onChange={(e) => setAddDocName(e.target.value)}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddDocument();
                        }}
                        disabled={!!selectedDoc?.label}
                        className="text-blue-600 p-2 flex items-center justify-center"
                      >
                        <Plus size={20} />
                      </button>
                    </div>
                  )}
                  {additionalDoc?.length > 0 && (
                    <div className="flex gap-2 items-center w-full">
                      {additionalDoc.map((doc, index) => (
                        <div key={index} className="flex items-center gap-3">
                          <button
                            key={doc.label}
                            className={`bg-[#E1EBFC] flex items-center gap-2 px-[18px] py-1.5 rounded-lg cursor-pointer transition-colors duration-200`}
                          >
                            <span className="text-sm text-blue-700 leading-[19.2px] font-normal">
                              {doc.label}
                            </span>
                            {
                              <button
                                className="w-4 h-4 text-blue-700 hover:text-blue-900"
                                onClick={(e) => removeChannel(doc.label, e)}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                    fill="#165FE3"
                                  />
                                  <path
                                    d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                    fill="#165FE3"
                                  />
                                  <path
                                    d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                    fill="#165FE3"
                                  />
                                </svg>
                              </button>
                            }
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-24 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label htmlFor="note" className="text-sm">
                    Notes{" "}
                    <span className="text-[#999999] font-medium text-sm leading-5">
                      {"( Visible to the partner )"}
                    </span>
                  </label>
                  <textarea
                    placeholder="Write note here...."
                    onChange={(e) => setPopupNotes(e.target.value)}
                    id="note"
                    value={popupnotes}
                    className="border h-36 border-black-label rounded-lg p-4 w-full"
                  />
                </div>
              </div>
            </form>
          </div>
          <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
            <AlertDialogCancel
              className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
              asChild
            >
              <button
                onClick={() => {
                  setPopupNotes("");
                  setOpenAddDoc(false);
                  setAddDocName("");
                  setAdditionalDoc([]);
                  setSelectedDoc(null);
                }}
              >
                Cancel
              </button>
            </AlertDialogCancel>
            <AlertDialogAction
              className="rounded-lg py-[11px] px-4 bg-blue-700 text-white-A700"
              asChild
            >
              <button
                className="disabled:opacity-40"
                onClick={() => {
                  handRequestDoc(
                    selectedDoc?.value || "",
                    additionalDoc?.[0]?.label || "",
                  );
                }}
                disabled={
                  !selectedDoc?.value && !additionalDoc.length
                    ? true
                    : additionalDoc?.length < 0
                }
              >
                Submit
              </button>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
