import { X } from "lucide-react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";

interface PaymentReviewProps {
  isOpen: boolean;
  setIsOpen: (enabled: boolean) => void;
  paymentProofUrl: {};
  supportingDocUrl: {};
  amount: number;
  onApprove: (status_AD2: string, notes: string) => Promise<void>;
  onReject: (reason: string) => Promise<void>;
  onClose: () => void;
  children: React.ReactNode;
  refetchOrder: () => void;
}

const rejectionReasons = [
  "Incorrect amount",
  "Invalid document",
  "Document not clear",
  "Wrong payment method",
  "Other",
];

export default function PaymentReview({
  paymentProofUrl,
  supportingDocUrl,
  amount,
  onApprove,
  onClose,
  refetchOrder,
  children,
}: Readonly<PaymentReviewProps>) {
  const [activeTab, setActiveTab] = useState("payment");
  const [loading, setLoading] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const handleNext = () => {
    if (activeTab === "payment") {
      setActiveTab("supporting");
    }
  };

  const handlePrevious = () => {
    if (activeTab === "supporting") {
      setActiveTab("payment");
    }
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      await onApprove("accept", "");
      refetchOrder();
      onClose();
    } catch (error) {
      console.error("Error approving payment:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    if (!rejectReason) return;

    setLoading(true);
    try {
      const finalReason = rejectReason === "Other" ? otherReason : rejectReason;
      await onApprove("reject", finalReason);
      await onApprove("reject", finalReason);
      setShowRejectDialog(false);
      refetchOrder();
      onClose();
    } catch (error) {
      console.error("Error rejecting payment:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className="bg-black-900/30" />
        <AlertDialogContent className="max-w-4xl py-5 bg-white-A700 rounded-md">
          <AlertDialogHeader className="flex flex-row items-center border-b pb-5 justify-between">
            <AlertDialogTitle>Review Payment</AlertDialogTitle>
            <AlertDialogCancel className="border-none p-0">
              <X className="cursor-pointer h-4 w-4" />
            </AlertDialogCancel>
          </AlertDialogHeader>
          <div className="flex flex-col h-full gap-4">
            <Tabs
              defaultValue="payment"
              value={activeTab}
              onValueChange={setActiveTab}
            >
              <TabsList className="p-0 gap-2">
                <TabsTrigger
                  value="payment"
                  className={`flex gap-2 p-0 pb-2 ${activeTab === "payment" ? "text-blue-700 border-b-4 border-blue-700 " : "text-muted-foreground"}`}
                >
                  <span className="text-sm font-medium font-inter leading-[16.9px] border rounded-md py-1 px-2.5 border-[#EFF4FF]">
                    1
                  </span>
                  <p className=" text-sm font-medium font-inter leading-[16.9px]">
                    Payment Proof
                  </p>
                </TabsTrigger>
                <TabsTrigger
                  value="supporting"
                  className={`flex gap-2 p-0 pb-2 ${activeTab === "supporting" ? "text-blue-700 border-b-4 border-blue-700 " : "text-muted-foreground"}`}
                >
                  <span className="text-sm font-medium font-inter leading-[16.9px] border rounded-md py-1 px-2.5 border-[#EFF4FF]">
                    2
                  </span>
                  <p className="text-sm font-medium font-inter leading-[16.9px]">
                    Supporting document
                  </p>
                </TabsTrigger>
              </TabsList>

              <div className="mt-4 space-y-4">
                <Label>Amount received</Label>
                <div className="flex items-center gap-2.5 border max-w-[340px] rounded-md p-1.5">
                  <span className="bg-[#F9F9F9] rounded p-2.5 text-[#666666] leading-[16.9px]">
                    INR
                  </span>
                  <p className="">{amount.toString()}</p>
                </div>
              </div>
              <TabsContent value="payment">
                <div className="relative border rounded-lg flex items-center w-full justify-center overflow-hidden h-full bg-muted mt-4">
                  {paymentProofUrl?.type === "application/pdf" ? (
                    <iframe
                      src={URL.createObjectURL(paymentProofUrl?.url)}
                      width="100%"
                      height="100%"
                      title={paymentProofUrl.name}
                    />
                  ) : (
                    <img
                      src={paymentProofUrl?.url}
                      alt={paymentProofUrl?.name}
                      className=" max-h-[350px] object-contain"
                    />
                  )}
                </div>
              </TabsContent>
              <TabsContent value="supporting">
                <div className="relative border flex justify-center items-center rounded-lg overflow-hidden bg-muted mt-4">
                  {supportingDocUrl?.type === "application/pdf" ? (
                    <iframe
                      src={URL.createObjectURL(supportingDocUrl?.url)}
                      width="100%"
                      height="100%"
                      title={supportingDocUrl.name}
                    />
                  ) : (
                    <img
                      src={supportingDocUrl?.url}
                      alt={supportingDocUrl?.name}
                      className=" max-h-[350px] object-contain"
                    />
                  )}
                </div>
              </TabsContent>
            </Tabs>

            <div className="flex items-center gap-2 py-2">
              <input
                checked={isConsentChecked}
                onChange={(e) => setIsConsentChecked(e.target.checked)}
                className="rounded border-[#D2D2D2]"
                type="checkbox"
                name="consent"
                id="consent"
              />
              <label
                htmlFor="consent"
                className="text-base select-none leading-[9.6px] text-muted-foreground text-[#666666]"
              >
                I have confirmed the payment status from AD
              </label>
            </div>

            {/* Actions */}
            <div className="flex items-center justify-between mt-auto">
              <div className="flex gap-4">
                <Button
                  variant="outline"
                  className="border border-blue-700"
                  size="icon"
                  onClick={handlePrevious}
                  disabled={activeTab === "payment"}
                >
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.1663 6H0.833008M0.833008 6L5.83301 1M0.833008 6L5.83301 11"
                      stroke="#165FE3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Button
                  variant="outline"
                  className="border border-blue-700"
                  size="icon"
                  onClick={handleNext}
                  disabled={activeTab === "supporting"}
                >
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.83366 6H14.167M14.167 6L9.16699 11M14.167 6L9.16699 1"
                      stroke="#165FE3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
              <div className="flex items-center gap-4">
                <Button
                  variant="outline"
                  onClick={() => setShowRejectDialog(true)}
                  className="text-destructive hover:text-destructive border rounded-md border-[#EA2C2C] text-[#EA2C2C]"
                  disabled={!isConsentChecked}
                >
                  Reject
                </Button>
                <Button
                  className="bg-blue-700 rounded-md text-white-A700 border border-blue-700"
                  onClick={handleApprove}
                  disabled={!isConsentChecked}
                >
                  Approve
                </Button>
              </div>
            </div>
          </div>
          {showRejectDialog && (
            <AlertDialog open={true}>
              <AlertDialogPortal>
                <AlertDialogOverlay className="bg-black-900/30" />
                <AlertDialogContent className="bg-white-A700 rounded-md">
                  <AlertDialogHeader className="text-left">
                    <AlertDialogTitle>Payment rejection</AlertDialogTitle>
                  </AlertDialogHeader>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label>
                        Select the reason for rejecting the payment{" "}
                        <span className="text-gray-500">
                          (Visible to partners)
                        </span>
                      </Label>
                      <Select
                        value={rejectReason}
                        onValueChange={setRejectReason}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a reason" />
                        </SelectTrigger>
                        <SelectContent>
                          {rejectionReasons.map((reason) => (
                            <SelectItem key={reason} value={reason}>
                              {reason}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    {rejectReason === "Other" && (
                      <div className="space-y-2 flex flex-col">
                        <Label>
                          Specify 'Other'{" "}
                          {/* <span className="text-muted-foreground">
                            (Visible to the customer)
                          </span> */}
                        </Label>
                        <div>
                          <textarea
                            placeholder="Write a reason why you rejected the payment..."
                            className="border border-gray-400 rounded-[10px]  w-[80%]"
                            value={otherReason}
                            rows={5}
                            onChange={(e) => setOtherReason(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex justify-end gap-4 mt-4">
                      <Button
                        variant="outline"
                        className="text-destructive hover:text-destructive border rounded-md border-blue-700 text-blue-700 "
                        onClick={() => setShowRejectDialog(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="destructive"
                        className="bg-[#FF0000] rounded-md text-white-A700 border border-[#FF0000]"
                        onClick={handleReject}
                        disabled={
                          loading ||
                          !rejectReason ||
                          (rejectReason === "Other" && !otherReason)
                        }
                      >
                        Reject Payment
                      </Button>
                    </div>
                  </div>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          )}
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
