import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { AxiosRequestConfig } from "axios";
import { Spinner } from "components";
import { Check, CircleX, Search } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { LinkLeadAPI } from "service/apis/CreateDealapi";
import {
  GetLinkLeadListRequestType,
  getLinkLeadSearchList,
} from "service/apis/DealDetails";
import { toast } from "sonner";

interface LinkPartnerProps {
  readonly children: React.ReactNode;
  readonly handleSubmit?: (partnerId: string) => void;
  readonly otherProps?: any;
  readonly onDone?: () => void;
  readonly showLinkPartnerButton?: boolean;
  readonly contact_name: string;
  readonly onClick?: () => Promise<void>;
  readonly isSetCancellationCharges: boolean;
  readonly DealDetails: () => void;
  readonly setIsSetCancellationCharges: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export interface Partner {
  id: string;
  name: string;
  email: string;
  role: string;
  unique_id: any;
  productName: any;
  CustomerName: any;
  CustomerConatct: any;
  isCoustomer: any;
}

export default function SetCancellationChargesModal({
  handleSubmit,
  otherProps,
  showLinkPartnerButton = false,
  isSetCancellationCharges,
  setIsSetCancellationCharges,
  DealDetails,
}: LinkPartnerProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [allPartners, setAllPartners] = useState<Partner[]>([]);
  const [filteredPartners, setFilteredPartners] = useState<Partner[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  function useDebounce(
    callback: () => void,
    delay: number,
    dependencies: any[],
  ) {
    useEffect(() => {
      if (!dependencies.some((dep) => dep === "")) {
        const handler = setTimeout(callback, delay);
        return () => clearTimeout(handler);
      }
    }, [...dependencies, delay]);
  }

  // search contact number
  const fetchSearchResults = async () => {
    if (!value.trim()) {
      setAllPartners([]);
      setFilteredPartners([]);
      return;
    }

    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetLinkLeadListRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        searchQuery: value,
        purpose_id: otherProps?.purposeID,
      },
    };

    try {
      setLoading(true);
      const response = await getLinkLeadSearchList(payload);
      const partners =
        response?.data?.map((partner: any) => ({
          id: partner.id.toString(),
          name: partner?._contacts?.[0]?.contactName || "N/A",
          email: partner.email || "N/A",
          role: partner.status || "N/A",
          unique_id: partner?.unique_id || "N/A",
          productName: partner?._product?.name || "N/A",
          CustomerName: partner?._contacts?.[0]?.contactName || "N/A",
          CustomerConatct: partner?._contacts?.[0]?.contactNumber || "N/A",
          isCoustomer: partner?._contacts?.[0]?.customer || false,
        })) || [];

      setAllPartners(partners);
      setFilteredPartners(partners);
    } catch (error) {
      setError("Failed to fetch partners. Please try again.");
      setAllPartners([]);
      setFilteredPartners([]);
      toast.error("Failed to fetch partners. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // conatct Search
  useDebounce(
    () => {
      if (value) {
        fetchSearchResults();
      }
    },
    500,
    [value],
  );

  useEffect(() => {
    if (!value) {
      setFilteredPartners(allPartners);
      return;
    }

    const searchTerm = value?.toLowerCase();
    const filtered = allPartners?.filter(
      (partner: any) =>
        partner.name.toLowerCase().includes(searchTerm) ||
        partner?.CustomerConatct.toLowerCase().includes(searchTerm) ||
        partner?.id?.toLowerCase().includes(searchTerm),
    );
    setFilteredPartners(filtered);
  }, [value, allPartners]);

  const handlePartnerSelect = (partner: Partner) => {
    setSelectedPartner(partner);
    setValue(partner.name);
    setOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeSelectedPartner = () => {
    setSelectedPartner(null);
    setValue("");
  };

  const handleDoneClick = async () => {
    if (selectedPartner?.id) {
      try {
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);
        const payload: AxiosRequestConfig<any> = {
          headers: { Authorization: `Bearer ${authToken}` },
          data: {
            opportunity_id: selectedPartner?.id,
            deals_id: otherProps?.dealID,
          },
        };
        await LinkLeadAPI(payload);
        toast.success("Lead linked successfully!");
        DealDetails();
        setIsSetCancellationCharges(false);
      } catch (error) {
        toast.error("Failed to link Lead. Please try again.");
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setOpen(true);
  };

  return (
    <>
      {loading && <Spinner />}
      <AlertDialog
        open={isSetCancellationCharges}
        onOpenChange={setIsSetCancellationCharges}
      >
        <AlertDialogPortal>
          <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
          <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
            <AlertDialogHeader className="flex text-left gap-4 py-5 px-6">
              <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                Link Lead
              </AlertDialogTitle>
            </AlertDialogHeader>
            <div className="px-9 overflow-y-auto h-[500px]">
              <div className="space-y-6">
                <div className="">
                  <h2 className="text-sm font-medium leading-[16.94px] mb-4 text-gray-600">
                    Search Lead
                  </h2>
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <Input
                      value={value}
                      onChange={handleInputChange}
                      disabled={loading || !!selectedPartner?.id}
                      placeholder="Search by mobile number or lead ID"
                      className="pl-10 py-6 text-base bg-white rounded-[30px] border-gray-200"
                      type="text"
                    />
                    {open && value && (
                      <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-[300px] overflow-y-auto z-50">
                        <div className="p-0 relative">
                          {filteredPartners?.map((partner: any) => (
                            <button
                              key={partner?.id}
                              className="flex justify-between items-center w-full p-3 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                handlePartnerSelect(partner);
                              }}
                            >
                              <div className="flex flex-col gap-2 text-[14px]">
                                <div className="flex flex-row gap-2 items-center">
                                  <span className="font-medium text-left text-[#999999]">
                                    {partner?.unique_id} |{" "}
                                  </span>
                                  <span className="text-sm text-[14px] text-black-500 text-left">
                                    {partner?.productName}
                                  </span>
                                </div>
                                <div className="flex flex-row gap-2 items-center">
                                  <span className="font-medium text-[#999999] text-left">
                                    {partner?.CustomerName}
                                  </span>
                                  <span className="text-sm text-gray-500 text-left">
                                    {partner?.CustomerConatct}
                                  </span>
                                </div>
                              </div>

                              <div>
                                <Label
                                  className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                                  style={{
                                    borderRadius: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  {partner?.isCoustomer
                                    ? "Customer"
                                    : "Contact"}
                                </Label>
                              </div>
                            </button>
                          ))}
                        </div>
                        {/* <div className="sticky bottom-0 bg-white">
                                                    <button
                                                        onClick={() =>
                                                            navigate(
                                                                `/lead/partner/createlead?c_id=${otherProps?.contact_id}&relation=${product}`,
                                                            )
                                                        }
                                                        className="w-full font-medium text-sm font-inter text-[#ffffff] p-3 bg-[#165FE3]"
                                                    >
                                                        + Add Partner Lead
                                                    </button>
                                                </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {selectedPartner && (
                <div className="mt-4">
                  <div className="flex flex-row justify-between items-center">
                    <h3 className="text-sm text-gray-600 mb-2">You added</h3>
                    <div className="flex">
                      <button onClick={removeSelectedPartner}>
                        <CircleX className="h-4 w-4 mb-2 text-[#165FE3]" />
                      </button>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg p-4 border border-gray-200 relative">
                    <div className="">
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-2 text-[14px]">
                          <div className="flex flex-row gap-2 items-center">
                            <span className="font-medium text-left text-[#999999]">
                              {selectedPartner?.unique_id} |{" "}
                            </span>
                            <span className="text-sm text-[14px] text-black-500 text-left">
                              {selectedPartner?.productName}
                            </span>
                          </div>
                          <div className="flex flex-row gap-2 items-center">
                            <span className="font-medium text-[#999999] text-left">
                              {selectedPartner?.CustomerName}
                            </span>
                            <span className="w-[7px] h-[7px] bg-gray-400 rounded-full inline-block"></span>
                            <span className="text-sm text-gray-500 text-left">
                              {selectedPartner?.CustomerConatct}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`flex items-center ${
                            selectedPartner.role === "lead"
                              ? "text-yellow-600"
                              : "text-green-600"
                          }`}
                        >
                          {selectedPartner.role === "active" && (
                            <Check className="h-4 w-4 mr-1" />
                          )}
                          <div>
                            <Label
                              className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                              style={{
                                borderRadius: "10px",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              {selectedPartner?.isCoustomer
                                ? "Customer"
                                : "Contact"}
                            </Label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <AlertDialogFooter className="flex items-center gap-2.5 py-2 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
              <AlertDialogCancel className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                onClick={handleDoneClick}
                disabled={!selectedPartner?.id}
              >
                Done
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </>
  );
}
