import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { AxiosRequestConfig } from "axios";
import { Spinner } from "components";
import { Option } from "components/Form/form";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setPurposeId } from "redux/features/CreateDeal";
import {
  resetState,
  setProductPurpose,
} from "redux/features/CreateOpportunity";
import { setIsReferralMade } from "redux/features/CustomerRegistration";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { postExistingOpportunity } from "service/apis/Opportunities";
import { toast } from "sonner";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  handlePrevious: () => void;
  processState: any;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

interface PayloadDataType {
  contact_id: string;
  typeOfRefferal: string;
  refferedBy_staff: string;
  refferedBy_contacts: string;
  refferedBy_partner?: string;
  pipelineType: string;
  forex_branch: string;
  outlet_branch: string;
  exp_time: string;
  quotation_req: boolean;
  took_loan: boolean;
  mode: string;
  channel_of_interaction_id: string;
  items: Array<{
    subject: string;
    remarks: string;
    notes: string;
    assignedTo: string;
    product_id: string;
  }>;
}

export const L2Step7: React.FC<Step2Props> = ({
  setCurrentStep,
  setPageTitle,
  handlePrevious,
  processState,
  setProcessState,
}) => {
  const {
    product_purpose,
    remit_note,
    outlet_id,
    moneeflo_branch,
    medium_of_contact,
    quoteProduct,
    partner_reffered,
    employee_reffered,
    contact_reffered,
  } = useAppSelector((state) => state.CreateOpportunity);

  const dispatch = useAppDispatch();
  const { state }: any = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const { getQuoteFlow }: any = location.state || {};
  const [loading, setLoading] = useState<boolean>(false);

  // final lead create
  const handleSubmit = async ({ type = "" }) => {
    try {
      setLoading(true);
      const auth = localStorage.getItem("authToken");
      if (!auth) throw new Error("Authorization token not found");
      const authToken = JSON.parse(auth);

      const payloadData: PayloadDataType = {
        contact_id: state?.id as string,
        typeOfRefferal: "partner",
        refferedBy_staff: employee_reffered,
        refferedBy_contacts: contact_reffered,
        refferedBy_partner:
          medium_of_contact === "refferal" ? partner_reffered : "",
        pipelineType: "retail",
        forex_branch: moneeflo_branch,
        outlet_branch: outlet_id,
        exp_time: "1",
        quotation_req: true,
        took_loan: false,
        mode:
          processState?.Product === "Study Maintenance"
            ? "StudyMaintenance"
            : "Gift",
        channel_of_interaction_id: "12",
        items: [
          {
            subject: "",
            remarks: "",
            notes: remit_note,
            assignedTo: "",
            product_id: product_purpose,
          },
        ],
      };

      const payload: AxiosRequestConfig<PayloadDataType> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        data: payloadData,
      };

      const res = (await postExistingOpportunity(payload)) as any;
      if (res.status === 200) {
        setLoading(false);
        setLoading && setLoading(false);
        if (
          (res.data?.throwError as any)?.toLowerCase() ===
          "contact already exists"
        ) {
          toast.error("Contact already exists");
          return;
        }
        toast.success("Request sent for quotation");
        dispatch(setIsReferralMade(false));
        dispatch(setPurposeId(res?.data?.purposeID));
        dispatch(resetState());
        navigate("/l2createremittancequotation", {
          state: {
            contact_name: res?.data?.response?._contacts?.[0]?.contactName,
            product_id: res?.data?.response?.product_id,
            product_name: res?.data?.response?._product?.name,
            opportunity_id: res?.data?.response?.id,
            contact_id: res?.data?.response?.contacts_id,
            channel_of_interaction_id:
              res?.data?.response?.channel_of_interaction_id,
            note: res?.data?.response?.interactions_id?.[0]?.[0]?.note,
            remarks: "",
            ed_payment_mode: "",
            getQuoteFlow: getQuoteFlow,
            purpose_id: res?.data?.purposeID,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create Lead");
      setLoading && setLoading(false);
    }
  };

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === processState?.Product,
        )
      : [];
  }, [quoteProduct, processState, productPurposeOptions]);

  return (
    <>
      {loading && <Spinner />}
      <form className="w-full">
        <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-[450px]">
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                disabled={product_purpose}
              >
                {finalProductValue?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        product_purpose === tab.value
                          ? " focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex w-auto items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>

          <div className="flex flex-row gap-8 mt-5">
            <button
              className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
              onClick={() => {
                dispatch(setProductPurpose(""));
                setProcessState((prevState: any) => ({
                  ...prevState,
                  Product: "",
                }));
                setCurrentStep(1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>

            <button
              className={`w-[150px] text-[14px] text-[#fff] p-3 ${
                !product_purpose ? "bg-blue-200" : "bg-[#165FE3]"
              } rounded-lg text-center`}
              disabled={!product_purpose}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit({ type: "" });
              }}
            >
              Get Quote
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
