import { AxiosRequestConfig } from "axios";
import { Img, Input } from "components";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { resetState } from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { postExistingOpportunity } from "service/apis/Opportunities";
import { toast } from "sonner";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  handlePrevious: () => void;
  processState: any;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
}

interface PayloadDataType {
  contact_id: string;
  typeOfRefferal: string;
  refferedBy_staff: string;
  refferedBy_contacts: string;
  refferedBy_partner?: string;
  pipelineType: string;
  forex_branch: string;
  outlet_branch: string;
  exp_time: string;
  quotation_req: boolean;
  took_loan: boolean;
  mode: string;
  amount: string;
  country: string;
  channel_of_interaction_id: string;
  items: Array<{
    subject: string;
    remarks: string;
    notes: string;
    assignedTo: string;
    product_id: string;
  }>;
}

export const L2Step6: React.FC<Step2Props> = ({
  handlePrevious,
  setPageTitle,
  processState,
  setLoading,
  setProcessState,
}) => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [amount, setAmount] = useState<string>("");
  const [isCalcLoading, setIsCalcLoading] = useState<boolean>(false);

  const {
    product_Courrency_val,
    product_purpose,
    remit_note,
    outlet_id,
    moneeflo_branch,
    medium_of_contact,
    partner_reffered,
    employee_reffered,
    contact_reffered,
  } = useAppSelector((state) => state.CreateOpportunity);

  const handleCurrency = (e: any) => {
    setAmount(e);
  };

  const dispatch = useAppDispatch();

  // final lead create
  const handleSubmit = async () => {
    setLoading && setLoading(true);
    try {
      const auth = localStorage.getItem("authToken");
      if (!auth) throw new Error("Authorization token not found");
      const authToken = JSON.parse(auth);

      const payloadData: PayloadDataType = {
        contact_id: state?.id as string,
        typeOfRefferal: "partner",
        refferedBy_staff: employee_reffered,
        refferedBy_contacts: contact_reffered,
        refferedBy_partner:
          medium_of_contact === "refferal" ? partner_reffered : "",
        pipelineType: "retail",
        forex_branch: moneeflo_branch,
        outlet_branch: outlet_id,
        exp_time: "1",
        quotation_req: true,
        took_loan: false,
        mode: processState?.Mode ?? "",
        channel_of_interaction_id: "12",
        amount: amount,
        country: processState?.Country ?? "",
        items: [
          {
            subject: "",
            remarks: "",
            notes: remit_note,
            assignedTo: "",
            product_id: product_purpose,
          },
        ],
      };

      const payload: AxiosRequestConfig<PayloadDataType> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        data: payloadData,
      };

      const res: any = await postExistingOpportunity(payload);
      if (res?.status === 200) {
        setLoading && setLoading(false);
        if (
          (res.data?.throwError as string)?.toLowerCase() ===
          "contact already exists"
        ) {
          toast.error("Contact already exists");
          return;
        }
        dispatch(resetState());
        navigate(`/leads/contact/${res?.data?.response?.contacts_id}`, {
          state: {
            leadID: res?.data?.response?.id,
            requestQuote: true,
          },
        });
        toast.success("Request sent for quotation");
      }
    } catch (error) {
      toast.error("Failed to create Lead");
      setLoading && setLoading(false);
    }
  };

  return (
    <form className="w-full">
      <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
        <div className="flex flex-col w-[450px]">
          <div className="relative z-50">
            <Input
              name="input"
              onWheel={(e) => e.currentTarget.blur()}
              value={amount}
              onFocus={(e) => {
                e.target.select();
              }}
              onChange={(e: string) => {
                handleCurrency(e);
              }}
              placeholder="Enter amonut"
              className="flex w-full bg-background px-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-12 max-w-[440px] pl-[70px]"
              wrapClassName={`bg-white-A700 flex justify-between border  border-black-900_4c border-solid rounded-lg w-full sm:w-full relative`}
              type="number"
              prefix={
                <div className="absolute left-[11px] top-1/2 -translate-y-1/2">
                  <span className="bg-[#e1ebfc] px-2 py-1 rounded-[6px] text-blue-700_01 text-[15px]">
                    {product_Courrency_val}
                  </span>
                </div>
              }
              suffix={
                <div className="pr-[11px] mt-2">
                  {isCalcLoading && (
                    <TailSpin
                      height="20"
                      color="#3892F3"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperClass=""
                      visible={true}
                    />
                  )}
                </div>
              }
            />
          </div>
          <div className=" flex flex-col py-3 mt-5 px-8 gap-8 border border-white-A700 rounded-xl bg-[#F9F9F9]">
            <p className=" flex gap-2 font-normal text-sm leading-[14px] text-[#5e5e5e]">
              <Img src="/images/overview/exclamation.svg" />
              <span>You wil get the quote in 10-15 minutes</span>
            </p>
          </div>
          <div className="flex flex-row gap-8 mt-5">
            <button
              type="button"
              className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
              onClick={(e) => {
                e.preventDefault();
                handlePrevious();
                setPageTitle("Select Country");
                setProcessState((prevState: any) => ({
                  ...prevState,
                  Country: "",
                }));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>

            <button
              type="button"
              className={`w-[150px] text-[14px] text-[#fff] p-3 ${
                !amount ? "bg-blue-200" : "bg-[#165FE3]"
              } rounded-lg text-center`}
              disabled={!amount}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Request Quote
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
