import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useCallback, useEffect } from "react";
import { Option } from "components/Form/form";

import { setProductMode } from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  handlePrevious: () => void;
  processState: any;
  isCardStep: boolean;
}

// remittence mode
const remitProductModeOptions: Option[] = [
  { value: "ViaTransfer", label: "Via Transfer" },
  { value: "Flywire", label: "Flywire" },
  { value: "WesternUnion", label: "Western Union" },
  { value: "CIBC", label: "CIBC" },
  { value: "PayMyTuition", label: "PayMyTuition" },
];

export const L2Step2: React.FC<Step2Props> = ({
  setCurrentStep,
  setPageTitle,
  setProcessState,
  handlePrevious,
  processState,
  isCardStep,
}) => {
  const { product_mode } = useAppSelector((state) => state.CreateOpportunity);

  const dispatch = useAppDispatch();

  const handleProductMode = useCallback(
    (value: string) => {
      dispatch(setProductMode(value));
      setPageTitle("Education Loan");
      setProcessState((prevState: any) => ({
        ...prevState,
        Mode: value,
      }));
      if (processState?.Product === "Education Payment") {
        if (value === "ViaTransfer") {
          setCurrentStep((prev) => prev + 2);
        } else {
          setCurrentStep(4);
        }
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    },
    [setProductMode, setProcessState, setPageTitle, processState],
  );

  useEffect(() => {
    setPageTitle("Select Mode");
  }, [setPageTitle]);

  return (
    <form className="w-full">
      <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
        <div>
          <RadioGroup
            className="flex w-full flex-col w-[350px] gap-4 mt-2"
            value={product_mode}
            onValueChange={handleProductMode}
          >
            {remitProductModeOptions.map((tab) => (
              <label
                key={tab.value}
                htmlFor={tab.value}
                className={`${
                  product_mode === tab.value
                    ? "focus-within:border-blue-700 border-black-label"
                    : ""
                } flex w-auto items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
              >
                <RadioGroupItem
                  id={tab.value}
                  value={tab.value}
                  className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <p className="text-[14px] font-medium w-full">{tab.label}</p>
              </label>
            ))}
          </RadioGroup>
        </div>
        <button
          className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
          onClick={() => {
            if (isCardStep) {
              setCurrentStep(9);
            } else {
              handlePrevious();
            }
            dispatch(setProductMode(""));
            setPageTitle("Education Loan");
            setProcessState((prevState: any) => ({
              ...prevState,
              Mode: "",
            }));
            setPageTitle("Select product");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </button>
      </div>
    </form>
  );
};
