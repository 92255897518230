import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const get_currency = `${BASE_URL}:S0YxmlVR/currency`;
const get_info = `${BASE_URL}:MhhkaM6y/getInfo`;
const get_partner_info = `${BASE_URL}:MhhkaM6y/getPartnerDetail`;
const get_pdf = `${BASE_URL}:qyMtgPCE/generate_PDF`;
const get_remittance_pdf = `${BASE_URL}:qyMtgPCE/generate_Remittance_PDF`;
const get_gst = `${BASE_URL}:qyMtgPCE/getGST`;
const get_prepare_quote_notes = `${BASE_URL}:qyMtgPCE/notes`;
const put_prepare_quote_notes = `${BASE_URL}:qyMtgPCE/notesEdit`;
const get_search_Conatct_deatils = `${BASE_URL}:qyMtgPCE/search_contact`;
const request_quotation_l2_to_l1 = `${BASE_URL}:rBHQT4XV/req_quotation`;

// Get Contact deatils based on search
export type GetPrepareQuoteNotesRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  params: {
    phone_number: string;
  };
}>;

export type GetPrepareQuoteNotesResponseType = Partial<{}>;
export const getPrepareQuoteNotes = (
  payload: AxiosRequestConfig<GetPrepareQuoteNotesRequestType>,
): AxiosPromise<GetSearchConatctForPrepareQuoteResponseType[]> =>
  apis.get(get_prepare_quote_notes, payload);

// Get Contact deatils based on search
export type GetSearchConatctForPrepareQuoteRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  params: {
    phone_number: string;
  };
}>;

export type GetSearchConatctForPrepareQuoteResponseType = Partial<{}>;
export const getContactDetailsPrepareQuote = (
  payload: AxiosRequestConfig<GetSearchConatctForPrepareQuoteRequestType>,
): AxiosPromise<GetSearchConatctForPrepareQuoteResponseType[]> =>
  apis.get(get_search_Conatct_deatils, payload);

export type PutUpdatePrepareQuoteNotesReqType = Partial<{
  headers: {
    Authorization: string;
  };
  phone_number: string;
  notes: string;
}>;

export type PutPrepareQuoteNotesResponseType = Partial<{}>;

export const PutUpdatePrepareQuoteNotes = (
  payload: AxiosRequestConfig<PutUpdatePrepareQuoteNotesReqType>,
): AxiosPromise<PutPrepareQuoteNotesResponseType> => {
  return apis.put(put_prepare_quote_notes, payload);
};

// get country
export type GetCurrencyRequestType = Partial<{}>;

export type GetCurrencyResponseType = {
  country: string;
  currency: string;
};
export const getCurrency = (
  payload: AxiosRequestConfig<GetCurrencyRequestType>,
): AxiosPromise<GetCurrencyResponseType[]> => apis.get(get_currency, payload);

// get gst
export type GetGSTRequestType = Partial<{}>;

export type GetGSTResponseType = {
  gst: string;
};
export const getGST = (
  payload: AxiosRequestConfig<GetCurrencyRequestType>,
): AxiosPromise<GetCurrencyResponseType[]> => apis.get(get_gst, payload);

// get info
export type GetInfoRequestType = Partial<{}>;

export type GetInfoResponseType = {
  name: string;
};
export const getInfo = (
  payload: AxiosRequestConfig<GetCurrencyRequestType>,
): AxiosPromise<GetInfoResponseType[]> => apis.get(get_info, payload);

// get pdf url
export type GetPDFURLRequestType = Partial<{
  headers: { Authorization: string };

  customer_name: string;
  email: string;
  product_action: string;
  phone_number: string;
  passport: string;
  createdBy: string;
  products: {
    product_name: string;
    product_currency: string;
    product_quantity: string | number;
    product_action: string;
    product_rate: string | number;
    product_gst: string | number;
    product_service_charge: string | number;
    product_total: string | number;
  }[];
  data: {
    action: string;
    name: string;
    product_type: string;
    customer_name: string;
    total: string | number;
    service_charges: string | number;
    gst: string | number;
    grand_total: string | number;
    date: string;
    products: {
      product_name: string;
      product_qty: string | number;
      product_rate: string | number;
      product_total: string | number;
    }[];
  };
}>;

export type GetPDFURLResponseType = {
  download_url: string;
};
export const getPDFURL = (
  payload: AxiosRequestConfig<GetPDFURLRequestType>,
): AxiosPromise<GetPDFURLResponseType> => apis.post(get_pdf, payload);

// get Remittance pdf url
export type GetRemittancePDFURLRequestType = Partial<{
  headers: { Authorization: string };

  data: {
    date: string;
    customer_name: string;
    customer_number: string;
    purpose: string;
    currency: string;
    remitter_send: string;
    beneficiary_received: string;
    customer_rate: string;
    total_charges: string;
    gst: string;
    fb_charges: string;
    tt_charges: string;
    payable_amount: string;
    partner_name: string;
  };
  opportunity_id: string;
}>;

export type GetRemittancePDFURLResponseType = {
  download_url: string;
};
export const getRemittancePDFURL = (
  payload: AxiosRequestConfig<GetRemittancePDFURLRequestType>,
): AxiosPromise<GetRemittancePDFURLResponseType> =>
  apis.post(get_remittance_pdf, payload);

// get partner info

export type GetPartnerInfoRequestType = Partial<{
  headers: { Authorization: string };
  channels_id: string | number;
}>;

export type GetPartnerInfoResponseType = Partial<{}>;

export const getPartnerInfo = (
  payload: AxiosRequestConfig<GetPartnerInfoRequestType>,
): AxiosPromise<GetPartnerInfoResponseType> =>
  apis.get(get_partner_info, payload);

// request quotation l2 to l1

export type RequestQuotationL2ToL1RequestType = Partial<{
  headers: { Authorization: string };
  opportunity_id: string;
}>;

export type RequestQuotationL2ToL1ResponseType = Partial<{}>;

export const requestQuotationL2ToL1 = (
  payload: AxiosRequestConfig<RequestQuotationL2ToL1RequestType>,
): AxiosPromise<RequestQuotationL2ToL1ResponseType> =>
  apis.patch(request_quotation_l2_to_l1, payload);
