import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Img, Input, Text } from "components";
import {
  postAadharVerification,
  PostAadharVerificationRequestType,
  PostAadharOTPVerificationRequestType,
  postAadharOTPVerification,
  PostAadhaarVerifyFallbackRequestType,
  POSTAadhaarVerifyFallback,
} from "service/apis/PartnerRegistrationapi";
import Aadhaarpopup from "models/aadhaarpopup";
import { toast } from "sonner";
import { Spinner } from "components";
import { AxiosRequestConfig } from "axios";
import VerifyOTP from "models/VerifyOTP";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setUidNumber,
  incrementVerifystep,
} from "../../redux/features/PartnerRegistration";
import FormPopup from "pages/CustomerRegistration/PANandAadhaarPopup/formpopup";

type FooterProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    disableButton: boolean;
  }>;

const Step3 = forwardRef<{ verifyAadhar: () => void }, FooterProps>(
  ({ setCurrentStep }, ref) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [files, setFiles] = useState<{ [key: string]: File }>({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [type, setType] = useState("");
    const [showOtp, setShowOtp] = React.useState<boolean>(false);
    const [showaadhaarpopup, setShowaadhaarpopup] =
      React.useState<boolean>(false);
    const [verifyaadhaardetails, setVerifyaadhaardetails] = React.useState({
      masked_aadhaar_no: "",
      dob: "",
      address: "",
    });
    const dispatch = useAppDispatch();
    const { currverifystep } = useAppSelector(
      (state) => state.PartnerRegistration,
    );
    const adhaarFrontBinary = useAppSelector(
      (state) => state.PartnerRegistration.adhaarFrontBinary,
    );

    const adhaarBackBinary = useAppSelector(
      (state) => state.PartnerRegistration.adhaarBackBinary,
    );
    const uid_number = useAppSelector(
      (state) => state.PartnerRegistration.uid_number,
    );

    useImperativeHandle(ref, () => ({
      verifyAadhar,
    }));

    function verifyAadhar(): null | void {
      // get auth token from localstorage
      const auth: string | null = localStorage.getItem("authToken");
      if (auth === null) return null;
      const authToken = JSON.parse(auth);

      const channel_id = localStorage.getItem("child_channel_id");
      if (channel_id === null) return null;

      const req: AxiosRequestConfig<PostAadharVerificationRequestType> = {
        data: {
          uid_number: uid_number,
          channels_id: channel_id,
          aadhaar_front: adhaarFrontBinary,
          aadhaar_back: adhaarBackBinary,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      setLoading(true);
      postAadharVerification(req)
        .then((res: any) => {
          // if (res.data.data) {
          //   localStorage.setItem("request_id", res.data.data.requestId);
          // }
          // toast.success("OTP send your aadhaar linked mobile number");
          setLoading(false);
          setShowOtp(false);

          if (currverifystep === 2) {
            dispatch(incrementVerifystep());
          }
          setVerifyaadhaardetails({
            masked_aadhaar_no: "**** **** ****",
            dob: res.data.data.aadhaar.dob,
            address:
              res.data.data.aadhaar.address_line_1 +
              " " +
              res.data.data.aadhaar.address_line_2,
          });

          if (setCurrentStep) {
            // setCurrentStep((prev) => prev + 1);
            setShowaadhaarpopup(true);
          }
        })
        .catch(() => {
          setLoading(false);
          setType("aadhaar");
          setDialogOpen(true);
        });
    }

    const verifyAadhaarFallback = async (data: {
      aadhaar_number: string;
      name: string;
      date_of_birth: string;
      address: string;
    }) => {
      const auth: string | null = localStorage.getItem("authToken");
      if (auth === null) return;
      const authToken = JSON.parse(auth);

      const channel_id = localStorage.getItem("child_channel_id");
      if (channel_id === null) return;

      const req: AxiosRequestConfig<PostAadhaarVerifyFallbackRequestType> = {
        data: {
          aadhar_no: data.aadhaar_number,
          channels_id: channel_id,
          name: data.name,
          dob: data.date_of_birth,
          address: data.address,
          aadhar_back_card: adhaarBackBinary,
          aadhar_front_card: adhaarFrontBinary,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      setLoading(true);
      try {
        const response = await POSTAadhaarVerifyFallback(req);
        if (response.status === 200) {
          if (currverifystep === 2) {
            dispatch(incrementVerifystep());
          }
          setLoading(false);
          setCurrentStep?.((step) => step + 1);
          setDialogOpen(false);
        }
      } catch (error: any) {
        setLoading(false);
        toast.error(error?.response.data.message);
      }
    };

    function adhaarOTPVerify(otp: string): void | null {
      // get channel id from localstorage
      const channel_id = localStorage.getItem("child_channel_id");
      if (channel_id === null) return null;

      // get auth token from localstorage
      const auth: string | null = localStorage.getItem("authToken");
      if (auth === null) return null;
      const authToken = JSON.parse(auth);

      const request_id = localStorage.getItem("request_id");

      if (request_id === null) return null;

      const OTP = parseInt(otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5]);
      const req: AxiosRequestConfig<PostAadharOTPVerificationRequestType> = {
        data: {
          request_id: request_id,
          channels_id: channel_id,
          otp: OTP,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      setLoading(true);
      postAadharOTPVerification(req)
        .then((res: any) => {
          setLoading(false);
          setShowOtp(false);
          if (currverifystep === 2) {
            dispatch(incrementVerifystep());
          }
          setVerifyaadhaardetails({
            masked_aadhaar_no: "**** ****",
            dob: res.data.aadhaar.dob,
            address:
              res.data.aadhaar.address_line_1 +
              " " +
              res.data.aadhaar.address_line_2,
          });

          if (setCurrentStep) {
            // setCurrentStep((prev) => prev + 1);
            setShowaadhaarpopup(true);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        });
    }

    const handleFileChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
    ) => {
      const file = event.target.files?.[0];
      if (file && name) {
        const validExtensions = ["jpg", "jpeg", "png", "webp"];
        const fileExtension = file.name.split(".").pop()?.toLowerCase();

        if (fileExtension && validExtensions.includes(fileExtension)) {
          setFiles((prev) => ({ ...prev, [name]: file }));
          const reader = new FileReader();

          reader.onload = () => {
            if (name === "aadhaar_front") {
              dispatch(setadhaarFront(file));
              dispatch(setadhaarFrontBinary(reader.result as string));
            } else if (name === "aadhaar_back") {
              dispatch(setadhaarBack(file));
              dispatch(setadhaarBackBinary(reader.result as string));
            }
          };

          reader.readAsDataURL(file);
        } else {
          toast.error("Only JPG, JPEG, PNG, and WEBP files are allowed.");
        }
      }
    };

    return (
      <div className="flex">
        {loading && <Spinner />}
        <FormPopup
          defaultValue={uid_number}
          entityType="partner"
          onSubmit={verifyAadhaarFallback}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          type={type}
        />
        <div className="relative flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start md:max-w-[] md:min-w-full md:px-5 w-[53%] md:w-full">
          {showOtp && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <VerifyOTP
                setCurrentStep={setCurrentStep}
                setShowOtp={setShowOtp}
                verifyOTP={adhaarOTPVerify}
                resendOTP={verifyAadhar}
                title="Sent to your aadhaar linked mobile number"
              />
            </div>
          )}
          {showaadhaarpopup && setCurrentStep && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <Aadhaarpopup
                setCurrentStep={setCurrentStep}
                setShowaadhaarpopup={setShowaadhaarpopup}
                aadhaarverifydetails={verifyaadhaardetails}
              />
            </div>
          )}
          <div className="flex flex-col pl-10 gap-8 w-2/3 items-start justify-start sm:w-full">
            <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              <Text className="text-sm font-medium leading-[17px]">
                Enter Aadhar card number
              </Text>
              <Input
                name="input"
                value={uid_number}
                onChange={(e: string) => dispatch(setUidNumber(e))}
                placeholder="Enter 12-digit Aadhar card number"
                className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                type="number"
              ></Input>
            </div>
            <div className="flex flex-col relative gap-2.5 items-start justify-center w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              <Text className="text-sm font-medium leading-[17px]">
                Upload Aadhaar front
              </Text>
              <div
                className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files["aadhaar_front"]?.name && "bg-[#F2F7FF]"}`}
              >
                <div className="flex gap-3 items-center overflow-hidden">
                  <Img src="/images/remit/file.svg" alt="" />
                  <p className="truncate">
                    {files["aadhaar_front"]?.name
                      ? files["aadhaar_front"]?.name
                      : "No file choosen"}
                  </p>
                </div>
                <label
                  htmlFor="fileupload"
                  className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                >
                  {files["aadhaar_front"]?.name ? "Re-upload" : "Upload"}
                </label>
                <input
                  name="fileupload"
                  type="file"
                  id="fileupload"
                  className="hidden"
                  onChange={(event) => handleFileChange(event, "aadhaar_front")}
                  accept=".jpg, .jpeg, .png, .webp"
                />
              </div>
            </div>
            <div className="flex flex-col relative gap-2.5 items-start justify-center w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0 "} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              <Text className="text-sm font-medium leading-[17px]">
                Upload Aadhaar back
              </Text>
              <div
                className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files["aadhaar_back"]?.name && "bg-[#F2F7FF]"}`}
              >
                <div className="flex gap-3 items-center overflow-hidden">
                  <Img src="/images/remit/file.svg" alt="" />
                  <p className="truncate">
                    {files["aadhaar_back"]?.name
                      ? files["aadhaar_back"]?.name
                      : "No file choosen"}
                  </p>
                </div>
                <label
                  htmlFor="fileupload1"
                  className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                >
                  {files["aadhaar_back"]?.name ? "Re-upload" : "Upload"}
                </label>
                <input
                  name="fileupload1"
                  type="file"
                  id="fileupload1"
                  className="hidden"
                  onChange={(event) => handleFileChange(event, "aadhaar_back")}
                  accept=".jpg, .jpeg, .png, .webp"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-solid flex sm:flex-1 flex-col md:h-auto md:hidden items-start justify-start md:px-5 sm:w-full">
          <div className="w-full flex flex-col gap-2">
            <h3 className="bg-[#F5F9FF] text-blue-700 py-3 px-4 rounded-md">
              Verify the details
            </h3>
            <div className="flex py-2 px-4 items-center gap-5">
              <Img src="/images/overview/exclamation.svg" />
              <p className=" text-sm text-black-label">
                Aadhar card is authorized as per document{" "}
              </p>
            </div>
            <div className="flex py-2 px-4 items-center gap-5">
              <Img src="/images/overview/exclamation.svg" />
              <p className=" text-sm text-black-label">
                Both the front and the back of the Aadhaar Card should be
                visible
              </p>
            </div>
            <div className="flex py-2 px-4 items-center gap-5">
              <Img src="/images/overview/exclamation.svg" />
              <p className=" text-sm text-black-label">
                The image uploaded is completely in the frame
              </p>
            </div>
            <div className="flex py-2 px-4 items-center gap-5">
              <Img src="/images/overview/exclamation.svg" />
              <p className=" text-sm text-black-label">
                Make sure that your details are clearly visible
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default Step3;
