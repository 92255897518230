import {
  Sheet,
  SheetContent,
  SheetOverlay,
  SheetPortal,
} from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useIsMobile } from "@/hooks/use-mobile";
import { OpportunityContactType } from "@/lib/validations/schema";
import { AxiosRequestConfig } from "axios";
import { Eye, RefreshCcw, XIcon } from "lucide-react";
import { useEffect, useState } from "react";
import {
  getLeadDocs,
  GetLeadDocsReqType,
  getLeadMilestonedeatils,
  GetLeadMilestoneRequestType,
  RequestBookRate,
  RequestBookRateReqType,
} from "service/apis/Opportunities";
import { LeadOverviewTabs } from "./utils/featureRule";
import MilestoneTracker from "./milestone";
import { Button } from "@/components/ui/button";
import DocumentReview from "pages/OrderDetails/verifydocuments";
import {
  postOrderDocuments,
  POSTOrderDocumentsRequestType,
} from "service/apis/CreateOrderapi";
import { toast } from "sonner";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";
import { useNavigate } from "react-router-dom";
import { setPurposeId } from "redux/features/CreateDeal";
import { useDispatch } from "react-redux";

interface LeadOverViewtProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  rowData: OpportunityContactType | any;
  getOpportunityList?: () => void;
}
interface IDocList {
  id: string;
  doclist_id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
  url: string;
  file_type: string;
}

export const LeadOverView: React.FC<LeadOverViewtProps> = ({
  isOpen,
  onOpenChange,
  rowData,
  getOpportunityList,
}) => {
  const [leadMilestoneData, setLeadMilestoneData] = useState<any>({});
  const [activeTab, setActiveTab] = useState("tracking");
  const [isDocOpen, setIsDocOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState<string>("");
  const [docList, setDocList] = useState<IDocList[]>([]);
  const orderCreated = leadMilestoneData?.milestone?.find(
    (item: any) => item.milestone === "Order Created",
  );

  useEffect(() => {
    if (!isOpen) {
      setActiveTab("tracking");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!rowData?.id) return;

    const fetchLeadMilestoneData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) return;

        const payload: AxiosRequestConfig<GetLeadMilestoneRequestType> = {
          headers: { Authorization: `Bearer ${JSON.parse(authToken)}` },
          params: { opportunity_id: rowData.id },
        };

        const response = await getLeadMilestonedeatils(payload);
        if (response?.data) {
          setLeadMilestoneData(response?.data);
          const order_id = response?.data?.milestone?.find(
            (milestone: any) => milestone.milestone === "Order Created",
          )?.id;
          if (order_id) setOrderId(order_id);
        }
      } catch (error) {
        console.error("Error fetching lead milestone details:", error);
      }
    };

    fetchLeadMilestoneData();
  }, [rowData?.id]);

  const fetchLeadDocData = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) return;

      const payload: AxiosRequestConfig<GetLeadDocsReqType> = {
        headers: { Authorization: `Bearer ${JSON.parse(authToken)}` },
        params: {
          opportunity_id: rowData?.id,
        },
      };

      const response: any = await getLeadDocs(payload);
      if (response?.data) {
        setDocList(
          response?.data?.map((doc: any) => {
            return {
              name: doc?.filename,
              id: doc?.doc_id,
              url: doc?.file?.url,
              file_type: doc?.file?.type,
            };
          }),
        );
      }
    } catch (error) {
      console.error("Error fetching lead milestone details:", error);
    }
  };

  const docs_status = docList?.every((doc) => doc.status === "Verified");

  useEffect(() => {
    if (activeTab === "documents") {
      if (orderId) {
        setDocList([]);
        fetchLeadDocData();
      }
    }
  }, [activeTab]);

  // add book rate request
  const fetchBookRateData = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) return;

      const payload: AxiosRequestConfig<RequestBookRateReqType> = {
        headers: { Authorization: `Bearer ${JSON.parse(authToken)}` },
        data: {
          opportunity_id: rowData?.id as string,
        },
      };

      const response: any = await RequestBookRate(payload);
      if (response?.status === 200) {
        toast.success("Book rate request submitted successfully!");
        await getOpportunityList?.();
        onOpenChange(false);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          "Failed to fetch book rate. Please try again.",
      );
    }
  };
  const handleBookRate = () => {
    dispatch(setPurposeId(rowData?.purpose_id));

    if (
      rowData?.product === "Education payment" ||
      rowData?.product === "GIC Letter"
    ) {
      fetchBookRateData();
    } else {
      navigate("/bookdeal", {
        state: {
          purposeID: rowData?.purpose_id || "",
          took_loan: "",
          productMode: "",
          product_purpose: "",
          selectedProductname: "",
          channel_of_interaction_id: rowData?.channel_of_interaction_id || "",
          opportunityId: rowData?.id,
          contactId: rowData?.contacts_id,
        },
      });
    }
  };

  if (!rowData) return null;
  return (
    <>
      {isMobile ? (
        <> </>
      ) : (
        <Sheet open={isOpen} onOpenChange={onOpenChange}>
          <SheetPortal>
            <SheetOverlay className="" />
            <SheetContent
              asChild={false}
              className=" bg-white-A700 w-[40%] sm:w-full p-2.5 sm:p-2 gap-3 flex h-full overflow-auto flex-col"
            >
              <button
                onClick={() => onOpenChange(false)}
                className="w-full hidden sm:flex justify-end"
              >
                <XIcon />
              </button>
              <div className="p-2.5 gap-[30px] flex rounded-lg flex-col w-full">
                <div className="flex w-full justify-between">
                  <div className="flex flex-col gap-[18px] w-full">
                    <div className="flex gap-2">
                      <div className="flex items-start gap-2">
                        <div className="flex flex-col">
                          <div className="flex flex-col gap-1">
                            <h4 className="text-xl leading-[30px] capitalize text-black-label after:pl-2 font-semibold after:content-[''] after:w-[4px] after:h-[4px]">
                              {rowData?.name}
                            </h4>
                            <div className="flex items-center gap-2">
                              <p className="text-sm leading-[21px] text-[#999999] font-normal">
                                {rowData?.product}
                              </p>
                              {rowData?.mode_opp && (
                                <p className="text-sm leading-[21px] text-[#999999] font-normal">
                                  | {rowData?.mode_opp} |
                                </p>
                              )}
                              {rowData?.took_loan && (
                                <p className="text-sm leading-[21px] text-[#999999] font-normal">
                                  {rowData?.took_loan}
                                </p>
                              )}
                              {rowData?.amount && (
                                <div className="flex items-center gap-2 bg-[#F3F7FF] rounded-lg py-0.5 px-1.5">
                                  <p className="text-sm -tracking-[4%] leading-[110%] text-black-label font-normal">
                                    {rowData?.currency}
                                  </p>
                                  <p className="text-sm -tracking-[4%] leading-[110%] text-black-label font-normal">
                                    {rowData?.amount}
                                  </p>
                                  <p className="text-sm -tracking-[4%] leading-[110%] text-black-label font-normal">
                                    {rowData?.country}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          <p className="text-sm leading-[21px] text-[#999999] font-normal">
                            Lead ID {rowData.id} | {rowData?.createDate} by{" "}
                            {rowData?.createdBy || "N/A"}
                          </p>
                          <p className="text-sm leading-[21px] text-[#999999] font-normal flex items-center gap-2">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.8845 13.769C10.6867 13.769 13.769 10.6867 13.769 6.8845C13.769 3.0823 10.6867 0 6.8845 0C3.0823 0 0 3.0823 0 6.8845C0 10.6867 3.0823 13.769 6.8845 13.769Z"
                                fill="#F1F1F1"
                              />
                              <path
                                d="M6.88637 3.40234C5.46128 3.40234 4.30469 4.55894 4.30469 5.98403C4.30469 7.38158 5.39932 8.51753 6.85195 8.55883C6.87261 8.55883 6.90014 8.55883 6.91391 8.55883C6.92768 8.55883 6.94833 8.55883 6.9621 8.55883C6.96899 8.55883 6.97587 8.55883 6.97587 8.55883C8.36654 8.51064 9.46118 7.38158 9.46806 5.98403C9.46806 4.55894 8.31147 3.40234 6.88637 3.40234Z"
                                fill="#333333"
                              />
                              <path
                                d="M11.5541 11.9415C10.3287 13.0706 8.69018 13.7659 6.88644 13.7659C5.0827 13.7659 3.44419 13.0706 2.21875 11.9415C2.38398 11.315 2.83147 10.7436 3.4855 10.303C5.36497 9.05004 8.42169 9.05004 10.2874 10.303C10.9483 10.7436 11.3889 11.315 11.5541 11.9415Z"
                                fill="#333333"
                              />
                            </svg>
                            {rowData?.assignedTo || "N/A"}{" "}
                            <span className="text-[#999999] font-normal text-xs leading-[13.2px]">
                              •
                            </span>{" "}
                            Branch : {rowData?.location || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* For Book Rate L2 */}
              {checkLevel(LEVEL_2) && leadMilestoneData?.quotation_sent_l2 && (
                <div>
                  {rowData?.req_book_rate ? (
                    <button
                      className="w-full bg-[#F9F9F9] text-[#666666] text-center py-3 px-6 rounded-lg cursor-not-allowed text-[14px] flex items-center justify-center gap-2"
                      disabled
                    >
                      Book rate request sent
                      <span className="w-[18px] h-[18px] flex items-center justify-center bg-green-500 text-[#fff] rounded-full">
                        ✓
                      </span>
                    </button>
                  ) : (
                    <button
                      className="w-full bg-[#165FE3] text-[#fff] text-center py-3 px-6 rounded-lg text-[14px] hover:bg-[#1449b2] transition-all"
                      onClick={handleBookRate}
                    >
                      Book Rate
                    </button>
                  )}
                </div>
              )}
              {/* Tabs */}
              <div className="flex h-full overflow-auto bg-white-A700 p-2.5 rounded-lg  ">
                <Tabs
                  value={activeTab}
                  onValueChange={(value) => {
                    if (value === "documents") {
                      fetchLeadDocData();
                    }
                    setActiveTab(value);
                  }}
                  className="w-full"
                >
                  <TabsList className="h-[44px] w-fit bg-navbar-menu-bg sm:w-full flex sm:items-center rounded-lg gap-2">
                    {LeadOverviewTabs.map((tab) => (
                      <TabsTrigger
                        disabled={!tab.enabled}
                        key={tab.value}
                        value={tab.value}
                        className={`py-3 px-5 text-sm h-[44px] sm:min-w-[50px] min-w-[200px] font-semibold ${tab.value === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                      >
                        {tab.label}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                  <TabsContent value="tracking">
                    <MilestoneTracker
                      lead_id={rowData?.id}
                      milestones={leadMilestoneData?.milestone}
                    />
                  </TabsContent>
                  <TabsContent value="documents">
                    {docList.length === 0 ? (
                      <div className="flex flex-row justify-between items-center gap-4 w-full p-[10px] mb-4 rounded-[10px]">
                        <h1 className="text-[14px] text-[#666666]">
                          No documents availbale
                        </h1>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-between gap-8 w-full pt-4">
                        <div
                          style={{
                            backgroundColor: docs_status
                              ? "#F1F1F1"
                              : "#FFEBEB",
                          }}
                          className="flex flex-row justify-between items-center gap-4 w-full p-[10px] mb-4 rounded-[10px]"
                        >
                          <h1 className="text-[14px] text-[#666666]">
                            Document status:
                          </h1>
                          <span
                            style={{
                              color: docs_status ? "#00A82D" : "#FF0000",
                            }}
                            className="text-base"
                          >
                            {docs_status ? "Verified" : "Unverfied"}
                          </span>
                        </div>

                        {docList.map((doc) => {
                          return (
                            <div key={doc.id} className="flex justify-between">
                              <h1>{doc.name} </h1>
                              <div className="flex gap-4">
                                <Button
                                  className="text-blue-700"
                                  onClick={() => {
                                    setIsDocOpen(true);
                                  }}
                                  disabled
                                >
                                  Re-Upload
                                  <RefreshCcw className="h-4 w-4 mr-2 border-none" />
                                </Button>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  className="text-blue-600"
                                  onClick={() => {
                                    window.open(doc.url, "_blank");
                                  }}
                                >
                                  <Eye className="h-4 w-4" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </TabsContent>
                </Tabs>
              </div>
              {orderCreated && (
                <div
                  className="text-sm font-semibold text-blue-600 underline cursor-pointer text-right ml-auto hover:text-blue-800"
                  onClick={() => navigate(`/orderdetails/${orderCreated?.id}`)}
                >
                  View Order Details
                </div>
              )}
            </SheetContent>
          </SheetPortal>
        </Sheet>
      )}
    </>
  );
};
