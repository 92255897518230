import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Option } from "pages/Opportunities/types";
import { useFormContext } from "react-hook-form";
import { FormData } from ".";
const paymentMode: Option[] = [
  { value: "via_transfer", label: "Via Transfer" },
  { value: "flywire", label: "Flywire" },
  { value: "western_union", label: "Western Union" },
  { value: "cibc", label: "CIBC" },
  { value: "paymytuition", label: "PayMyTuition" },
];

export default function Step2() {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();
  return (
    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
      <RadioGroup
        className="flex w-full flex-col gap-4"
        value={watch("mode")}
        onValueChange={(value) => {
          setValue("mode", value);
          setValue("currentStep", 3);
        }}
      >
        {paymentMode?.map((tab: { label: string; value: string }) => (
          <label
            key={tab?.value}
            htmlFor={tab?.value}
            className={`${
              watch("mode") === tab?.value
                ? "border-blue-700"
                : "border-black-label"
            } flex w-auto items-center rounded-lg border p-3 space-x-2`}
          >
            <RadioGroupItem
              value={tab?.value}
              id={tab?.value}
              className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
            />
            <p className="text-[14px] font-medium w-full">{tab?.label}</p>
          </label>
        ))}
      </RadioGroup>
    </div>
  );
}
