import { apis } from "../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";
const AddBeneficiaryEntry = `${BASE_URL}:M4Re3bq8:UAT/addBeneficiary`;
const staff_purpose = `${BASE_URL}:W9rFQOtq/purpose`;
const staff_order = `${BASE_URL}:M4Re3bq8/D1_order_v2`;
const staff_order_new = `${BASE_URL}:M4Re3bq8/D1_order_v3`;
const get_country = `${BASE_URL}:S0YxmlVR/currency`;
const staff_customer_discloser = `${BASE_URL}:0l2j--SC/discloser`;
const staff_customer = `${BASE_URL}:NcXLAC78/customers-order`;
const moneeflo_customer = `${BASE_URL}:0l2j--SC/moneeflo_transaction`;
const staff_channel = `${BASE_URL}:MhhkaM6y/channels`;
const document_mapping = `${BASE_URL}:TeunTrJI/document_mapping`;
const order_calc = `${BASE_URL}:7K7mNQ3j/order-calc`;
const staff_deal = `${BASE_URL}:6JkkZBcl/allDeals`;
const get_filter_orders = `${BASE_URL}:M4Re3bq8/orders_0`;
const add_order_notes = `${BASE_URL}:M4Re3bq8/EditOrderDetail`;
const fetch_passport = `${BASE_URL}:M4Re3bq8/getPassPortInfo`;
const get_kyc_docs = `${BASE_URL}:M4Re3bq8/customerDocs`;
const upload_all_docs = `${BASE_URL}:M4Re3bq8/uploadCutomerDoc`;
const edit_payment_order = `${BASE_URL}:M4Re3bq8/edit_payment_status_1`;

const ORDER_DOCUMENTS_ENDPOINT = {
  approve: `${BASE_URL}:M4Re3bq8/reuploadOrderDocs`,
  milestone: `${BASE_URL}:M4Re3bq8/order_milestone`,
  payment_status: `${BASE_URL}:M4Re3bq8/edit_payment_status_1`,
  challan_generate: `${BASE_URL}:M4Re3bq8/generate_challan_0`,
};

// get filters Order
export type GetOrdersReqType = Partial<{
  headers: {
    Authorization: string;
  };
  page: string;
  service_partner_id: Number;
  per_page: Number;
  start: null;
  end: string[];
  status: string[];
  payment_status: string[];
  q: string;
}>;

export const GetFilterOrders = (
  payload: AxiosRequestConfig<GetOrdersReqType>,
): AxiosPromise<GetOrdersReqType> => {
  return apis.get(get_filter_orders, payload);
};

export type POSTAddNotesOrderRequestType = Partial<{
  headers: {
    Authorization: string;
  };

  order_id: number;
  note: string;
  tcs_amount: number;
  doc_status: string;
}>;

export type POSTAddNotesOrderResponseType = Partial<{}>;

export const AddOrderNotesAPI = (
  payload: AxiosRequestConfig<POSTAddNotesOrderRequestType>,
): AxiosPromise => apis.patch(add_order_notes, payload);

// updated payment
export type POSTeditPaymentStatusRequestType = Partial<{
  headers: {
    Authorization: string;
  };

  order_id: Number;
  ack_no: Number;
  status_AD2: string;
  notes: string;
}>;

export const PaymentStatusAPI = (
  payload: AxiosRequestConfig<POSTeditPaymentStatusRequestType>,
): AxiosPromise => apis.patch(edit_payment_order, payload);

// get channel/ partner
export type GetChannelRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetChannelResponseType = Partial<{
  items: {
    approval_status: string;
    status: string;
    id: number;
    name: string;
  }[];
}>;
export const getChannel = (
  payload: AxiosRequestConfig<GetChannelRequestType>,
): AxiosPromise<GetChannelResponseType> => apis.get(staff_channel, payload);

// get purpose
export type GetPurposeRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetPurposeResponseType = {
  name: string;
  id: number;
};
export const getPurpose = (
  payload: AxiosRequestConfig<GetPurposeRequestType>,
): AxiosPromise<GetPurposeResponseType[]> => apis.get(staff_purpose, payload);
// fetch country

export type GetCountryRequestType = Partial<{}>;
export type GetCountryResponseType = {
  country: string;
  currency: string;
};
export const getCountry = (
  payload: AxiosRequestConfig<GetCountryRequestType>,
): AxiosPromise<GetCountryResponseType[]> => apis.get(get_country, payload);

// create order
export type POSTCreateOrderRequestType = {
  headers: { Authorization: string };
};
export type POSTCreateOrderResponseType = {
  result: {
    id: string;
    payable_amount: string;
  };
  remaining_orders: number;
};
export const postcreateOrder = (
  payload: AxiosRequestConfig<POSTCreateOrderRequestType>,
): AxiosPromise<POSTCreateOrderResponseType> => apis.post(staff_order, payload);

export const postcreateOrderNew = (
  payload: AxiosRequestConfig<POSTCreateOrderRequestType>,
): AxiosPromise<POSTCreateOrderResponseType> =>
  apis.post(staff_order_new, payload);

// get constomer
export type GetCustomerRequestType = Partial<{
  headers: { Authorization: string };
  q: string;
}>;

export type GetCustomerResponseType = {
  full_name: string;
  email: string;
  id: string;
  phone_number: string;
  address_line_1: string;
  beneficiary: any;
};
export const getCustomer = (
  payload: AxiosRequestConfig<GetCustomerRequestType>,
): AxiosPromise<GetCustomerResponseType> => apis.get(staff_customer, payload);

// for moneeflo customer details
export type GetMoneefloCustomerRequestType = Partial<{
  headers: { Authorization: string };
  pan_no: string;
}>;

export type GetMoneefloCustomerResponseType = Partial<{}>;
export const getMoneefloCustomer = (
  payload: AxiosRequestConfig<GetMoneefloCustomerRequestType>,
): AxiosPromise<GetMoneefloCustomerResponseType> =>
  apis.get(moneeflo_customer, payload);

// get disclose
export type GetDiscloseRequestType = Partial<{
  headers: { Authorization: string };
  customers_id: string;
  deals_id: string;
}>;
export type GetDiscloseResponseType = {
  purpose: string;
  amount: string;
  order_id: number;
};
export const getDisclose = (
  payload: AxiosRequestConfig<GetDiscloseRequestType>,
): AxiosPromise<GetDiscloseResponseType[]> =>
  apis.get(staff_customer_discloser, payload);

// post disclose
export type PostDiscloseRequestType = Partial<{
  headers: { Authorization: string };
  customers_id: string;
  amount: string;
  date: string;
  supporting_document: string;
  purpose: string;
}>;
export type PostDiscloseResponseType = {};
export const postDisclose = (
  payload: AxiosRequestConfig<PostDiscloseRequestType>,
): AxiosPromise<PostDiscloseResponseType> =>
  apis.post(staff_customer_discloser, payload);

// get document mapping
export type GetDocumentMappingRequestType = Partial<{
  headers: { Authorization: string };
  params: { purpose_id: string; service_provider: string };
}>;

export type GetDocumentMappingResponseType = {
  id: number;
  doc_list_id: string;
  doc_name: string;
};

export const getDocumentMapping = (
  payload: AxiosRequestConfig<GetDocumentMappingRequestType>,
): AxiosPromise<GetDocumentMappingResponseType[]> =>
  apis.get(document_mapping, payload);

// post order calc

export type PostOrderCalcRequestType = Partial<{
  headers: { Authorization: string };
  purpose_id: number;
  amount: number;
  currency: string;
  customers_id: number;
  type: string;
  created_by: string;
  tcs_type: number;
  discount: number;
  add_on_rates: number;
  mail: string;
  service_partner_id: string | number;
  settlement_rates: string | number;
  tt_charges: string | number;
  foreign_bank_charges: string | number;
  deal_id: string | number;
  l1_commission: string | number;
  l2_commission: string | number;
  l3_commission: string | number;
}>;

export type PostOrderCalcResponseType = {
  payable_amount: string;
  base_amount: string;
  chain: {
    l1: string;
    l2: string;
    l3: string;
    branch: string;
  };
  customer_rate: string;
  tcs: string;
  gst: string;

  commission: {
    l1: number;
    l2: number;
    l3: number;
  };

  cumulative_commission: {
    l1: number;
    l2: number;
    l3: number;
  };

  cumulative_commission_without_addon: {
    l1: number;
    l2: number;
    l3: number;
  };

  profit: {
    l1: string;
    l2: string;
    l3: string;
  };

  tt_charges: number;
  foreign_bank_charges: number;
  tt_charges_with_gst: number;
  foreign_bank_charges_with_gst: number;
  discloser: string;
  settlement_rates: string;
  service_partner: string;
  service_partner_mode: string;
};

export const postOrderCalc = (
  payload: AxiosRequestConfig<PostOrderCalcRequestType>,
): AxiosPromise<PostOrderCalcResponseType> => apis.post(order_calc, payload);

// get deals
export type GetAllDealRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetAllDealResponseType = {
  id: number;
  status: string;
  utilization_status: string;
};
export const getAllDeals = (
  payload: AxiosRequestConfig<GetPurposeRequestType>,
): AxiosPromise<GetAllDealResponseType[]> => apis.get(staff_deal, payload);

export type postBeneficiaryEntryRequestType = Partial<{
  headers: { Authorization: string };
  name: string;
  addr_line_1: string;
  addr_line_2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  account_number: string;
  bank_name: string;
  bank_addr: string;
  bank_country: string;
  swift_code: string;
  routing_number: string;
  transit_code: string;
  bsbcode0: string;
  short_code: string;
  national_id: string;
  iban_no: string;
  correspondant_bank_name: string;
  correspondant_bank_no: string;
  Nickname: string;
  beneficiaryType: string;
  customers_id: string;
  reference_no: string;
  BSB: string;
  SORT: string;
  TRANSIT: string;
  OTHER: string;
  relation: string;
}>;

// post Beneficiary Entry
export const postBeneficiaryEntry = (
  payload: AxiosRequestConfig<postBeneficiaryEntryRequestType>,
): AxiosPromise<PostOrderCalcResponseType> =>
  apis.post(AddBeneficiaryEntry, payload);

// get kyc docs

export type GetKYCDocsRequestType = Partial<{
  headers: { Authorization: string };
  customers_id: string;
}>;

export type GetKYCDocsResponseType = Partial<{
  customer_kyc: any;
}>;

export const getKYCDocs = (
  payload: AxiosRequestConfig<GetKYCDocsRequestType>,
): AxiosPromise<GetKYCDocsResponseType> => apis.get(get_kyc_docs, payload);

// upload all docs

export type UploadAllDocsRequestType = Partial<{
  headers: { Authorization: string };
  customers_id: string;
  docs: FormData;
}>;

export type UploadAllDocsResponseType = Partial<{}>;

export const uploadAllDocs = (
  payload: AxiosRequestConfig<UploadAllDocsRequestType>,
): AxiosPromise<UploadAllDocsResponseType> =>
  apis.post(upload_all_docs, payload);

export type GetPassportRequestType = Partial<{
  headers: { Authorization: string };
  passport_front: string;
  passport_back: string;
}>;

export type GetPassportResponseType = Partial<{}>;

export const getPassportData = (
  payload: AxiosRequestConfig<GetPassportRequestType>,
): AxiosPromise<GetPassportResponseType> => apis.post(fetch_passport, payload);

export type POSTOrderDocumentsRequestType = Partial<{
  headers: { Authorization: string };
  order_id: string;
  doc_list_id: string | null;
  file: string | null;
  status: string;
  file_name: string | null;
  filename: string | null;
}>;
export type POSTOrderDocumentsResponseType = Partial<{}>;

export const postOrderDocuments = (
  payload: AxiosRequestConfig<POSTOrderDocumentsRequestType>,
): AxiosPromise<POSTOrderDocumentsResponseType> =>
  apis.post(ORDER_DOCUMENTS_ENDPOINT.approve, payload);

// get order milestone
export type GetOrderMilestoneRequestType = Partial<{
  headers: { Authorization: string };
  order_id: string;
}>;
export type GetOrderMilestoneResponseType = Partial<{}>;

export const getOrderMilestone = (
  payload: AxiosRequestConfig<GetOrderMilestoneRequestType>,
): AxiosPromise<GetOrderMilestoneResponseType> =>
  apis.get(ORDER_DOCUMENTS_ENDPOINT.milestone, payload);

// generate challan

export type GenerateChallanRequestType = Partial<{
  headers: { Authorization: string };
  order_id: string;
  data: {};
}>;

export type GenerateChallanResponseType = Partial<{}>;

export const generateChallan = (
  payload: AxiosRequestConfig<GenerateChallanRequestType>,
): AxiosPromise<GenerateChallanResponseType> =>
  apis.post(ORDER_DOCUMENTS_ENDPOINT.challan_generate, payload);
