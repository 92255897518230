export const handleSectionNavigation = (id: string) => {
  const element = document.getElementById(id);
  const offset = 45;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element?.getBoundingClientRect().top ?? 0;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};
export type partnerRegistrationStepsType = {
  name: string;
  step: number;
};
export const Capitalize = (str: string) => {
  if (str === "") {
    return str;
  }
  const words = str
    .replace(/^\s+|\s+$/g, "")
    .replace(/\s+/g, " ")
    .split(" ");
  let CapitalizedWords: string[] = [];
  words.forEach((element) => {
    CapitalizedWords.push(
      element[0].toUpperCase() + element.slice(1, element.length).toLowerCase(),
    );
  });
  return CapitalizedWords.join(" ");
};
export const partnerRegistrationSteps = [
  {
    name: "Basic Details",
    step: 1,
  },
  {
    name: "Identity Verification",
    step: 2,
  },
  {
    name: "Address Verification",
    step: 3,
  },
  {
    name: "Office Address",
    step: 4,
  },
  // {
  //   name: "Set Commission",
  //   step: 5,
  // },
  {
    name: "Application Status",
    step: 5,
  },
];

export const customerRegistrationSteps = [
  {
    name: "Basic Details",
    step: 1,
  },
  {
    name: "Identity Proof",
    step: 2,
  },
  {
    name: "Address Proof",
    step: 3,
  },
  // {
  //   name: "Bank Account",
  //   step: 4,
  // },
  {
    name: "Status",
    step: 4,
  },
];

export const createOrderStepsL2 = [
  {
    name: "Deal details",
    step: 1,
  },
  {
    name: "Documentations",
    step: 2,
  },
  {
    name: "Check LRS",
    step: 3,
  },
];

export const createOrderSteps = [
  {
    name: "Select Deal",
    step: 1,
  },
  {
    name: "Remitter Details",
    step: 2,
  },
  {
    name: "Beneficiary details",
    step: 3,
  },
  {
    name: "LRS Declaration",
    step: 4,
  },
  {
    name: "Document",
    step: 5,
  },
  {
    name: "Confirmation",
    step: 6,
  },
];

export const createContactSteps = [
  {
    name: "Select product",
    step: 1,
  },
  // {
  //   name: "Assign lead",
  //   step: 2,
  // },
  // {
  //   name: "Other details",
  //   step: 3,
  // },
  {
    name: "Other details",
    step: 2,
  },
];

export const createLevelTwoContactSteps = [
  {
    name: "Select product",
    step: 1,
  },
  {
    name: "Document",
    step: 2,
  },
];

export const partnerLeadSteps = [
  {
    name: "Partner Details",
    step: 1,
  },
  {
    name: "Partner interest",
    step: 2,
  },
  {
    name: "Other details",
    step: 3,
  },
];

export const corporateLeadSteps = [
  {
    name: "Corporate Details",
    step: 1,
  },
  {
    name: "Corporate interest",
    step: 2,
  },
  {
    name: "Other details",
    step: 3,
  },
];

export const createremitSteps = [
  {
    name: "basic details",
    step: 1,
  },
  {
    name: "Share quotation",
    step: 2,
  },
];

export const createGroupSteps = [
  {
    name: "Select Role",
    step: 1,
  },
  {
    name: "Select Office",
    step: 2,
  },
  {
    name: "Select Products",
    step: 3,
  },
  {
    name: "Basic Details",
    step: 4,
  },
];

export const createExistingContactSteps = [
  {
    name: "Contact Details",
    step: 1,
  },
  {
    name: "Opportunity Details",
    step: 2,
  },
  {
    name: "Interaction Details",
    step: 3,
  },
];

export const createExistingEducationPaymentSteps = [
  {
    name: "Amount",
    step: 1,
  },
  {
    name: "Remitter Details",
    step: 2,
  },
  {
    name: "Previous Transactions",
    step: 3,
  },
  {
    name: "Recipient Details",
    step: 4,
  },
  {
    name: "Documnetation",
    step: 5,
  },
  {
    name: "Review",
    step: 6,
  },
];

export const createEducationPaymentSteps = [
  {
    name: "Basic Information",
    step: 1,
  },
  {
    name: "Payment Details",
    step: 2,
  },
  {
    name: "Status",
    step: 3,
  },
];

export const productActivationSteps = [
  {
    name: "Select Product",
    step: 1,
  },
  {
    name: "Set Commission",
    step: 2,
  },
  {
    name: "Confirmation",
    step: 3,
  },
];

export const createDealSteps = [
  {
    name: "Basic Information",
    step: 1,
  },
  {
    name: "More Information",
    step: 2,
  },
  {
    name: "Status",
    step: 3,
  },
];

export const createForexQuotationSteps = [
  {
    name: "Customer Details",
    step: 1,
  },
  {
    name: "Prepare Quote",
    step: 2,
  },
  {
    name: "Share",
    step: 4,
  },
];

export const statusOptions = [
  {
    name: "All Status",
    val: "",
  },
  {
    name: "Active",
    val: "active",
  },
  {
    name: "In-active",
    val: "not applicable",
  },
  {
    name: "Pending",
    val: "pending",
  },
  {
    name: "Incomplete",
    val: "incomplete",
  },
];

export const typeOptions = [
  {
    name: "All Type",
    val: "",
  },
  {
    name: "Freelancer",
    val: "freelancer",
  },
  {
    name: "Education Consultant",
    val: "education consultant",
  },
  {
    name: "Other",
    val: "other",
  },
];

export const cityOptions = [
  {
    name: "All Cities",
    val: "",
  },
  {
    name: "Delhi",
    val: "Delhi",
  },
  {
    name: "Surat",
    val: "surat",
  },
  {
    name: "Mumbai",
    val: "Mumbai",
  },
  {
    name: "Noida",
    val: "Noida",
  },
];

export const roleOptions = [
  {
    name: "All Roles",
    val: "",
  },
  {
    name: "CEO",
    val: "CEO",
  },
  {
    name: "CFO",
    val: "CFO",
  },
  {
    name: "Manager",
    val: "Manager",
  },
  {
    name: "CTO",
    val: "CTO",
  },
];

export const branchOptions = [
  {
    label: "HQ",
    value: "HQ",
  },
  {
    label: "Branch",
    value: "Branch",
  },
  {
    label: "Store",
    value: "Store",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const kycStatusOptions = [
  {
    name: "All KYC Status",
    val: "",
  },
  {
    name: "Active",
    val: "active",
  },
  {
    name: "Not Applicable",
    val: "not applicable",
  },
  {
    name: "Expired",
    val: "expired",
  },
];
export const formatIndianNumber = (number: string | number) => {
  if (number === null || number === undefined) return number;

  // Convert the input to a number
  const numericValue = typeof number === "string" ? parseFloat(number) : number;

  // Check if the number is NaN (not a number)
  if (isNaN(numericValue)) return number;

  // Split the number into integer and decimal parts
  const parts = numericValue.toString().split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1] || "";

  // Handle negative numbers
  const isNegative = numericValue < 0;
  const integerWithoutSign = isNegative ? integerPart.slice(1) : integerPart;

  // Format the integer part with commas
  let formattedStr = "";
  let count = 0;

  for (let i = integerWithoutSign.length - 1; i >= 0; i--) {
    formattedStr = integerWithoutSign[i] + formattedStr;
    count++;
    if (count >= 2 && i !== 0 && i !== integerWithoutSign.length - 2) {
      formattedStr = "," + formattedStr;
      count = 0;
    }
  }

  // Combine the integer and decimal parts
  formattedStr = isNegative ? "-" + formattedStr : formattedStr;
  if (decimalPart !== "") {
    formattedStr = formattedStr + "." + decimalPart?.slice(0, 4);
  }

  return formattedStr;
};

export const convertTimestampToDate = (timestamp: number | string | Date) => {
  if (timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const pad = (num: number | string | Date) => ("0" + num).slice(-2); // or use padStart
    const time = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);

    return `${day}:${month}:${year} ${time}`;
  }
};

export const statusClass = [
  {
    class: "border-green-500 text-green-500",
    status: "active",
  },
  {
    class: "border-green-500 text-green-500",
    status: "conditional active",
  },
  {
    class: "border-yellow-500 text-yellow-500",
    status: "not applicable",
  },
  {
    class: "border-yellow-500 text-yellow-500",
    status: "inactive",
  },
  {
    class: "border-red-500 text-red-500",
    status: "expired",
  },
  {
    class: "border-red-500 text-red-500",
    status: "blocked",
  },
  {
    class: "border-grey-500 text-grey-500",
    status: "archived",
  },
  {
    class: "border-red-500 text-red-500",
    status: "rejected",
  },
  {
    class: "border-green-500 text-green-500",
    status: "processing",
  },
  {
    class: "border-green-500 text-green-500",
    status: "partially recieved",
  },
  {
    class: "border-green-500 text-green-500",
    status: "recieved",
  },
  {
    class: "border-green-500 text-green-500",
    status: "fulfilled",
  },
  {
    class: "border-red-500 text-red-500",
    status: "cancelled",
  },
  {
    class: "border-green-500 text-green-500",
    status: "accepted",
  },
  {
    class: "border-green-500 text-green-500",
    status: "approved",
  },
  {
    class: "border-red-500 text-red-500",
    status: "denied",
  },
  {
    class: "border-green-500 text-green-500",
    status: "complete",
  },
  {
    class: "border-blue-500 text-blue-500",
    status: "incomplete",
  },
];

export const convertDate = (str: number | string | Date) => {
  const date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
};

export const paymentSatusOption = [
  {
    name: "All Payment Status",
    val: "",
  },
  {
    name: "Pending",
    val: "pending",
  },
  {
    name: "Recieved",
    val: "recieved",
  },
  {
    name: "Partially Recieved",
    val: "partially recieved",
  },
];
export const serviceProviderOption = [
  {
    name: "All Service Provider",
    val: "",
  },
  {
    name: "Provider 1",
    val: "Provider 2",
  },
  {
    name: "Provider 2",
    val: "Provider 2",
  },
  {
    name: "Provider 3",
    val: "Provider 3",
  },
];

export function formatCurrency(amount: number, currency: string) {
  switch (currency) {
    case "USD":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })
        .format(amount)
        .replace("$", "$ ");
    case "INR":
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      })
        .format(amount)
        .replace("₹", "₹ ");
    case "EUR":
      return new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
      })
        .format(amount)
        .replace("€", "€ ");
    case "GBP":
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      })
        .format(amount)
        .replace("£", "£ ");
    case "CAD":
      return new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      })
        .format(amount)
        .replace("$", "CAD ");
    default:
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
  }
}

export const handleDownloadViaURL = async (download_url: string) => {
  try {
    // Fetch the document
    const response = await fetch(download_url);

    if (!response.ok) {
      throw new Error(
        `Failed to download: ${response.status} ${response.statusText}`,
      );
    }

    // Get the blob from the response
    const blob = await response.blob();

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = `${document.title}.pdf`;
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Download error:", error);
    alert("Failed to download the document. Please try again.");
  }
};
