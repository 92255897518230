// input form attributes type

export const CURRENCY = "currency";
export const SELELCT = "select";
export const RADIO = "radio";
export const NUMBER = "number";
export const TEXT = "text";
export const LEVEL_1 = "1";
export const LEVEL_2 = "2";
export const LEVEL_3 = "3";
