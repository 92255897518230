import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionsExtended } from "pages/Groups/utils";
import { off } from "process";

export type ProductName =
  | "remittance"
  | "forex"
  | "visa"
  | "travel"
  | "studyabroad";
export type ProductType = {
  id: string;
  name: string;
};

type CreateGroupState = {
  role: string;
  office: string;
  products: { [key in ProductName]: ProductType[] };
  group_name: string;
  group_description: string;
  offices: OptionsExtended[];
};

export const initialState: CreateGroupState = {
  role: "",
  office: "",
  products: {
    remittance: [],
    forex: [],
    visa: [],
    travel: [],
    studyabroad: [],
  },
  offices: [],
  group_name: "",
  group_description: "",
};

export const CreateGroupSlice = createSlice({
  name: "CreateGroup",
  initialState,
  reducers: {
    setOffices: (state, action: PayloadAction<OptionsExtended[]>) => {
      state.offices = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setOffice: (state, action: PayloadAction<string>) => {
      state.office = action.payload;
    },
    setProducts: (
      state,
      action: PayloadAction<{ name: ProductName; products: ProductType[] }>,
    ) => {
      state.products[action.payload.name] = action.payload.products;
    },
    setGroupName: (state, action: PayloadAction<string>) => {
      state.group_name = action.payload;
    },
    setGroupDescription: (state, action: PayloadAction<string>) => {
      state.group_description = action.payload;
    },
    resetState: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  setRole,
  setOffices,
  setOffice,
  setProducts,
  setGroupName,
  setGroupDescription,
  resetState,
} = CreateGroupSlice.actions;

export default CreateGroupSlice.reducer;
