import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Spinner } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetState,
  setMediumOfContact,
  setMeetingType,
  setProduct,
  setProductPurpose,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { toast, Toaster } from "sonner";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step4 } from "./step4";
import Step5otherDetails from "./step5";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";
import {
  CreateContactReqType,
  postExistingOpportunity,
} from "service/apis/Opportunities";
import { AxiosRequestConfig } from "axios";
import {
  channel_options_inbound,
  channel_options_outbound,
} from "pages/Opportunities/utils/featureRule";
import { createContactSteps, createLevelTwoContactSteps } from "utils";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import { setIsReferralMade } from "redux/features/CustomerRegistration";
import { setPurposeId } from "redux/features/CreateDeal";
import DocPopup from "pages/Create Opportunities/popup/docpopup";
import { Option } from "components/Form/form";
import { L2Step1 } from "./L2Case/L2Step1";
import { L2Step2 } from "./L2Case/L2Step2";
import { L2Step3 } from "./L2Case/L2Step3";
import { L2Step4 } from "./L2Case/L2Step4";
import { L2Step5 } from "./L2Case/L2Step5";
import { L2Step6 } from "./L2Case/L2Step6";
import { L2Step7 } from "./L2Case/L2Step7";
import { L1ProductCard } from "./L2Step1ProductGroup";
import { L1Step2Component } from "./L2StepProductList";

const productOptions: Option[] = [
  { value: "remittance", label: "Remittance" },
  { value: "forex", label: "Forex" },
  { value: "visa", label: "Visa" },
  { value: "travel", label: "Travel" },
  { value: "studyabroad", label: "Study Abroad" },
];

const pageHeader = (currentSteps: number) => {
  switch (currentSteps) {
    case 1:
      return "Select product";
    case 2:
      return "Select mode";
    case 3:
      return "Select loan type";
    case 4:
      return "Other details";
    default:
      return "Select product";
  }
};

const ReatilsCreateLead = () => {
  const {
    product_purpose,
    assigned_to,
    remit_note,
    outlet_id,
    meeting_type,
    moneeflo_branch,
    product,
    who_contacted,
    medium_of_contact,
    pipelineType,
    refferal_source,
    partner_reffered,
    employee_reffered,
    contact_reffered,
    entityEmail,
    entityName,
    entityNumber,
    entityType,
    partner_name,
    company_address,
    company_email,
    company_name,
    contact_person_name,
    contact_person_phone,
    contact_person_type,
    doc_list,
    quoteProduct,
    product_loan_type,
    product_mode,
  } = useAppSelector((state) => state.CreateOpportunity);
  const isLevelTwo = checkLevel(LEVEL_2);
  const location = useLocation();
  const { state }: any = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [channel, setChannel] = useState("whatsapp");
  const [expires, setExpires] = useState("1");
  const [popupNotes, setPopupNotes] = useState<string>("");
  const [selectedMode, setSelectedMode] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const { currentpath, getQuoteFlow }: any = location.state || {};
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [pageTitle, setPageTitle] = useState<string>("Select product");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isProductLoan, setIsProductLoan] = useState<string>("");
  const [selectProduct, setSelectProduct] = useState<string>("");
  const [processState, setProcessState] = useState<any>({
    "Product Group": "",
    Product: "",
    Mode: "",
    "Education Loan": "",
    "Offer Letter": "",
    Country: "",
    Currency: "",
  });

  const handlePrevious = () => {
    if (currentStep === 1) return;
    if (
      processState?.Product === "Study Maintenance" ||
      processState?.Product === "Gift / Family Maintenance"
    ) {
      setCurrentStep(1);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
    setErrors({});
  };

  useEffect(() => {
    dispatch(setProductPurpose(""));
    dispatch(setProduct(""));
    dispatch(setMediumOfContact(""));
    dispatch(setMeetingType(""));
  }, [location.pathname, dispatch]);

  const buttonState = () => {
    switch (currentStep) {
      case 1:
        if (pipelineType === "retail") {
          if (!product || !product_purpose) return false;
          return true;
        }
        return true;
      // case 4:
      //     return true;
      case 5:
        if (
          medium_of_contact === "refferal" &&
          refferal_source === "contacts" &&
          !contact_reffered
        )
          return false;
        if (!medium_of_contact || !who_contacted) return false;
        return true;
      default:
        return false;
    }
  };

  const channel_of_interaction_id =
    who_contacted === "customer" && medium_of_contact === "refferal"
      ? refferal_source === "contacts"
        ? "20"
        : refferal_source === "partner"
          ? "12"
          : refferal_source === "staff"
            ? "18"
            : ""
      : who_contacted === "myself"
        ? meeting_type === "whatsapp"
          ? "4"
          : meeting_type === "other"
            ? "14"
            : channel_options_inbound
                .find((item) => medium_of_contact === item.label.toLowerCase())
                ?.value.toString() ?? ""
        : meeting_type === "whatsapp"
          ? "8"
          : meeting_type === "other"
            ? "15"
            : meeting_type === "instagram"
              ? "10"
              : meeting_type === "facebook"
                ? "17"
                : meeting_type === "linkedin"
                  ? "16"
                  : channel_options_outbound
                      .find(
                        (item) =>
                          medium_of_contact === item.label.toLowerCase(),
                      )
                      ?.value.toString() ?? "";

  // final lead create
  const handleSubmit = async (type = "") => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const formData = new FormData();

    // for quotation_req
    let isquotation_req = false;
    if (isLevelTwo) {
      isquotation_req = true;
    }

    switch (pipelineType) {
      case "retail":
        formData.append("contact_id", state?.id as string);
        formData.append("typeOfRefferal", refferal_source);
        formData.append("refferedBy_staff", employee_reffered);
        formData.append("refferedBy_contacts", contact_reffered);
        formData.append(
          "refferedBy_partner",
          medium_of_contact === "refferal" ? partner_reffered : "",
        );
        formData.append("pipelineType", "retail");
        formData.append("forex_branch", moneeflo_branch);
        formData.append("outlet_branch", outlet_id);
        formData.append("exp_time", expires);
        formData.append("note", popupNotes);

        formData.append("quotation_req", JSON.stringify(isquotation_req));
        if (product_loan_type && product_mode) {
          formData.append("took_loan", JSON.stringify(!!product_loan_type));
          formData.append("mode", product_mode ?? "");
        } else if (location?.state) {
          formData.append(
            "took_loan",
            JSON.stringify(!!location?.state?.took_loan),
          );
          formData.append("mode", location?.state?.mode ?? "");
        }
        if (isLevelTwo) {
          for (let doc of doc_list) {
            const base64 = await convertFileToBase64(doc.document as File);
            formData.append(
              "doc_list[]",
              JSON.stringify({ filename: doc.id, file: base64 }),
            );
          }
        } else {
          selectedMode.forEach((mode) => {
            formData.append("doc_list[]", JSON.stringify({ filename: mode }));
          });
        }
        if (isLevelTwo) {
          formData.append("channel_of_interaction_id", "12");
        } else
          formData.append(
            "channel_of_interaction_id",
            channel_of_interaction_id,
          );
        break;
      case "corporate":
        formData.append("company_name", company_name);
        formData.append("company_email", company_email);
        formData.append("contactName", contact_person_name);
        formData.append("contactNumber", contact_person_phone);
        formData.append("typeOfContact", contact_person_type);
        formData.append("contactEmail", "bdbbh@gmail.com");
        formData.append("contactAddress", company_address);
        formData.append("pipelineType", "coorporate");
        break;
      case "partner":
        formData.append("contactName", entityName);
        formData.append("contactEmail", entityEmail);
        formData.append("contactNumber", entityNumber);
        formData.append("typeOfPartner", entityType);
        formData.append("typeOfContact", "partner");
        formData.append("pipelineType", "partner");
        break;
      default:
        break;
    }
    // product_list.forEach((product) => {
    formData.append("channel", channel);
    formData.append(
      "items[]",
      JSON.stringify({
        subject: "",
        remarks: "",
        notes: remit_note,
        assignedTo: assigned_to || localStorage.getItem("staff_id"),
        product_id: product_purpose || location?.state?.product_purpose,
      }),
    );
    // });

    const payload: AxiosRequestConfig<CreateContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: formData as any,
    };

    try {
      const res = (await postExistingOpportunity(payload)) as any;
      if (res.status === 200) {
        if (
          (res.data?.throwError as any)?.toLowerCase() ===
          "contact already exists"
        ) {
          toast.error("Contact already exists");
          return;
        }
        toast.success("Lead created successfully");
        setLoading(false);
        dispatch(setIsReferralMade(false));
        if (type === "prepareQuote") {
          dispatch(resetState());
          dispatch(setPurposeId(res?.data?.purposeID));
          handlePrepareQuote(
            res?.data?.response?.id as string,
            res?.data?.response.interactions_id?.[0] as string,
            res?.data?.purposeID as string,
          );
          return;
        }
      }
      if (getQuoteFlow === "getQuoteforexFlow") {
        navigate("/createforexquotation", {
          state: {
            contact_name: res?.data?.response?._contacts?.[0]?.contactName,
            product_id: res?.data?.response?.product_id,
            product_name: res?.data?.response?._product?.name,
            opportunity_id: res?.data?.response?.id,
            contact_id: res?.data?.response?.id?.contacts_id,
            channel_of_interaction_id:
              res?.data?.response?.channel_of_interaction_id,
            note: res?.data?.response?.interactions_id?.[0]?.[0]?.note,
            remarks: "",
            getQuoteFlow: getQuoteFlow,
            purpose_id: res?.data?.purposeID,
          },
        });
      } else if (getQuoteFlow === "getQuoteremittanceFlow") {
        dispatch(setPurposeId(res?.data?.purposeID));
        navigate("/L2CreateRemittanceQuotation", {
          state: {
            contact_name: res?.data?.response?._contacts?.[0]?.contactName,
            product_id: res?.data?.response?.product_id,
            product_name: res?.data?.response?._product?.name,
            opportunity_id: res?.data?.response?.id,
            contact_id: res?.data?.response?.id?.contacts_id,
            channel_of_interaction_id:
              res?.data?.response?.channel_of_interaction_id,
            note: res?.data?.response?.interactions_id?.[0]?.[0]?.note,
            remarks: "",
            ed_payment_mode: "",
            getQuoteFlow: getQuoteFlow,
            purpose_id: res?.data?.purposeID,
          },
        });
      } else {
        if (currentpath === "customerdetails") {
          navigate(-1);
        } else {
          navigate(`/leads/contact/${state?.id}`);
        }
      }
      dispatch(resetState());
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create Lead");
    }
  };

  const handleNext = () => {
    const maxSteps = isLevelTwo
      ? createLevelTwoContactSteps.length
      : createContactSteps.length;
    if (currentStep >= maxSteps) {
      handleSubmit();
      return;
    }
    if (true) {
      setCurrentStep((prev) => prev + 1);
      setErrors({});
    }
  };

  const handlePrepareQuote = (
    opp_id: string,
    interaction_id: string,
    purposeID: string,
  ) => {
    if (product === "remittance") {
      navigate("/L2CreateRemittanceQuotation", {
        state: {
          contact_name: state?.contact_name,
          contact_number: state?.contact_number,
          contact_email: state?.contact_email,
          product_id: product_purpose,
          product_name: "",
          opportunity_id: opp_id,
          interaction_id,
          remarks: "",
          meeting_time: "",
          meetingStaff_id: [],
          note: remit_note || "Quotation shared",
          channel_of_interaction_id: channel_of_interaction_id,
          partner_id:
            medium_of_contact === "refferal" && refferal_source === "partner"
              ? partner_reffered
              : "",
          partner_name: partner_name,
          contact_id: state?.id,
          getQuoteFlow: "prepareQuoteFlow",
          purpose_id: purposeID,
        },
      });
    }
    if (product === "forex") {
      navigate("/createforexquotation", {
        state: {
          contact_name: state?.contact_name,
          contact_number: state?.contact_number,
          contact_email: state?.contact_email,
          product_id: product_purpose,
          opportunity_id: opp_id,
          interaction_id,
          remarks: "",
          meeting_time: "",
          meetingStaff_id: [],
          note: remit_note || "Quotation shared",
          channel_of_interaction_id: channel_of_interaction_id,
          getQuoteFlow: "prepareQuoteFlow",
          purpose_id: purposeID,
        },
      });
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="sm:p-1 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
        <div className="gap-5 w-full flex h-full flex-col">
          <div className=" flex items-center w-full justify-between border-b gap-2 px-5 pt-2.5 pb-5">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">{pageTitle}</span>
            </h3>
            <AlertDialog>
              <AlertDialogTrigger>
                <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                  Cancel
                </button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                    <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                      Do you want to{" "}
                      <span className="font-semibold text-blue-700">
                        discard
                      </span>{" "}
                      the lead?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                      This action cannot be undone. This will permanently cancel
                      the ongoing progress.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                    <AlertDialogCancel
                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                      asChild
                    >
                      <button>Close</button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                      asChild
                    >
                      <button
                        onClick={() => {
                          dispatch(resetState());
                          if (currentpath === "customerdetails") {
                            navigate(
                              `/customerdetails/${location?.state?.customerID}`,
                            );
                          } else if (currentpath === "contactDetails") {
                            navigate(
                              `/leads/contact/${location?.state?.contactID}`,
                            );
                          } else {
                            navigate("/lead/contacts");
                          }
                        }}
                      >
                        Confirm
                      </button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          </div>
          <div className="rounded-xl flex h-full gap-5 w-full sm:px-0 px-5 py-2.5 justify-between overflow-auto">
            {checkLevel(LEVEL_2) ? (
              <>
                {/* select Product */}
                {currentStep === 1 && (
                  <L2Step1
                    setCurrentStep={setCurrentStep}
                    setPageTitle={setPageTitle}
                    setProcessState={setProcessState}
                  />
                )}
                {/* select Mode */}
                {currentStep === 2 && (
                  <>
                    <L2Step2
                      setCurrentStep={setCurrentStep}
                      currentStep={currentStep}
                      setPageTitle={setPageTitle}
                      setProcessState={setProcessState}
                      handlePrevious={handlePrevious}
                    />
                  </>
                )}
                {/* select Loan type */}
                {currentStep === 3 && (
                  <>
                    <L2Step3
                      setIsProductLoan={setIsProductLoan}
                      setCurrentStep={setCurrentStep}
                      setPageTitle={setPageTitle}
                      setProcessState={setProcessState}
                      isProductLoan={isProductLoan}
                      handlePrevious={handlePrevious}
                      processState={processState}
                    />
                  </>
                )}
                {currentStep === 4 && (
                  <>
                    <L2Step4
                      setIsProductLoan={setIsProductLoan}
                      setCurrentStep={setCurrentStep}
                      setPageTitle={setPageTitle}
                      setProcessState={setProcessState}
                      isProductLoan={isProductLoan}
                      handlePrevious={handlePrevious}
                      processState={processState}
                    />
                  </>
                )}
                {currentStep === 5 && (
                  <L2Step5
                    setCurrentStep={setCurrentStep}
                    setPageTitle={setPageTitle}
                    setProcessState={setProcessState}
                    handlePrevious={handlePrevious}
                  />
                )}

                {currentStep === 6 && (
                  <L2Step6
                    setCurrentStep={setCurrentStep}
                    setPageTitle={setPageTitle}
                    handlePrevious={handlePrevious}
                    processState={processState}
                    setLoading={setLoading}
                    setProcessState={setProcessState}
                  />
                )}

                {currentStep === 7 && (
                  <L2Step7
                    setCurrentStep={setCurrentStep}
                    setPageTitle={setPageTitle}
                    handlePrevious={handlePrevious}
                    processState={processState}
                    setProcessState={setProcessState}
                    setLoading={setLoading}
                  />
                )}
              </>
            ) : (
              <div className="flex relative flex-col gap-5 w-[80%] overflow-y-auto flex-grow">
                {currentStep === 1 && (
                  <L1ProductCard
                    setCurrentStep={setCurrentStep}
                    setSelectProduct={setSelectProduct}
                    setProcessState={setProcessState}
                    setPageTitle={setPageTitle}
                  />
                )}
                {currentStep === 2 && (
                  <Step4
                    setCurrentStep={setCurrentStep}
                    setProcessState={setProcessState}
                    setPageTitle={setPageTitle}
                  />
                )}
                {/* select Mode */}
                {currentStep === 3 && (
                  <>
                    <Step1
                      setCurrentStep={setCurrentStep}
                      currentStep={currentStep}
                      setPageTitle={setPageTitle}
                      setProcessState={setProcessState}
                      handlePrevious={handlePrevious}
                      processState={processState}
                    />
                  </>
                )}
                {/* select Loan type */}
                {currentStep === 4 && (
                  <>
                    <Step2
                      setIsProductLoan={setIsProductLoan}
                      setCurrentStep={setCurrentStep}
                      setPageTitle={setPageTitle}
                      setProcessState={setProcessState}
                      isProductLoan={isProductLoan}
                      handlePrevious={handlePrevious}
                      processState={processState}
                    />
                  </>
                )}

                {/* other details */}
                {currentStep === 5 && (
                  <>
                    <Step5otherDetails
                      errors={errors}
                      handlePrevious={handlePrevious}
                    />
                    <div className="flex flex-row gap-8">
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            processState?.Product === "Study Maintenance" ||
                            processState?.Product ===
                              "Gift / Family Maintenance" ||
                            product === "forex"
                          ) {
                            setProcessState((prevState: any) => ({
                              ...prevState,
                              "Product Group": "",
                              Product: "",
                            }));
                            setCurrentStep(1);
                          } else {
                            setCurrentStep((prev) => prev - 1);
                          }
                          setErrors({});
                        }}
                        className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
                      >
                        Previous
                      </button>
                      {["forex", "remittance"].includes(product) &&
                        currentStep === 5 && (
                          <button
                            onClick={() => handleSubmit("prepareQuote")}
                            disabled={!buttonState()}
                            className="px-4 py-[11px] text-sm font-semibold rounded-lg text-blue-700 border-blue-700 border disabled:opacity-40"
                          >
                            Prepare Quote
                          </button>
                        )}
                      <button
                        className="w-[150px] text-[14px] text-[#fff] p-3 bg-[#165FE3] rounded-lg text-center"
                        disabled={!buttonState()}
                        onClick={handleNext}
                      >
                        Submit
                      </button>
                      {currentStep === 5 &&
                        getQuoteFlow !== "getQuoteremittanceFlow" &&
                        getQuoteFlow !== "getQuoteforexFlow" &&
                        pipelineType === "retail" &&
                        product !== "remittance" && (
                          <DocPopup
                            buttonState={buttonState}
                            onSubmit={handleSubmit}
                            selectedMode={selectedMode}
                            setSelectedMode={setSelectedMode}
                            expires={expires}
                            setExpires={setExpires}
                            popupNotes={popupNotes}
                            setPopupNotes={setPopupNotes}
                            channel={channel}
                            setChannel={setChannel}
                          />
                        )}
                    </div>
                  </>
                )}
              </div>
            )}

            {Object.keys(processState).some((key) => processState[key]) && (
              <div className="flex flex-col gap-5 w-[20%] overflow-y-auto">
                <h3 className="font-semibold text-lg text-black">
                  <span className="capitalize">You Selected</span>
                  {/* {!checkLevel(LEVEL_2) && (
                    <div className="flex flex-col items-start gap-2">
                      <span className="text-[14px] font-semibold">
                        Product Group
                      </span>
                      {productOptions
                        ?.filter((item: any) => item?.value === quoteProduct)
                        .map((item: any, index: number) => (
                          <h1
                            key={index}
                            className="w-full bg-[#F9F9F9] text-[#333] text-[14px] font-medium py-1 px-2 rounded-md text-center"
                          >
                            {item?.label}
                          </h1>
                        ))}
                    </div>
                  )} */}
                </h3>
                <div className="flex flex-col gap-4">
                  {Object.keys(processState)
                    .filter((key) => processState[key])
                    .map((key) => (
                      <div
                        key={key}
                        className="flex flex-col items-start gap-2"
                      >
                        <span className="text-[14px] font-semibold">{key}</span>
                        <h1 className="w-full bg-[#F9F9F9] text-[#333] text-[14px] font-medium py-1 px-2 rounded-md text-center">
                          {processState[key]}
                        </h1>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </>
  );
};

export default ReatilsCreateLead;
