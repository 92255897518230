import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { LrsType, OrderDocumentType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { Eye, UploadCloud } from "lucide-react";
import DocumentReview from "./verifydocuments";
import { useState } from "react";
import { AxiosRequestConfig } from "axios";
import {
  postOrderDocuments,
  POSTOrderDocumentsRequestType,
} from "service/apis/CreateOrderapi";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import RequestOrderDoc from "./request_doc";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";

interface IOrderProps {
  order_id?: string;
  doc_status: string;
  doc_list?: (OrderDocumentType & { type: "image" | "pdf" })[];
  lrs_list?: LrsType[];
  refetchOrderDetails?: () => Promise<void>;
}

export default function OrderDocuments({
  doc_status = "Review",
  doc_list,
  order_id,
  refetchOrderDetails,
  lrs_list,
}: Readonly<IOrderProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const docsForDialog = doc_list?.map((doc) => {
    const name = doc.doc_name;
    return {
      id: doc.doc_list_id,
      name: name,
      status: doc.doc_status as "Unverified" | "Verified" | "Requested",
      url: doc.doc_url,
      type: doc.type,
    };
  });
  const docsForTable = doc_list?.map((doc) => {
    const name = doc.doc_name;
    return {
      ...doc,
      doc_name: name,
    };
  });
  const OrderDcoumentsColumn: ColumnDef<OrderDocumentType>[] = [
    {
      id: "doc_name",
      accessorKey: "doc_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Document name" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-between gap-1.5">
            <p className="max-w-[200px] whitespace-normal break-words">
              {row.getValue("doc_name")}
            </p>
          </div>
        );
      },
    },
    {
      id: "upload_date",
      accessorKey: "upload_date",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Upload Date" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-between gap-1.5">
            <p className="max-w-[200px] whitespace-normal break-words">
              {row.getValue("upload_date") || "N/A"}
            </p>
          </div>
        );
      },
    },
    {
      id: "doc_status",
      accessorKey: "doc_status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        const status = row.getValue("doc_status");
        if (status === "Verified") {
          return (
            <div className="flex items-center gap-1.5">
              <p className="max-w-[200px] bg-[#E1FFE2] px-2 rounded-md text-[#00A82D] whitespace-normal break-words">
                Approved
              </p>
              <button
                onClick={() => window.open(row.original.doc_url)}
                className=""
              >
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3221 5.28375C16.5501 5.60325 16.6641 5.76375 16.6641 6C16.6641 6.237 16.5501 6.39675 16.3221 6.71625C15.2976 8.15325 12.6808 11.25 9.16406 11.25C5.64656 11.25 3.03056 8.1525 2.00606 6.71625C1.77806 6.39675 1.66406 6.23625 1.66406 6C1.66406 5.763 1.77806 5.60325 2.00606 5.28375C3.03056 3.84675 5.64731 0.75 9.16406 0.75C12.6816 0.75 15.2976 3.8475 16.3221 5.28375Z"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4141 6C11.4141 5.40326 11.177 4.83097 10.7551 4.40901C10.3331 3.98705 9.7608 3.75 9.16406 3.75C8.56733 3.75 7.99503 3.98705 7.57307 4.40901C7.15112 4.83097 6.91406 5.40326 6.91406 6C6.91406 6.59674 7.15112 7.16903 7.57307 7.59099C7.99503 8.01295 8.56733 8.25 9.16406 8.25C9.7608 8.25 10.3331 8.01295 10.7551 7.59099C11.177 7.16903 11.4141 6.59674 11.4141 6Z"
                    stroke="#165FE3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          );
        }
        if (status === "Requested") {
          return (
            <div className="flex items-center gap-1.5">
              <p className="max-w-[200px] bg-[#E1EBFC] px-2 rounded-md text-blue-700 whitespace-normal break-words">
                Requested
              </p>
            </div>
          );
        }
        return (
          <button onClick={() => setIsOpen(true)} className=" w-full ">
            <p className=" max-w-[200px] flex-1 flex justify-center items-center rounded-md p-2.5 border-black-label border gap-1.5">
              {!checkLevel(LEVEL_2) ? "Review" : "Re-Upload"}
            </p>
          </button>
        );
      },
    },
  ];
  const lrsColumns: ColumnDef<LrsType>[] = [
    {
      accessorKey: "ref_no",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Ref number" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-between gap-1.5">
            <p className="text-[#333333] font-medium text-sm leading-[21px]">
              {row.getValue("ref_no")}
            </p>
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className="text-center w-full"
          title="Amount"
        />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-center gap-1.5">
            <p className="text-[#333333] font-medium text-sm leading-[21px]">
              ₹ {row.getValue("amount")}
            </p>
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "doc_url",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="text-center w-full"
          column={column}
          title="Supporting Documnet"
        />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-center gap-1.5">
            <p className="text-[#333333] font-medium text-sm leading-[21px]">
              <Button
                onClick={() => window.open(row.getValue("doc_url"))}
                variant="ghost"
                size="sm"
                className="text-blue-600"
              >
                <Eye className="h-4 w-4" />
              </Button>
            </p>
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  const checkAllDocsStatus = () => {
    return doc_list?.every((doc) => doc.doc_status === "Verified");
  };

  const { id } = useParams();

  const onApprove = async (doc_id: string, name: string, file: File) => {
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    let base64;
    if (file) {
      base64 = await convertFileToBase64(file);
    } else {
      base64 = null;
    }
    const payload: AxiosRequestConfig<POSTOrderDocumentsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id: id,
        status: "Verified",
        doc_list_id: doc_id.toString() === "0" ? null : doc_id,
        filename: doc_id.toString() === "0" ? name : null,
        file: file?.name ? base64 : null,
      },
    };
    try {
      const response = await postOrderDocuments(payload);
      if (response.status === 200) {
        refetchOrderDetails?.();
        toast.success("Document approved successfully");
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    }
  };

  const onReupload = async (doc_id: string, file: string, name: string) => {
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTOrderDocumentsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id: id,
        status: "Verified",
        doc_list_id: doc_id.toString() === "0" ? null : doc_id,
        file: file,
        file_name: doc_id.toString() === "0" ? name : null,
      },
    };
    try {
      const response = await postOrderDocuments(payload);
      if (response?.status === 200) {
        refetchOrderDetails?.();
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    }
  };
  return (
    <section className="bg-white-A700 px-3 rounded-xl gap-5 flex flex-col">
      <DocumentReview
        refetchOrderDetails={refetchOrderDetails as () => Promise<void>}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        documents={docsForDialog || []}
        onApprove={onApprove}
        onReUpload={onReupload as any}
      />
      <div className={`rounded-lg flex items-center justify-between`}>
        <div className="flex items-center gap-4">
          <div>
            <span className="font-medium">Moneeflo: </span>
            <span
              className={`${!checkAllDocsStatus() ? "text-red-500" : "text-green-500"}`}
            >
              {!checkAllDocsStatus() ? "Unverified" : "Verified"}
            </span>
            <AlertDialog>
              <AlertDialogTrigger>
                <Button
                  variant="link"
                  disabled={doc_status !== "Review"}
                  className="text-blue-600 pl-2 hidden"
                >
                  Verify Documents
                </Button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10">
                    <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-gray-900">
                      Verify documents
                    </AlertDialogTitle>
                  </AlertDialogHeader>
                  <form className="px-10 pb-4">
                    <label
                      htmlFor="verify"
                      className="flex gap-2 text-sm leading-5 font-normal text-[#666666] items-center"
                    >
                      <input
                        checked={false}
                        onChange={(e) => {
                          // setVerifyDocs(e.target.checked);
                        }}
                        type="checkbox"
                        name="verify"
                        id="verify"
                      />
                      I here by declare on behalf of AD2 that the documents
                      submitted by are correct and valid.
                    </label>
                  </form>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-50">
                    <AlertDialogCancel className="rounded-lg m-0 border-blue-700 border py-[11px] px-4 text-blue-700">
                      Close
                    </AlertDialogCancel>
                    <AlertDialogAction
                      type="submit"
                      onClick={() => {
                        //   handleDocReview(verifyDocs);
                      }}
                      className="rounded-lg border-blue-700 py-[11px] px-4 text-white-A700 bg-blue-700 border"
                    >
                      Verify
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          </div>
        </div>
        <div className="flex gap-4">
          {!checkLevel(LEVEL_2) && (
            <RequestOrderDoc
              refetchOrderDetails={refetchOrderDetails}
              order_id={order_id as string}
            >
              <Button
                variant="default"
                className="rounded-lg py-[11px] flex gap-2 px-4 border-blue-700 border text-blue-700 font-semibold text-sm"
              >
                Request Docs.
              </Button>
            </RequestOrderDoc>
          )}
          <Button
            onClick={() => setIsOpen(true)}
            disabled={!doc_list?.length}
            variant="outline"
            className="rounded-lg disabled:opacity-40 py-[11px] flex gap-2 px-4 bg-blue-700 text-white-A700 font-semibold text-sm"
          >
            <UploadCloud className="h-4 w-4" />
            <div className="">Upload Document</div>
          </Button>
        </div>
      </div>
      <div className="space-y-8">
        <section>
          <DataTable
            columns={OrderDcoumentsColumn}
            data={docsForTable || []}
            isToolbarVisible={false}
            isPaginationVisible={false}
            isPageCountVisible={false}
            columnsBackground="black"
          />
        </section>
        <section className="flex flex-col gap-4 border-t pt-4">
          <p className="text-[#666666] leading-4 font-normal text-sm">
            LRS Decalations
          </p>
          <DataTable
            columns={lrsColumns}
            data={lrs_list || []}
            isToolbarVisible={false}
            isPaginationVisible={false}
            isPageCountVisible={false}
            columnsBackground="black"
          />
        </section>
        <section className="hidden">
          <h3 className="text-lg text-gray-500 mb-4">LRS Declarations</h3>
          <div className="bg-[#2F3336] text-white-A700 rounded-t-lg">
            <div className="grid grid-cols-3 p-4">
              <div>Ref number</div>
              <div>Amount</div>
              <div>Supporting Document</div>
            </div>
          </div>
          <div className="border rounded-b-lg">
            <div className="grid grid-cols-3 p-4">
              <div>N/A</div>
              <div>N/A</div>
              <div className="flex gap-4">
                <Button
                  disabled
                  variant="ghost"
                  size="sm"
                  className="text-blue-600"
                >
                  <Eye className="h-4 w-4" />
                </Button>
                <Button
                  disabled
                  variant="ghost"
                  size="sm"
                  className="text-blue-600"
                >
                  <UploadCloud className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
