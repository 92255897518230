import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const DealDetails = `${BASE_URL}:6JkkZBcl/deal/:id`;
const service_rate = `${BASE_URL}:6JkkZBcl/service-rates/:id`;
const service_partner = `${BASE_URL}:6JkkZBcl/service_provider/:id`;
const cancel_deal = `${BASE_URL}:6JkkZBcl/deal-status/:id`;
const cancel_deal_charges = `${BASE_URL}:6JkkZBcl/cancellation-charges`;
const link_lead_search_list = `${BASE_URL}:vQ4fLKDn:UAT/assign_lead_deal`;

// Get Contact deatils based on search
export type GetLinkLeadListRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  params: {
    searchQuery: string;
    purpose_id: number;
  };
}>;

export type GetLinkLeadListResponseType = Partial<{}>;
export const getLinkLeadSearchList = (
  payload: AxiosRequestConfig<GetLinkLeadListRequestType>,
): AxiosPromise<GetLinkLeadListResponseType[]> =>
  apis.get(link_lead_search_list, payload);

// Get Deal Details
export type GetDealDetailsRequestType = Partial<{
  deal_id: string | number;
  headers: { Authorization: string };
}>;

export type GetDealDetailsResponseType = {
  id: string;
  created_at: string;
  created_by: string;
  purpose_id: string | number;
  country: string;
  currency: string;
  amount: string | number;
  foregin_bank_rates: string | number;
  tt_charges: string | number;
  service_partner_id: string | number;
  service_partner_rates: string | number;
  settlement_rate: string | number;
  fx_charges: string;
  l1_commission_rates: string | number;
  l2_commission_rates: string | number;
  l3_commission_rates: string | number;
  _channels: {
    id: string;
    name: string;
    level: string | number;
  };
};

export const getDealDetails = (
  payload: AxiosRequestConfig<GetDealDetailsRequestType>,
): AxiosPromise<GetDealDetailsResponseType> => {
  const dealId = payload.data?.deal_id;

  if (dealId === undefined) {
    throw new Error("order_id is not provided in the payload.");
  }

  const url = DealDetails.replace(":id", dealId.toString());
  return apis.get(url, payload);
};

// Put Service Rate
export type PutServiceRateRequestType = Partial<{
  service_partner_rates: number;
  foreign_bank_rate: number;
  tt_charges: number;
  service_partner_id: number;
  deal_id: string | number;

  headers: { Authorization: string };
}>;

export type PutServiceRateResponseType = Partial<{}>;

export const putServiceRate = (
  payload: AxiosRequestConfig<PutServiceRateRequestType>,
): AxiosPromise<PutServiceRateResponseType> => {
  const dealId = payload?.data?.deal_id;

  if (dealId === undefined) {
    throw new Error("Deal_id is not provided in the payload.");
  }

  const url = service_rate.replace(":id", dealId.toString());
  return apis.put(url, payload);
};

//Update Service Provider
export type PutServicePartnerRequestType = Partial<{
  service_partner_id: number;
  deal_id: string | number;
  documents: {
    label: string;
    doc: string;
  }[];
  headers: { Authorization: string };
}>;

export type PutServicePartnerResponseType = Partial<{}>;

export const putServicePartner = (
  payload: AxiosRequestConfig<PutServicePartnerRequestType>,
): AxiosPromise<PutServicePartnerResponseType> => {
  const dealId = payload?.data?.deal_id;

  if (dealId === undefined) {
    throw new Error("Deal_id is not provided in the payload.");
  }

  const url = service_partner.replace(":id", dealId.toString());
  return apis.put(url, payload);
};

// Cancel Deal
export type CancelDealRequestType = Partial<{
  deal_id: string | number;

  headers: { Authorization: string };
}>;

export type CancelDealResponseType = Partial<{}>;

export const cancelDeal = (
  payload: AxiosRequestConfig<CancelDealRequestType>,
): AxiosPromise<CancelDealResponseType> => {
  const dealId = payload?.data?.deal_id;

  if (dealId === undefined) {
    throw new Error("Deal_id is not provided in the payload.");
  }

  const url = cancel_deal.replace(":id", dealId.toString());
  return apis.put(url, payload);
};

// Cancel Deal
export type CancelDealChargesRequestType = Partial<{
  deal_id: string | number;
  service_partner_cancellation_charges: string | number;
  channel_partner_cancellation_charges: string | number;

  headers: { Authorization: string };
}>;

export type CancelDealChargesResponseType = Partial<{}>;

export const cancelDealCharges = (
  payload: AxiosRequestConfig<CancelDealChargesRequestType>,
): AxiosPromise<CancelDealChargesResponseType> => {
  return apis.post(cancel_deal_charges, payload);
};
