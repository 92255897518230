// Maxx-Remittance_Webapp/src/common/InteractionCardMobile/Index.tsx
import { Card, CardContent } from "@/components/ui/card";
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { channel_options } from "common/filters";
import { Img } from "components";
import { Download } from "lucide-react";
import { ChannelOptions } from "pages/Leads/lead";
import { getRelativeDate } from "pages/Opportunities/utils/featureRule";

interface InteractionCardProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onRowClick?: (row: TData) => void;
  assignedTo: string;
}

export function InteractionCard<TData, TValue>({
  columns,
  data,
  onRowClick,
  assignedTo,
}: Readonly<InteractionCardProps<TData, TValue>>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const sortedByTime = table.getRowModel().rows.sort(
    (a, b) =>
      Number(b?.original?.interactionDateandTime) -
      Number(a?.original?.interactionDateandTime),
  );

  return (
    <div className="space-y-4">
      {sortedByTime?.map((row) => {
        const interactionDateTime = row.getValue(
          "interactionDateandTime",
        ) as string;
        const interactionNote = row.getValue("interactionNote") as string;
        const interactionSource = row.getValue("interactionSource") as string;
        const newSource = (channel_options as ChannelOptions)?.[
          (row?.original?.channelType as string)?.toLowerCase()
        ]?.find(
          (item) =>
            item.value?.toLowerCase() ===
            (row.getValue("interactionSource") as string)
              ?.toLowerCase()
              .split(" ")
              .join(""),
        );

        return (
          <Card
            key={row.id}
            className="bg-white-A700 w-full"
            onClick={() => onRowClick?.(row.original)}
          >
            <CardContent className="py-2">
              {/* Header with Date and Time */}
              <div className="flex justify-between items-start mb-2">
                <div className="flex flex-col items-start gap-2">
                  <span className="text-sm text-black-400 font-bold">
                    {getRelativeDate(Number(interactionDateTime))}
                  </span>
                  <span className="text-sm text-gray-500">
                    {assignedTo ?? localStorage.getItem("owner_name")}
                  </span>
                </div>
                {row?.original?.quotationURL && (
                  <button
                    onClick={() => {
                      window.open(row?.original?.quotationURL, "_blank");
                    }}
                    className="text-blue-600"
                  >
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.932 0.15457C10.8403 0.0579688 10.7146 0 10.5843 0H2.78234C1.34262 0 0.154297 1.18359 0.154297 2.62313V17.3767C0.154297 18.8164 1.34266 20 2.7823 20H13.3716C14.8113 20 15.9996 18.8164 15.9996 17.3767V5.66172C15.9996 5.53617 15.9416 5.41547 15.8596 5.32359L10.932 0.15457ZM11.0721 1.70535L14.3717 5.16906H12.2268C11.5891 5.16906 11.0721 4.65695 11.0721 4.01934V1.70535ZM13.3716 19.0338H2.7823C1.87898 19.0338 1.12047 18.2851 1.12047 17.3767V2.62313C1.12047 1.7198 1.8741 0.966172 2.7823 0.966172H10.1059V4.0193C10.1059 5.19316 11.0529 6.13523 12.2268 6.13523H15.0334V17.3767C15.0334 18.2851 14.2798 19.0338 13.3716 19.0338Z"
                        fill="#165FE3"
                      />
                      <path
                        d="M12.052 15.6968H4.10027C3.83465 15.6968 3.61719 15.914 3.61719 16.1798C3.61719 16.4455 3.83461 16.6629 4.10027 16.6629H12.0568C12.3225 16.6629 12.5399 16.4455 12.5399 16.1798C12.5399 15.914 12.3225 15.6968 12.052 15.6968ZM7.72348 13.9624C7.81535 14.059 7.9409 14.117 8.07609 14.117C8.21145 14.117 8.33703 14.059 8.42875 13.9624L11.2597 10.9238C11.4433 10.7304 11.4288 10.4213 11.2356 10.2427C11.0422 10.0591 10.7331 10.0734 10.5545 10.2668L8.55918 12.4068V7.13152C8.55918 6.8657 8.34176 6.64844 8.07609 6.64844C7.81047 6.64844 7.59301 6.8657 7.59301 7.13152V12.4068L5.60266 10.2668C5.41906 10.0736 5.11469 10.0591 4.92148 10.2427C4.72832 10.4262 4.71383 10.7306 4.89738 10.9238L7.72348 13.9624Z"
                        fill="#165FE3"
                      />
                    </svg>
                  </button>
                )}
              </div>

              {/* Notes Section */}
              <div className="rounded mb-4">
                <p className="w-full h-16 p-2 text-sm bg-[#F9F9F9] rounded resize-none overflow-auto max-h-16">
                  {interactionNote || "N/A"}
                </p>
              </div>

              {/* Footer Actions */}
              <div className="flex items-center gap-4 border-t pt-3">
                <h3 className="text-gray-500 text-sm">{interactionSource}</h3>
                <div className="h-5 w-px bg-gray-300" />{" "}
                {/* Vertical separator */}
                <Img
                  className="text-right h-5 w-5"
                  src={newSource?.icon || "/placeholder.svg"}
                  alt="icon"
                  width={20}
                  height={20}
                />
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}
