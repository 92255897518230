import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import loginsrc from "assets/images/login.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import {  useAppDispatch } from "redux/hooks";
import "../../styles/styles.css";

import Cookies from "universal-cookie";

const cookies = new Cookies();
import {
  PostStaffLoginRequestType,
  PostStaffLoginResponseType,
  postStaffLogin,
} from "../../service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "../../components";

import useForm from "../../hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import useLoggedIn from "../../hooks/useLoggedIn";
import AuthActionPage from "components/AuthAction/Index";
import { toast } from "react-toastify";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const isLoggedin = useLoggedIn();
  useEffect(() => {
    if (isLoggedin) {
      navigate("/dashboard");
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [, setLogin] = useState<PostStaffLoginResponseType>();

  const [loading, setLoading] = useState<boolean>(false);
  // const dispatch = useAppDispatch();

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter valid email"),
    password: yup.string().required("Password is required"),
    // is_owner: yup.string().required("User Type is required"),
  });
  const form = useForm(
    { email: "", password: "" },
    {
      validate: true,
      validateSchema: formValidationSchema,
      validationOnChange: true,
    },
  );

  function login1(data: { email: string; password: string }) {
    const req: PostStaffLoginRequestType = {
      data: {
        email: data.email,
        password: data.password,
      },
    };
    setLoading(true);
    localStorage.setItem("email", req.data.email.toString());

    postStaffLogin(req)
      .then((res) => {
        console.log(res?.data);
        setLogin(res?.data);
        localStorage.setItem("logosrc", res?.data?.authToken?.logo?.url);
        localStorage.setItem("owner_name", res?.data?.authToken?.owner_name);
        localStorage.setItem("name", res?.data?.authToken?.name);
        localStorage.setItem("is_owner", res?.data?.authToken?.is_owner);
        localStorage.setItem("state", res?.data?.authToken?.state);
        setLoading(false);
        localStorage.setItem(
          "authToken",
          JSON.stringify(res?.data?.authToken?.auth_token),
        );
        localStorage.setItem("channel_id", res?.data?.authToken?.channel_id);
        localStorage.setItem("level", res?.data?.authToken?.level);
        localStorage.setItem(
          "staff_id",
          JSON.stringify(res?.data?.authToken?.staff_id),
        );

        localStorage.setItem("branch", res?.data?.authToken?.branch);

        // set cookies for 3 hours Inactivity //3 * 60 * 60 * 1000
        cookies.set("authToken", res?.data?.authToken?.auth_token, {
          sameSite: "strict",
          path: "/",
          expires: new Date(new Date().getTime() + 3 * 60 * 60 * 1000),
          secure: true,
          //httpOnly: true,
        });

        if (req.data.email === "example@example.com") {
          window.location.href = "https://www.google.com";
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Please reset the default password"
        ) {
          navigate("/setpassword");
        }
        setLoading(false);
        toast.error(
          "The email address or password you entered is incorrect. Please try again.",
        );
      });
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      form.handleSubmit(login1);
    }
  };

  return (
    <AuthActionPage loading={loading}>
      <div className="flex flex-col gap-[23px] items-start justify-start w-full">
        <Text
          className="leading-[25.00px] flex flex-col gap-2 text-xl text-black-900 text-left"
          size="txtInterRegular16"
        >
          <span className="text-black-900_bf font-inter text-left font-medium">
            Welcome Onboard
          </span>
          <span className="text-[#707070] font-inter text-base text-left font-normal">
            Explore your diverse range of services.
          </span>
        </Text>
      </div>
      <div className="flex flex-col font-jost gap-2.5 h-auto md:h-auto items-center justify-center w-full">
        <div className="flex flex-col gap-8 items-start justify-start w-full">
          <div className="flex flex-col gap-2.5 items-start justify-start w-full">
            <Text className="text-base text-form-content-label leading-5 tracking-[0.15px] w-auto">
              Email
            </Text>
            <Input
              name="emailaddress"
              placeholder="Enter your Email Address"
              className="font-inter p-0 placeholder:text-inputBorder-base sm:pr-5 text-[15px] border-inputBorder-base text-left w-full"
              wrapClassName="bg-white-A700 focus-within:border-[#333333] border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              type="email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                form.handleChange("email", e);
              }}
              errors={form?.errors?.email}
              value={form?.values?.email}
            ></Input>
          </div>
          <div className="flex flex-col gap-2.5 items-start justify-start w-full">
            <div className=" flex justify-between w-full">
              <Text className="text-base text-form-content-label leading-5 tracking-[0.15px] w-auto">
                Password
              </Text>
              <Link to="/forgetpassword">
                <Text
                  className="common-pointer text-[#1562EF] text-sm font-medium text-right w-full"
                  size="txtInterRegular16"
                >
                  Forgot Password
                </Text>
              </Link>
            </div>
            <Input
              name="password"
              placeholder="Enter your Password"
              className="font-inter p-0 placeholder:text-inputBorder-base text-[15px] border-inputBorder-base text-left w-full"
              wrapClassName="bg-white-A700 border focus-within:border-[#333333] border-black-900_4c border-solid flex pb-[13px] pl-3 pr-4 pt-4 rounded-md w-full"
              type={showPassword ? "text" : "password"}
              onKeyPress={handleKeyPress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                form.handleChange("password", e);
              }}
              errors={form?.errors?.password}
              value={form?.values?.password}
              suffix={
                <button
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    togglePasswordVisibility(e)
                  }
                  className="pr-2"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              }
            ></Input>
          </div>
          {/* <div className="flex flex-row font-inter gap-2 items-center justify-start w-auto">
                  <label>
                    <input
                      className="ml-0 mr-2"
                      type="radio"
                      value="emp"
                      checked={selectedOption === "emp"}
                      onChange={handleOptionChange}
                    />
                    &nbsp; Employee Login
                  </label>
                  <label>
                    <input
                      className="ml-2 mr-2"
                      type="radio"
                      value="owner"
                      checked={selectedOption === "owner"}
                      onChange={handleOptionChange}
                    />
                    &nbsp; Admin Login
                  </label>
                </div> */}
          <div className="flex flex-col items-center justify-between w-full gap-5">
            <Button
              className="common-pointer bg-blue-700 cursor-pointer font-medium py-4 px-5 rounded-md shadow-bs2 text-base text-center text-white-A700 w-full"
              onClick={() => {
                form.handleSubmit(login1);
              }}
            >
              Sign in
            </Button>
            {/* <div className="flex flex-col font-inter items-center justify-center w-full">
                    <Text
                      className="common-pointer text-base text-center text-black-900 w-full"
                      size="txtInterRegular16"
                      onClick={() => navigate("/forgetpassword")}
                    >
                      Forgot Password
                    </Text>
                  </div> */}
          </div>
        </div>
      </div>
    </AuthActionPage>
  );
};

export default LoginPage;
