import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useCallback, useEffect, useMemo } from "react";
import { Option } from "components/Form/form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setProduct,
  setProductLoanType,
  setProductMode,
  setProductPurpose,
} from "redux/features/CreateOpportunity";
import { setIsReferralMade } from "redux/features/CustomerRegistration";
import { setPurposeId } from "redux/features/CreateOrder";
import { useLocation, useNavigate } from "react-router-dom";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setIsProductLoan: React.Dispatch<React.SetStateAction<string>>;
  isProductLoan: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  processState: any;
  connectedContactList: any[];
  productMode: any;
  selectProduct: string;
}
const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

const remittenceProductLoantakenOptions: Option[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
export const L2EducationLoanStep3: React.FC<Step2Props> = ({
  setCurrentStep,
  setPageTitle,
  setIsProductLoan,
  setProcessState,
  isProductLoan,
  processState,
  selectProduct,
  connectedContactList,
  productMode,
}) => {
  const dispatch = useAppDispatch();
  const { product_purpose, quoteProduct, product, prepare_quote_contact_data } =
    useAppSelector((state) => state.CreateOpportunity);

  const location = useLocation();
  const navigate = useNavigate();

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
      forex: forexProductPurposeOptions,
      visa: visaProductPurposeOptions,
      travel: travelProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === selectProduct,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleProductLoan = useCallback(
    (e: string) => {
      setIsProductLoan(e);
      setProcessState((prevState: any) => ({
        ...prevState,
        "Education Loan": e === "true" ? "Yes" : "No",
      }));
      dispatch(setProductLoanType(e === "true" ? true : false));
      if (quoteProduct === "remittance") {
        dispatch(setIsReferralMade(false));
        if (!product) {
          dispatch(setProduct(quoteProduct));
        }
        dispatch(
          setPurposeId(connectedContactList?.[0]?.purposeDetail?.purpose_id),
        );
        navigate("/lead/createlead", {
          state: {
            id: prepare_quote_contact_data?.value,
            contact_name: location?.state?.contact_name,
            contact_number: location?.state?.contact_number,
            contact_email: location?.state?.contact_email,
            currentpath: "customerdetails",
            quoteProduct: quoteProduct,
            product_purpose: product_purpose,
            getQuoteFlow: "getQuoteremittanceFlow",
            purpose_id: null,
            selectedProductname: finalProductValue?.[0]?.label,
            mode: productMode,
            took_loan: isProductLoan === "true" ? true : false,
          },
        });
      }
    },
    [setIsProductLoan],
  );

  useEffect(() => {
    setPageTitle("Education Loan");
  }, [setPageTitle]);

  return (
    <form className="w-full">
      <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
        <div>
          <label className="text-[14px] font-medium text-[#000]">
            Have you taken an education loan?
          </label>
          <RadioGroup
            className="flex w-full flex-col gap-4 mt-3"
            value={isProductLoan}
            onValueChange={handleProductLoan}
          >
            {remittenceProductLoantakenOptions?.map((tab) => (
              <label
                key={tab.value}
                htmlFor={tab.value}
                className={`${
                  isProductLoan === tab.value
                    ? "focus-within:border-blue-700 border-black-label"
                    : ""
                } flex w-[250px] items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
              >
                <RadioGroupItem
                  id={tab.value}
                  value={tab.value}
                  className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <p className="text-[14px] font-medium w-full">{tab.label}</p>
              </label>
            ))}
          </RadioGroup>
        </div>
        <button
          className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
          onClick={() => {
            if (processState?.Product === "GIC") {
              setCurrentStep(4);
              dispatch(setProductPurpose(""));
              setProcessState((prevState: any) => ({
                ...prevState,
                Product: "",
              }));
              setPageTitle("Select Product");
            } else {
              setCurrentStep((prev) => prev - 1);
              dispatch(setProductMode(""));
              setPageTitle("Select Mode");
              setProcessState((prevState: any) => ({
                ...prevState,
                Mode: "",
              }));
            }
            setIsProductLoan("");
            setProcessState((prevState: any) => ({
              ...prevState,
              "Education Loan": "",
            }));
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </button>
      </div>
    </form>
  );
};
