import { Label } from "@/components/ui/label";
import { RadioGroupItem, RadioGroup } from "@/components/ui/radio-group";
import { Option } from "components/Form/form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setProduct,
  setProductPurpose,
  setQuotePurposeProduct,
} from "redux/features/CreateOpportunity";
import { setPurposeId } from "redux/features/CreateOrder";
import { setIsReferralMade } from "redux/features/CustomerRegistration";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { toast } from "sonner";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setProductMode: React.Dispatch<React.SetStateAction<string>>;
  selectProduct: string;
  productMode: string;
  connectedContactList: any[];
  processState: any;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  setIsProductLoan: React.Dispatch<React.SetStateAction<string>>;
  isProductLoan: string;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  fetchGetQuoteDetails: () => Promise<any[]>;
}
const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

export const ProductBasesCardComponent: React.FC<Step2Props> = ({
  setCurrentStep,
  setProductMode,
  selectProduct,
  productMode,
  connectedContactList,
  setIsProductLoan,
  isProductLoan,
  setProcessState,
  setSelectProduct,
  processState,
  fetchGetQuoteDetails,
}) => {
  const { product_purpose, quoteProduct, product, prepare_quote_contact_data } =
    useAppSelector((state) => state.CreateOpportunity);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
      forex: forexProductPurposeOptions,
      visa: visaProductPurposeOptions,
      travel: travelProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === selectProduct,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleCardSelect = useCallback(
    (item: any) => setSelectedCardId(item.id),
    [],
  );

  const handlePrepareQuoteDirection = useCallback(
    (item: any, event: React.FormEvent) => {
      event.preventDefault();

      if (quoteProduct === "remittance") {
        dispatch(setPurposeId(item?.purposeDetail?.purpose_id));
        navigate("/L2CreateRemittanceQuotation", {
          state: {
            contact_name: item?._contacts?.contactName,
            product_id: product_purpose,
            product_name: item?._product?.name,
            opportunity_id: item?.id,
            contact_id: location?.state?.id,
            channel_of_interaction_id: item?._channel_of_interaction?.[0]?.id,
            note: "Prepare Quote",
            ed_payment_mode: productMode,
            loan: isProductLoan === "true" ? true : false,
            purpose_id: item?.purposeDetail?.purpose_id,
            getQuoteFlow: "getQuoteremittanceFlow",
          },
        });
      }
    },
    [
      navigate,
      quoteProduct,
      product_purpose,
      productMode,
      isProductLoan,
      location,
      finalProductValue,
      connectedContactList,
    ],
  );

  const currentProductOptions = useMemo(() => {
    return (
      productPurposeOptions[
        quoteProduct as keyof typeof productPurposeOptions
      ] || []
    );
  }, [quoteProduct]);

  const handleProductChoose = useCallback(
    (selectedValue: string) => {
      const selectedLabel = currentProductOptions.find(
        (tab: any) => tab.value === selectedValue,
      )?.label;
      if (selectedLabel) {
        dispatch(setProductPurpose(selectedValue));
        setSelectProduct(selectedLabel);
        dispatch(setQuotePurposeProduct(selectedLabel));
        setCurrentStep((prev) => prev + 1);
        setProcessState((prevState: any) => ({
          ...prevState,
          Product: selectedLabel,
        }));
      } else {
        toast.error("No matching label found for the selected value");
      }
    },
    [dispatch, setSelectProduct, setCurrentStep, currentProductOptions],
  );

  // Prepare quote for a new lead redirection
  const handlePrepareQuote = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (
        quoteProduct === "remittance" &&
        selectProduct != "Education Payment" &&
        selectProduct != "GIC"
      ) {
        dispatch(setIsReferralMade(false));
        if (!product) {
          dispatch(setProduct(quoteProduct));
        }
        dispatch(
          setPurposeId(connectedContactList?.[0]?.purposeDetail?.purpose_id),
        );
        setProductMode(
          selectProduct === "Study Maintenance" ? "StudyMaintenance" : "Gift",
        );
        //
        setCurrentStep(6);
      } else {
        if (selectProduct === "GIC") {
          setCurrentStep(5);
        } else {
          setCurrentStep((prev) => prev + 1);
        }
      }
    },
    [
      navigate,
      quoteProduct,
      location,
      connectedContactList,
      isProductLoan,
      productMode,
      finalProductValue,
      selectProduct,
    ],
  );

  // state updated
  useEffect(() => {
    const productLabel = finalProductValue?.[0]?.label;
    const modeMapping: any = {
      "Gift / Family Maintenance": "Gift",
      "Study Maintenance": "StudyMaintenance",
    };

    if (productLabel !== "Education Payment") {
      const finalMode = modeMapping[productLabel] || productLabel;
      const finalLoan = ["Education Payment", "GIC"].includes(productLabel)
        ? isProductLoan
        : "false";
      if (productMode !== finalMode) {
        setProductMode(finalMode);
      }
      if (isProductLoan !== finalLoan) {
        // setIsProductLoan(finalLoan);
      }
    }
  }, [finalProductValue, isProductLoan, productMode]);

  useEffect(() => {
    if (fetchGetQuoteDetails) {
      fetchGetQuoteDetails().then((res) => {
        if (!res) {
          if (processState?.Product === "Education Payment") {
            setCurrentStep(4);
          } else if (processState?.Product === "GIC") {
            setCurrentStep(5);
          } else if (
            processState?.Product === "Study Maintenance" ||
            processState?.Product === "Gift / Family Maintenance"
          ) {
            // alert("joo")
            dispatch(setIsReferralMade(false));
            setCurrentStep(6);
          }
        }
      });
    }
  }, []);

  return (
    <div className="flex flex-col gap-6 max-w-[850px]">
      <form className="w-full">
        <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex items-end justify-between gap-10">
            <label className="text-[18px] font-medium text-[#333]">
              Select any one to proceed.
            </label>
          </div>

          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                onValueChange={handleProductChoose}
              >
                {finalProductValue?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        product_purpose === tab.value
                          ? " focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>

          {connectedContactList?.length > 0 && (
            <>
              <div>
                <label className="text-[14px] font-medium text-[#000]">
                  We found an open lead for {selectProduct}!
                </label>
              </div>
              {connectedContactList?.map((item: any) => {
                return (
                  <div
                    className={`flex flex-row w-[650px] justify-between p-5 mb-4 rounded-lg cursor-pointer ${
                      selectedCardId === item.id
                        ? "border-2 border-blue-500"
                        : "border-1 border-[#88AEF3]"
                    }`}
                    style={{
                      background:
                        "linear-gradient(89.99deg, #FFFFFF 0.01%, #E1EBFC 99.99%)",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleCardSelect(item)}
                    key={item?.id}
                  >
                    <div
                      className="flex flex-col gap-4"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <h2 className="text-left">
                        <Label className="text-[18px] ">
                          {item?._product?.name}
                        </Label>
                        <span className="text-[#999999] px-2">|</span>
                        <Label className="text-[14px] text-[#999999] leading-[140%]">
                          {item?.mode_opp || "N/A"}
                        </Label>
                      </h2>
                      <div className="flex flex-row  items-center gap-2">
                        <Label className="text-[#999999] font-noraml text-[14px]">
                          Lead ID: OP - {item?.id} |
                        </Label>
                        <Label className="text-[#999999] text-[14px] font-noraml">
                          {new Date(item?.created_at)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace(",", "  |")
                            .replace(/ /g, " - ")
                            .replace(" - |", " |")
                            .replace(/-\s/g, " ")
                            .toUpperCase()}
                        </Label>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src="/images/ProfileIConNew.svg" />
                        <Label className="text-[16px] font-normal">
                          {item?._contacts?.contactName || "N/A"}
                        </Label>
                      </div>
                    </div>
                    <div
                      className="flex flex-col"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                        style={{ borderRadius: "10px", textAlign: "center" }}
                      >
                        {" "}
                        {item?._contacts?.customer ? "Customer" : "Contact"}
                      </Label>

                      <div className="flex flex-row gap-8">
                        <button
                          type="button"
                          disabled={
                            !item?.interactions_id?.[
                              connectedContactList[0].interactions_id.length - 1
                            ]?.quotation_pdf
                          }
                          className={`text-[14px] font-bold underline ${
                            item?.interactions_id?.[
                              connectedContactList[0].interactions_id.length - 1
                            ]?.quotation_pdf
                              ? "text-[#165FE3] cursor-pointer"
                              : "text-[#A0C1E8] cursor-not-allowed"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            const pdfUrl =
                              item?.interactions_id?.[
                                connectedContactList[0].interactions_id.length -
                                  1
                              ]?.quotation_pdf;
                            if (pdfUrl) {
                              window.open(pdfUrl, "_blank");
                            }
                          }}
                        >
                          View Quote
                        </button>
                        <button
                          className="text-[#165FE3] text-[14px] font-bold underline"
                          onClick={(e) => handlePrepareQuoteDirection(item, e)}
                        >
                          Prepare Quote
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <div className="flex flex-row gap-8">
            <button
              className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
              onClick={() => {
                setCurrentStep((prev) => prev - 1),
                  setProcessState((prevState: any) => ({
                    ...prevState,
                    Product: "",
                  }));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>
            <button
              className={`w-[350px] text-[14px] text-[#fff] p-3 bg-blue-500 rounded-lg text-center`}
              onClick={(e) => handlePrepareQuote(e)}
            >
              Prepare quote for a new lead
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
