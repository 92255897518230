"use client";

import { useState } from "react";
import { Spinner } from "components";
import Step2 from "./Step2";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Alert from "models/Alert/alert";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step1 from "./Step1";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { AxiosRequestConfig } from "axios";
import {
  CreateContactReqType,
  postExistingOpportunity,
} from "service/apis/Opportunities";
import { toast } from "sonner";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";
import { useLocation } from "react-router-dom";

const formSchema = z.object({
  currentStep: z.number().optional(),
  product: z.string().min(1, "Please select a product"),
  mode: z.string().optional(),
  isLoan: z.string().optional(),
  offerletter: z.string().optional(),
  offerletterConsent: z
    .boolean()
    .refine((value) => value === true, {
      message: "Please agree to the offer letter consent",
    })
    .optional(),
  country: z.string().optional(),
  amount: z.string().optional(),
  offerletterFile: z.instanceof(File).optional(),
});

export type FormData = z.infer<typeof formSchema>;

export default function CreateQuote() {
  const [loading, setLoading] = useState(false);
  const { state }: any = useLocation();

  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      product: "",
      mode: "",
      isLoan: "",
      offerletter: "",
      offerletterConsent: false,
      country: "",
      amount: "",
      currentStep: 1,
      offerletterFile: undefined,
    },
  });

  const currentStep = methods.watch("currentStep") || 1;
  const setCurrentStep = (step: number) =>
    methods.setValue("currentStep", step);

  const {
    getValues,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = methods;

  const location = useLocation();

  // Handle form submission
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await handleFormSubmit();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    let fieldsToValidate: string[] = [];

    if (currentStep === 1) {
      fieldsToValidate = ["name", "email"];
    } else if (currentStep === 2) {
      fieldsToValidate = ["phone"];
    }

    const isStepValid = await trigger(fieldsToValidate as any);

    if (isStepValid) {
      if (currentStep < 2) {
        setCurrentStep(currentStep + 1);
      } else {
        // If on last step, submit the form
        handleSubmit(onSubmit)();
      }
    }
  };

  // Go back to previous step
  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Determine if next button should be enabled
  const buttonState = () => {
    if (currentStep === 1) {
      return !errors.product;
    } else if (currentStep === 2) {
      return !errors.mode;
    }
    return true;
  };

  const studyAndGift =
    getValues("product") === "18" || getValues("product") === "20";

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 />;
      case 2:
        if (studyAndGift) return;
        if (getValues("product") === "17") return <Step2 />;
        return <Step3 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <Step5 />;
      case 6:
        return <Step6 />;
      default:
        return null;
    }
  };

  let isLoan: boolean;
  const docId: string = getValues("product") === "30" ? "13" : "5";

  if (getValues("isLoan") === "yes") {
    isLoan = true;
  } else if (getValues("isLoan") === "no") {
    isLoan = false;
  }

  const handleFormSubmit = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const formData = new FormData();
    formData.append("contact_id", state?.contact_id);
    formData.append("typeOfRefferal", "partner");
    formData.append("channel_of_interaction_id", "12");
    formData.append("took_loan", JSON.stringify(isLoan));
    formData.append("mode", getValues("mode") as string);
    formData.append(
      "items[]",
      JSON.stringify({
        subject: "",
        remarks: "",
        notes: "",
        assignedTo: "",
        product_id: getValues("product"),
      }),
    );
    formData.append(
      "doc_quote[]",
      JSON.stringify({
        doclist_id: docId,
        file: getValues("offerletter"),
      }),
    );

    const payload: AxiosRequestConfig<CreateContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: formData as any,
    };

    try {
      const res = (await postExistingOpportunity(payload)) as any;
      if (res.status === 200) {
        if (
          (res.data?.throwError as any)?.toLowerCase() ===
          "contact already exists"
        ) {
          toast.error("Contact already exists");
          return;
        }
        toast.success("Lead created successfully");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create Lead");
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="p-3 sm:p-2 flex flex-col font-inter h-full items-start justify-start mx-auto w-full">
        <div className="gap-5 w-full flex h-full flex-col">
          <div className="flex items-center w-full justify-between border-b gap-2 px-5 pt-2.5 pb-5">
            <h3 className="font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">
                {currentStep === 1 ? "Basic Information" : "Contact Details"}
              </span>
            </h3>
            <Alert
              handleSubmit={() => {}}
              actionText="lead"
              actionButtonText="Confirm"
            >
              <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                Cancel
              </button>
            </Alert>
          </div>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="h-full flex-1">
              <div className="rounded-xl flex flex-col justify-between h-full gap-5 w-full sm:px-0 px-5 py-2.5 overflow-auto">
                <div className="flex relative flex-col max-w-md gap-5 w-full overflow-y-auto flex-grow">
                  {renderStep()}
                </div>
                <div className="flex gap-3 sm:flex-col sm:items-start">
                  {currentStep !== 1 && (
                    <button
                      type="button"
                      onClick={handlePrev}
                      className="px-4 py-[11px] w-fit text-sm font-semibold rounded-lg border border-blue-700 text-blue-700"
                    >
                      Back
                    </button>
                  )}
                  {studyAndGift && (
                    <button
                      type="button"
                      disabled={!buttonState()}
                      onClick={() => {}}
                      className="px-4 py-[11px] w-fit text-sm font-semibold rounded-lg border bg-blue-700 text-white-A700 disabled:opacity-40"
                    >
                      Get Quote
                    </button>
                  )}
                  {currentStep === 4 && (
                    <button
                      type="button"
                      onClick={() => {
                        if (methods.watch("offerletterConsent")) {
                          methods.setValue("currentStep", 5);
                        }
                      }}
                      className="px-4 py-[11px] w-fit text-sm font-semibold rounded-lg border bg-blue-700 text-white-A700 disabled:opacity-40"
                    >
                      Request Quotation
                    </button>
                  )}
                  {currentStep === 6 && (
                    <button
                      type="submit"
                      className="px-4 py-[11px] w-fit text-sm font-semibold rounded-lg border bg-blue-700 text-white-A700 disabled:opacity-40"
                    >
                      Request Quotation
                    </button>
                  )}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
}
