import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface GlobalTooltipProps {
  children: React.ReactNode;
  contentClassName?: string;
  content: React.ReactNode;
  triggerClassName?: string;
  trigger?: React.ReactNode;
}

export function TooltipGlobal(
  props: Readonly<GlobalTooltipProps>,
): React.ReactElement {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger
          asChild
          className={cn("cursor-pointer", props.triggerClassName)}
        >
          {props.children}
        </TooltipTrigger>
        <TooltipContent className={cn("", props.contentClassName)}>
          {props.content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
