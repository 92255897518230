import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from "@/components/ui/accordion";
import { Input, Text } from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { getUniversity } from "service/apis/BeneficiaryApi";

// import { AxiosRequestConfig } from "axios";
// import { getCountry } from "service/apis/CreateDealapi";
import { useAppSelector } from "redux/hooks";

interface FormState {
  beneficiaryType: string;
  // beneficiaryCountry: string;
  beneficiaryName: string;
  // beneficiaryAddress: string;
  beneficiarySwiftCode: string;
  beneficiaryBankName: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress: string;
  beneficiaryAccountNumber: string;
  beneficiaryReferenceNumber: string;
  BSBNumber: string;
  SORTNumber: string;
  TRANSITNumber: string;
  OTHERNumber: string;
  RoutingNumber: string;
  isOtherSelected: boolean;
}

interface AddBeneficiaryModalProps {
  setIsEnable: (enabled: boolean) => void;
  setFormStateVal: React.Dispatch<React.SetStateAction<FormState>>;
}

interface Country {
  country: string;
  id: string;
}

interface University {
  id: string;
  University: string;
}

const countryMapping: Record<string, string> = {
  "United Kingdom": "SORT",
  Australia: "BSB",
  Canada: "Transit",
  "United States": "Routing number",
};

const EducationModal: React.FC<AddBeneficiaryModalProps> = ({
  setIsEnable,
  setFormStateVal,
}) => {
  const authToken = useMemo(
    () => JSON.parse(localStorage.getItem("authToken") || "null"),
    [],
  );

  const [formState, setFormState] = useState({
    beneficiaryType: "institution",
    // beneficiaryCountry: "",
    beneficiaryName: "",
    // beneficiaryAddress: "",
    beneficiarySwiftCode: "",
    beneficiaryBankName: "",
    beneficiaryBankCountry: "",
    beneficiaryBankAddress: "",
    beneficiaryAccountNumber: "",
    beneficiaryReferenceNumber: "",
    BSBNumber: "",
    SORTNumber: "",
    TRANSITNumber: "",
    OTHERNumber: "",
    RoutingNumber: "",
    isOtherSelected: false,
  });

  // const [countryList, setCountryList] = useState<Country[]>([]);
  const [universitylist, setUniversityList] = useState<University[]>([]);
  const [swiftcodeResData, setSwiftCrodeResData] = useState<string>("");
  const [debouncedSwiftCode, setDebouncedSwiftCode] = useState("");
  const { purpose_id, beneficiary_reference_number } = useAppSelector(
    (state) => state.CreateOrder,
  );
  const mandatoryPurposeIDs = ["10", "11"];
  const isMandatory = mandatoryPurposeIDs.includes(purpose_id);
  const ForRefOptionValue = isMandatory ? "Mandatory" : "Optional";

  // const fetchResults = useCallback(async () => {
  //   try {
  //     const payload: AxiosRequestConfig = {};
  //     const { data } = await getCountry(payload);

  //     if (data?.length) {
  //       const sortedData = data.sort((a: any, b: any) => a.id - b.id);
  //       setCountryList(sortedData);
  //     }
  //   } catch (error) {
  //     toast.error("Error fetching country data");
  //   }
  // }, []);

  const fetchUniversityResults = useCallback(
    async (country: string) => {
      if (!authToken) return;
      try {
        const payload = {
          headers: { Authorization: `Bearer ${authToken}` },
          data: { country },
        };
        const { data } = await getUniversity(payload);
        if (data?.length) {
          setUniversityList(data);
        } else {
          setUniversityList([]);
        }
      } catch (error) {
        toast.error("Error fetching university data");
        setUniversityList([]);
      }
    },
    [authToken],
  );

  const fetchBankWithSwift = useCallback(
    async (swiftCode: string) => {
      if (!swiftCode) return;
      try {
        const res = await fetch(
          `https://api.api-ninjas.com/v1/swiftcode?swift=${swiftCode}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "C0lIcBJDFv05CUJ28+X3ZA==8U7oSJqOoJDCxzyG",
            },
          },
        );

        if (!res.ok) throw new Error("Failed to fetch bank details");
        const response = await res.json();

        if (Array.isArray(response) && response.length > 0) {
          const { bank_name, country, city } = response[0];
          setSwiftCrodeResData(country);
          setFormState((prev) => ({
            ...prev,
            beneficiaryBankName: bank_name,
            beneficiaryBankCountry: country,
            beneficiaryBankAddress: [city, country].filter(Boolean).join(", "),
          }));
        } else {
          setFormState((prev) => ({
            ...prev,
            beneficiaryBankName: "",
            beneficiaryBankCountry: "",
            beneficiaryBankAddress: "",
          }));
          if (!toast.isActive("noDataFound")) {
            toast.error("No data found.", { toastId: "noDataFound" });
          }
        }
      } catch (error) {
        if (!toast.isActive("fetchError")) {
          toast.error("Error fetching bank details", { toastId: "fetchError" });
        }
      }
    },
    [debouncedSwiftCode],
  );

  const memoizedFetchUniversityResults = useMemo(
    () => (country: string) => fetchUniversityResults(country),
    [authToken],
  );

  useEffect(() => {
    if (formState.beneficiaryBankCountry)
      memoizedFetchUniversityResults(formState.beneficiaryBankCountry);
  }, [formState.beneficiaryBankCountry, memoizedFetchUniversityResults]);

  // useEffect(() => {
  //   fetchResults();
  // }, [fetchResults]);

  // const countryOptions = useMemo(
  //   () =>
  //     countryList.map(({ country, id }: Country) => ({
  //       value: id,
  //       label: country,
  //     })),
  //   [countryList],
  // );

  const universityOptions = useMemo(() => {
    const options = universitylist.map(({ id, University }) => ({
      value: id,
      label: University,
    }));
    options.push({
      value: "other",
      label: "Other",
    });

    return options;
  }, [universitylist]);

  const isAccType = swiftcodeResData
    ? countryMapping[swiftcodeResData] || "IBAN"
    : "IBAN";

  const handleInputChange = useCallback(
    (field: keyof FormState, value: string) => {
      setFormState((prev) => ({
        ...prev,
        [field]: value,
        ...(field === "beneficiaryName" && {
          isOtherSelected: value === "Other",
          beneficiaryName: value === "Other" ? "" : value,
        }),
      }));
    },
    [setFormState],
  );

  const checkFormCompletion = () => {
    const {
      beneficiaryReferenceNumber,
      BSBNumber,
      SORTNumber,
      TRANSITNumber,
      OTHERNumber,
      RoutingNumber,
      isOtherSelected,
      ...requiredFields
    } = formState;

    const areGeneralFieldsValid = Object.entries(requiredFields).every(
      ([key, value]) => {
        // console.log(`Checking field: ${key}, Value:`, value );
        return typeof value === "string" && value.trim() !== "";
      },
    );

    const isAccTypeValid = (() => {
      switch (isAccType) {
        case "Transit":
          return formState.TRANSITNumber.trim() !== "";
        case "SORT":
          return formState.SORTNumber.trim() !== "";
        case "Routing number":
          return formState.RoutingNumber.trim() !== "";
        case "BSB":
          return formState.BSBNumber.trim() !== "";
        case "IBAN":
          return true;
        default:
          return formState.OTHERNumber.trim() !== "";
      }
    })();

    if (isMandatory) {
      return beneficiaryReferenceNumber.trim() !== "";
    } else {
      return areGeneralFieldsValid && isAccTypeValid;
    }
  };

  useEffect(() => {
    setIsEnable(checkFormCompletion());
    setFormStateVal(formState);
  }, [formState, setIsEnable]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSwiftCode(formState.beneficiarySwiftCode);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [formState.beneficiarySwiftCode]);

  useEffect(() => {
    if (debouncedSwiftCode) {
      fetchBankWithSwift(debouncedSwiftCode);
    } else {
      setFormState((prev) => ({
        ...prev,
        beneficiaryBankName: "",
        beneficiaryBankCountry: "",
        beneficiaryBankAddress: "",
      }));
    }
  }, [debouncedSwiftCode]);

  const handleSwiftCodeChange = (e: string) => {
    setFormState((prevState) => ({
      ...prevState,
      beneficiarySwiftCode: e.toUpperCase(),
    }));
  };

  useEffect(() => {
    if (beneficiary_reference_number && isMandatory) {
      setFormState((prevState) => ({
        ...prevState,
        beneficiaryReferenceNumber: beneficiary_reference_number,
      }));
    }
  }, [beneficiary_reference_number, isMandatory]);

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      beneficiaryType: "institution",
    }));
  }, []);

  return (
    <div className="bg-white-A700 rounded-lg px-8 flex md:flex-1 flex-col gap-5 items-start justify-start md:px-5 w-full md:w-full sm:w-full">
      <div className="flex flex-col gap-2.5 items-start justify-start w-full sm:w-full">
        <Accordion
          className="w-full sm:w-full"
          type="single"
          collapsible={false}
          value="item-1"
        >
          <AccordionItem value="item-1">
            <AccordionContent className="w-[377px] pl-10 sm:w-full">
              <div className="flex relative flex-col items-start justify-start w-full gap-6 pb-8">
                {!isMandatory && (
                  <>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Select beneficiary type
                      </Text>
                      <style>
                        {`
                          [type='radio']:checked {
                            border-color: #1d1e20 !important;
                            background-color: #ffffff !important;
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            border : 5px solid #000 !important
                          }
                        `}
                      </style>
                      <div className="flex flex-row gap-2 w-full">
                        {["institution", "individual"].map((type) => (
                          <label
                            key={type}
                            className={`flex items-center gap-2 justify-center flex-1 p-4 rounded-lg cursor-pointer border
                              ${
                                formState.beneficiaryType != type
                                  ? "border-black bg-white"
                                  : "border-gray-400 bg-gray-100"
                              }`}
                          >
                            <input
                              type="radio"
                              name="beneficiaryType"
                              value={type}
                              checked={formState.beneficiaryType === type}
                              className="h-5 w-5 border-2 border-black appearance-none rounded-full checked:bg-white"
                              disabled
                            />
                            <span className="text-sm font-medium text-gray-700">
                              {type === "institution"
                                ? "Institution"
                                : "Individual"}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10 ${
                          false
                            ? "after:h-12 "
                            : "after:h-24 after:border-blue-700"
                        } absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Enter the valid Swift Code
                      </Text>
                      <Input
                        value={formState.beneficiarySwiftCode}
                        onChange={(e) => handleSwiftCodeChange(e)}
                        placeholder="Enter Swift Code"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>

                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is the {formState?.beneficiaryName} Beneficiary
                        Bank Name ?
                      </Text>
                      <Input
                        name="beneficiary_bank_name"
                        disabled
                        value={formState.beneficiaryBankName}
                        placeholder="Enter Bank Name"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>

                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is the {formState?.beneficiaryName} Bank Address ?
                      </Text>
                      <Input
                        name="beneficiary_bank_addr"
                        value={formState.beneficiaryBankAddress}
                        disabled
                        placeholder="Enter Bank Address"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>

                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Bank Country ?
                      </Text>
                      <Input
                        name="beneficiary_country"
                        value={formState.beneficiaryBankCountry}
                        disabled
                        placeholder="Enter Beneficiary bank country Name"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>

                    {/* <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${
                          false
                            ? "after:h-12 "
                            : "after:h-24 after:border-blue-700"
                        } absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Country {isMandatory && "(Optional)"}
                      </Text>
                      <Select
                        options={countryOptions}
                        value={
                          countryOptions.find(
                            (c) => c.label === formState.beneficiaryCountry,
                          ) || null
                        }
                        onChange={(option) =>
                          handleInputChange(
                            "beneficiaryCountry",
                            option?.label || "",
                          )
                        }
                        className="w-full"
                        classNamePrefix="react-select"
                        placeholder="Select Beneficiary Country"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "40px",
                            minHeight: "40px",
                          }),
                        }}
                      />
                    </div> */}

                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Name
                      </Text>
                      <Select
                        options={[...universityOptions]}
                        value={
                          formState.isOtherSelected
                            ? { label: "Other", value: "other" }
                            : universityOptions.find(
                                (c) => c.label === formState.beneficiaryName,
                              ) || null
                        }
                        onChange={(option) =>
                          handleInputChange(
                            "beneficiaryName",
                            option?.label || "",
                          )
                        }
                        className="w-full"
                        classNamePrefix="react-select"
                        placeholder="Search Beneficiary Name"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "40px",
                            minHeight: "40px",
                          }),
                        }}
                      />
                    </div>
                    {formState.isOtherSelected && (
                      <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                        <div
                          className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10"
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          Enter Beneficiary name.{" "}
                          <span className="text-gray-400">(Nickname)</span>
                        </Text>
                        <Input
                          id="beneficiaryName"
                          value={formState.beneficiaryName}
                          onChange={(e) =>
                            setFormState((prevState) => ({
                              ...prevState,
                              beneficiaryName: e,
                            }))
                          }
                          placeholder="Enter Beneficiary Address"
                          className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                          wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        />
                      </div>
                    )}
                    {/* <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Address
                      </Text>
                      <Input
                        id="beneficiary_addr_line_1"
                        value={formState.beneficiaryAddress}
                        onChange={(e) =>
                          setFormState((prevState) => ({
                            ...prevState,
                            beneficiaryAddress: e,
                          }))
                        }
                        placeholder="Enter Beneficiary Address"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div> */}

                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10 after:relative ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Bank Account number / IBAN ?
                      </Text>
                      <Input
                        value={formState.beneficiaryAccountNumber}
                        onChange={(e) =>
                          setFormState((prevState) => ({
                            ...prevState,
                            beneficiaryAccountNumber: e.toUpperCase(),
                          }))
                        }
                        placeholder={`eg. ABCD12234455`}
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    {isAccType !== "IBAN" && (
                      <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                        <div
                          className={`flex items-center flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10"
                            src={`images/overview/ellipse.svg`}
                            alt=""
                          />
                        </div>
                        <Text className="text-sm font-medium leading-[17px]">
                          {`{Enter ${isAccType}}`}
                        </Text>
                        <Input
                          id={
                            isAccType === "Transit"
                              ? "TRANSITNumber"
                              : isAccType === "SORT"
                                ? "SORTNumber"
                                : isAccType === "Routing number"
                                  ? "RoutingNumber"
                                  : isAccType === "BSB"
                                    ? "BSBNumber"
                                    : "OTHERNumber"
                          }
                          value={
                            isAccType === "Transit"
                              ? formState.TRANSITNumber
                              : isAccType === "SORT"
                                ? formState.SORTNumber
                                : isAccType === "Routing number"
                                  ? formState.RoutingNumber
                                  : isAccType === "BSB"
                                    ? formState.BSBNumber
                                    : formState.OTHERNumber
                          }
                          onChange={(e) =>
                            setFormState((prevState) => ({
                              ...prevState,
                              ...(isAccType === "Transit" && {
                                TRANSITNumber: e.toUpperCase(),
                              }),
                              ...(isAccType === "SORT" && {
                                SORTNumber: e.toUpperCase(),
                              }),
                              ...(isAccType === "Routing number" && {
                                RoutingNumber: e.toUpperCase(),
                              }),
                              ...(isAccType === "BSB" && {
                                BSBNumber: e.toUpperCase(),
                              }),
                              ...(isAccType !== "Transit" &&
                                isAccType !== "SORT" &&
                                isAccType !== "Routing number" &&
                                isAccType !== "BSB" && {
                                  OTHERNumber: e.toUpperCase(),
                                }),
                            }))
                          }
                          placeholder={`Enter ${isAccType}`}
                          className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                          wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <div
                    className={`flex items-center flex-col after:right-10  ${true ? "after:h-0 " : "after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10"
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    Reference number ({ForRefOptionValue})
                  </Text>
                  <Input
                    value={formState.beneficiaryReferenceNumber}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        beneficiaryReferenceNumber: e.toUpperCase(),
                      }))
                    }
                    placeholder="eg. BSU123456789"
                    className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                    wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};
export default EducationModal;
