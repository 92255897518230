import { Button } from "@/components/ui/button";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { AxiosRequestConfig } from "axios";
import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import { Spinner } from "components";
import { Check, ChevronDown } from "lucide-react";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { ContactData } from "pages/Dashboard/dashboard-data";
import { formatUnixTimestamp } from "pages/Opportunities/utils/featureRule";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setContactPersonPhone,
  setCountryCode,
  setMediumOfContact,
  setMeetingType,
  setPrepareQuoteContactData,
  setPrepareQuoteContactId,
  setProduct,
  setProductPurpose,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getContactDetailsPrepareQuote,
  getPrepareQuoteNotes,
  GetPrepareQuoteNotesRequestType,
  GetSearchConatctForPrepareQuoteRequestType,
  PutUpdatePrepareQuoteNotes,
  PutUpdatePrepareQuoteNotesReqType,
} from "service/apis/CreateForexQuotation";
import {
  getQuoteforCustomer,
  GetQuoteLeadRequestType,
} from "service/apis/CustomerDetailsapi";
import { toast, Toaster } from "sonner";
import { L2Step1 } from "./L2Step1";
import { L2Step2 } from "./L2Step2";
import { L2Step2CardComponent } from "./L2Step2Card";
import { L2Step3 } from "./L2Step3";
import { L2Step4 } from "./L2Step4";
import { L2Step5 } from "./L2Step5";
import { L2Step6 } from "./L2Step6";
import { L2Step7 } from "./L2Step7";

interface PrepareOuoteL2FlowStepsProps {
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}
interface Step1Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  setContactDetails?: React.Dispatch<React.SetStateAction<any>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
}
const ComboBoxCurrency = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [countryCodeList, setCountryCodeList] = useState<string[]>([]);
  const { country_code } = useAppSelector((state) => state.CreateOpportunity);
  const [value, setValue] = useState("");
  const URL =
    "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json";

  const fetchCurrency = useCallback(async () => {
    try {
      const res = await fetch(URL);
      const data = await res.json();
      setCountryCodeList(data?.map((item: any) => item?.dial_code));
    } catch (error) {
      console.error(error);
    }
  }, [open]);

  return (
    <Popover
      open={open}
      onOpenChange={(val) => {
        setOpen(val);
        if (val) fetchCurrency();
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          aria-expanded={open}
          className="justify-between h-12 py-4 px-1 flex border-r-0 border-black-label rounded-tr-none rounded-br-none"
        >
          {country_code ? (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              {country_code}
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          ) : (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              Select
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-28 py-2">
        <Command className="p-0">
          <CommandInput placeholder="Code" />
          <CommandList>
            <CommandGroup>
              {countryCodeList?.map((framework, index) => (
                <CommandItem
                  key={index.toFixed(2)}
                  value={framework}
                  className="hover:bg-[#EFF4FF] py-3.5 w-full"
                  onSelect={(currentValue) => {
                    dispatch(setCountryCode(currentValue));
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === framework ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {framework}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

// for searching contact number based data customer or contact
export const Step1Component: React.FC<Step1Props> = ({
  setCurrentStep,
  setContactDetails = () => {},
  setProcessState,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Partial<ContactData[]>>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [customerDetails, setcustomerDetails] = useState<any>();
  const [notesInputValue, setNotesInputValue] = useState<string>(
    customerDetails?.notes || "",
  );
  const [isSelectedNumber, setISSelectedNumber] = useState<boolean>(false);
  const ContactDetailsInfo = options?.[0];
  const [selectdConatcted, setSelectedContatcted] = useState<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleEditClick = () => {
    setNotesInputValue(customerDetails?.notes || "");
    setIsEditing(true);
  };

  // updated  notes
  const handlePrepareQuoteNotesUpdated = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    let payload: AxiosRequestConfig<PutUpdatePrepareQuoteNotesReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    payload.data = {
      phone_number: ContactDetailsInfo?.contactPhone as string,
      notes: notesInputValue || "",
    };

    try {
      const res = (await PutUpdatePrepareQuoteNotes(payload)) as any;
      if (res.status === 200) {
        toast.success("Customer notes updated successfully");
        setcustomerDetails(res?.data);
        setLoading(false);
        setIsEditing(false);
      }
    } catch (error) {
      toast.error("Failed to Customer notes updated");
      setLoading(false);
    }
  };

  const fetchGetQuoteNotes = async () => {
    if (!inputValue) {
      return;
    }
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetPrepareQuoteNotesRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        phone_number: selectdConatcted?.contactPhone
          ? selectdConatcted?.contactPhone
          : inputValue,
      },
    };
    try {
      if (inputValue === "") return;
      const response = (await getPrepareQuoteNotes(payload)) as any;
      if (response?.data?.length === 0) {
        return;
      }
      if (response.status === 200) {
        setcustomerDetails(response?.data);
        setLoading(false);
        setIsEditing(false);
        setNotesInputValue(response?.data?.notes);
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error("Search limit exceeded.", {
          description:
            "You have exceeded the limit of requests. Please try again later.",
        });
      }
      toast.error("Error fetching options");
    }
  };

  // search contact number
  const fetchSearchResults = async () => {
    if (!inputValue) {
      return;
    }
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetSearchConatctForPrepareQuoteRequestType> =
      {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          phone_number: inputValue,
        },
      };
    try {
      if (inputValue === "") return;
      const response = (await getContactDetailsPrepareQuote(payload)) as any;
      if (response?.data?.length === 0) {
        dispatch(setContactPersonPhone(inputValue));
        setOptions(() => []);
        setShowDropdown(true);
        setSelectedNumber(null);
        return;
      }
      let newOptions: ContactData[];
      newOptions = response?.data?.map((item: any) => ({
        value: item?.id,
        label: item?.contactName,
        contactName: item?.contactName,
        contactPhone: item?.contactNumber,
        email: item?.contactEmail,
        location: item?.contactAddress[0],
        type: item?.contactPersonType,
        contactType: item?.contactPersonType,
      }));

      const startingWithInput = newOptions.filter((item) =>
        item.contactPhone.startsWith(inputValue),
      );
      const containingInput = newOptions.filter(
        (item) =>
          !item.contactPhone.startsWith(inputValue) &&
          item.contactPhone.includes(inputValue),
      );

      // Prioritize numbers that start with inputValue
      const sortedOptions = [...startingWithInput, ...containingInput];

      setShowDropdown(true);
      setOptions(sortedOptions);
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error("Search limit exceeded.", {
          description:
            "You have exceeded the limit of requests. Please try again later.",
        });
      }
      toast.error("Error fetching options");
    }
  };

  // conatct Search
  useDebounce(
    () => {
      if (!isSelectedNumber) {
        fetchSearchResults();
      }
    },
    500,
    [inputValue, isSelectedNumber],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotesInputValue(e.target.value);
  };

  const handleSave = () => {
    if (notesInputValue) {
      handlePrepareQuoteNotesUpdated();
    }
  };

  const handleSelectList = (
    option: any,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    setInputValue(option.contactPhone);
    setSelectedNumber(option.contactPhone);
    setContactDetails(option);
    dispatch(setPrepareQuoteContactData(option));
    dispatch(setPrepareQuoteContactId(option?.value));
    setShowDropdown(false);
    setSelectedContatcted(option);
    setISSelectedNumber(true);
  };

  useEffect(() => {
    if (customerDetails?.notes) {
      setNotesInputValue(customerDetails.notes);
    }
  }, [customerDetails]);

  // get notes
  useEffect(() => {
    if (inputValue) {
      fetchGetQuoteNotes();
    }
  }, [inputValue]);

  return (
    <div
      className={`max-w-md flex flex-col ${showDropdown ? "gap-4" : "gap-8"}`}
    >
      <label
        htmlFor="contact_person_phone"
        className={`text-sm font-medium text-[#333]`}
      >
        What is the Mobile Number ?
      </label>
      <div className="relative w-full h-12 flex rounded-md">
        <ComboBoxCurrency />
        <input
          type="number"
          placeholder="Enter the mobile number"
          onChange={(e) => {
            const value = e.target.value;
            setInputValue(value);
            if (value.trim() === "") {
              setOptions([]);
              setShowDropdown(false);
              setSelectedNumber(null);
              setISSelectedNumber(false);
            }
          }}
          value={inputValue}
          className="text-sm font-medium border border-black-label h-12 rounded-lg rounded-tl-none border-l-0 rounded-bl-none p-4 w-full"
        />
      </div>
      {showDropdown && (
        <div
          className={`${showDropdown ? "block" : "hidden"} relative w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg max-h-48 overflow-y-auto`}
        >
          <div className="dropdown">
            {options?.length > 0 ? (
              options.map((option: any) => (
                <button
                  key={option.value}
                  className="flex w-full justify-between items-center p-3 text-sm font-medium cursor-pointer hover:bg-gray-100"
                  onClick={(event) => handleSelectList(option, event)}
                >
                  <span className="text-gray-600">{option.contactPhone}</span>
                  <span className="font-medium">{option.contactName}</span>
                </button>
              ))
            ) : (
              <div className="p-3">
                <div className="text-sm font-medium flex justify-between items-center p-3">
                  <span className="text-gray-600">No Contact found</span>
                </div>
                {inputValue.length >= 10 && (
                  <button
                    className="flex w-full text-sm font-medium justify-between items-center p-3 cursor-pointer bg-[#165FE3] text-[#fff] rounded-md hover:bg-[#144AB6]"
                    onClick={() =>
                      navigate(`/lead/createcontact`, {
                        state: {
                          pageName: "prepareQuote",
                        },
                      })
                    }
                  >
                    <span className="font-medium w-full text-center">
                      + Add Contact
                    </span>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* contact deatils */}
      {selectedNumber && (
        <>
          <div className="flex flex-col gap-3 w-full">
            <div className="bg-[#F9F9F9] rounded-xl py-8 px-10 flex flex-col gap-6 w-full">
              <div className="flex flex-col gap-3 text-sm font-medium">
                <div className="flex items-center gap-2.5">
                  <img
                    src="/images/createorder/profileIcon.svg"
                    alt="Remitter Name"
                    className="w-5 h-5"
                  />
                  <small className="text-gray-900 text-[14px] font-medium">
                    {selectdConatcted?.contactName ?? "N/A"}
                  </small>
                </div>
                <div className="flex text-sm font-medium flex-col gap-3">
                  <div className="flex items-center gap-2.5">
                    <img
                      src="/images/createorder/call.svg"
                      alt="Phone"
                      className="w-5 h-5"
                    />
                    <small className="text-gray-900 text-[14px] font-medium">
                      {selectdConatcted?.contactPhone !== ""
                        ? selectdConatcted?.contactPhone
                        : "N/A"}
                    </small>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <img
                      src="/images/createorder/sms.svg"
                      alt="Email"
                      className="w-5 h-5"
                    />
                    <small className="text-gray-900 text-[14px] font-medium">
                      {selectdConatcted?.email === ""
                        ? "N/A"
                        : selectdConatcted?.email}
                    </small>
                  </div>
                </div>
                <div className="flex items-center gap-2.5">
                  <img
                    src="/images/createorder/location.svg"
                    alt="Address"
                    className="w-5 h-5"
                  />
                  <small className="max-w-xs text-gray-900 text-[14px] font-medium">
                    {selectdConatcted?.location !== ""
                      ? selectdConatcted?.location
                      : "N/A"}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#fafafa] p-5 flex sm:flex-row gap-3 w-full md:w-full sm: w-full rounded-xl relative">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  {isEditing ? (
                    <div className="relative flex flex-col gap-2 items-start">
                      <textarea
                        value={notesInputValue}
                        onChange={handleInputChange}
                        className="text-[14px] font-medium border rounded p-2 sm:w-full w-[400px] resize-none focus:outline-none overflow-auto "
                        placeholder="Enter your notes here"
                        rows={4}
                        style={{ minHeight: "100px", maxHeight: "200px" }}
                      />
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={handleSave}
                          className="text-white-A700 cursor-pointer bg-blue-700 text-sm rounded-lg py-1.5 px-3 hover:bg-blue-700"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setIsEditing(false)}
                          className="text-gray-600 bg-gray-300 cursor-pointer text-sm rounded-lg py-1.5 px-3 hover:bg-gray-400"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[400px] h-[150px]">
                      {customerDetails?.notes ? (
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row gap-2">
                            <img
                              src="/images/intialNotes.svg"
                              width={15}
                              height={15}
                            />
                            <TooltipProvider>
                              <Tooltip>
                                {!checkLevel(LEVEL_2) && (
                                  <TooltipTrigger
                                    className="flex cursor-pointer items-center w-full md:w-auto"
                                    asChild
                                  >
                                    <div className="flex items-center space-x-1">
                                      <p className="text-[14px] font-medium md:text-base leading-[22px] md:leading-[25px] text-black-label">
                                        Internal notes
                                      </p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8-3a1 1 0 100 2 1 1 0 000-2zm-1 4a1 1 0 012 0v2a1 1 0 01-2 0v-2z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                  </TooltipTrigger>
                                )}
                                <TooltipContent
                                  side="bottom"
                                  className="bg-black-900 text-white-A700 max-w-[200px] whitespace-normal break-words border-0"
                                >
                                  <p className="text-[14px] font-medium md:text-base leading-[22px] md:leading-[25px]">
                                    Visible to the partner
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <p className="whitespace-normal break-words h-[70px] text-[14px] font-medium overflow-auto overflow-ellipsis">
                            {customerDetails?.notes || "N/A"}
                          </p>
                          <p className="font-normal text-[14px] font-medium leading-[18px] text-[#999999] mt-3">
                            {customerDetails?.updated_at && (
                              <>
                                Edited on{" "}
                                {formatUnixTimestamp(
                                  customerDetails?.updated_at,
                                )}{" "}
                                by {customerDetails?.fname}
                              </>
                            )}
                          </p>
                        </div>
                      ) : (
                        <p className="whitespace-normal text-[14px] font-medium break-words h-full overflow-hidden overflow-ellipsis">
                          No notes available
                        </p>
                      )}
                    </div>
                  )}
                </TooltipTrigger>
              </Tooltip>

              {/* Right div - Edit button */}
              <div className="flex ml-auto cursor-pointer">
                {!isEditing && !checkLevel(LEVEL_2) && (
                  <svg
                    onClick={handleEditClick}
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 cursor-pointer"
                  >
                    <g clipPath="url(#clip0_1689_36824)">
                      <path
                        d="M3.83594 4.82812H3.0026C2.56058 4.82813 2.13665 5.00372 1.82409 5.31628C1.51153 5.62884 1.33594 6.05276 1.33594 6.49479V13.9948C1.33594 14.4368 1.51153 14.8607 1.82409 15.1733C2.13665 15.4859 2.56058 15.6615 3.0026 15.6615H10.5026C10.9446 15.6615 11.3686 15.4859 11.6811 15.1733C11.9937 14.8607 12.1693 14.4368 12.1693 13.9948V13.1615"
                        stroke="#165FE3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.3333 3.16676L13.8333 5.66676M14.9875 4.48759C15.3157 4.15938 15.5001 3.71424 15.5001 3.25009C15.5001 2.78594 15.3157 2.34079 14.9875 2.01259C14.6593 1.68438 14.2142 1.5 13.75 1.5C13.2858 1.5 12.8407 1.68438 12.5125 2.01259L5.5 9.00009V11.5001H8L14.9875 4.48759Z"
                        stroke="#165FE3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1689_36824">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            </TooltipProvider>
          </div>
        </>
      )}
      <button
        className={`w-[150px] p-3 rounded-lg text-center text-[14px] font-medium ${selectedNumber ? "bg-[#165FE3] text-[#fff]" : "bg-gray-400 text-gray-200"}`}
        onClick={() => setCurrentStep((prev) => prev + 1)}
        disabled={!selectedNumber}
      >
        {/* Select Product */}
        Get Quote
      </button>
    </div>
  );
};

const PrepareOuoteL2FlowSteps: React.FC<PrepareOuoteL2FlowStepsProps> = ({
  setPageTitle = () => {},
}) => {
  const { product_purpose, prepare_quote_Contact_id } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [productMode, setProductMode] = useState<string>("");
  const [selectProduct, setSelectProduct] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [connectedContactList, setConneectedList] = useState([]);
  const [contactDetails, setContactDetails] = useState<any>();
  const [isProductLoan, setIsProductLoan] = useState<string>("");
  const [isModeCondition, setIsModeConditoion] = useState<boolean>(false);
  const [isCardStep, setISCardStep] = useState<boolean>(false);
  const [processState, setProcessState] = useState<any>({
    Product: "",
    Mode: "",
    "Education Loan": "",
    "Offer Letter": "",
    Country: "",
  });

  useEffect(() => {
    dispatch(setProductPurpose(""));
    dispatch(setProduct(""));
    dispatch(setMediumOfContact(""));
    dispatch(setMeetingType(""));
  }, [location.pathname, dispatch]);

  const fetchGetQuoteDetails = useCallback(async () => {
    try {
      setLoading(true);
      const auth: string | null = localStorage.getItem("authToken");
      if (!auth) {
        toast.error("Authorization token is missing.");
        setLoading(false);
        return;
      }

      const authToken = JSON.parse(auth);
      const payload: AxiosRequestConfig<GetQuoteLeadRequestType> = {
        data: {
          product_id: product_purpose,
          contacts_id: prepare_quote_Contact_id || contactDetails?.value,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const res: any = await getQuoteforCustomer(payload);

      if (!res?.data || res?.data?.length === 0) {
        setConneectedList([]);
        setIsModeConditoion(false);
        return;
      } else {
        setConneectedList(res.data);
        setIsModeConditoion(true);
      }
      return res.data;
    } catch (err) {
      toast.error("Error fetching partner details.");
    } finally {
      setLoading(false);
    }
  }, [product_purpose, prepare_quote_Contact_id, contactDetails]);

  useEffect(() => {
    const hasId = prepare_quote_Contact_id || contactDetails?.value;
    if (product_purpose && currentStep === 9 && hasId) {
      fetchGetQuoteDetails();
    }
  }, [
    prepare_quote_Contact_id,
    product_purpose,
    currentStep,
    contactDetails,
    fetchGetQuoteDetails,
  ]);

  const handlePrevious = () => {
    if (currentStep === 1) return;
    if (!connectedContactList.length && currentStep === 3) {
      setCurrentStep(2);
    } else setCurrentStep((prev) => prev - 1);
    setErrors({});
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="sm:p-1 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
        <div className="gap-5 w-full flex h-full flex-col">
          <div className="rounded-xl flex h-full gap-5 w-full sm:px-0 px-5 justify-between py-2.5 overflow-auto">
            <div className="flex relative flex-col gap-5 overflow-y-auto flex-grow">
              {/* select contact */}
              {currentStep === 1 && (
                <Step1Component
                  setCurrentStep={setCurrentStep}
                  setContactDetails={setContactDetails}
                  setSelectProduct={setSelectProduct}
                  setPageTitle={setPageTitle}
                  setProcessState={setProcessState}
                />
              )}
              {/* select Product */}
              {currentStep === 2 && (
                <L2Step1
                  setCurrentStep={setCurrentStep}
                  setPageTitle={setPageTitle}
                  setProcessState={setProcessState}
                />
              )}

              {/* select Mode */}
              {currentStep === 3 && (
                <>
                  <L2Step2
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    setPageTitle={setPageTitle}
                    setProcessState={setProcessState}
                    handlePrevious={handlePrevious}
                    processState={processState}
                    isCardStep={isCardStep}
                  />
                </>
              )}
              {/* select Loan type */}
              {currentStep === 4 && (
                <>
                  <L2Step3
                    setIsProductLoan={setIsProductLoan}
                    setCurrentStep={setCurrentStep}
                    setPageTitle={setPageTitle}
                    setProcessState={setProcessState}
                    isProductLoan={isProductLoan}
                    handlePrevious={handlePrevious}
                    processState={processState}
                    connectedContactList={connectedContactList}
                  />
                </>
              )}
              {currentStep === 5 && (
                <>
                  <L2Step4
                    setIsProductLoan={setIsProductLoan}
                    setCurrentStep={setCurrentStep}
                    setPageTitle={setPageTitle}
                    setProcessState={setProcessState}
                    isProductLoan={isProductLoan}
                    handlePrevious={handlePrevious}
                    processState={processState}
                  />
                </>
              )}
              {currentStep === 6 && (
                <L2Step5
                  setCurrentStep={setCurrentStep}
                  setPageTitle={setPageTitle}
                  setProcessState={setProcessState}
                  handlePrevious={handlePrevious}
                />
              )}

              {currentStep === 7 && (
                <L2Step6
                  setCurrentStep={setCurrentStep}
                  setPageTitle={setPageTitle}
                  handlePrevious={handlePrevious}
                  processState={processState}
                  setProcessState={setProcessState}
                />
              )}

              {currentStep === 8 && (
                <L2Step7
                  setCurrentStep={setCurrentStep}
                  setPageTitle={setPageTitle}
                  handlePrevious={handlePrevious}
                  processState={processState}
                  setProcessState={setProcessState}
                />
              )}

              {currentStep === 9 && (
                <L2Step2CardComponent
                  setCurrentStep={setCurrentStep}
                  productMode={productMode}
                  currentStep={currentStep}
                  setProductMode={setProductMode}
                  selectProduct={selectProduct}
                  connectedContactList={connectedContactList}
                  setIsProductLoan={setIsProductLoan}
                  isProductLoan={isProductLoan}
                  setPageTitle={setPageTitle}
                  isModeCondition={isModeCondition}
                  processState={processState}
                  setProcessState={setProcessState}
                  setISCardStep={setISCardStep}
                  fetchGetQuoteDetails={fetchGetQuoteDetails}
                />
              )}
            </div>
            {Object.keys(processState).some((key) => processState[key]) && (
              <div className="flex flex-col gap-5 sm:hidden w-[20%] overflow-y-auto">
                <h3 className="font-semibold text-lg text-black">
                  <span className="capitalize">You Selected</span>
                </h3>
                <div className="flex flex-col gap-4">
                  {Object.keys(processState)
                    .filter((key) => processState[key])
                    .map((key) => (
                      <div
                        key={key}
                        className="flex flex-col items-start gap-2"
                      >
                        <span className="text-[14px] font-semibold">{key}</span>
                        <h1 className="w-full bg-[#F9F9F9] text-[#333] text-[14px] font-medium py-1 px-2 rounded-md text-center">
                          {processState[key]}
                        </h1>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </>
  );
};

export default PrepareOuoteL2FlowSteps;
