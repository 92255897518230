import { AxiosPromise, AxiosRequestConfig } from "axios";
import { apis } from "../index";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const PartnerDetails = `${BASE_URL}:MhhkaM6y/channels/:id`;
const approvalcommmisonrate = `${BASE_URL}:MhhkaM6y/approvals`;
const getcommmisonrate = `${BASE_URL}:CwCdonPE/default_commision_rates`;
const branchdetails = `${BASE_URL}:yyd3i3Ww/branch`;
const partnerregistrationdetails = `${BASE_URL}:EetDwnQJ/channels/getStep/:id`;
const deactivate = `${BASE_URL}:MhhkaM6y/deactivate/:id`;
const axtivate = `${BASE_URL}:MhhkaM6y/activate/:id`;
const activeGICProduct = `${BASE_URL}:MhhkaM6y/enableGIC`;
const GET_PRODUCTS = `${BASE_URL}:zm5pHrC3/product`;
const ACTIVATE_PRODUCT = `${BASE_URL}:EetDwnQJ/channels/activateProduct`;
const LINK_BANK_ACCOUNT = `${BASE_URL}:MhhkaM6y/bank-account/link`;
const UPDATE_BANK_ACCOUNT = `${BASE_URL}:MhhkaM6y/bank-account/update`;
const GET_BANK_ACCOUNT = `${BASE_URL}:MhhkaM6y/bank-account/get/:id`;
const GET_ALL_BANK_ACCOUNT = `${BASE_URL}:MhhkaM6y/bank-account/get`;
const REMOVE_BANK_ACCOUNT = `${BASE_URL}:MhhkaM6y/bank-account/remove`;
const BANK_ACCOUNT_TRANSFER = `${BASE_URL}:MhhkaM6y/bank-account/transfer`;
const BANK_ACCOUNT_VERIFY_TRANSFER = `${BASE_URL}:MhhkaM6y/bank-account/verify_transfer`;
const MARK_CHECKLIST = `${BASE_URL}:zK0A2jXj/mark_particular_task`;
const corporate_channel_details = `${BASE_URL}:AtzopIo1/corporate/getStep/:id`

// Get Partner Details
export type GetPartnerDetailsRequestType = Partial<{
  channels_id: string | number;
  headers: { Authorization: string };
}>;

export type EditPartnerDetailRequestType = {
  partner_id: number;
  staff_id: number;
  reminder_time?: number;
  reminder_note: string;
  note: string;
};

export type EditPartnerDetailResponseType = Partial<{}>;

export type ProductRowType = {
  id: string | number;
  name: string;
  status: string;
  label: string;
  value: string;
};

export type BranchDetailsType = {
  id: string | number;
  name: string;
  address_line_1: string;
  city: string;
  gst_number: string;
  status: string;
};
export type CommissionRateTableRowType = {
  purpose_id: string | number;
  margin: string;
  propose_margin: string;
  purpose: string;
  id: string;
  charges: string;
  proposed_charges: string;
};

export type BankDetailsRowType = {
  status: string;
  bank_name: string;
  account_number: string;
  ifsc_code: string;
};

export type TeamMemberType = {
  id: number;
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  channels_id: number;
  linked_partner_id: number;
};

export type GetPartnerDetailsResponseType = Partial<{
  id: string | number;
  name: string;
  phone: string;
  email: string;
  status?: string;
  pan_type: string;
  type: string;
  pan: {
    url: string;
  };
  aadhar_front: {
    url: string;
  };
  address_proof: {
    url: string;
  };
  masked_pan_no: string;
  masked_aadhar_no: string;
  _realtionship_manager_channels: any;
  _onboarding_checklist: {
    id: string;
    checklist: {
      task: string;
      value: boolean;
      can_user_tick: boolean;
    }[];
  };
  approval_status: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  property_type: string;
  interactions: any[];
  products: ProductRowType[];
  office_photos?: { url: string }[];
  photos?: { url: string }[];
  _branch?: BranchDetailsType[];
  _channel_commission?: CommissionRateTableRowType[];
  __bank_account_of_channels: BankDetailsRowType[];
  team_member?: TeamMemberType[];
}>;

export const getPartnerDetails = (
  payload: AxiosRequestConfig<GetPartnerDetailsRequestType>,
): AxiosPromise<GetPartnerDetailsResponseType> => {
  const channelsId = payload.data?.channels_id;
  if (channelsId === undefined) {
    throw new Error("channels_id is not provided in the payload.");
  }
  const url = PartnerDetails.replace(":id", channelsId.toString());
  return apis.get(url, payload);
};

// Get Commision Rate

export type GetCommisionRateRequestType = Partial<{
  headers: { Authorization: string };
  product_id: number;
}>;
export type GetCommisionRateResponseType = Partial<{}>;

export const getCommisionRate = (
  payload: AxiosRequestConfig<GetCommisionRateRequestType>,
): AxiosPromise<GetCommisionRateResponseType> => {
  return apis.get(getcommmisonrate, payload);
};

// Edit partner details
export const editPartnerDetail = (
  payload: AxiosRequestConfig<EditPartnerDetailRequestType>,
): AxiosPromise<EditPartnerDetailResponseType> => {
  const url = `${BASE_URL}:rNhSdWJ8:UAT/editPartnerDetail`;
  return apis.patch(url, payload);
};

// Post Approval Commision Rate
export type PostApprovalCommisionRateRequestType = Partial<{
  child_channel: string | number;
  commision_rates: [
    {
      commision_rates_id: string | number;
      charges: string | number;
    },
  ];
  headers: { Authorization: string };
}>;

export type PostApprovalCommisionRateResponseType = Partial<{}>;

export const postApprovalCommisionRate = (
  payload: AxiosRequestConfig<PostApprovalCommisionRateRequestType>,
): AxiosPromise<PostApprovalCommisionRateResponseType> => {
  return apis.post(approvalcommmisonrate, payload);
};

// get branch details

export type GetBranchDetailsRequestType = Partial<{
  headers: { Authorization: string };
  channels_id: string | number;
}>;

export type GetBranchDetailsResponseType = Partial<{}>;

export const getBranchDetails = (
  payload: AxiosRequestConfig<GetBranchDetailsRequestType>,
): AxiosPromise<GetBranchDetailsResponseType> => {
  const url = branchdetails + `/${payload.data?.channels_id}`;
  return apis.get(url, payload);
};

// get incomplete partner details and resume steps

export type GetPartnerIncompleteStepsRequestType = Partial<{
  headers: { Authorization: string };
  channels_id: string | number;
}>;

export type GetPartnerIncompleteStepsResponseType = Partial<{
  id: string | number;
  name: string;
  phone: string;
  email: string;
  status?: string;
  pan_type: string;
  type: string;
  pan: {
    url: string;
  };
  aadhar_front: {
    url: string;
  };
  address_proof: {
    url: string;
  };
  masked_pan_no: string;
  masked_aadhar_no: string;
  _realtionship_manager_channels: any;
  approval_status: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  property_type: string;
  office_photos?: { url: string }[];
  photos?: { url: string }[];
  _branch?: BranchDetailsType[];
  _channel_commission?: CommissionRateTableRowType[];
}>;

export const getPartnerIncompleteSteps = (
  payload: AxiosRequestConfig<GetPartnerIncompleteStepsRequestType>,
): AxiosPromise<GetPartnerIncompleteStepsResponseType> => {
  const channelsId = payload.data?.channels_id;

  if (channelsId === undefined) {
    throw new Error("channels_id is not provided in the payload.");
  }

  const url = partnerregistrationdetails.replace(":id", channelsId.toString());
  return apis.get(url, payload);
};

// get corporate steps details
export type GetcorporateIncompleteStepsRequestType = Partial<{
  headers: { Authorization: string };
  corporate_id: string | number;
}>;

export type GetcorporateIncompleteStepsResponseType = Partial<{
  id: string | number;
  name: string;
  phone: string;
  email: string;
  status?: string;
  pan_type: string;
  type: string;
  pan: {
    url: string;
  };
  aadhar_front: {
    url: string;
  };
  address_proof: {
    url: string;
  };
  masked_pan_no: string;
  masked_aadhar_no: string;
  _realtionship_manager_channels: any;
  approval_status: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  property_type: string;
  office_photos?: { url: string }[];
  photos?: { url: string }[];
  _branch?: BranchDetailsType[];
  _channel_commission?: CommissionRateTableRowType[];
}>;

export const getCorparteIncompleteSteps = (
  payload: AxiosRequestConfig<GetcorporateIncompleteStepsRequestType>,
): AxiosPromise<GetcorporateIncompleteStepsResponseType> => {
  const corporatesId = payload.data?.corporate_id;

  if (corporatesId === undefined) {
    throw new Error("channels_id is not provided in the payload.");
  }

  const url = corporate_channel_details.replace(":id", corporatesId.toString());
  return apis.get(url, payload);
};


// put deactivate
export type PutDeactivateRequestType = Partial<{
  channels_id: string | number;
  headers: { Authorization: string };
}>;

export type PutDeactivateResponseType = Partial<{}>;

export const putDeactivate = (
  payload: AxiosRequestConfig<PutDeactivateRequestType>,
): AxiosPromise<PutDeactivateResponseType> => {
  const channelsId = payload.data?.channels_id;

  if (channelsId === undefined) {
    throw new Error("channels_id is not provided in the payload.");
  }

  const url = deactivate.replace(":id", channelsId.toString());
  return apis.put(url, payload);
};

// put activate
export type PutActivateRequestType = Partial<{
  channels_id: string | number;
  headers: { Authorization: string };
}>;
export type PutActivateResponseType = Partial<{}>;

export const putActivate = (
  payload: AxiosRequestConfig<PutActivateRequestType>,
): AxiosPromise<PutActivateResponseType> => {
  const channelsId = payload.data?.channels_id;

  if (channelsId === undefined) {
    throw new Error("channels_id is not provided in the payload.");
  }

  const url = axtivate.replace(":id", channelsId.toString());
  return apis.put(url, payload);
};

// post activate product
export type EnableGICRequestType = Partial<{
  headers: { Authorization: string };
  channel_id: string | number;
  gic_account_margin: string | number;
}>;
export type EnableGICResponseType = Partial<{}>;

export const enableGIC = (
  payload: AxiosRequestConfig<PutActivateRequestType>,
): AxiosPromise<PutActivateResponseType> => {
  return apis.post(activeGICProduct, payload);
};

// Get All Products API
export type GetAllProductsRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetAllProductsResponseType = Partial<{
  id: string;
  name: string;
}>;

export const getAllProducts = (
  payload: AxiosRequestConfig<GetAllProductsRequestType>,
): AxiosPromise<GetAllProductsResponseType> => apis.get(GET_PRODUCTS, payload);

// Activate Product Commision Rates API Step 5
export type ActivateProductRequestType = Partial<{
  child_channel: number | string;
  unapproved_commision_rates: {
    purpose_id: number | string;
    charges: string | number;
  }[];
  approved_commision_rates: {
    purpose_id: number | string;
    charges: string | number;
  };
  headers: { Authorization: string };
}>;
export type ActivateProductResponseType = Partial<{
  channel: {
    id: string;
    approval_status: string;
  };
}>;
export const activateProduct = (
  payload: AxiosRequestConfig<ActivateProductRequestType>,
): AxiosPromise<ActivateProductResponseType> =>
  apis.post(ACTIVATE_PRODUCT, payload);

// Link Bank Account
export type LinkBankAccountRequestType = Partial<{
  channel_id: string | number;
  cancel_cheque: string;
  headers: { Authorization: string };
}>;
export type LinkBankAccountResponseType = Partial<{
  id: string;
  bank_name: string;
  account_number: string | number;
  ifsc_code: string;
}>;
export const linkBankAccount = (
  payload: AxiosRequestConfig<LinkBankAccountRequestType>,
): AxiosPromise<LinkBankAccountResponseType> =>
  apis.post(LINK_BANK_ACCOUNT, payload);

// Update Bank Account Details
export type UpdateBankAccountRequestType = Partial<{
  account_id: string | number;
  cancel_cheque: string;
  headers: { Authorization: string };
}>;
export type UpdateBankAccountResponseType = Partial<{
  id: string;
  bank_name: string;
  account_number: string | number;
  ifsc_code: string;
}>;
export const updateBankAccount = (
  payload: AxiosRequestConfig<UpdateBankAccountRequestType>,
): AxiosPromise<UpdateBankAccountResponseType> =>
  apis.post(UPDATE_BANK_ACCOUNT, payload);

// get incomplete partner details and resume steps

export type GetBankAccountRequestType = Partial<{
  headers: { Authorization: string };
  account_id: string | number;
}>;

export type GetBankAccountResponseType = Partial<{
  account_id: string | number;
  bank_name: string;
  account_number: string | number;
  ifsc_code: string;
}>;

export const getBankAccount = (
  payload: AxiosRequestConfig<GetBankAccountRequestType>,
): AxiosPromise<GetBankAccountResponseType> => {
  const accountId = payload.data?.account_id;

  if (accountId === undefined) {
    throw new Error("Account_id is not provided in the payload.");
  }

  const url = GET_BANK_ACCOUNT.replace(":id", accountId.toString());
  return apis.get(url, payload);
};

// Get All Bank Account
export type GetAllBankAccountRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetAllBankAccountResponseType = Partial<{}>;
export const getAllBankAccount = (
  payload: AxiosRequestConfig<LinkBankAccountRequestType>,
): AxiosPromise<LinkBankAccountResponseType> =>
  apis.get(GET_ALL_BANK_ACCOUNT, payload);

// Delete Bank Account
export type DeleteBankAccountRequestType = Partial<{
  headers: { Authorization: string };
  account_id: string | number;
}>;
export type DeleteBankAccountResponseType = Partial<{}>;
export const deleteBankAccount = (
  payload: AxiosRequestConfig<DeleteBankAccountRequestType>,
): AxiosPromise<DeleteBankAccountResponseType> =>
  apis.delete(REMOVE_BANK_ACCOUNT, payload);

// Bank Account Transfer
export type BankAccountTranferRequestType = Partial<{
  account_id: string | number;
  bank_name: string;
  account_number: string | number;
  ifsc_code: string;
  headers: { Authorization: string };
}>;
export type BankAccountTranferResponseType = Partial<{}>;
export const bankAccountTranfer = (
  payload: AxiosRequestConfig<BankAccountTranferRequestType>,
): AxiosPromise<BankAccountTranferResponseType> =>
  apis.post(BANK_ACCOUNT_TRANSFER, payload);

// Bank Account Verify Transfer
export type BankAccountVerifyTranferRequestType = Partial<{
  account_id: string | number;
  amount: string | number;
  headers: { Authorization: string };
}>;
export type BankAccountVerifyTranferResponseType = Partial<{}>;
export const bankAccountVerifyTranfer = (
  payload: AxiosRequestConfig<BankAccountVerifyTranferRequestType>,
): AxiosPromise<BankAccountVerifyTranferResponseType> =>
  apis.post(BANK_ACCOUNT_VERIFY_TRANSFER, payload);

// Mark/Unmark CheckList
export type ToggleCheckListRequestType = Partial<{
  onboarding_checklist_id: string | number;
  task_obj: {
    task: string;
    value: boolean;
  };
  headers: { Authorization: string };
}>;
export type ToggleCheckListResponseType = Partial<{
  completed: boolean;
  checklist: {
    id: string;
    checklist: {
      task: string;
      value: boolean;
      can_user_tick: boolean;
    }[];
  };
}>;
export const toggleCheckList = (
  payload: AxiosRequestConfig<ToggleCheckListRequestType>,
): AxiosPromise<ToggleCheckListResponseType> =>
  apis.put(MARK_CHECKLIST, payload);
