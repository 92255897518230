import { Option } from "pages/Opportunities/types";
import { useFormContext } from "react-hook-form";
import { FormData } from ".";
import { AxiosRequestConfig } from "axios";
import { getCountry, GetCountryRequestType } from "service/apis/CreateDealapi";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export default function Step5() {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();

  const [countryoption, setCountryoption] = useState<Option[]>([]);

  const fetchCountry = async () => {
    try {
      const payload: AxiosRequestConfig<GetCountryRequestType> = {};
      const { data } = await getCountry(payload);

      if (!data || data.length === 0) return;

      const countryList = data.map((item: any) => ({
        value: item.country,
        label: item.country,
      }));
      setCountryoption(countryList);
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  return (
    <div className="flex relative flex-col gap-3 items-start h-full justify-start w-full">
      <Select
        value={watch("country")}
        onValueChange={(value) => {
          setValue("country", value);
          setValue("currentStep", 6);
        }}
      >
        <SelectTrigger className="w-full py-6 px-3">
          <SelectValue placeholder="Select country" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {countryoption.map((item) => (
              <SelectItem
                className="hover:bg-blue_gray-lite-bg cursor-pointer py-2.5"
                key={item.value}
                value={item.value}
              >
                {item.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
