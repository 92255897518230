import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Spinner } from "components";
import Stepper from "components/Stepper/newstepper";
import { useState } from "react";
import { toast, Toaster } from "sonner";
import { createGroupSteps } from "utils";
import SelectRole from "./step1";
import SelectOffice from "./step2";
import SelectProducts from "./step3";
import BasicDetails from "./step4";
import NavbarWithActions from "components/Navbar/navbarwithactions";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { AxiosRequestConfig } from "axios";
import { createGroup, CreateGroupRequestType } from "service/apis/CreateStaff";
import { resetState } from "redux/features/CreateGroup";
import { useDispatch } from "react-redux";

interface CreateGroupProps {
  dialogOpen?: boolean;
  handleDialogOpen?: (value: boolean) => void;
  showNav?: boolean;
}

export default function CreateGroup({
  showNav = true,
}: Readonly<CreateGroupProps>) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const state = useAppSelector((state) => state.CreateGroups);
  const dispatch = useDispatch();

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <SelectRole />;
      case 2:
        return <SelectOffice />;
      case 3:
        return <SelectProducts />;
      case 4:
        return <BasicDetails />;
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (currentStep === createGroupSteps.length) {
      handleSubmit();
      return;
    }
    if (currentStep < createGroupSteps.length) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const buttonState = () => {
    switch (currentStep) {
      case 1:
        return state.role;
      case 2:
        return state.office;
      case 3:
        return (
          state.products.remittance.length ||
          state.products.forex.length ||
          state.products.visa.length ||
          state.products.travel.length ||
          state.products.studyabroad.length
        );
      case 4:
        return state.group_name;
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const auth_token = localStorage.getItem("authToken");
    if (auth_token === null) return;
    const authToken = JSON.parse(auth_token);
    const payload: AxiosRequestConfig<CreateGroupRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        roles_id: state.role.toString(),
        branch_access: state.office.toString(),
        product_id: [
          ...state.products.remittance.map((product) => product.id),
          ...state.products.forex.map((product) => product.id),
          ...state.products.visa.map((product) => product.id),
          ...state.products.travel.map((product) => product.id),
          ...state.products.studyabroad.map((product) => product.id),
        ],
        name: state.group_name,
        description: state.group_description,
      },
    };

    try {
      await createGroup(payload);
      setLoading(false);
      dispatch(resetState());
      toast.success("Group created successfully");
      navigate("/group");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create group");
    }
  };

  return (
    <div
      style={{ margin: showNav ? "0" : "", padding: showNav ? "12px" : "0" }}
      className="bg-[#EBEEF5] flex flex-col h-screen overflow-hidden gap-3"
    >
      {loading && <Spinner />}
      {showNav && <NavbarWithActions header="New Group" />}
      <div
        style={{ borderRadius: showNav ? "12px" : "0" }}
        className="sm:p-1 bg-white-A700 h-full overflow-auto flex p-3 flex-col font-inter items-start justify-start mx-auto w-full"
      >
        <div className="gap-2 w-full flex h-full flex-col">
          <div className=" flex items-start w-full gap-2 px-5 pt-2.5 pb-0">
            <div className="flex-1">
              <Stepper
                steps={createGroupSteps.map((val) => val.name)}
                currentStep={currentStep - 1}
              />
            </div>
            <AlertDialog>
              <AlertDialogTrigger>
                <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                  Cancel
                </button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                    <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                      Do you want to{" "}
                      <span className="font-semibold text-blue-700">
                        discard
                      </span>{" "}
                      the group?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                      This action cannot be undone. This will permanently cancel
                      the ongoing progress.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                    <AlertDialogCancel
                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                      asChild
                    >
                      <button>Close</button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                      asChild
                    >
                      <button
                        className=""
                        onClick={() => {
                          navigate("/group");
                        }}
                      >
                        Confirm
                      </button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          </div>
          <div className="rounded-xl flex flex-col justify-between h-full gap-5 w-full sm:px-3 px-5 py-2.5 overflow-auto">
            <div className="flex relative flex-col gap-5 w-full overflow-y-auto flex-grow">
              {renderStep()}
            </div>
            <div className="flex gap-3 sm:flex-col sm:items-start">
              {currentStep !== 1 && (
                <button
                  type="button"
                  onClick={handlePrevious}
                  className={`px-4 py-[11px] w-fit text-sm font-semibold border border-blue-700 rounded-lg text-blue-700 ${true ? "" : "opacity-40"}`}
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                disabled={!buttonState()}
                onClick={handleNext}
                className={`px-4 py-[11px] whitespace-nowrap text-sm font-semibold rounded-lg border border-blue-700 bg-blue-700 text-white-A700 disabled:opacity-40`}
              >
                {currentStep === createGroupSteps.length ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </div>
  );
}
