export const checkLevel = (lvl: string) => {
  const level = localStorage.getItem("level");
  if (level === lvl) {
    return true;
  }
  return false;
};

export const rules = {
  dashboard: {},
};
