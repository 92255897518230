import { AxiosRequestConfig } from "axios";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { Option } from "pages/Opportunities/types";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
  setCompanyAddress,
  setContactPersonName,
  setContactPersonPhone,
  setContactPersonType,
  setCountryCode,
  setCustomerContactAddress,
  setCustomerContactEmail,
  setRemitNote,
} from "redux/features/CreateOpportunity";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { GetContactReqType, getContacts } from "service/apis/Opportunities";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Check, ChevronDown } from "lucide-react";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

const typeOfContactPerson: Option[] = [
  { value: "employee", label: "Employee" },
  { value: "coordinator", label: "Coordinator" },
];

export default function ExistingContactDetail({ errors }: ContactDetailsProps) {
  const { id } = useParams();
  const {
    remit_note,
    customer_contact_email,
    contact_person_name,
    contact_person_phone,
    customer_contact_address,
    company_address,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<Option[]>(typeOfContactPerson);
  const [contacType, setContactType] = useState<Option[]>([]);
  const [selectedContactOption, setSelectedContactOption] =
    useState<Option | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [addAddress, setAddAddress] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dottedBorderWidth = "after:h-28";

  const handleChange = (newValue: SingleValue<Option>) => {
    setSelectedOption(newValue);
    dispatch(setContactPersonType(newValue?.value ?? ""));
  };

  const fetchContacts = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        companyId: id,
        number: inputValue,
      },
    };
    try {
      const res = (await getContacts(payload)) as any;
      setContactType(() =>
        res?.data?.[0]?.map((item: any) => ({
          value: item?.contactName,
          label: item?.contactNumber,
        })),
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Only check required fields (name and phone)
    if (
      [contact_person_name, contact_person_phone].some((item) => item === "")
    ) {
      setAddAddress(false);
      errors = {};
    }
  }, [contact_person_name, contact_person_phone]);

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setCustomerContactEmail(""));
    dispatch(setRemitNote(""));
    dispatch(setCompanyAddress(""));
  }, [pathname]);

  useDebounce(fetchContacts, 500, [inputValue]);

  return (
    <form className="max-w-md">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full">
        {/* Phone Field (Required) */}
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${
              errors?.contact_person_phone ? "after:h-36" : dottedBorderWidth
            } after:border-blue-700 absolute after:relative after:content-[''] after:border after:border-dashed`}
          >
            <img
              className="relative -left-10"
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="contact_person_phone"
            className={`text-sm ${true ? "text-[#6B7280]" : "text-[#c4c4c4]"}`}
          >
            What is the mobile number of {contact_person_name}? *
          </label>
          <div className="relative w-full h-12 flex rounded-md">
            <ComboBoxCurrency />
            <input
              type="number"
              placeholder="Enter the mobile number"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 15) {
                  alert("The mobile number cannot exceed 15 digits.");
                  return;
                }
                dispatch(setContactPersonPhone(value));
              }}
              id="contact_person_phone"
              value={contact_person_phone}
              className="border border-black-label h-12 rounded-lg rounded-tl-none border-l-0 rounded-bl-none p-4 w-full"
              required
            />
          </div>
          {errors?.contact_person_phone && (
            <p className="text-red-500 text-sm">
              {errors.contact_person_phone}
            </p>
          )}
        </div>
        {/* Name Field (Required) */}
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${
              errors?.contact_person_phone
                ? "after:h-36"
                : contact_person_phone
                  ? dottedBorderWidth
                  : "after:h-12 after:border-[#999999]"
            } after:border-blue-700 absolute after:relative after:content-[''] after:border after:border-dashed`}
          >
            <img
              className="relative -left-10"
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="contact_person"
            className={`text-sm ${contact_person_phone ? "text-[#6B7280]" : "text-[#c4c4c4]"}`}
          >
            What is the full name of the customer? *
          </label>
          {contact_person_phone && (
            <input
              type="text"
              placeholder="Enter full name of the customer"
              onChange={(e) => dispatch(setContactPersonName(e.target.value))}
              id="contact_person"
              value={contact_person_name}
              className="border border-black-label h-12 rounded-lg p-4 w-full"
              required
            />
          )}
          {errors?.contact_person_name && (
            <p className="text-red-500 text-sm">{errors.contact_person_name}</p>
          )}
        </div>

        {/* Email Field (Optional) */}
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${
              errors?.contact_person_email
                ? "after:h-36"
                : contact_person_name && contact_person_phone
                  ? dottedBorderWidth
                  : "after:h-12 after:border-[#999999]"
            } after:border-blue-700 absolute after:relative after:content-[''] after:border after:border-dashed`}
          >
            <img
              className="relative -left-10"
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="contact_person_email"
            className={`text-sm ${
              contact_person_name && contact_person_phone
                ? "text-[#6B7280]"
                : "text-[#c4c4c4]"
            }`}
          >
            What is the email ID of {contact_person_name}? (Optional)
          </label>
          {contact_person_name && contact_person_phone && (
            <input
              type="email"
              placeholder="Enter the email ID (Optional)"
              onChange={(e) =>
                dispatch(setCustomerContactEmail(e.target.value))
              }
              id="contact_person_email"
              value={customer_contact_email}
              className="border border-black-label h-12 rounded-lg p-4 w-full"
            />
          )}
          {errors?.contact_person_email && (
            <p className="text-red-500 text-sm">
              {errors.contact_person_email}
            </p>
          )}
        </div>

        {/* Notes Field (Optional) */}
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${
              !addAddress ? "after:h-0" : "after:h-52 after:border-blue-700"
            } absolute after:relative after:content-[''] after:border after:border-dashed`}
          >
            <img
              className="relative -left-10"
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="product_notes"
            className={`text-sm ${
              contact_person_name && contact_person_phone
                ? "text-[#6B7280]"
                : "text-[#c4c4c4]"
            }`}
          >
            Add contact internal notes (if any)
          </label>
          {contact_person_name && contact_person_phone && (
            <textarea
              placeholder="Write note here.... (Optional)"
              value={remit_note}
              onChange={(e) => dispatch(setRemitNote(e.target.value))}
              className="border h-36 border-black-label rounded-lg p-4 w-full"
            />
          )}
        </div>

        {/* Address Section (Optional) */}
        {addAddress && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div className="flex sm:hidden items-center top-0 flex-col after:right-10 after:h-0 absolute after:relative after:content-[''] after:border after:border-dashed">
              <img
                className="relative -left-10"
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="address" className="text-sm">
              Add address (Optional)
            </label>
            <textarea
              placeholder="Enter address here (Optional)"
              value={company_address}
              onChange={(e) => dispatch(setCompanyAddress(e.target.value))}
              className="border h-36 border-black-label rounded-lg p-4 w-full"
            />
          </div>
        )}

        {/* Add/Cancel Address Button */}
        {[contact_person_name, contact_person_phone].some(
          (item) => item !== "",
        ) && (
          <button
            disabled={!contact_person_name || !contact_person_phone}
            type="button"
            onClick={() => {
              if (addAddress) {
                dispatch(setCustomerContactAddress(""));
              }
              setAddAddress(!addAddress);
            }}
            className="text-left text-blue-700 underline"
          >
            {addAddress ? "Cancel" : "Add"} address
          </button>
        )}
      </div>
    </form>
  );
}

const ComboBoxCurrency = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [countryCodeList, setCountryCodeList] = useState<string[]>([]);
  const { country_code } = useAppSelector((state) => state.CreateOpportunity);
  const [value, setValue] = useState("");
  const URL =
    "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json";

  const fetchCurrency = useCallback(async () => {
    try {
      const res = await fetch(URL);
      const data = await res.json();
      setCountryCodeList(data?.map((item: any) => item?.dial_code));
    } catch (error) {
      console.error(error);
    }
  }, [open]);

  return (
    <Popover
      open={open}
      onOpenChange={(val) => {
        setOpen(val);
        if (val) fetchCurrency();
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          aria-expanded={open}
          className="justify-between h-12 py-4 px-1 flex border-r-0 border-black-label rounded-tr-none rounded-br-none"
        >
          {country_code ? (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              {country_code}
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          ) : (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              Select
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-28 py-2">
        <Command className="p-0">
          <CommandInput placeholder="Code" />
          <CommandList>
            <CommandGroup>
              {countryCodeList?.map((framework, index) => (
                <CommandItem
                  key={index.toFixed(2)}
                  value={framework}
                  className="hover:bg-[#EFF4FF] py-3.5 w-full"
                  onSelect={(currentValue) => {
                    dispatch(setCountryCode(currentValue));
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === framework ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {framework}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
