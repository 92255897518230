import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import { memo, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

type SidebarMainProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    showSidebar: boolean;
    setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  }>;

interface SideBarMenuType {
  icon?: string;
  label: string;
  suffix?: JSX.Element;
  href?: string;
  target?: string;
  active?: boolean;
  enabled?: boolean;
  sideBarSubMenu?: {
    icon?: string;
    label: string;
    href?: string;
    active?: boolean;
    subItems?: {
      label: string;
      href: string;
      active: boolean;
      enabled?: boolean;
    }[];
  }[];
}

const SidebarMain: React.FC<SidebarMainProps> = memo((props) => {
  const [, setCurrentMenu] = useState<string>("");
  const location = useLocation();
  const currentPath = location.pathname;
  const isEmployee = localStorage.getItem("isEmployee") !== "true";
  const level = localStorage.getItem("level");
  // const logosrc = localStorage.getItem("logosrc");
  // const { logo } = useAppSelector((state) => state.User);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const sideBarClick = () => {
    props?.setShowSidebar && props.setShowSidebar(!props.showSidebar);
    scrollToTop();
  };

  let sideBarMenu: SideBarMenuType[] = [
    {
      icon: "/images/sidebar_img/overview.svg",
      label: "Home",
      href: "/dashboard",
      active: currentPath === "/dashboard",
    },
    // {
    //   icon: "/images/sidebar_img/customer.svg",
    //   label: "Lead",
    //   href: "/opportunities",
    //   active: currentPath === "/opportunities",
    // },
    // {
    //   icon: "/images/sidebar_img/calc.svg",
    //   label: "Forex Quotation",
    //   href: "/createforexQuotation",
    //   active: currentPath === "/createforexQuotation",
    // },
    {
      label: "Services",
      sideBarSubMenu: [
        {
          icon: "/images/sidebar_img/remit.svg",
          label: "Remittance",
          href: "/remit",
          active: currentPath === "/remit",
          subItems: [
            {
              label: "Overview",
              href: "/remit",
              active: currentPath === "/remit",
            },
            {
              label: "Leads",
              href: "/remit/leads",
              active: currentPath === "/remit/leads",
            },
            {
              label: "Orders",
              href: "/remit/orders",
              active: currentPath === "/remit/orders",
            },
            {
              label: "Deals",
              href: "/remit/deals",
              active: currentPath === "/remit/deals",
            },
          ],
        },
        {
          icon: "/images/sidebar_img/forex.svg",
          label: "Forex",
          href: "/leads/forex",
          active: currentPath === "/leads/forex",
        },
        {
          icon: "/images/sidebar_img/visa.svg",
          label: "Visa",
          href: "/leads/visa",
          active: currentPath === "/leads/visa",
        },
        {
          icon: "/images/sidebar_img/travel.svg",
          label: "Travel",
          href: "/leads/travel",
          active: currentPath === "/leads/travel",
        },
        {
          icon: "/images/sidebar_img/remit.svg",
          label: "Quotation",
          href: "/quotations",
          active: currentPath === "/quotations",
        },
        // {
        //   icon: "/images/sidebar_img/forex.svg",
        //   label: "Forex",
        //   href: "/deals",
        //   active: currentPath === "/deals",
        //   subItems:[
        //     {
        //       label:'Orders',
        //       href:'/orders',
        //       active:currentPath==='/orders'
        //     },
        //     {
        //       label:'Deals',
        //       href:'/deals',
        //       active:currentPath==='/deals'
        //     }
        //   ]
        // }
      ],
    },
    // {
    //   label: "Quotation",
    //   sideBarSubMenu: [
    //     {
    //       icon: "/images/sidebar_img/calc.svg",
    //       label: "Forex Quotation",
    //       href: "/createforexQuotation",
    //       active: currentPath === "/createforexQuotation",
    //     },
    //     {
    //       icon: "/images/sidebar_img/remit_calc.svg",
    //       label: "Remit Quotation",
    //       href: "/createRemittanceQuotation",
    //       active: currentPath === "/createRemittanceQuotation",
    //     },
    //   ],
    // },
    {
      label: "CRM",
      sideBarSubMenu: [
        {
          icon: "/images/sidebar_img/retail.svg",
          label: "Retail",
          href: "/lead/contacts",
          active:
            currentPath === "/lead/contacts" ||
            currentPath.includes("/leads/contact"),
        },
        {
          icon: "/images/sidebar_img/partner.svg",
          label: "Partner",
          href: "/lead/contacts/partner",
          active: currentPath === "/lead/contacts/partner",
        },
        {
          icon: "/images/sidebar_img/corporate.svg",
          label: "Corporate",
          href: "/lead/contacts/corporate",
          active: currentPath === "/lead/contacts/corporate",
        },
      ],
    },
    {
      label: "General",
      sideBarSubMenu: [
        // {
        //   icon: "/images/sidebar_img/calc.svg",
        //   label: "Forex Quotation",
        //   href: "/createforexQuotation",
        //   active: currentPath === "/createforexQuotation",
        // },
        // {
        //   icon: "/images/sidebar_img/remit_calc.svg",
        //   label: "Remit Quotation",
        //   href: "/createRemittanceQuotation",
        //   active: currentPath === "/createRemittanceQuotation",
        // },
        {
          icon: "/images/sidebar_img/customer.svg",
          label: "My Leads",
          href: "/leads",
          active: currentPath === "/leads",
        },
        {
          icon: "/images/sidebar_img/admin.svg",
          label: "Admin",
          href: "/group",
          active: currentPath === "/group",
          subItems: [
            {
              label: "Staff",
              href: "/staff",
              active: currentPath === "/staff",
            },
            {
              label: "Groups",
              href: "/group",
              active: currentPath === "/group",
            },
          ],
        },
        // {
        //   icon: "/images/sidebar_img/customer.svg",
        //   label: "Customers",
        //   href: "/customers",
        //   active: currentPath === "/customers",
        // },
        // {
        //   icon: "/images/sidebar_img/approvals.svg",
        //   label: "Approvals",
        //   href: "/approvals",
        //   active: currentPath === "/approvals",
        // },
        // {
        //   icon: "/images/sidebar_img/customer.svg",
        //   label: "Partners",
        //   href: "/partners",
        //   active: currentPath === "/partners",
        // },
        // {
        //   icon: "/images/sidebar_img/accessandroles.svg",
        //   label: "Access and Roles",
        //   href: "/accessandroles",
        //   active: currentPath === "/accessandroles",
        // },
        // {
        //   icon: "/images/sidebar_img/finance.svg",
        //   label: "Finance",
        //   href: "/finance",
        //   active: currentPath === "/finance",
        // },
        {
          icon: "/images/sidebar_img/finance.svg",
          label: "Staff",
          href: "/staff",
          active: currentPath === "/staff",
        },
        {
          icon: "/images/sidebar_img/finance.svg",
          label: "Groups",
          href: "/group",
          active: currentPath === "/group",
        },
        {
          icon: "/images/overview/logout.svg",
          label: "Logout",
          href: "/logout",
          active: currentPath === "/logout",
        },
      ],
    },
  ];

  // let sideBarMenu: SideBarMenuType[] = [
  //   {
  //     icon: "images/sidebar_img/overview.svg",
  //     label: "Overview",
  //     href: "/dashboard",
  //     active: currentPath === "/dashboard",
  //     enabled: true,
  //   },
  //   {
  //     icon: "images/sidebar_img/crm.svg",
  //     label: "Meetings",
  //     href: "/entry-form",
  //     active: currentPath === "/entry-form",
  //     enabled: true,
  //   },
  //   {
  //     label: "Our Products",
  //     sideBarSubMenu: [
  //       {
  //         icon: "images/sidebar_img/remit.svg",
  //         label: "Remit",
  //         href: "/remit",
  //         active: currentPath === "/remit",
  //         // subItems: [
  //         //   {
  //         //     label: "Orders",
  //         //     href: "/orders",
  //         //     active: currentPath === "/orders",
  //         //   },
  //         //   {
  //         //     label: "Deals",
  //         //     href: "/deals",
  //         //     active: currentPath === "/deals",
  //         //   },
  //         // ],
  //       },
  //       // {
  //       //   icon: "images/sidebar_img/forex.svg",
  //       //   label: "Forex",
  //       //   href: "/deals",
  //       //   active: currentPath === "/deals",
  //       //   subItems: [
  //       //     {
  //       //       label: "Orders",
  //       //       href: "/orders",
  //       //       active: currentPath === "/orders",
  //       //     },
  //       //     {
  //       //       label: "Deals",
  //       //       href: "/deals",
  //       //       active: currentPath === "/deals",
  //       //     },
  //       //   ],
  //       // },
  //     ],
  //   },
  //   {
  //     label: "Quotation",
  //     sideBarSubMenu: [
  //       {
  //         icon: "images/sidebar_img/calc.svg",
  //         label: "Forex Quotation",
  //         href: "/createforexQuotation",
  //         active: currentPath === "/createforexQuotation",
  //       },
  //       {
  //         icon: "images/sidebar_img/remit_calc.svg",
  //         label: "Remit Quotation",
  //         href: "/createRemittanceQuotation",
  //         active: currentPath === "/createRemittanceQuotation",
  //       },
  //     ],
  //   },
  //   {
  //     icon: "images/sidebar_img/customer.svg",
  //     label: "Customers",
  //     href: "/customers",
  //     active: currentPath === "/customers",
  //   },
  //   {
  //     icon: "/images/sidebar_img/customer.svg",
  //     label: "Opportunity",
  //     href: "/opportunities",
  //     active: currentPath === "/opportunities",
  //   },
  //   {
  //     icon: "/images/sidebar_img/accessandroles.svg",
  //     label: "Access and Roles",
  //     href: "/accessandroles",
  //     active: currentPath === "/accessandroles",
  //   },
  //   {
  //     icon: "/images/sidebar_img/finance.svg",
  //     label: "Finance",
  //     href: "/finance",
  //     active: currentPath === "/finance",
  //   },
  //   {
  //     icon: "/images/sidebar_img/finance.svg",
  //     label: "Staff",
  //     href: "/staff",
  //     active: currentPath === "/staff",
  //   },
  //   {
  //     icon: "/images/sidebar_img/customer.svg",
  //     label: "Customers",
  //     href: "/customers",
  //     active: currentPath === "/customers",
  //   },
  //   {
  //     icon: "/images/sidebar_img/approvals.svg",
  //     label: "Approvals",
  //     href: "/approvals",
  //     active: currentPath === "/approvals",
  //   },
  //   {
  //     label: "General",
  //     sideBarSubMenu: [
  //       // {
  //       //   icon: "images/overview/settings.svg",
  //       //   label: "Settings",
  //       //   href: "/settings",
  //       //   active: currentPath === "/settings",
  //       // },
  //       // {
  //       //   icon: "images/overview/profile.svg",
  //       //   label: "Profile",
  //       //   href: "/profile",
  //       //   active: currentPath === "/profile",
  //       // },
  //       {
  //         icon: "images/overview/logout.svg",
  //         label: "Logout",
  //         href: "/logout",
  //         active: currentPath === "/logout",
  //       },
  //     ],
  //   },
  // ];

  if (isEmployee) {
    sideBarMenu = sideBarMenu.filter(
      (obj) =>
        obj.label !== "Staff" &&
        obj.label !== "Approvals" &&
        obj.label !== "Settings" &&
        obj.label !== "Finance",
    );
  }
  if (level !== "1") {
    sideBarMenu = sideBarMenu.filter(
      (obj) =>
        obj.label !== "Quote" &&
        obj.label !== "Finance" &&
        obj.label !== "Staff" &&
        obj.label !== "Approvals" &&
        obj.label !== "Partners" &&
        obj.label !== "Access and Roles",
    );

    sideBarMenu.forEach((item) => {
      // Check if the item has a subtype array and subtypeName exists in it
      if (
        item.sideBarSubMenu &&
        item.sideBarSubMenu.find((subtype) => subtype.label === "GIC Accounts")
      ) {
        // Remove the subtype with the given name
        item.sideBarSubMenu = item.sideBarSubMenu.filter(
          (subtype) => subtype.label !== "GIC Accounts",
        );
      }
    });
  }
  if (level === "3") {
    sideBarMenu = sideBarMenu.filter((obj) => obj.label !== "Partners");
  }

  // const handleFocusChange = () => {
  //   if (currentSubMenuOpen !== "") {
  //     setTimeout(() => {
  //       setCurrentSubMenuOpen("");
  //     }, 150);
  //   }
  // }

  if (checkLevel(LEVEL_2)) {
    sideBarMenu = sideBarMenu.map((obj) => {
      if (obj.label === "Services" && obj.sideBarSubMenu) {
        return {
          ...obj,
          sideBarSubMenu: obj.sideBarSubMenu.filter(
            (subItem) => subItem.label === "Remittance",
          ),
        };
      }

      if (obj.label === "CRM" && obj.sideBarSubMenu) {
        return {
          ...obj,
          sideBarSubMenu: obj.sideBarSubMenu
            .filter((subItem) => subItem.label === "Retail")
            .map((subItem) => ({ ...subItem, label: "Contact" })),
        };
      }
      if (obj.label === "General" && obj.sideBarSubMenu) {
        return {
          ...obj,
          sideBarSubMenu: obj.sideBarSubMenu.filter(
            (subItem) => subItem.label === "Logout",
          ),
        };
      }

      return obj;
    });
  }

  return (
    <Sidebar
      className={`${props.className} sm:px-0 md:px-0 lg:hidden px-2 bg-white-A700 flex flex-row sm:h-auto h-full overflow-y-auto`}
    >
      <Menu
        menuItemStyles={{
          button: {
            padding: "14px 20px",
            gap: "20px",
            margin: "6.5px 0",
            // backgroundColor: "#ffffff",
            color: "#292d32",
            fontSize: "16px",
            fontFamily: "Inter",
            borderRadius: "8px",
            "&:hover, &.ps-active": {
              color: "#165FE3",
              fontWeight: "400 !important",
              backgroundColor: "#E1EBFC !important",
            },
          },
        }}
        className={`flex p-3 bg-white-A700 rounded-b-xl flex-col items-center ${"justify-start"} `}
      >
        {sideBarMenu?.map((menu, i: number) => {
          return (
            <div aria-hidden key={i} className="">
              {menu?.sideBarSubMenu ? (
                <div className="">
                  <span className="text-xs text-neutral-400_1">
                    {menu.label}
                  </span>
                  <div className="max-h-56 overflow-y-scroll scrollbar-hide">
                    {
                      // skipcq: JS-0323
                      menu.sideBarSubMenu.map((submenu: any, key: number) => {
                        return (
                          <div className="" key={key}>
                            {submenu?.subItems?.length > 0 ? (
                              <SubMenu
                                className=""
                                label={
                                  <div>
                                    <div className="w-full flex gap-x-2 justify-start items-center">
                                      {submenu.icon && (
                                        <img
                                          className=""
                                          src={submenu.icon}
                                          alt="icon"
                                        />
                                      )}
                                      {
                                        <span className="w-full text-xs font-medium tracking-[0.50px]">
                                          {submenu.label}
                                        </span>
                                      }
                                    </div>
                                  </div>
                                }
                              >
                                {// skipcq: JS-0323
                                submenu?.subItems?.map(
                                  (subItem: any, key: number) => {
                                    return (
                                      <div key={key} className="w-full flex">
                                        <div className="border-l border-[#DCDCDC] ml-8">
                                          {" "}
                                        </div>
                                        <MenuItem
                                          className={`${"px-4"}`}
                                          style={{ border: "none" }}
                                          active={
                                            subItem.href
                                              ? subItem.href === currentPath
                                              : false
                                          }
                                        >
                                          <Link
                                            className={`w-full flex gap-x-2 ${"justify-start"} items-center`}
                                            to={subItem.href || ""}
                                            onClick={sideBarClick}
                                          >
                                            {
                                              <span className="w-full text-xs font-medium tracking-[0.50px]">
                                                {subItem.label}
                                              </span>
                                            }
                                          </Link>
                                        </MenuItem>
                                      </div>
                                    );
                                  },
                                )}
                              </SubMenu>
                            ) : (
                              <MenuItem
                                className=""
                                key={key}
                                active={
                                  submenu.href
                                    ? submenu.href === currentPath
                                    : false
                                }
                              >
                                <Link
                                  className={`w-full flex gap-x-2 ${"justify-start"} items-center`}
                                  to={submenu.href || ""}
                                  // skipcq: JS-0417
                                  onClick={() => {
                                    setCurrentMenu(submenu.label);
                                    sideBarClick();
                                  }}
                                >
                                  {submenu.icon && (
                                    <img
                                      className=""
                                      src={submenu.icon}
                                      alt="icon"
                                    />
                                  )}
                                  {
                                    <span className="w-full text-wrap text-xs font-medium tracking-[0.50px]">
                                      {submenu.label}
                                    </span>
                                  }
                                </Link>
                              </MenuItem>
                            )}
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className="pt-3">
                    <hr />
                  </div>
                </div>
              ) : (
                <div className="">
                  <MenuItem
                    active={menu.href ? menu.href === currentPath : false}
                  >
                    <Link
                      className="w-full flex gap-2 justify-start items-center"
                      // skipcq: JS-0417
                      onClick={() => {
                        setCurrentMenu(menu.label);
                        sideBarClick();
                      }}
                      to={menu.href || ""}
                    >
                      {menu.icon && (
                        <img className="" src={menu.icon} alt="icon" />
                      )}
                      {
                        <span className="w-full text-xs font-medium">
                          {menu.label}
                        </span>
                      }
                    </Link>
                  </MenuItem>
                </div>
              )}
            </div>
          );
        })}
      </Menu>
    </Sidebar>
  );
});

SidebarMain.defaultProps = {};

export default SidebarMain;
