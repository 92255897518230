import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Option } from "components/Form/form";
import React, { useEffect, useState } from "react";
import {
  setProduct,
  setProductPurpose,
  setQuoteProduct,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const productOptions: Option[] = [
  { value: "remittance", label: "Remittance" },
  { value: "forex", label: "Forex" },
  { value: "visa", label: "Visa" },
  { value: "travel", label: "Travel" },
  { value: "studyabroad", label: "Study Abroad" },
];

interface Step1Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setContactDetails?: React.Dispatch<React.SetStateAction<any>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
}

export const L1ProductCard: React.FC<Step1Props> = ({
  setCurrentStep,
  setSelectProduct,
  setProcessState,
  setPageTitle,
}) => {
  const { quoteProduct } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const [selectedProduct, setSelectedProduct] = useState<string>("");

  useEffect(() => {
    dispatch(setQuoteProduct(""));
    setSelectedProduct("");
  }, [dispatch]);

  useEffect(() => {
    setPageTitle("Select Product Group");
  }, [setPageTitle]);

  const handleProductGroupChange = (tab: string) => {
    setSelectedProduct(tab);
    dispatch(setQuoteProduct(tab));
    dispatch(setProductPurpose(""));
    dispatch(setProduct(tab));
    setCurrentStep((prev) => prev + 1);
    setProcessState((prevState: any) => ({
      ...prevState,
      "Product Group": tab,
    }));
    setPageTitle("Select Product");
  };

  return (
    <div className="flex flex-col gap-6 max-w-md">
      <form className="max-w-md">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <label className={`text-[18px] font-medium text-[#333]`}>
            Select any one to proceed.
          </label>
          {productOptions && (
            <RadioGroup
              className="flex w-full flex-col gap-4"
              value={selectedProduct}
              onValueChange={handleProductGroupChange}
            >
              {productOptions.map((tab) => (
                <label
                  key={tab.value}
                  htmlFor={tab.value}
                  className={`${
                    selectedProduct === tab.value
                      ? "border-blue-700"
                      : "border-black-label"
                  } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value={tab.value}
                    id={tab.value}
                    className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <p className="text-[14px] font-medium w-full">{tab.label}</p>
                </label>
              ))}
            </RadioGroup>
          )}
        </div>
      </form>
    </div>
  );
};
