import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import L1BookDealandRateNewDeal from "./L1Flow/newdeal";
import L2BookDealandRateNewDeal from "./L2Flow/newdeal";
import { useLocation } from "react-router-dom";

export const BookDealAndRatePage = () => {
  let isLevel2 = checkLevel(LEVEL_2);
  const { state }: any = useLocation();
  return (
    <div>
      {!isLevel2 ? (
        <L1BookDealandRateNewDeal partnerId={state?.partnerId} />
      ) : (
        <L2BookDealandRateNewDeal />
      )}
    </div>
  );
};
