import { Accordion } from "@/components/ui/accordion";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { OrderType } from "@/lib/validations/schema";
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { order_status_options } from "common/filters";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { Search } from "lucide-react";
import { IPage } from "pages/LeadContacts/partner_contact";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetFilterOrders, GetOrdersReqType } from "service/apis/CreateOrderapi";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";
import { getChannel, GetChannelRequestType } from "service/apis/CreateDealapi";

export const columns: ColumnDef<OrderType>[] = [
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = String(row.getValue(id));
      const searchValue = String(value);
      return rowValue.includes(searchValue);
    },
  },
  {
    accessorKey: "customerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Customer Name" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("customerName")}</div>,
    // filterFn: (row, id, value) => {
    //   return String(row.getValue(id))
    //     .toLowerCase()
    //     .includes(String(value).toLowerCase());
    // },
    enableSorting: false,
  },
  {
    accessorKey: "source",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Channel" />
    ),
    cell: ({ row }) => {
      return (
        <div>
          <div>{row.getValue("source") ?? "N/A"}</div>
          <div className="text-sm text-gray-500">
            {row?.original?.channelSource ?? "N/A"}
          </div>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({ row }) => {
      const amount = row.getValue("amount") as number;
      const paymentStatus = row?.original?.orderStatus;
      const status = order_status_options.find(
        (status) => status.value === paymentStatus,
      );

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex gap-2 items-center">
                {amount}
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.19987 13.6673C10.8819 13.6673 13.8665 10.6827 13.8665 7.00065C13.8665 3.31865 10.8819 0.333984 7.19987 0.333984C3.51787 0.333984 0.533203 3.31865 0.533203 7.00065C0.533203 10.6827 3.51787 13.6673 7.19987 13.6673ZM8.19987 4.33398C8.41854 4.62465 8.5752 4.96465 8.64987 5.33398H9.86654V6.33398H8.64987C8.5346 6.89867 8.22778 7.40618 7.78132 7.77064C7.33486 8.13509 6.7762 8.3341 6.19987 8.33398H6.0732L8.5532 10.814L7.84654 11.5207L4.5332 8.20732V7.33398H6.19987C6.51014 7.33408 6.8128 7.23796 7.06616 7.05886C7.31952 6.87976 7.51111 6.62651 7.61454 6.33398H4.5332V5.33398H7.61454C7.51111 5.04146 7.31952 4.7882 7.06616 4.60911C6.8128 4.43001 6.51014 4.33389 6.19987 4.33398H4.5332V3.33398H9.86654V4.33398H8.19987Z"
                    fill={status?.textColor}
                  />
                </svg>
              </div>
            </TooltipTrigger>
            <TooltipContent className="bg-black-900 flex gap-2 items-center border-none rounded-lg">
              <p className="text-white-A700 text-xs font-medium">
                Payment Status
              </p>
              {status ? (
                <p
                  style={{
                    backgroundColor: status.bgColor,
                    color: status.textColor,
                  }}
                  className="py-[3px] text-sm leading-4 font-medium px-2 text-center rounded-md"
                >
                  {status.label}
                </p>
              ) : (
                "Status not available"
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "serviceProvider",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Service Provider" />
    ),
    // filterFn: (row, id, value) => {
    //   return String(row.getValue(id))
    //     .toLowerCase()
    //     .includes(String(value).toLowerCase());
    // },
    enableSorting: false,
  },
  // {
  //   accessorKey: "Action",
  //   cell: ({ row }) => {
  //     return (
  //       <Link
  //         className="text-center w-full"
  //         to={`/remit/orderdetails/${(row.getValue("id") as string)?.split("-")[1]}`}
  //       >
  //         <Eye className="h-4 w-4 hover:text-blue-700" />
  //       </Link>
  //     );
  //   },
  // },
  {
    accessorKey: "orderStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Order Status" />
    ),
    cell: ({ row }) => {
      const status = order_status_options.find(
        (status) => status.value === row.getValue("orderStatus"),
      );
      if (!status) {
        return null;
      }
      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
        >
          {status.label}
        </p>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id));
    // },
    enableSorting: false,
  },
];

export const columnsL2: ColumnDef<OrderType>[] = [
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = String(row.getValue(id));
      const searchValue = String(value);
      return rowValue.includes(searchValue);
    },
  },
  {
    accessorKey: "customerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Customer Name" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("customerName")}</div>,
    // filterFn: (row, id, value) => {
    //   return String(row.getValue(id))
    //     .toLowerCase()
    //     .includes(String(value).toLowerCase());
    // },
    enableSorting: false,
  },
  {
    accessorKey: "amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({ row }) => {
      const amount = row.getValue("amount") as number;
      const paymentStatus = row?.original?.orderStatus;
      const status = order_status_options.find(
        (status) => status.value === paymentStatus,
      );

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex gap-2 items-center">
                {amount}
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.19987 13.6673C10.8819 13.6673 13.8665 10.6827 13.8665 7.00065C13.8665 3.31865 10.8819 0.333984 7.19987 0.333984C3.51787 0.333984 0.533203 3.31865 0.533203 7.00065C0.533203 10.6827 3.51787 13.6673 7.19987 13.6673ZM8.19987 4.33398C8.41854 4.62465 8.5752 4.96465 8.64987 5.33398H9.86654V6.33398H8.64987C8.5346 6.89867 8.22778 7.40618 7.78132 7.77064C7.33486 8.13509 6.7762 8.3341 6.19987 8.33398H6.0732L8.5532 10.814L7.84654 11.5207L4.5332 8.20732V7.33398H6.19987C6.51014 7.33408 6.8128 7.23796 7.06616 7.05886C7.31952 6.87976 7.51111 6.62651 7.61454 6.33398H4.5332V5.33398H7.61454C7.51111 5.04146 7.31952 4.7882 7.06616 4.60911C6.8128 4.43001 6.51014 4.33389 6.19987 4.33398H4.5332V3.33398H9.86654V4.33398H8.19987Z"
                    fill={status?.textColor}
                  />
                </svg>
              </div>
            </TooltipTrigger>
            <TooltipContent className="bg-black-900 flex gap-2 items-center border-none rounded-lg">
              <p className="text-white-A700 text-xs font-medium">
                Payment Status
              </p>
              {status ? (
                <p
                  style={{
                    backgroundColor: status.bgColor,
                    color: status.textColor,
                  }}
                  className="py-[3px] text-sm leading-4 font-medium px-2 text-center rounded-md"
                >
                  {status.label}
                </p>
              ) : (
                "Status not available"
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
    enableSorting: false,
  },
  // {
  //   accessorKey: "Action",
  //   cell: ({ row }) => {
  //     return (
  //       <Link
  //         className="text-center w-full"
  //         to={`/remit/orderdetails/${(row.getValue("id") as string)?.split("-")[1]}`}
  //       >
  //         <Eye className="h-4 w-4 hover:text-blue-700" />
  //       </Link>
  //     );
  //   },
  // },
  {
    accessorKey: "orderStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Order Status" />
    ),
    cell: ({ row }) => {
      const status = order_status_options.find(
        (status) => status.value === row.getValue("orderStatus"),
      );
      if (!status) {
        return null;
      }
      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
        >
          {status.label}
        </p>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id));
    // },
    enableSorting: false,
  },
];

type Checked = DropdownMenuCheckboxItemProps["checked"];

export default function OrderPage() {
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [productFilter, setProductFilter] = useState<string[]>([]);
  const [channelFilter, setChannelFilter] = useState<string[]>([]);
  const [partnerFilter, setPartnerFilter] = useState<string[]>([]);
  const [showStatusBar, setShowStatusBar] = useState<Checked>(false);
  const [showActivityBar, setShowActivityBar] = useState<Checked>(false);
  const [showPanel, setShowPanel] = useState<Checked>(false);
  const [showPartnerBar, setShowPartnerBar] = useState<Checked>(false);

  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [pageDetails, setPageDetails] = useState<IPage>();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [partneroption, setPartneroption] = useState<
    { label: string; value: string }[]
  >([]);

  const serviceProviderOptions = [
    {
      label: "World One India Forex Pvt Ltd",
      value: "world One India Forex Pvt Ltd",
      serviceID: 15,
    },
    { label: "Zenith", value: "zenith", serviceID: 17 },
    { label: "Transwire", value: "transwire", serviceID: 7 },
    { label: "Nium", value: "nium", serviceID: 20 },
    { label: "Multimoney", value: "multimoney", serviceID: 8 },
    { label: "Transcorp", value: "transcorp", serviceID: 10 },
    { label: "PrithviFX", value: "prithviFX", serviceID: 14 },
    { label: "Ebix", value: "ebix", serviceID: 12 },
  ];

  const channelOptions = [
    { label: "Walk in", value: "walkIn", channelID: 7 },
    { label: "Partner Referral", value: "partnerReferral", channelID: 12 },
    { label: "Employee Referral", value: "employeeReferral", channelID: 18 },
  ];

  const filterOptions: Record<
    string,
    { label: string; options: { label: string; value: string }[] }
  > = {
    status: {
      label: "Order Status",
      options: [
        { label: "Order Created", value: "orderCreated" },
        { label: "Processing", value: "processing" },
        { label: "Fulfilled", value: "fulfilled" },
        { label: "Cancelled", value: "cancelled" },
      ],
    },
    product: {
      label: "Service provider",
      options: serviceProviderOptions,
    },
    channel: {
      label: "Channel",
      options: [
        { label: "Walk in", value: "walkIn" },
        { label: "Partner Referral", value: "partnerReferral" },
        { label: "Employee Referral", value: "employeeReferral" },
      ],
    },
    partner: {
      label: "Partner",
      options: partneroption,
    },
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleAction = (rowData: any) => {
    // Your action logic here, e.g.:
    navigate(`/orderdetails/${(rowData.id as string)?.split("-")[1]}`);
  };

  // fecth all Partner
  const fetchPartner = (): void | null => {
    setIsLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };

    getChannel(payload)
      .then((res) => {
        if (res?.data && res?.data) {
          setPartneroption(
            res?.data
              ?.filter((item: any) => item.name && item.name.trim() !== "")
              .map((item: any) => {
                return {
                  label: item?.name,
                  value: item?.id,
                  status: item?.status,
                };
              }),
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPartner();
  }, []);

  // fecthOrder
  const fetchOrders = async (
    search: string,
    page: string | number,
    perPage: string | number,
  ) => {
    setIsLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const finalProduct = serviceProviderOptions
      .filter((option) => productFilter?.includes(option?.value))
      .map((option) => option?.serviceID);

    const finalChannelIDlist = channelOptions
      ?.filter((option) => channelFilter?.includes(option?.value))
      .map((option) => option?.channelID);

    const finalPartnerList = partneroption
      ?.filter((option) => partnerFilter?.includes(option?.value))
      .map((option) => option?.value);

    console.log("Print Partner FIlter ===>>", partnerFilter, finalPartnerList);

    const payload: AxiosRequestConfig<GetOrdersReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        page: page,
        per_page: perPage,
        service_partner_id: 0,
        start: null,
        end: null,
        payment_status: "",
        status: statusFilter || [],
        channel_of_interaction: finalChannelIDlist || [],
        service_partner_id_filter: finalProduct || [],
        partner_id: finalPartnerList || [],
        q: search.length == 0 ? "" : search,
      },
    };

    try {
      const res = (await GetFilterOrders(payload)) as any;
      const filterOrderData = res?.data?.result1;
      if (filterOrderData?.items && Array.isArray(filterOrderData?.items)) {
        const channel_id = localStorage.getItem("channel_id");
        setOrders(
          filterOrderData?.items?.map(
            (
              result: Partial<{
                id: string | number;
                _order_customers: {
                  full_name: string;
                  phone_number: string;
                };
                customers_id: string;
                status: string;
                amount: string;
                amount_inr: number;
                payable_amount: number;
                serviceProvider: string;
                Channel: string | string[];
                channelSource: string;
                branchName: string;
                partner_referral: string;
                employee_referral: string;
                _order_channels: {
                  id: string | number;
                  name: string;
                  status: string;
                };
                _order_service_partner: {
                  display_name: string;
                };
                payment_status: string;
                partner_status: string;
              }>,
            ) => {
              const finalSourceValue =
                result?._opportunity?.[0]?.channel_of_interaction_id;
              return {
                id: `ORD-${result.id}`,
                customerName: result?._order_customers?.full_name,
                // phone_number: result?._order_customers?.phone_number,
                orderStatus: result?.status,
                amount: result?.payable_amount?.toFixed(2),
                source:
                  finalSourceValue === 7
                    ? "Walk-In"
                    : finalSourceValue === 12
                      ? "Partner referral"
                      : finalSourceValue === 18
                        ? "Employee referral"
                        : "N/A",
                channelSource:
                  finalSourceValue === 7
                    ? result?._order_channels?.city
                    : finalSourceValue === 12
                      ? result?.createdBy?.name
                      : finalSourceValue === 18
                        ? result?._opportunity?.refferedBy_staff?.name
                        : "N/A",
                // result?._order_channels?.id == channel_id
                //   ? "Walkin Order"
                //   : result?._order_channels?.name &&
                //   result?._order_channels?.name
                //     ?.slice(0, 1)
                //     ?.toUpperCase() +
                //   result?._order_channels?.name?.slice(1).toLowerCase(),
                serviceProvider: result?._order_service_partner?.display_name,
                // paymentStatus:
                //   result?.payment_status === "recieved"
                //     ? "received"
                //     : result?.payment_status === "partially recieved"
                //       ? "partially received"
                //       : result?.payment_status,
                // partner_status: result?._order_channels?.status,
              };
            },
          ),
        );
      }
      if (currentPage > filterOrderData?.pageTotal) {
        setCurrentPage(filterOrderData?.pageTotal);
      } else if (currentPage === 0 && filterOrderData?.pageTotal > 0) {
        setCurrentPage(1);
      }
      setPageDetails({
        pageTotal: filterOrderData?.pageTotal,
        curPage: filterOrderData?.curPage,
        itemsReceived: filterOrderData?.itemsReceived,
        itemsTotal: filterOrderData?.itemsTotal,
        nextPage: filterOrderData?.nextPage,
        offset: filterOrderData?.offset,
        perPage: filterOrderData?.perPage,
        prevPage: filterOrderData?.prevPage,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setStatusFilter([]);
    setProductFilter([]);
    setChannelFilter([]);
    setPartnerFilter([]);
    setActiveFilter([]);
    setShowStatusBar(false);
    setShowActivityBar(false);
    setShowPanel(false);
    setShowPartnerBar(false);
    setSearch("");
  }, [pathname]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    fetchOrders(debouncedSearch, currentPage, perPage);
  }, [
    debouncedSearch,
    currentPage,
    perPage,
    statusFilter,
    channelFilter,
    productFilter,
    partnerFilter,
  ]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      {/* <div className=" flex w-full justify-end items-center"> */}
      {/* <div className=" flex flex-col gap-2">
          <h3 className=" font-semibold leading-4 text-lg text-black-label">
            Remit Orders
          </h3>
          <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
            List of all remittance order
          </p>
        </div> */}
      {/* <div className=" flex gap-3 pr-[40px]"> */}
      {/* <Link to={"/createRemit"}>
            <button className="rounded-lg py-[11px] px-4 border border-blue-700 text-blue-700 font-semibold text-sm">
              Create Gift & Maint. Order
            </button>
          </Link> */}
      {/* {!checkLevel(LEVEL_2) && (
            <Link to={"/createorder"}>
              <button className="rounded-lg py-[11px]  px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                Create Order
              </button>
            </Link>
          )} */}
      {/* </div> */}
      {/* </div> */}
      <div className="flex w-full sm:flex-col justify-left gap-2">
        <div className="flex flex-row justify-between md:flex-col gap-2 justify-between w-full items-center p-4">
          <div className="flex flex-row w-full sm:flex-row gap-2 w-full">
            <div className="relative flex items-center w-[300px] sm:w-96 md:w-[250px]">
              <Input
                placeholder={"Search by ID, customer name"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="py-3 pl-10 pr-4 flex gap-3.5 rounded-[30px] w-full"
              />
              <Search className="absolute left-2 h-3.5" />
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button className="">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9384 23.1516C10.4584 23.1516 9.98844 23.0316 9.54844 22.7916C8.66844 22.3016 8.13844 21.4116 8.13844 20.4116V15.1116C8.13844 14.6116 7.80844 13.8616 7.49844 13.4816L3.75844 9.52156C3.12844 8.89156 2.64844 7.81156 2.64844 7.00156V4.70156C2.64844 3.10156 3.85844 1.85156 5.39844 1.85156H18.5984C20.1184 1.85156 21.3484 3.08156 21.3484 4.60156V6.80156C21.3484 7.85156 20.7184 9.04156 20.1284 9.63156L15.7984 13.4616C15.3784 13.8116 15.0484 14.5816 15.0484 15.2016V19.5016C15.0484 20.3916 14.4884 21.4216 13.7884 21.8416L12.4084 22.7316C11.9584 23.0116 11.4484 23.1516 10.9384 23.1516ZM5.39844 3.35156C4.69844 3.35156 4.14844 3.94156 4.14844 4.70156V7.00156C4.14844 7.37156 4.44844 8.09156 4.82844 8.47156L8.63844 12.4816C9.14844 13.1116 9.64844 14.1616 9.64844 15.1016V20.4016C9.64844 21.0516 10.0984 21.3716 10.2884 21.4716C10.7084 21.7016 11.2184 21.7016 11.6084 21.4616L12.9984 20.5716C13.2784 20.4016 13.5584 19.8616 13.5584 19.5016V15.2016C13.5584 14.1316 14.0784 12.9516 14.8284 12.3216L19.1084 8.53156C19.4484 8.19156 19.8584 7.38156 19.8584 6.79156V4.60156C19.8584 3.91156 19.2984 3.35156 18.6084 3.35156H5.39844Z"
                      fill="#165FE3"
                    />
                    <path
                      d="M6.00358 11.2473C5.86358 11.2473 5.73358 11.2073 5.60358 11.1373C5.25358 10.9173 5.14358 10.4473 5.36358 10.0973L10.2936 2.19733C10.5136 1.84733 10.9736 1.73733 11.3236 1.95733C11.6736 2.17733 11.7836 2.63733 11.5636 2.98733L6.63358 10.8873C6.49358 11.1173 6.25358 11.2473 6.00358 11.2473Z"
                      fill="#165FE3"
                    />
                  </svg>
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-56"
                onSelect={(e) => e.preventDefault()}
              >
                <DropdownMenuCheckboxItem
                  checked={showStatusBar || "indeterminate"}
                  onSelect={(e) => e.preventDefault()}
                  onCheckedChange={(val) => {
                    setShowStatusBar(val);
                    if (val) {
                      setActiveFilter([...activeFilter, "status"]);
                    } else {
                      setActiveFilter(
                        activeFilter.filter((filter) => filter !== "status"),
                      );
                      setStatusFilter([]);
                    }
                  }}
                  className="py-3.5"
                >
                  By Order status
                </DropdownMenuCheckboxItem>
                {!checkLevel(LEVEL_2) && (
                  <>
                    <DropdownMenuCheckboxItem
                      disabled={checkLevel(LEVEL_2)}
                      checked={showActivityBar || "indeterminate"}
                      onSelect={(e) => e.preventDefault()}
                      onCheckedChange={(val) => {
                        setShowActivityBar(val);
                        if (val) {
                          setActiveFilter([
                            ...activeFilter,
                            "service provider",
                          ]);
                        } else {
                          setActiveFilter(
                            activeFilter.filter(
                              (filter) => filter !== "service provider",
                            ),
                          );
                          setProductFilter([]);
                        }
                      }}
                      className="py-3.5 "
                    >
                      By Service provider
                    </DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem
                      checked={showPanel || "indeterminate"}
                      onSelect={(e) => e.preventDefault()}
                      onCheckedChange={(val) => {
                        setShowPanel(val);
                        if (val) {
                          setActiveFilter([...activeFilter, "channel"]);
                        } else {
                          setActiveFilter(
                            activeFilter.filter(
                              (filter) => filter !== "channel",
                            ),
                          );
                          setChannelFilter([]);
                        }
                      }}
                      className="py-3.5 "
                    >
                      By Channel
                    </DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem
                      checked={showPartnerBar || "indeterminate"}
                      onSelect={(e) => e.preventDefault()}
                      onCheckedChange={(val) => {
                        setShowPartnerBar(val);
                        if (val) {
                          setActiveFilter([...activeFilter, "partner"]);
                        } else {
                          setActiveFilter(
                            activeFilter.filter(
                              (filter) => filter !== "partner",
                            ),
                          );
                          setPartnerFilter([]);
                        }
                      }}
                      className="py-3.5"
                    >
                      By Partner name
                    </DropdownMenuCheckboxItem>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <div className="flex gap-5 items-center">
              {activeFilter.length > 0 &&
                activeFilter.map((filter, index) => {
                  console.log("print activeFilter", filter);
                  return (
                    <DropdownMenu key={index.toFixed(2)}>
                      <DropdownMenuTrigger className="z-50" asChild>
                        <button className="py-2 relative z-50 px-3.5 flex items-center gap-2 bg-[#E1EBFC] rounded-lg text-sm font-medium text-black-label">
                          {filter === "status" && statusFilter.length > 0 ? (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (filter === "status") {
                                  setStatusFilter([]);
                                  setShowStatusBar(false);
                                }
                                setActiveFilter(
                                  activeFilter.filter((f) => f !== filter),
                                );
                              }}
                              className="absolute right-0 -top-4 pointer-events-auto bg-[#E1EBFC]"
                              style={{ pointerEvents: "auto" }}
                            >
                              <svg
                                className=""
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                  fill="#165FE3"
                                />
                              </svg>
                            </button>
                          ) : filter === "service provider" &&
                            productFilter.length > 0 ? (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (filter === "service provider") {
                                  setProductFilter([]);
                                  setShowActivityBar(false);
                                }
                                setActiveFilter(
                                  activeFilter.filter((f) => f !== filter),
                                );
                              }}
                              className="absolute right-0 -top-4 pointer-events-auto"
                              style={{ pointerEvents: "auto" }}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                  fill="#165FE3"
                                />
                              </svg>
                            </button>
                          ) : filter === "channel" &&
                            channelFilter.length > 0 ? (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (filter === "channel") {
                                  setChannelFilter([]);
                                  setShowPanel(false);
                                }
                                setActiveFilter(
                                  activeFilter.filter((f) => f !== filter),
                                );
                              }}
                              className="absolute right-0 -top-4 pointer-events-auto"
                              style={{ pointerEvents: "auto" }}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                  fill="#165FE3"
                                />
                              </svg>
                            </button>
                          ) : filter === "partner" &&
                            partnerFilter &&
                            partnerFilter.length > 0 ? (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (filter === "partner") {
                                  setPartnerFilter([]);
                                  setShowPartnerBar(false);
                                }
                                setActiveFilter(
                                  activeFilter.filter((f) => f !== filter),
                                );
                              }}
                              className="absolute right-0 -top-4 pointer-events-auto"
                              style={{ pointerEvents: "auto" }}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                  fill="#165FE3"
                                />
                                <path
                                  d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                  fill="#165FE3"
                                />
                              </svg>
                            </button>
                          ) : null}
                          {filter === "status"
                            ? "Order status"
                            : filter === "channel"
                              ? "Channel"
                              : filter === "partner"
                                ? "By Partner name"
                                : "Service provider"}
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.6181 4.19035L6.81156 7.93679C6.72851 8.01895 6.61639 8.06504 6.49956 8.06504C6.38273 8.06504 6.27062 8.01895 6.18756 7.93679L2.381 4.19116C2.29743 4.10904 2.18495 4.06302 2.06778 4.06302C1.95061 4.06302 1.83813 4.10904 1.75456 4.19116C1.71341 4.23128 1.6807 4.27923 1.65837 4.33219C1.63604 4.38514 1.62454 4.44203 1.62454 4.49951C1.62454 4.55698 1.63604 4.61387 1.65837 4.66682C1.6807 4.71978 1.71341 4.76773 1.75456 4.80785L5.56031 8.55348C5.81098 8.7996 6.14825 8.9375 6.49956 8.9375C6.85087 8.9375 7.18814 8.7996 7.43881 8.55348L11.2446 4.80785C11.2858 4.76772 11.3186 4.71972 11.3411 4.66669C11.3635 4.61366 11.375 4.55667 11.375 4.4991C11.375 4.44153 11.3635 4.38455 11.3411 4.33151C11.3186 4.27848 11.2858 4.23048 11.2446 4.19035C11.161 4.10823 11.0485 4.06221 10.9313 4.06221C10.8142 4.06221 10.7017 4.10823 10.6181 4.19035Z"
                              fill="#333333"
                            />
                          </svg>
                        </button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        onSelect={(e) => e.preventDefault()}
                        className="w-56 max-h-80 overflow-y-auto"
                      >
                        <Accordion className="w-full" type="single" collapsible>
                          {(filter === "service provider"
                            ? serviceProviderOptions
                            : filter === "channel"
                              ? channelOptions
                              : filter === "partner"
                                ? partneroption
                                : filterOptions[filter]?.options || []
                          ).map((option) => (
                            <DropdownMenuCheckboxItem
                              key={option.value}
                              onSelect={(e) => e.preventDefault()}
                              checked={
                                (filter === "service provider" &&
                                  productFilter.includes(option.value)) ||
                                (filter === "channel" &&
                                  channelFilter.includes(option.value)) ||
                                (filter === "partner" &&
                                  partnerFilter.includes(option.value)) ||
                                (filter !== "service provider" &&
                                  filter !== "channel" &&
                                  filter !== "partner" &&
                                  statusFilter.includes(option.value)) ||
                                "indeterminate"
                              }
                              onCheckedChange={(val) => {
                                if (filter === "service provider") {
                                  setProductFilter(
                                    val
                                      ? [...productFilter, option.value]
                                      : productFilter.filter(
                                          (value) => value !== option.value,
                                        ),
                                  );
                                } else if (filter === "channel") {
                                  setChannelFilter(
                                    val
                                      ? [...channelFilter, option.value]
                                      : channelFilter.filter(
                                          (value) => value !== option.value,
                                        ),
                                  );
                                } else if (filter === "partner") {
                                  setPartnerFilter(
                                    val
                                      ? [...partnerFilter, option.value]
                                      : partnerFilter.filter(
                                          (value) => value !== option.value,
                                        ),
                                  );
                                } else {
                                  setStatusFilter(
                                    val
                                      ? [...statusFilter, option.value]
                                      : statusFilter.filter(
                                          (value) => value !== option.value,
                                        ),
                                  );
                                }
                              }}
                              className="py-3.5"
                            >
                              {option.label}
                            </DropdownMenuCheckboxItem>
                          ))}
                        </Accordion>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  );
                })}
            </div>
          </div>
          {!checkLevel(LEVEL_2) && (
            <Link to={"/createorder"}>
              <button className="rounded-lg py-[11px] w-[250p]x  px-4 border bg-blue-700 text-white-A700 font-semibold text-sm w-[150px]">
                Create Order
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className="flex-1 relative overflow-auto">
        {isLoading && <Spinner />}
        <DataTable
          onRowClick={handleAction}
          data={orders}
          columns={checkLevel(LEVEL_2) ? columnsL2 : columns}
          isToolbarVisible={false}
          searchData={{
            placeholder: "Search by ID, customer name",
            columns: ["id", "customerName"],
          }}
          onClickNextPage={() => setCurrentPage((page) => page + 1)}
          onClickPreviousPage={() => setCurrentPage((page) => page - 1)}
          totalPages={pageDetails?.pageTotal}
          currentPage={pageDetails?.curPage}
          canNextPage={!!pageDetails?.nextPage}
          canPreviousPage={!!pageDetails?.prevPage}
        />
      </div>
    </div>
  );
}
