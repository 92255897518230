import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AxiosRequestConfig } from "axios";
import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import { Spinner } from "components";
import { Option } from "components/Form/form";
import DocPopup from "pages/Create Opportunities/popup/docpopup";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import {
  channel_options_inbound,
  channel_options_outbound,
} from "pages/Opportunities/utils/featureRule";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetState,
  setMediumOfContact,
  setMeetingType,
  setProduct,
  setProductPurpose,
  setQuoteProduct,
  setQuotePurposeProduct,
} from "redux/features/CreateOpportunity";
import { setPurposeId } from "redux/features/CreateOrder";
import { setIsReferralMade } from "redux/features/CustomerRegistration";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getQuoteforCustomer,
  GetQuoteLeadRequestType,
} from "service/apis/CustomerDetailsapi";
import {
  CreateContactReqType,
  postExistingOpportunity,
} from "service/apis/Opportunities";
import { toast, Toaster } from "sonner";
import { createContactSteps, createLevelTwoContactSteps } from "utils";
import OtherDetails from "./L1Flow";
import { L1ProductgroupList } from "./NewFlow/ProductgroupList";
import { ProductListComponent } from "./NewFlow/ProductList";
import { ProductBasesCardComponent } from "./NewFlow/ProdcutbasesCard";
import { L1ModeStep } from "./NewFlow/ModeList";
import { L2EducationLoanStep3 } from "./NewFlow/Eductaionloan";

const productOptions: Option[] = [
  { value: "remittance", label: "Remittance" },
  { value: "forex", label: "Forex" },
  { value: "visa", label: "Visa" },
  { value: "travel", label: "Travel" },
  { value: "studyabroad", label: "Study Abroad" },
];

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

// remittence mode
const remitProductModeOptions: Option[] = [
  { value: "ViaTransfer", label: "Via Transfer" },
  { value: "Flywire", label: "Flywire" },
  { value: "WesternUnion", label: "Western Union" },
  { value: "CIBC", label: "CIBC" },
  { value: "PayMyTuition", label: "PayMyTuition" },
];

const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

const remittenceProductLoantakenOptions: Option[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

interface Step1Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
}
interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setProductMode: React.Dispatch<React.SetStateAction<string>>;
  selectProduct: string;
  productMode: string;
  connectedContactList: any[];
  setIsProductLoan: React.Dispatch<React.SetStateAction<string>>;
  isProductLoan: string;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

interface Step3Props {
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setProductMode: React.Dispatch<React.SetStateAction<string>>;
  selectProduct: string;
  productMode: string;
  connectedContactList: any[];
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

const defaultProductPurpose: {
  [key: string]: string;
} = {
  remittance: "17",
  forex: "16",
  visa: "21",
  travel: "12",
};

const productPurposeOptions: Record<string, Option[]> = {
  remittance: remitProductPurposeOptions,
  forex: forexProductPurposeOptions,
  visa: visaProductPurposeOptions,
  travel: travelProductPurposeOptions,
};

export const Step1Component: React.FC<Step1Props> = ({
  setCurrentStep,
  setSelectProduct,
  setProcessState,
}) => {
  const { product_purpose, quoteProduct } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const dispatch = useAppDispatch();

  const currentProductOptions = useMemo(() => {
    return (
      productPurposeOptions[
        quoteProduct as keyof typeof productPurposeOptions
      ] || []
    );
  }, [quoteProduct]);

  useEffect(() => {
    if (!quoteProduct) {
      dispatch(setQuoteProduct("remittance"));
    }
  }, [quoteProduct, dispatch]);

  // useEffect(() => {
  //   if (quoteProduct && !product_purpose) {
  //     const defaultPurpose = defaultProductPurpose[quoteProduct];
  //     if (defaultPurpose !== product_purpose) {
  //       dispatch(setProductPurpose(defaultPurpose));
  //     }
  //   }
  // }, [quoteProduct, product_purpose, dispatch]);

  const handleProductChoose = useCallback(
    (selectedValue: string) => {
      const selectedLabel = currentProductOptions.find(
        (tab: any) => tab.value === selectedValue,
      )?.label;
      if (selectedLabel) {
        dispatch(setProductPurpose(selectedValue));
        setSelectProduct(selectedLabel);
        dispatch(setQuotePurposeProduct(selectedLabel));
        setCurrentStep((prev) => prev + 1);
        setProcessState((prevState: any) => ({
          ...prevState,
          Product: selectedLabel,
        }));
      }
    },
    [dispatch, setSelectProduct, setCurrentStep, currentProductOptions],
  );

  return (
    <div className="flex flex-col gap-6 max-w-md">
      <form className="max-w-md">
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <Tabs
              className="flex rounded-lg gap-2"
              value={quoteProduct}
              onValueChange={async (tab) => {
                if (["remittance", "forex"].includes(tab)) {
                  dispatch(setQuoteProduct(tab));
                  dispatch(setProductPurpose(""));
                  dispatch(setProduct(tab));
                }
              }}
            >
              <TabsList className="sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                {productOptions?.map(
                  (tab: { label: string; value: string }, index) => (
                    <TabsTrigger
                      key={tab.value}
                      value={tab.value}
                      disabled={index > 1}
                      className={`py-1.5 px-[18px] text-[14px] h-[32px] font-normal rounded-lg 
                          ${tab.value === quoteProduct ? "bg-navbar-menu-bg text-blue-700" : "text-black-label bg-[#fff] border border-[#D7D7D7]"}
                          ${index > 1 ? "opacity-50 cursor-not-allowed" : ""}
                        `}
                    >
                      {tab.label}
                    </TabsTrigger>
                  ),
                )}
              </TabsList>
            </Tabs>
          </div>
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <label className={`text-[18px] text-[#33333] font-[500]`}>
              Select any one to proceed.
            </label>
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                onValueChange={handleProductChoose}
              >
                {currentProductOptions?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        product_purpose === tab.value
                          ? "border-blue-700"
                          : "border-black-label"
                      } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        id={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export const Step2Component: React.FC<Step2Props> = ({
  setCurrentStep,
  currentStep,
  setProductMode,
  selectProduct,
  productMode,
  connectedContactList,
  setIsProductLoan,
  isProductLoan,
  setProcessState,
  setPageTitle,
}) => {
  const { product_purpose, quoteProduct, product } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
      forex: forexProductPurposeOptions,
      visa: visaProductPurposeOptions,
      travel: travelProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === selectProduct,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleProductChoose = useCallback(
    (e: string) => {
      setProcessState((prevState: any) => ({
        ...prevState,
        Product: e,
      }));
      dispatch(setProductPurpose(e));
    },
    [dispatch],
  );

  const handleCardSelect = useCallback(
    (item: any) => setSelectedCardId(item.id),
    [],
  );

  const handleProductMode = useCallback(
    (value: string) => {
      setProductMode(value);
      setProcessState((prevState: any) => ({
        ...prevState,
        Mode: value,
      }));
    },
    [setProductMode],
  );

  const handleProductLoan = useCallback(
    (e: string) => {
      setIsProductLoan(e);
      setProcessState((prevState: any) => ({
        ...prevState,
        "Education Loan": e,
      }));
    },
    [setIsProductLoan],
  );

  const handlePrepareQuoteDirection = useCallback(
    (item: any, event: React.FormEvent) => {
      event.preventDefault();
      if (
        !productMode &&
        finalProductValue?.[0]?.label === "Education Payment"
      ) {
        toast.error("Please select Product Mode");
      } else if (quoteProduct === "remittance") {
        navigate("/L2CreateRemittanceQuotation", {
          state: {
            contact_name: item?._contacts?.contactName,
            product_id: product_purpose,
            product_name: item?._product?.name,
            opportunity_id: item?.id,
            contact_id: location?.state?.id?.toString(),
            channel_of_interaction_id:
              item?._channel_of_interaction?.[0]?.id?.toString(),
            note: "Prepare Quote",
            ed_payment_mode: productMode,
            loan: isProductLoan === "true" ? true : false,
            getQuoteFlow: "getQuoteremittanceFlow",
            purpose_id: item?.purposeDetail?.purpose_id,
          },
        });
      }
    },
    [
      navigate,
      quoteProduct,
      product_purpose,
      productMode,
      isProductLoan,
      location,
    ],
  );

  // Prepare quote for a new lead redirection
  const handlePrepareQuote = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (quoteProduct === "remittance") {
        dispatch(setIsReferralMade(false));
        if (!product) {
          dispatch(setProduct(quoteProduct));
        }
        setPageTitle("Other Details");
        setCurrentStep((prev) => prev + 1);
        // navigate("/lead/createlead", {
        //   state: {
        //     id: location?.state?.id,
        //     contact_name: location?.state?.contact_name,
        //     contact_number: location?.state?.contact_number,
        //     contact_email: location?.state?.contact_email,
        //     currentpath: "customerdetails",
        //     quoteProduct: quoteProduct,
        //     product_purpose: product_purpose,
        //     getQuoteFlow: "getQuoteremittanceFlow",
        //     purpose_id: null,
        //     selectedProductname: finalProductValue?.[0]?.label,
        //     mode: productMode,
        //     took_loan: isProductLoan === "true" ? true : false,
        //   },
        // });
      }
    },
    [
      navigate,
      quoteProduct,
      location,
      connectedContactList,
      isProductLoan,
      productMode,
      finalProductValue,
    ],
  );

  // state updated
  useEffect(() => {
    const productLabel = finalProductValue?.[0]?.label;
    const modeMapping: any = {
      "Gift / Family Maintenance": "Gift",
      "Study Maintenance": "StudyMaintenance",
    };

    if (productLabel !== "Education Payment") {
      const finalMode = modeMapping[productLabel] || productLabel;
      const finalLoan = ["Education Payment", "GIC"].includes(productLabel)
        ? isProductLoan
        : "false";
      if (productMode !== finalMode) {
        setProductMode(finalMode);
      }
      if (isProductLoan !== finalLoan) {
        setIsProductLoan(finalLoan);
      }
    }
  }, [finalProductValue, isProductLoan, productMode]);

  return (
    <div className="flex flex-col gap-6 max-w-[850px]">
      <form className="w-full">
        <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex items-end justify-between gap-10">
            <label className="text-[18px] font-medium text-[#333]">
              Select any one to proceed.
            </label>

            <button
              className="text-[#165FE3] text-[18px] font-bold underline"
              onClick={() => {
                setCurrentStep(currentStep - 1);
                setProcessState((prevState: any) => ({
                  ...prevState,
                  Product: "",
                  Mode: "",
                  "Education Loan": "",
                }));
              }}
            >
              Change
            </button>
          </div>

          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                onValueChange={handleProductChoose}
              >
                {finalProductValue?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        product_purpose === tab.value
                          ? " focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex w-[350px] items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>

          {/* Mode Selection for Remittance */}
          {quoteProduct === "remittance" &&
            finalProductValue?.[0]?.label === "Education Payment" && (
              <div>
                <label className="text-[14px] font-medium text-[#000]">
                  Mode
                </label>
                <RadioGroup
                  className="flex w-full flex-row gap-4 mt-2"
                  value={productMode}
                  onValueChange={handleProductMode}
                >
                  {remitProductModeOptions.map((tab) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        productMode === tab.value
                          ? "focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex w-auro items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
                    >
                      <RadioGroupItem
                        id={tab.value}
                        value={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ))}
                </RadioGroup>
              </div>
            )}
          {/* show here Loan about For Education and GIC */}
          {(finalProductValue?.[0]?.label === "Education Payment" ||
            finalProductValue?.[0]?.label === "GIC") && (
            <div>
              <label className="text-[14px] font-medium text-[#000]">
                Have you taken an education loan?
              </label>
              <RadioGroup
                className="flex w-full flex-row gap-4 mt-3"
                value={isProductLoan}
                onValueChange={handleProductLoan}
              >
                {remittenceProductLoantakenOptions?.map((tab) => (
                  <label
                    key={tab.value}
                    htmlFor={tab.value}
                    className={`${
                      isProductLoan === tab.value
                        ? "focus-within:border-blue-700 border-black-label"
                        : ""
                    } flex w-[250px] items-center rounded-lg border p-3 space-x-2 cursor-pointer`}
                  >
                    <RadioGroupItem
                      id={tab.value}
                      value={tab.value}
                      className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                    />
                    <p className="w-full">{tab.label}</p>
                  </label>
                ))}
              </RadioGroup>
            </div>
          )}

          {connectedContactList?.length > 0 && (
            <>
              <div>
                <label className="text-[14px] font-medium text-[#000]">
                  We found an open lead for {selectProduct}!
                </label>
              </div>
              {connectedContactList?.map((item: any) => {
                return (
                  <div
                    className={`flex flex-row w-[650px] justify-between p-5 mb-4 rounded-lg cursor-pointer ${
                      selectedCardId === item.id
                        ? "border-2 border-blue-500"
                        : "border-1 border-[#88AEF3]"
                    }`}
                    style={{
                      background:
                        "linear-gradient(89.99deg, #FFFFFF 0.01%, #E1EBFC 99.99%)",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleCardSelect(item)}
                    key={item?.id}
                  >
                    <div
                      className="flex flex-col gap-4"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <h2 className="text-left">
                        <Label className="text-[18px] ">
                          {item?._product?.name}
                        </Label>
                        <span className="text-[#999999] px-2">|</span>
                        <Label className="text-[14px] text-[#999999] leading-[140%]">
                          {item?.mode_opp || "N/A"}
                        </Label>
                      </h2>
                      <div className="flex flex-row  items-center gap-2">
                        <Label className="text-[#999999] font-noraml text-[14px]">
                          Lead ID: OP - {item?.id} |
                        </Label>
                        <Label className="text-[#999999] text-[14px] font-noraml">
                          {new Date(item?.created_at)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace(",", "  |")
                            .replace(/ /g, " - ")
                            .replace(" - |", " |")
                            .replace(/-\s/g, " ")
                            .toUpperCase()}
                        </Label>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src="/images/ProfileIConNew.svg" />
                        <Label className="text-[16px] font-normal">
                          {item?._contacts?.contactName || "N/A"}
                        </Label>
                      </div>
                    </div>
                    <div
                      className="flex flex-col"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                        style={{ borderRadius: "10px", textAlign: "center" }}
                      >
                        {" "}
                        {item?._contacts?.customer ? "Customer" : "Contact"}
                      </Label>
                      <button
                        className="text-[#165FE3] text-[14px] font-bold underline"
                        onClick={(e) => handlePrepareQuoteDirection(item, e)}
                      >
                        Prepare Quote
                      </button>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          <button
            className={`w-[350px] text-[14px] text-[#fff] p-3 ${
              (finalProductValue?.[0]?.label === "Education Payment" &&
                (!productMode || isProductLoan === "")) ||
              (finalProductValue?.[0]?.label === "GIC" && isProductLoan === "")
                ? "bg-blue-200"
                : "bg-[#165FE3]"
            } rounded-lg text-center`}
            onClick={(e) => handlePrepareQuote(e)}
            disabled={
              (finalProductValue?.[0]?.label === "Education Payment" &&
                (!productMode || isProductLoan === "")) ||
              (finalProductValue?.[0]?.label === "GIC" && isProductLoan === "")
            }
          >
            Prepare quote for a new lead
          </button>
        </div>
      </form>
    </div>
  );
};

export const Step3Component: React.FC<Step3Props> = ({
  setCurrentStep,
  currentStep,
  selectProduct,
  productMode,
  connectedContactList,
}) => {
  const { product, product_purpose, quoteProduct } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
      forex: forexProductPurposeOptions,
      visa: visaProductPurposeOptions,
      travel: travelProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === selectProduct,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleProductChoose = useCallback(
    (e: string) => dispatch(setProductPurpose(e)),
    [dispatch],
  );

  const handleCardSelect = useCallback(
    (item: any) => setSelectedCardId(item.id),
    [],
  );

  const handlePrepareQuoteDirection = useCallback(
    (item: any) => {
      if (quoteProduct === "forex") {
        navigate("/createforexquotation", {
          state: {
            contact_name: item?._contacts?.contactName,
            product_id: product_purpose,
            product_name: item?._product?.name,
            opportunity_id: item?.id,
            contact_id: location?.state?.id,
            channel_of_interaction_id: item?._channel_of_interaction?.[0]?.id,
            note: "Prepare Quote",
            remarks: "",
            getQuoteFlow: "getQuoteremittanceFlow",
            purpose_id: item?.purposeDetail?.purpose_id,
          },
        });
      }
    },
    [navigate, quoteProduct, product_purpose, productMode, location],
  );

  // Prepare quote for a new lead redirection
  const handlePrepareQuote = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (quoteProduct === "forex") {
        if (!product) {
          dispatch(setProduct(quoteProduct));
        }
        setCurrentStep((prev) => prev + 1);
        // navigate("/lead/createlead", {
        //   state: {
        //     id: location?.state?.id,
        //     contact_name: location?.state?.contact_name,
        //     contact_number: location?.state?.contact_number,
        //     contact_email: location?.state?.contact_email,
        //     quoteProduct: quoteProduct,
        //     product_purpose: product_purpose,
        //     currentpath: "customerdetails",
        //     getQuoteFlow: "getQuoteforexFlow",
        //     purpose_id: null,
        //     selectedProductname: finalProductValue?.[0]?.label,
        //     mode: "",
        //     took_loan: false,
        //   },
        // });
      }
    },
    [navigate, quoteProduct, location],
  );

  return (
    <form className="w-full">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
        <div className="flex items-end justify-between gap-10">
          <label className="text-[18px] font-medium  text-[#333]">
            Select any one to proceed.
          </label>

          <button
            className="text-[#165FE3] text-[18px] font-bold underline"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Change
          </button>
        </div>

        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          {quoteProduct && (
            <RadioGroup
              className="flex w-full flex-col gap-4"
              value={product_purpose}
              onValueChange={handleProductChoose}
            >
              {finalProductValue?.map(
                (tab: { label: string; value: string }) => (
                  <label
                    key={tab.value}
                    htmlFor={tab.value}
                    className={`${
                      product_purpose === tab.value
                        ? "focus-within:border-blue-700 border-black-label"
                        : ""
                    } flex w-auto items-center rounded-lg border p-3 space-x-2`}
                  >
                    <RadioGroupItem
                      value={tab.value}
                      className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4
                     checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500
                    dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                    />
                    <p className="text-[14px] font-medium w-full">
                      {tab.label}
                    </p>
                  </label>
                ),
              )}
            </RadioGroup>
          )}
        </div>

        {connectedContactList?.length > 0 && (
          <>
            <div>
              <label className="text-[14px] font-medium text-[#000]">
                We found an open lead for {selectProduct}!
              </label>
            </div>
            {connectedContactList?.map((item: any) => {
              return (
                <div
                  className={`flex flex-row justify-between p-5 mb-4 rounded-lg cursor-pointer ${
                    selectedCardId === item.id
                      ? "border-2 border-blue-500"
                      : "border-1 border-[#88AEF3]"
                  }`}
                  style={{
                    background:
                      "linear-gradient(89.99deg, #FFFFFF 0.01%, #E1EBFC 99.99%)",
                    justifyContent: "space-between",
                  }}
                  onClick={() => handleCardSelect(item)}
                  key={item?.id}
                >
                  <div
                    className="flex flex-col gap-4"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="flex flex-row  items-center gap-2">
                      <Label className="text-[#999999] font-noraml text-[14px]">
                        Lead ID: OP - {item?.id} |
                      </Label>
                      <Label className="text-[#999999] text-[14px] font-noraml">
                        {new Date(item?.created_at)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace(",", "  |")
                          .replace(/ /g, " - ")
                          .replace(" - |", " |")
                          .replace(/-\s/g, " ")
                          .toUpperCase()}
                      </Label>
                    </div>
                    <Label className="text-[18px]">
                      {item?._product?.name}
                    </Label>
                    <div className="flex flex-row items-center gap-2">
                      <img src="/images/ProfileIConNew.svg" />
                      <Label className="text-[16px] font-normal">
                        {item?._contacts?.contactName}
                      </Label>
                    </div>
                  </div>
                  <div
                    className="flex flex-col"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                      style={{ borderRadius: "10px", textAlign: "center" }}
                    >
                      {" "}
                      {item?._contacts?.customer ? "Customer" : "Contact"}
                    </Label>
                    <button
                      className="text-[#165FE3] text-[14px] font-bold underline"
                      disabled={!product_purpose}
                      onClick={() => handlePrepareQuoteDirection(item)}
                    >
                      Prepare Quote
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
        <button
          className={`w-[350px] text-[14px] text-[#fff] p-3 ${
            (finalProductValue?.[0]?.label === "Education Payment" &&
              !productMode) ||
            finalProductValue?.[0]?.label === "GIC"
              ? "bg-blue-200 cursor-not-allowed"
              : "bg-[#165FE3]"
          } rounded-lg text-center`}
          onClick={(e) => handlePrepareQuote(e)}
          disabled={
            (finalProductValue?.[0]?.label === "Education Payment" &&
              !productMode) ||
            finalProductValue?.[0]?.label === "GIC"
          }
        >
          Prepare Quote for a New Lead
        </button>
      </div>
    </form>
  );
};

export default function GetOuoteSteps() {
  const {
    product_purpose,
    assigned_to,
    remit_note,
    outlet_id,
    meeting_type,
    moneeflo_branch,
    product,
    who_contacted,
    medium_of_contact,
    pipelineType,
    refferal_source,
    partner_reffered,
    employee_reffered,
    contact_reffered,
    entityEmail,
    entityName,
    entityNumber,
    entityType,
    partner_name,
    company_address,
    company_email,
    company_name,
    contact_person_name,
    contact_person_phone,
    contact_person_type,
    doc_list,
    quoteProduct,
    product_loan_type,
    product_mode,
  } = useAppSelector((state) => state.CreateOpportunity);
  const isLevelTwo = checkLevel(LEVEL_2);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [channel, setChannel] = useState("whatsapp");
  const [expires, setExpires] = useState("1");
  const [popupNotes, setPopupNotes] = useState<string>("");
  const [selectedMode, setSelectedMode] = useState<string[]>([]);
  const { currentpath, getQuoteFlow }: any = location.state || {};
  const [productMode, setProductMode] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("Prepare Quote");
  const [selectProduct, setSelectProduct] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [connectedContactList, setConneectedList] = useState([]);
  const [isProductLoan, setIsProductLoan] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [processState, setProcessState] = useState<any>({
    "Product Group": "",
    Product: "",
    Mode: "",
    "Education Loan": "",
    Country: "",
  });

  const handleOtherPrevious = () => {
    if (currentStep === 1) return;
    if (
      processState?.Product === "Study Maintenance" ||
      processState?.Product === "Gift / Family Maintenance"
    ) {
      setCurrentStep(1);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
    setErrors({});
  };

  const handlePrevious = () => {
    if (currentStep === 1) return;
    if (!connectedContactList.length && currentStep === 4) {
      setCurrentStep(2);
    } else setCurrentStep((prev) => prev - 1);
    setErrors({});
  };

  useEffect(() => {
    dispatch(setProductPurpose(""));
    dispatch(setProduct(""));
    dispatch(setMediumOfContact(""));
    dispatch(setMeetingType(""));
  }, [location.pathname, dispatch]);

  const fetchGetQuoteDetails = useCallback(async () => {
    setLoading(true);
    try {
      const auth: string | null = localStorage.getItem("authToken");
      if (!auth) return;

      const authToken = JSON.parse(auth);
      const payload: AxiosRequestConfig<GetQuoteLeadRequestType> = {
        data: {
          product_id: product_purpose,
          contacts_id: location?.state?.id,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const res: any = await getQuoteforCustomer(payload);
      if (!res?.data || res?.data?.length === 0) {
        setConneectedList([]);
        return;
      } else {
        setConneectedList(res?.data);
      }
      return res?.data;
    } catch (err) {
      toast.error("Error fetching partner details");
    } finally {
      setLoading(false);
    }
  }, [product_purpose, location?.state?.id]);

  useEffect(() => {
    if (product_purpose && currentStep === 3) {
      fetchGetQuoteDetails();
    }
  }, [fetchGetQuoteDetails, product_purpose, currentStep]);

  const buttonState = () => {
    switch (currentStep) {
      case 1:
        if (pipelineType === "retail") {
          if (!product || !product_purpose) return false;
          return true;
        }
        return true;
      case 6:
        if (
          medium_of_contact === "refferal" &&
          refferal_source === "contacts" &&
          !contact_reffered
        )
          return false;
        if (!medium_of_contact || !who_contacted) return false;
        return true;
      default:
        return false;
    }
  };

  const channel_of_interaction_id =
    who_contacted === "customer" && medium_of_contact === "refferal"
      ? refferal_source === "contacts"
        ? "20"
        : refferal_source === "partner"
          ? "12"
          : refferal_source === "staff"
            ? "18"
            : ""
      : who_contacted === "myself"
        ? meeting_type === "whatsapp"
          ? "4"
          : meeting_type === "other"
            ? "14"
            : channel_options_inbound
                .find((item) => medium_of_contact === item.label.toLowerCase())
                ?.value.toString() ?? ""
        : meeting_type === "whatsapp"
          ? "8"
          : meeting_type === "other"
            ? "15"
            : meeting_type === "instagram"
              ? "10"
              : meeting_type === "facebook"
                ? "17"
                : meeting_type === "linkedin"
                  ? "16"
                  : channel_options_outbound
                      .find(
                        (item) =>
                          medium_of_contact === item.label.toLowerCase(),
                      )
                      ?.value.toString() ?? "";

  // final lead create
  const handleSubmit = async (type = "") => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const formData = new FormData();

    // for quotation_req
    let isquotation_req = false;
    if (isLevelTwo) {
      isquotation_req = true;
    }

    switch (pipelineType) {
      case "retail":
        formData.append("contact_id", state?.id as string);
        formData.append("typeOfRefferal", refferal_source);
        formData.append("refferedBy_staff", employee_reffered);
        formData.append("refferedBy_contacts", contact_reffered);
        formData.append(
          "refferedBy_partner",
          medium_of_contact === "refferal" ? partner_reffered : "",
        );
        formData.append("pipelineType", "retail");
        formData.append("forex_branch", moneeflo_branch);
        formData.append("outlet_branch", outlet_id);
        formData.append("exp_time", expires);
        formData.append("note", popupNotes);

        formData.append("quotation_req", JSON.stringify(isquotation_req));

        if (isProductLoan || productMode) {
          formData.append("took_loan", isProductLoan);
          formData.append("mode", productMode ?? "");
        } else if (location?.state) {
          formData.append(
            "took_loan",
            JSON.stringify(!!location?.state?.took_loan),
          );
          formData.append("mode", location?.state?.mode ?? "");
        }
        if (isLevelTwo) {
          for (let doc of doc_list) {
            const base64 = await convertFileToBase64(doc.document as File);
            formData.append(
              "doc_list[]",
              JSON.stringify({ filename: doc.id, file: base64 }),
            );
          }
        } else {
          selectedMode.forEach((mode) => {
            formData.append("doc_list[]", JSON.stringify({ filename: mode }));
          });
        }
        if (isLevelTwo) {
          formData.append("channel_of_interaction_id", "12");
        } else
          formData.append(
            "channel_of_interaction_id",
            channel_of_interaction_id,
          );
        break;
      case "corporate":
        formData.append("company_name", company_name);
        formData.append("company_email", company_email);
        formData.append("contactName", contact_person_name);
        formData.append("contactNumber", contact_person_phone);
        formData.append("typeOfContact", contact_person_type);
        formData.append("contactEmail", "bdbbh@gmail.com");
        formData.append("contactAddress", company_address);
        formData.append("pipelineType", "coorporate");
        break;
      case "partner":
        formData.append("contactName", entityName);
        formData.append("contactEmail", entityEmail);
        formData.append("contactNumber", entityNumber);
        formData.append("typeOfPartner", entityType);
        formData.append("typeOfContact", "partner");
        formData.append("pipelineType", "partner");
        break;
      default:
        break;
    }
    // product_list.forEach((product) => {
    formData.append("channel", channel);
    formData.append(
      "items[]",
      JSON.stringify({
        subject: "",
        remarks: "",
        notes: remit_note,
        assignedTo: assigned_to || localStorage.getItem("staff_id"),
        product_id: product_purpose || location?.state?.product_purpose,
      }),
    );
    // });
    const payload: AxiosRequestConfig<CreateContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: formData as any,
    };

    try {
      const res = (await postExistingOpportunity(payload)) as any;
      if (res.status === 200) {
        if (
          (res.data?.throwError as any)?.toLowerCase() ===
          "contact already exists"
        ) {
          toast.error("Contact already exists");
          return;
        }
        toast.success("Lead created successfully");
        setLoading(false);
        dispatch(setIsReferralMade(false));
        if (type === "prepareQuote") {
          dispatch(resetState());
          dispatch(setPurposeId(res?.data?.purposeID));
          handlePrepareQuote(
            res?.data?.response?.id as string,
            res?.data?.response.interactions_id?.[0] as string,
            res?.data?.purposeID as string,
          );
          return;
        }
      }
      if (getQuoteFlow === "getQuoteforexFlow") {
        navigate("/createforexquotation", {
          state: {
            contact_name: res?.data?.response?._contacts?.[0]?.contactName,
            product_id: res?.data?.response?.product_id,
            product_name: res?.data?.response?._product?.name,
            opportunity_id: res?.data?.response?.id,
            contact_id: res?.data?.response?.id?.contacts_id,
            channel_of_interaction_id:
              res?.data?.response?.channel_of_interaction_id,
            note: res?.data?.response?.interactions_id?.[0]?.[0]?.note,
            remarks: "",
            getQuoteFlow: getQuoteFlow,
            purpose_id: res?.data?.purposeID,
          },
        });
      } else if (getQuoteFlow === "getQuoteremittanceFlow") {
        dispatch(setPurposeId(res?.data?.purposeID));
        navigate("/L2CreateRemittanceQuotation", {
          state: {
            contact_name: res?.data?.response?._contacts?.[0]?.contactName,
            product_id: res?.data?.response?.product_id,
            product_name: res?.data?.response?._product?.name,
            opportunity_id: res?.data?.response?.id,
            contact_id: res?.data?.response?.id?.contacts_id,
            channel_of_interaction_id:
              res?.data?.response?.channel_of_interaction_id,
            note: res?.data?.response?.interactions_id?.[0]?.[0]?.note,
            remarks: "",
            ed_payment_mode: "",
            getQuoteFlow: getQuoteFlow,
            purpose_id: res?.data?.purposeID,
          },
        });
      } else {
        if (currentpath === "customerdetails") {
          navigate(-1);
        } else {
          navigate(`/leads/contact/${state?.id}`);
        }
      }
      dispatch(resetState());
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create Lead");
    }
  };

  const handleNext = () => {
    const maxSteps = isLevelTwo
      ? createLevelTwoContactSteps?.length
      : createContactSteps?.length;
    if (currentStep >= maxSteps) {
      handleSubmit();
      return;
    }
    if (true) {
      setCurrentStep((prev) => prev + 1);
      setErrors({});
    }
  };

  const handlePrepareQuote = (
    opp_id: string,
    interaction_id: string,
    purposeID: string,
  ) => {
    if (product === "remittance") {
      navigate("/L2CreateRemittanceQuotation", {
        state: {
          contact_name: state?.contact_name,
          contact_number: state?.contact_number,
          contact_email: state?.contact_email,
          product_id: product_purpose,
          product_name: "",
          opportunity_id: opp_id,
          interaction_id,
          remarks: "",
          meeting_time: "",
          meetingStaff_id: [],
          note: remit_note || "Quotation shared",
          channel_of_interaction_id: channel_of_interaction_id,
          partner_id:
            medium_of_contact === "refferal" && refferal_source === "partner"
              ? partner_reffered
              : "",
          partner_name: partner_name,
          contact_id: state?.id,
          getQuoteFlow: "prepareQuoteFlow",
          purpose_id: purposeID,
        },
      });
    }
    if (product === "forex") {
      navigate("/createforexquotation", {
        state: {
          contact_name: state?.contact_name,
          contact_number: state?.contact_number,
          contact_email: state?.contact_email,
          product_id: product_purpose,
          opportunity_id: opp_id,
          interaction_id,
          remarks: "",
          meeting_time: "",
          meetingStaff_id: [],
          note: remit_note || "Quotation shared",
          channel_of_interaction_id: channel_of_interaction_id,
          getQuoteFlow: "prepareQuoteFlow",
          purpose_id: purposeID,
        },
      });
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="sm:p-1 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
        <div className="gap-5 w-full flex h-full flex-col">
          <div className=" flex items-center w-full justify-between border-b gap-2 px-5 pt-2.5 pb-5">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">{pageTitle}</span>
            </h3>
            <AlertDialog>
              <AlertDialogTrigger>
                <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                  Cancel
                </button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                    <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                      Do you want to{" "}
                      <span className="font-semibold text-blue-700">
                        discard
                      </span>{" "}
                      the lead?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                      This action cannot be undone. This will permanently cancel
                      the ongoing progress.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                    <AlertDialogCancel
                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                      asChild
                    >
                      <button>Close</button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                      asChild
                    >
                      <button
                        onClick={() => {
                          dispatch(resetState());
                          if (
                            location?.state?.currentpath === "customerdetails"
                          ) {
                            navigate(-1);
                          } else if (
                            location?.state?.currentpath === "contactDetails"
                          ) {
                            navigate(
                              `/leads/contact/${location?.state?.contactID}`,
                            );
                          } else {
                            navigate("/lead/contacts");
                          }
                        }}
                      >
                        Confirm
                      </button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          </div>
          <div className="rounded-xl flex h-full gap-5 w-full sm:px-0 px-5 justify-between py-2.5 overflow-auto">
            <div className="flex relative flex-col gap-5 overflow-y-auto flex-grow">
              {/* product group list */}
              {currentStep === 1 && (
                <L1ProductgroupList
                  setCurrentStep={setCurrentStep}
                  setSelectProduct={setSelectProduct}
                  setProcessState={setProcessState}
                  setPageTitle={setPageTitle}
                />
              )}
              {/* product */}
              {currentStep === 2 && (
                <ProductListComponent
                  setCurrentStep={setCurrentStep}
                  setSelectProduct={setSelectProduct}
                  setProcessState={setProcessState}
                  setPageTitle={setPageTitle}
                />
              )}

              {/* product card */}
              {currentStep === 3 && quoteProduct === "remittance" && (
                <>
                  <ProductBasesCardComponent
                    setCurrentStep={setCurrentStep}
                    productMode={productMode}
                    currentStep={currentStep}
                    setProductMode={setProductMode}
                    selectProduct={selectProduct}
                    connectedContactList={connectedContactList}
                    setIsProductLoan={setIsProductLoan}
                    isProductLoan={isProductLoan}
                    setProcessState={setProcessState}
                    setSelectProduct={setSelectProduct}
                    processState={processState}
                    fetchGetQuoteDetails={fetchGetQuoteDetails}
                  />
                </>
              )}
              {/* product Mode */}
              {currentStep === 4 && (
                <L1ModeStep
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                  setPageTitle={setPageTitle}
                  setProcessState={setProcessState}
                  setProductMode={setProductMode}
                  processState={processState}
                  handlePrevious={handlePrevious}
                />
              )}

              {/* product loan */}
              {currentStep === 5 && (
                <L2EducationLoanStep3
                  setIsProductLoan={setIsProductLoan}
                  setCurrentStep={setCurrentStep}
                  setPageTitle={setPageTitle}
                  setProcessState={setProcessState}
                  isProductLoan={isProductLoan}
                  processState={processState}
                  productMode={productMode}
                  selectProduct={selectProduct}
                  connectedContactList={connectedContactList}
                />
              )}

              {/* other details */}
              {currentStep === 6 && (
                <>
                  <OtherDetails
                    errors={errors}
                    handlePrevious={handleOtherPrevious}
                  />
                  <div className="flex flex-row gap-8">
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          processState?.Product === "Study Maintenance" ||
                          processState?.Product ===
                            "Gift / Family Maintenance" ||
                          product === "forex"
                        ) {
                          setCurrentStep(1);
                        } else {
                          setCurrentStep((prev) => prev - 1);
                        }
                        setErrors({});
                      }}
                      className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
                    >
                      Previous
                    </button>
                    {["forex", "remittance"].includes(product) &&
                      currentStep === 6 && (
                        <button
                          onClick={() => handleSubmit("prepareQuote")}
                          disabled={!buttonState()}
                          className="px-4 py-[11px] text-sm font-semibold rounded-lg text-blue-700 border-blue-700 border disabled:opacity-40"
                        >
                          Prepare Quote
                        </button>
                      )}
                    <button
                      className="w-[150px] text-[14px] text-[#fff] p-3 bg-[#165FE3] rounded-lg text-center"
                      disabled={!buttonState()}
                      onClick={handleNext}
                    >
                      Submit
                    </button>
                    {currentStep === 6 &&
                      getQuoteFlow !== "getQuoteremittanceFlow" &&
                      getQuoteFlow !== "getQuoteforexFlow" &&
                      pipelineType === "retail" &&
                      product !== "remittance" && (
                        <DocPopup
                          buttonState={buttonState}
                          onSubmit={handleSubmit}
                          selectedMode={selectedMode}
                          setSelectedMode={setSelectedMode}
                          expires={expires}
                          setExpires={setExpires}
                          popupNotes={popupNotes}
                          setPopupNotes={setPopupNotes}
                          channel={channel}
                          setChannel={setChannel}
                        />
                      )}
                  </div>
                </>
              )}
            </div>

            {Object.keys(processState).some((key) => processState[key]) && (
              <div className="flex flex-col gap-5 sm:hidden w-[20%] overflow-y-auto">
                <h3 className="font-semibold text-lg text-black">
                  <span className="capitalize">You Selected</span>
                </h3>
                <div className="flex flex-col gap-4">
                  {Object.keys(processState)
                    .filter((key) => processState[key])
                    .map((key) => (
                      <div
                        key={key}
                        className="flex flex-col items-start gap-2"
                      >
                        <span className="text-[14px] font-semibold">{key}</span>
                        <h1 className="w-full bg-[#F9F9F9] text-[#333] text-[14px] font-medium py-1 px-2 rounded-md text-center">
                          {processState[key]}
                        </h1>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </>
  );
}
