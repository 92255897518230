import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Option } from "pages/Opportunities/types";
import { useFormContext } from "react-hook-form";
import { FormData } from ".";
const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];
export default function Step1() {
  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();
  return (
    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
      <p className={`text-[18px] font-medium text-[#333]`}>
        Select any one to proceed.
      </p>
      <RadioGroup
        className="flex w-full flex-col gap-4"
        value={watch("product")}
        onValueChange={(value) => {
          setValue("product", value);
          setValue("currentStep", 2);
        }}
      >
        {remitProductPurposeOptions?.map(
          (tab: { label: string; value: string }) => (
            <label
              key={tab?.value}
              htmlFor={tab?.value}
              className={`${
                watch("product") === tab?.value
                  ? "border-blue-700"
                  : "border-black-label"
              } flex w-auto items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value={tab?.value}
                id={tab?.value}
                className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p className="text-[14px] font-medium w-full">{tab?.label}</p>
            </label>
          ),
        )}
      </RadioGroup>
    </div>
  );
}
