import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { AxiosRequestConfig } from "axios";
import { Option } from "components/Form/form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setProductPurpose } from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  requestQuotationL2ToL1,
  RequestQuotationL2ToL1RequestType,
} from "service/apis/CreateForexQuotation";
import { toast } from "sonner";

interface Step2Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  setProductMode: React.Dispatch<React.SetStateAction<string>>;
  selectProduct: string;
  productMode: string;
  connectedContactList: any[];
  setIsProductLoan: React.Dispatch<React.SetStateAction<string>>;
  isProductLoan: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  isModeCondition: boolean;
  processState: any;
  setProcessState: React.Dispatch<React.SetStateAction<any>>;
  setISCardStep: React.Dispatch<React.SetStateAction<boolean>>;
  fetchGetQuoteDetails: () => Promise<any[]>;
}

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "30", label: "GIC" },
  { value: "20", label: "Gift / Family Maintenance" },
];

export const L2Step2CardComponent: React.FC<Step2Props> = ({
  setCurrentStep,
  currentStep,
  setProductMode,
  selectProduct,
  productMode,
  connectedContactList,
  setIsProductLoan,
  isProductLoan,
  processState,
  setProcessState,
  setISCardStep,
  setPageTitle,
  fetchGetQuoteDetails,
}) => {
  const { product_purpose, quoteProduct } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const productPurposeOptions = useMemo(
    () => ({
      remittance: remitProductPurposeOptions,
    }),
    [],
  );

  type ProductType = keyof typeof productPurposeOptions;

  const finalProductValue = useMemo(() => {
    return quoteProduct && quoteProduct in productPurposeOptions
      ? productPurposeOptions[quoteProduct as ProductType]?.filter(
          (item) => item.label === processState?.Product,
        )
      : [];
  }, [quoteProduct, selectProduct, productPurposeOptions]);

  const handleCardSelect = useCallback(
    (item: any) => setSelectedCardId(item.id),
    [],
  );

  useEffect(() => {
    if (fetchGetQuoteDetails) {
      fetchGetQuoteDetails().then((res) => {
        if (!res) {
          setCurrentStep(3);
          setISCardStep(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    setPageTitle("Select product");
    setISCardStep(true);
  }, [setPageTitle]);

  const handlePrepareQuoteDirection = useCallback(
    async (item: any, event: React.FormEvent) => {
      event.preventDefault();
      const auth = localStorage.getItem("authToken");
      if (auth === null) return;
      const authToken = JSON.parse(auth);
      const payload: AxiosRequestConfig<RequestQuotationL2ToL1RequestType> = {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          opportunity_id: item.id,
        },
      };
      try {
        const res = (await requestQuotationL2ToL1(payload)) as any;
        if (res.status === 200) {
          toast.success("Request sent for quotation");
          navigate(`/leads/contact/${item?.contacts_id}`);
        }
      } catch (error) {
        toast.error("Failed to send Request for Quotation");
      }
    },
    [
      navigate,
      quoteProduct,
      product_purpose,
      productMode,
      isProductLoan,
      location,
      finalProductValue,
      connectedContactList,
    ],
  );

  return (
    <>
      <form className="w-full">
        <div className="flex flex-col p-4 sm:pl-0 pl-10 gap-8 w-full ">
          <div className="flex relative flex-col gap-3 items-start justify-start w-[400px]">
            {quoteProduct && (
              <RadioGroup
                className="flex w-full flex-col gap-4"
                value={product_purpose}
                disabled={product_purpose}
              >
                {finalProductValue?.map(
                  (tab: { label: string; value: string }) => (
                    <label
                      key={tab.value}
                      htmlFor={tab.value}
                      className={`${
                        product_purpose === tab.value
                          ? " focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex w-auto items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="text-[14px] font-medium w-full">
                        {tab.label}
                      </p>
                    </label>
                  ),
                )}
              </RadioGroup>
            )}
          </div>
          {connectedContactList?.length > 0 && (
            <>
              <div>
                <label className="text-[14px] font-medium text-[#000]">
                  We found an open lead for {processState?.Product} !
                </label>
              </div>
              {connectedContactList?.map((item: any) => {
                return (
                  <button
                    type="button"
                    className={`flex flex-row justify-between p-5 w-[600px] mb-4 rounded-lg cursor-pointer ${
                      selectedCardId === item.id
                        ? "border-2 border-blue-500"
                        : "border-1 border-[#88AEF3]"
                    }`}
                    style={{
                      background:
                        "linear-gradient(89.99deg, #FFFFFF 0.01%, #E1EBFC 99.99%)",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleCardSelect(item)}
                    key={item?.id}
                  >
                    <div
                      className="flex flex-col gap-4"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <h2 className="text-left">
                        <Label className="text-[18px] ">
                          {item?._product?.name}
                        </Label>
                        <span className="text-[#999999] px-2">|</span>
                        <Label className="text-[14px] text-[#999999] leading-[140%]">
                          {item?.mode_opp || "N/A"}
                        </Label>
                      </h2>
                      <div className="flex flex-row  items-center gap-2">
                        <Label className="text-[#999999] font-noraml text-[14px]">
                          Lead ID: OP - {item?.id} |
                        </Label>
                        <Label className="text-[#999999] text-[14px] font-noraml">
                          {new Date(item?.created_at)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace(",", "  ")
                            .replace(/ /g, " - ")
                            .replace(" - |", " |")
                            .replace(/-\s/g, " ")
                            .toUpperCase()}
                        </Label>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src="/images/ProfileIConNew.svg" />
                        <Label className="text-[16px] font-normal">
                          {item?._contacts?.contactName}
                        </Label>
                      </div>
                    </div>
                    <div
                      className="flex flex-col"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        className="p-3 bg-[#E1FFE2] text-[#23AB28]"
                        style={{ borderRadius: "10px", textAlign: "center" }}
                      >
                        {" "}
                        {item?._contacts?.customer ? "Customer" : "Contact"}
                      </Label>
                      <div className="flex flex-row gap-8">
                        <button
                          type="button"
                          disabled={
                            !item?.interactions_id?.[
                              connectedContactList[0].interactions_id.length - 1
                            ]?.quotation_pdf
                          }
                          className={`text-[14px] font-bold underline ${
                            item?.interactions_id?.[
                              connectedContactList[0].interactions_id.length - 1
                            ]?.quotation_pdf
                              ? "text-[#165FE3] cursor-pointer"
                              : "text-[#A0C1E8] cursor-not-allowed"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            const pdfUrl =
                              item?.interactions_id?.[
                                connectedContactList[0].interactions_id.length -
                                  1
                              ]?.quotation_pdf;
                            if (pdfUrl) {
                              window.open(pdfUrl, "_blank");
                            }
                          }}
                        >
                          View Quote
                        </button>
                        <button
                          className="text-[#165FE3] text-[14px] font-bold underline"
                          onClick={(e) => handlePrepareQuoteDirection(item, e)}
                        >
                          Request Quote
                        </button>
                      </div>
                    </div>
                  </button>
                );
              })}
            </>
          )}
          <div className="flex flex-row gap-8">
            <button
              className="w-[100px] text-[14px] text-blue-500 p-3 bg-transparent border border-blue-500 rounded-lg flex items-center justify-center gap-2"
              onClick={() => {
                setCurrentStep(2), dispatch(setProductPurpose(""));
                setProcessState((prevState: any) => ({
                  ...prevState,
                  Product: "",
                }));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>
            <button
              className="w-[250px] text-[14px] text-[#fff] p-3 bg-[#165FE3] rounded-lg text-center"
              onClick={() => setCurrentStep(3)}
            >
              Request quote for a new lead
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
