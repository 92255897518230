import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Option } from "pages/Opportunities/types";
import { useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { CreatePartnerLeadType, services_options } from "./schema";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useEffect, useState } from "react";
import {
  getSiblingBranch,
  GetSiblingBranchRequestType,
} from "service/apis/BranchDetailsApi";
import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import {
  getRMByProductCategory,
  GetRMByProductCategoryRequestType,
} from "service/apis/PartnerRegistrationapi";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setAssignedTo } from "redux/features/CreateOpportunity";

const teamMemberOptions: Option[] = [
  { value: "kirti", label: "Kirti" },
  { value: "neha", label: "Neha" },
  { value: "shivani", label: "Shivani" },
];

export function isStep2Valid(
  services: string[],
  branch: string,
  assigned_to: string,
) {
  // Check if at least one service is selected
  if (!services || services.length === 0) return false;

  // Check if branch is selected
  if (!branch) return false;

  // Check if someone is assigned
  if (!assigned_to) return false;

  // All conditions met
  return true;
}

export default function Step2() {
  const methods = useFormContext<CreatePartnerLeadType>();
  const [assignedToOptions, setAssignedToOptions] = useState<Option[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [branches, setBranches] = useState([]);
  const { branch, services, assigned_to } = methods.watch();
  //   const {
  //     product_list,
  //     pipelineType,
  //     refferal_source,
  //     team_member,
  //     assigned_to,
  //     moneeflo_branch,
  //     product,
  //   } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedServices.length > 0) {
      fetchRelationShipManager();
    }
  }, [selectedServices]);

  async function fetchRelationShipManager() {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) {
      return;
    }
    const authToken = JSON.parse(auth);

    // Get all selected services
    const selectedServiceValues = services_options
      .filter((service) => services.includes(service.value))
      .map((service) => service.value);

    const req: AxiosRequestConfig<GetRMByProductCategoryRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        category: selectedServiceValues, // Now passing array of selected services
      },
    };

    try {
      const res = await getRMByProductCategory(req);

      if (!res?.data?.length) {
        setAssignedToOptions([]);
        return;
      }

      // Deduplicate RMs by ID to handle cases where an RM might be returned multiple times
      const uniqueRMs = Array.from(
        new Map(
          res.data.map((item) => [
            item.id,
            { value: item.id, label: item.fname },
          ]),
        ).values(),
      );

      const myself = localStorage.getItem("staff_id");

      // Move current user to top of list if present
      const index = uniqueRMs.findIndex((item) => item?.value === myself);
      if (index > 0) {
        const item = uniqueRMs.splice(index, 1)[0];
        uniqueRMs.unshift(item);
      }
      setAssignedToOptions(uniqueRMs);
    } catch (error) {
      setAssignedToOptions([]);
    }
  }

  const getBranch = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetSiblingBranchRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getSiblingBranch(payload)
      .then((res) => {
        setBranches(
          res?.data?.map((result) => ({
            value: result.id,
            label: result?.name,
          })),
        );
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    const storedBranch = localStorage.getItem("branch");
    const assignedToStaffID = Number(localStorage.getItem("staff_id"));

    if (storedBranch) {
      methods.setValue("branch", Number(storedBranch), {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    if (assignedToStaffID) {
      methods.setValue("assigned_to", assignedToStaffID, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    getBranch();
  }, []);

  return (
    <form className="max-w-sm">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${
              services.length ? "after:h-24 after:border-blue-700" : "after:h-0"
            }  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="customer_contact_phone"
            className={`text-sm ${
              !services.length ? "text-[#C4C4C4]" : "text-[#6B7280]"
            } `}
          >
            Select services
          </label>
          <ToggleGroup
            onValueChange={(value) => {
              methods.setValue("services", value, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
              // Update selected services when toggled
              setSelectedServices(value);
            }}
            value={services}
            type="multiple"
            className="sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap"
          >
            {services_options?.map((tab: { label: string; value: string }) => (
              <ToggleGroupItem
                key={tab.value}
                value={tab.value}
                className={`py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${
                  services.find((el) => el === tab.value)
                    ? "bg-navbar-menu-bg text-blue-700"
                    : "text-black-label bg-[#F1F1F1]"
                }`}
              >
                {tab.label}
              </ToggleGroupItem>
            ))}
          </ToggleGroup>
        </div>

        {/* Rest of the component remains the same */}
        {/* {services.length > 0 && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute ${
                branch ? "after:h-28 after:border-blue-700" : "after:h-0"
              } after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="entity_name" className="text-sm text-[#6B7280]">
              Select branch
            </label>
            <ReactSelect
              className="w-full"
              placeholder="Select the branch"
              menuPlacement="auto"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  zIndex: 0,
                  borderRadius: ".5rem",
                  padding: ".3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: state.isFocused ? "#165FE3" : "#333333",
                  boxShadow: "none",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000,
                }),
              }}
              isClearable
              isSearchable={true}
              options={branches}
              onChange={(e) => {
                if (e) {
                  methods.setValue("branch", e.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                } else {
                  methods.setValue("branch", "", { shouldValidate: true });
                }
              }}
              value={
                branches.find((b) => b.value === methods.watch("branch")) ??
                null
              }
              name="location"
              id="location"
            />
          </div>
        )} */}
        {services.length > 0 && branch && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute ${
                false ? "after:h-28 after:border-blue-700" : "after:h-0"
              } after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="entity_name" className="text-sm text-[#6B7280]">
              Assign the lead to
            </label>
            <ReactSelect
              className="w-full"
              placeholder="search and select the team member"
              menuPlacement="auto"
              menuPosition="fixed"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  zIndex: 0,
                  borderRadius: ".5rem",
                  padding: ".3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: state.isFocused ? "#165FE3" : "#333333",
                  boxShadow: "none",
                  height: "3rem",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000,
                }),
              }}
              defaultValue={teamMemberOptions[0]}
              value={
                assignedToOptions.find(
                  (item) =>
                    item.value === assigned_to ||
                    item.value === Number(localStorage.getItem("staff_id")),
                ) ?? null
              }
              onChange={(val) => {
                methods.setValue("assigned_to", val?.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
                dispatch(setAssignedTo(val?.value as string));
              }}
              options={assignedToOptions}
              name="assigned_to"
              id="assigned_to"
            />
          </div>
        )}
      </div>
    </form>
  );
}
