import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CustomerRegistrationStatetype = {
  currverifystep: number;
  email: string;
  phone: string;
  Panfront: File | null;
  PanfrontBinary: string;
  PanNumber: string;
  customers_id: string | number;
  adhaarFront: File | null;
  adhaarFrontBinary: string;
  adhaarBack: File | null;
  adhaarBackBinary: string;
  aadhar_number: string;
  Canceled_Cheque: File | null;
  Canceled_ChequeBinary: string;
  account_no: string;
  account_no_re: string;
  bank_name: string;
  irfc_code: string;
  passport_number: string;
  passport_place: string;
  passport_issueDate: string;
  passport_expiryDate: string;
  resume_registration: boolean;
  pan_name: string;
  aadhar_name: string;
  name_consent: boolean;
  isReferralMade: boolean;
  contact_id: string;
};

export const initialState: CustomerRegistrationStatetype = {
  currverifystep: 0,
  contact_id: "",
  email: "",
  phone: "",
  Panfront: null,
  PanfrontBinary: "",
  PanNumber: "",
  customers_id: "",
  adhaarFront: null,
  adhaarFrontBinary: "",
  adhaarBack: null,
  adhaarBackBinary: "",
  aadhar_number: "",
  Canceled_Cheque: null,
  Canceled_ChequeBinary: "",
  account_no: "",
  account_no_re: "",
  bank_name: "",
  irfc_code: "",
  passport_number: "",
  passport_place: "",
  passport_issueDate: "",
  passport_expiryDate: "",
  resume_registration: false,
  pan_name: "",
  aadhar_name: "",
  name_consent: false,
  isReferralMade: false,
};

export const CustomerRegistrationSlice = createSlice({
  name: "CustomerRegistration",
  initialState,
  reducers: {
    setCurrVerifyStep: (state, action: PayloadAction<number>) => {
      state.currverifystep = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setPanfront: (state, action: PayloadAction<File | null>) => {
      state.Panfront = action.payload;
    },
    setPanfrontBinary: (state, action: PayloadAction<string>) => {
      state.PanfrontBinary = action.payload;
    },
    setPanNumber: (state, action: PayloadAction<string>) => {
      state.PanNumber = action.payload;
    },
    setCustomersId: (state, action: PayloadAction<string | number>) => {
      state.customers_id = action.payload;
    },
    setadhaarFront: (state, action: PayloadAction<File | null>) => {
      state.adhaarFront = action.payload;
    },
    setadhaarFrontBinary: (state, action: PayloadAction<string>) => {
      state.adhaarFrontBinary = action.payload;
    },
    setadhaarBack: (state, action: PayloadAction<File | null>) => {
      state.adhaarBack = action.payload;
    },
    setadhaarBackBinary: (state, action: PayloadAction<string>) => {
      state.adhaarBackBinary = action.payload;
    },
    setAadharNumber: (state, action: PayloadAction<string>) => {
      state.aadhar_number = action.payload;
    },
    setCanceledCheque: (state, action: PayloadAction<File | null>) => {
      state.Canceled_Cheque = action.payload;
    },
    setCanceledChequeBinary: (state, action: PayloadAction<string>) => {
      state.Canceled_ChequeBinary = action.payload;
    },
    setAccountNo: (state, action: PayloadAction<string>) => {
      state.account_no = action.payload;
    },
    setAccountNoRe: (state, action: PayloadAction<string>) => {
      state.account_no_re = action.payload;
    },
    setBankName: (state, action: PayloadAction<string>) => {
      state.bank_name = action.payload;
    },
    setIrfcCode: (state, action: PayloadAction<string>) => {
      state.irfc_code = action.payload;
    },
    setPassportNumber: (state, action: PayloadAction<string>) => {
      state.passport_number = action.payload;
    },
    setPassportPlace: (state, action: PayloadAction<string>) => {
      state.passport_place = action.payload;
    },
    setPassportIssueDate: (state, action: PayloadAction<string>) => {
      state.passport_issueDate = action.payload;
    },
    setPassportExpiryDate: (state, action: PayloadAction<string>) => {
      state.passport_expiryDate = action.payload;
    },
    setResumeRegistration: (state, action: PayloadAction<boolean>) => {
      state.resume_registration = action.payload;
    },
    setPanName: (state, action: PayloadAction<string>) => {
      state.pan_name = action.payload;
    },
    setAadharName: (state, action: PayloadAction<string>) => {
      state.aadhar_name = action.payload;
    },
    setNameConsent: (state, action: PayloadAction<boolean>) => {
      state.name_consent = action.payload;
    },
    setIsReferralMade: (state, action: PayloadAction<boolean>) => {
      state.isReferralMade = action.payload;
    },
    setContactId: (state, action: PayloadAction<string>) => {
      state.contact_id = action.payload;
    },
  },
});

export const {
  setContactId,
  setCurrVerifyStep,
  setEmail,
  setPhone,
  setPanfront,
  setPanfrontBinary,
  setPanNumber,
  setCustomersId,
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setAadharNumber,
  setCanceledCheque,
  setCanceledChequeBinary,
  setAccountNo,
  setAccountNoRe,
  setBankName,
  setIrfcCode,
  setPassportNumber,
  setPassportPlace,
  setPassportIssueDate,
  setPassportExpiryDate,
  setResumeRegistration,
  setPanName,
  setAadharName,
  setNameConsent,
  setIsReferralMade,
} = CustomerRegistrationSlice.actions;
export default CustomerRegistrationSlice.reducer;
