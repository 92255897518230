import DashboardNavbar from "components/DashboardNavbar";
import { navLinks } from "pages/Opportunities";
import { Outlet } from "react-router-dom";

export default function HomeLayout({ name }: { name?: string }) {
  return (
    <div className="flex flex-col flex-grow h-full gap-2.5 font-inter">
      <DashboardNavbar routename={name} navLinks={navLinks} />
      <div className="flex-1 bg-white-A700 sm:flex-col overflow-hidden flex w-full rounded-xl p-3 border border-[#D9E0F7]">
        <Outlet />
      </div>
    </div>
  );
}
