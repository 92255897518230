import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Img, Spinner, Text } from "../../components";
import { useAppDispatch } from "redux/hooks";
import { Capitalize } from "utils";
import {
  setCurrVerifyStep,
  setEmail,
  setPhone,
  setPanfront,
  setPanfrontBinary,
  setPanNumber,
  setCustomersId,
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setAadharNumber,
  setCanceledCheque,
  setCanceledChequeBinary,
  setAccountNo,
  setAccountNoRe,
  setBankName,
  setIrfcCode,
} from "redux/features/CustomerRegistration";

const Step5: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      // Programmatically trigger the click event on the "Close" button
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    }, 3000); // 3 seconds
    return () => clearTimeout(timer);
  }, []);

  const handleonClose = () => {
    setLoading(true);
    dispatch(setCurrVerifyStep(1));
    dispatch(setEmail(""));
    dispatch(setPhone(""));
    dispatch(setPanfront(null));
    dispatch(setPanfrontBinary(""));
    dispatch(setPanNumber(""));
    dispatch(setCustomersId(""));
    dispatch(setadhaarFront(null));
    dispatch(setadhaarFrontBinary(""));
    dispatch(setadhaarBack(null));
    dispatch(setadhaarBackBinary(""));
    dispatch(setAadharNumber(""));
    dispatch(setCanceledCheque(null));
    dispatch(setCanceledChequeBinary(""));
    dispatch(setAccountNo(""));
    dispatch(setAccountNoRe(""));
    dispatch(setBankName(""));
    dispatch(setIrfcCode(""));
    setLoading(false);
    navigate("/dashboard");
  };
  const ApplicationId = localStorage.getItem("ApplicationId");
  const ApplicationStatus = localStorage.getItem("ApplicationStatus");

  return (
    <>
      {loading && <Spinner />}
      <section className="sm:flex w-full md:h-[85vh] lg:h-[85vh] overflow-y-scroll sm:w-full flex-col gap-8 flex py-3 sm:px-0 px-4">
        <div className=" flex flex-col py-5 px-8 w-1/2 gap-5 border border-white-A700 rounded-xl shadow-deal-success">
          <div className="flex justify-between w-full">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold leading-[22px] text-blue-700">
                  New customer is created
                </h3>
                <p className="text-sm font-normal leading-[22px] text-[#7e7e7e]">
                  We have successfully registered the new customer.
                </p>
              </div>
              <span className="border border-blue-700 rounded-lg py-2 px-4">
                Your Customer ID will be{" "}
                <span className=" text-blue-700">
                  CU-{ApplicationId && ApplicationId}
                </span>
              </span>
            </div>
            <div className="flex flex-col justify-between">
              <Img src="/images/overview/success.svg" />
              <p className="text-green-parrot">Success</p>
            </div>
          </div>
          {/* <div className=" rounded-lg py-5 items-center justify-between flex gap-2.5">
          <div className="">
            <span>Partner name: </span>
            <span className="font-semibold text-base leading-4"> Name</span>
          </div>
          <div className="">
            <span>Partner Type: </span>
            <span className="font-semibold text-base leading-4"> Name</span>
          </div>
        </div> */}
        </div>
        <button className="py-[11px] px-4 rounded-lg bg-blue-700 text-white-A700 w-fit">
          <Link to="/lead/contacts" className="text-white-A700">
            Go back to customers
          </Link>
        </button>
      </section>
    </>
  );
};

export default Step5;
