import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import { resetState } from "redux/features/CreateOpportunity";
import { Toaster } from "sonner";
import PrepareOuoteL1FlowSteps from "./L1Flow";
import { useAppDispatch } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PrepareOuoteL2FlowSteps from "./L2Flow";

export default function PrepareOuoteSteps() {
  const CheckFlow = checkLevel(LEVEL_2);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState<string>("Prepare Quote");

  return (
    <div className="sm:p-1 flex flex-col bg-[#EBEEF5] gap-2.5 font-inter no_border items-start justify-start overflow-hidden mx-auto w-full">
      <div className=" flex items-center bg-white-A700 w-full justify-between rounded-lg gap-2 px-5 pt-2.5 pb-5">
        <h3 className=" font-semibold leading-4 text-lg text-black-label">
          <span className="capitalize">{pageTitle}</span>
        </h3>
        <AlertDialog>
          <AlertDialogTrigger>
            <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
              Cancel
            </button>
          </AlertDialogTrigger>
          <AlertDialogPortal>
            <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
            <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
              <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                  Do you want to{" "}
                  <span className="font-semibold text-blue-700">discard</span>{" "}
                  the lead?
                </AlertDialogTitle>
                <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                  This action cannot be undone. This will permanently cancel the
                  ongoing progress.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel
                  className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                  asChild
                >
                  <button>Close</button>
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  asChild
                >
                  <button
                    onClick={() => {
                      dispatch(resetState());
                      navigate("/dashboard");
                    }}
                  >
                    Confirm
                  </button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
      <div className=" w-full bg-white-A700 flex h-full flex-col">
        {CheckFlow ? (
          <PrepareOuoteL2FlowSteps setPageTitle={setPageTitle} />
        ) : (
          <PrepareOuoteL1FlowSteps setPageTitle={setPageTitle} />
        )}
      </div>
      <Toaster theme="dark" position="top-right" />
    </div>
  );
}
