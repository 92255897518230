import { LeadContactType } from "@/lib/validations/schema";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ColumnDef } from "@tanstack/react-table";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Toaster } from "sonner";
import { AxiosRequestConfig } from "axios";
import {
  searchContact,
  searchContact2,
  SearchContactReqType,
} from "service/apis/Opportunities";
import { contact_lead_status } from "common/filters";
import {
  getCustomerContacts,
  GetCustomerContactsRequestType,
} from "service/apis/CustomerDetailsapi";
import NewCustomersPage from "pages/Customers/newcustomer";
import { IPage } from "./partner_contact";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";

const allcolumns: ColumnDef<LeadContactType>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    enableSorting: false,
    filterFn: "includesString",
  },
  {
    id: "name",
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    id: "mobile",
    accessorKey: "mobile",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Mobile"
      />
    ),
    cell: ({ row }) => {
      return <p className=" text-center w-full">{row.getValue("mobile")}</p>;
    },
    enableSorting: false,
  },
  {
    id: "contactStatus",
    accessorKey: "contactStatus",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Status"
      />
    ),
    cell: ({ row }) => {
      const status = contact_status.find(
        (status) => status.value === row.getValue("contactStatus"),
      );

      if (!status) {
        return null;
      }

      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 text-center font-medium max-w-[160px]  px-2 mx-auto rounded-md"
        >
          {status.label}
        </p>
      );
    },
    enableSorting: false,
  },
];

const tabData = [
  { label: "All", value: "all", enabled: true },
  { label: "Customers", value: "customers", enabled: true },
];

interface LeadContactsProps {
  id: string;
  name: string;
  mobile: string;
  contactStatus: string;
}

const contact_status = [
  {
    value: "customer",
    label: "Customer",
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "contact",
    label: "Contact",
    textColor: "#9a9a9a",
    bgColor: "#e5e5e5",
  },
];

export default function LeadContacts() {
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<LeadContactsProps[]>([]);
  const [activeTab, setTab] = useState("all");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageDetails, setPageDetails] = useState<IPage>();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");

  const onRowClick = (row: any) => {
    if (activeTab === "customers") return;
    if (!row?.link_customer) {
      navigate(`/leads/contact/${row?.id}`);
    } else {
      navigate(`/customerdetails/${row?.link_customer}`);
    }
  };

  const fetchCustomerContacts = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetCustomerContactsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    try {
      const response = await getCustomerContacts(payload);
      if (response?.data?.length === 0) return;
      const newOptions = response?.data?.map((item) => {
        return {
          id: item?.id,
          name: item?.full_name,
          mobile: item?.phone_number,
          contactStatus: item?.status,
          city: item?.city,
          state: item?.state,
          email: item?.email,
        };
      });
      setContacts(
        newOptions?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            mobile: item?.mobile,
            contactStatus: item?.contactStatus,
          };
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchContacts = async (
    searchQuery = "",
    page: number,
    per_page: number,
  ) => {
    const auth = localStorage.getItem("authToken");
    if (!auth) return;

    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<SearchContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        type: "retail",
        searchQuery: searchQuery,
        paging: {
          page: page,
          per_page: per_page,
          offset: 0,
        },
      },
    };
    setLoading(true);
    try {
      const response = (await searchContact2(payload)) as any;
      if (!response?.data?.items?.length) {
        setContacts([]);
        setLoading(false);
        return;
      }

      // for new changes bases
      const newOptions: LeadContactsProps[] = response?.data?.items?.map(
        (item: any) => ({
          id: item?.id,
          name: item?.contactName,
          mobile: item?.contactNumber,
          contactStatus:
            item?.linked_customer_id && item?.customer ? "customer" : "contact",
          isCustomer: item?.customer,
          link_customer: item?.linked_customer_id,
        }),
      );

      setContacts(newOptions);
      setPageDetails({
        pageTotal: response?.data?.pageTotal,
        curPage: response?.data?.curPage,
        itemsReceived: response?.data?.itemsReceived,
        itemsTotal: response?.data?.itemsTotal,
        nextPage: response?.data?.nextPage,
        offset: response?.data?.offset,
        perPage: response?.data?.perPage,
        prevPage: response?.data?.prevPage,
      });
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false);
    }
  };

  useDebounce(() => fetchContacts(search, page, perPage), 500, [
    search,
    page,
    perPage,
  ]);

  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-10 py-5 px-6 sm:px-0 w-full flex-col overflow-hidden">
        <div className=" flex border-b w-full justify-between items-center">
          <div className=" flex pb-4 flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">
                {activeTab === "all" ? "Contacts" : "Customers"}
              </span>
            </h3>
          </div>
        </div>
        <div className="flex gap-5 justify-between w-full items-center">
          <Tabs
            className="bg-navbar-menu-bg w-fit flex rounded-lg gap-2"
            value={activeTab}
            onValueChange={(val) => {
              setTab(val);
            }}
          >
            <TabsList className="h-[44px]">
              {tabData
                .filter((tab) => true)
                .map((tab, index: number) => (
                  <TabsTrigger
                    key={index}
                    value={tab.value}
                    disabled={!tab.enabled}
                    className={`py-3 px-10 disabled:opacity-40 text-sm h-[44px] min-w-[150px] font-semibold ${tab.value === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
            </TabsList>
          </Tabs>

          {activeTab !== "all" && !checkLevel(LEVEL_2) && (
            <Link className="sm:hidden" to={"/customerregistration"}>
              <button
                disabled={checkLevel(LEVEL_2)}
                className="rounded-lg py-[11px] px-4 disabled:opacity-40 border bg-blue-700 text-white-A700 font-semibold text-sm"
              >
                Register customer
              </button>
            </Link>
          )}
        </div>
        <div className="flex gap-5 justify-between w-full items-center">
          {activeTab != "customers" && (
            <div className="relative flex items-center w-[500px]">
              <Input
                placeholder={"Search by name or mobile number"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="py-3 pl-10 pr-4 flex gap-3.5 rounded-[30px] w-full"
              />
              <Search className="absolute left-2 h-3.5" />
            </div>
          )}
          {activeTab === "all" && (
            <Link className="sm:hidden" to={"/lead/createcontact"}>
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                + Add Contact
              </button>
            </Link>
          )}
        </div>

        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          {activeTab === "customers" ? (
            <NewCustomersPage />
          ) : (
            <DataTable
              columns={allcolumns}
              data={contacts}
              onRowClick={(row) => onRowClick(row)}
              isToolbarVisible={false}
              isPaginationVisible={true}
              // searchData={{
              //   placeholder: "Search by name or mobile number",
              //   columns: ["name", "mobile"],
              // }}
              isPageCountVisible={false}
              defaultSorting={[{ id: "id", desc: true }]}
              onClickNextPage={() => setPage((page) => page + 1)}
              onClickPreviousPage={() => setPage((page) => page - 1)}
              onGotoPage={(page) => setPage(page)}
              totalPages={pageDetails?.pageTotal}
              currentPage={pageDetails?.curPage}
              canNextPage={!!pageDetails?.nextPage}
              canPreviousPage={!!pageDetails?.prevPage}
            />
          )}
        </div>
        {activeTab === "all" ? (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/lead/createcontact"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              + Add Contact
            </button>
          </Link>
        ) : (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/customerregistration"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              Register customer
            </button>
          </Link>
        )}
        <Toaster position="top-right" />
      </div>
    </>
  );
}
